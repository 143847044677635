import * as yup from 'yup';

export const wizardAboutDeceasedFormValidation = () =>
    yup.object({
        name: yup.string().required(),
        birthDate: yup.date().nullable(),
        deathDate: yup.date().nullable(),
        birthPlace: yup.string(),
        deathPlace: yup.string(),
        termsAccepted: yup
            .boolean()
            .required()
            .oneOf([true], 'errors.required'),
    });
