import { EventType } from '../../../interfaces/event-type';
import { IIncluded } from '../../../utils/included';

export interface IEventResponseData {
    id: string;
    type: 'event';
    attributes: {
        startTimestamp: string | null;
        endTimestamp: null;
        title: string;
        description: string;
        schedulableType: EventType;
        timeZone: string;
        sourceApp?: SourceApp;
    };
    relationships: {
        schedulable: {
            data: {
                id: string;
                type: 'ceremony' | 'reception' | 'custom_event';
            };
        };
    };
}

export interface ICeremonyAttributes {
    funeralHomeNotes?: string | null;
    isPrivate?: boolean | null;
    publicMessageForPrivateCeremony?: string | null;
}

interface ICeremonyRelationships {
    location: {
        data: {
            id: string;
            type: 'location';
        };
    };
    event: {
        data: {
            id: string;
            type: 'event';
        };
    };
}

type ISchedulableInclusion = IIncluded<
    'ceremony' | 'reception' | 'custom_event',
    ICeremonyAttributes,
    ICeremonyRelationships
>;

export interface ILocationAttributes {
    name: string | null;
    lat: string | null;
    lon: string | null;
    street: string;
    buildingNo: string | null;
    flatNo: string | null;
    floor: string | null;
    zipCode: string;
    city: string;
    state: string | null;
    countryId: string | null;
    municipality: string | null;
}

type LocationInclusion = IIncluded<'location', ILocationAttributes>;

export type ICeremony = ISchedulableInclusion | LocationInclusion;

export interface IEventResponseDto {
    data: IEventResponseData | IEventResponseData[];
    included: Array<ICeremony>;
}

// If this is altered, partner app also has to be altered
export enum SourceApp {
    NotifyEditor = 'notify_editor',
    Partner = 'partner',
}
