import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getDeceasedData } from '../model/deceased-person/selectors/get-deceased-data.selector';
import { getObituaryText } from '../model/obituary/selectors/get-obituary-text-selector';
import { getGeneratedObituary } from '../model/obituary/selectors/get-generated-obituary.selector';
import { getObituaryStatus } from '../model/obituary/selectors/get-obituary-status.selector';
import { getObituaryStatusMessage } from '../model/obituary/selectors/get-obituary-status-message.selector';
import { getObituaryLoading } from '../model/obituary/selectors/get-obituary-loading.selector';

import {
    generateObituaryRequested,
    resetObituaryStore,
    updateObituaryRequested,
} from './model/obituary.actions';
import { ObituaryFormValues } from './model/obituary.types';

export const useObituaryScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetObituaryStore());
        };
    }, [dispatch]);

    const deceased = useSelector(getDeceasedData);

    const obituaryText = useSelector(getObituaryText);
    const generatedObituary = useSelector(getGeneratedObituary);

    const obituaryStatus = useSelector(getObituaryStatus);
    const obituaryStatusMessage = useSelector(getObituaryStatusMessage);
    const obituaryLoading = useSelector(getObituaryLoading);

    const onSubmit = (values: ObituaryFormValues) => {
        dispatch(updateObituaryRequested(values));
    };

    const onGenerate = (values: ObituaryFormValues) => {
        dispatch(generateObituaryRequested(values));
    };

    return {
        deceased,
        obituaryText,
        generatedObituary,
        obituaryStatus,
        obituaryStatusMessage,
        obituaryLoading,
        onSubmit,
        onGenerate,
    };
};
