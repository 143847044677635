import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    addNotificationContactApiActions,
    sendNotificationsToContactsApiActions,
    updateNotificationMessageApiActions,
    fetchNotificationContactsApiActions,
    rejectSuggestedContactApiActions,
    deleteContactApiActions,
} from '../contacts-api.actions';

import { updateNotificationMessageSaga } from './update-notification-message.saga';
import { addNotificationContactSaga } from './add-notification-contact.saga';
import { sendNotificationsSaga } from './send-notifications.saga';
import { fetchNotificationContactsSaga } from './fetch-notification-contacts.saga';
import { rejectSuggestedContactApiSaga } from './reject-suggested-contact.saga';
import { deleteContactApiSaga } from './delete-contact.saga';

export function* contactsApiSaga() {
    yield takeEvery(
        getType(updateNotificationMessageApiActions.request),
        updateNotificationMessageSaga,
    );

    yield takeEvery(
        getType(addNotificationContactApiActions.request),
        addNotificationContactSaga,
    );

    yield takeEvery(
        getType(sendNotificationsToContactsApiActions.request),
        sendNotificationsSaga,
    );

    yield takeEvery(
        getType(fetchNotificationContactsApiActions.request),
        fetchNotificationContactsSaga,
    );

    yield takeEvery(
        getType(rejectSuggestedContactApiActions.request),
        rejectSuggestedContactApiSaga,
    );

    yield takeEvery(
        getType(deleteContactApiActions.request),
        deleteContactApiSaga,
    );
}
