import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import cx from 'classnames';

import { BlueHeadline } from '../../../components/blue-headline/blue-headline';
import { Colors } from '../../../../styles/colors';
import { hexToRgba } from '../../../utils/hex-to-rgba';

export interface ISectionProps {
    children?: React.ReactNode;
    className?: string;
    description?: React.ReactNode;
    title?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `0 2px 6px 0 ${hexToRgba(Colors.TOREA_BAY, 0.09)}`,
        padding: theme.spacing(5, 10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
    description: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
}));

export const Section = ({
    children,
    className,
    description,
    title,
}: ISectionProps) => {
    const styles = useStyles();

    return (
        <Paper
            data-testid={`section-${
                title
                    ? title
                          .toString()
                          .trim()
                          .replace(/\s/g, '-')
                          .toLowerCase()
                    : 'preview'
            }`}
            elevation={1}
            className={cx(styles.root, className)}
        >
            {title && <BlueHeadline>{title}</BlueHeadline>}
            {description && (
                <Typography className={styles.description}>
                    {description}
                </Typography>
            )}
            {children}
        </Paper>
    );
};
