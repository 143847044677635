import { createReducer } from 'typesafe-actions';

import { IMilestone } from '../../interfaces/milestone';
import {
    createMilestoneApiActions,
    deleteMilestoneApiActions,
    fetchMilestonesApiActions,
    updateMilestoneApiActions,
} from '../../api/milestones/milestones-api.actions';

export interface IMilestonesSectionStore {
    milestones: IMilestone[];
}

const initialState: IMilestonesSectionStore = {
    milestones: [],
};

type Actions =
    | ReturnType<typeof createMilestoneApiActions.success>
    | ReturnType<typeof updateMilestoneApiActions.success>
    | ReturnType<typeof deleteMilestoneApiActions.success>
    | ReturnType<typeof fetchMilestonesApiActions.success>;

export const milestonesReducer = createReducer<
    IMilestonesSectionStore,
    Actions
>(initialState)
    .handleAction(fetchMilestonesApiActions.success, (state, action) => {
        return {
            ...state,
            milestones: action.payload,
        };
    })
    .handleAction(createMilestoneApiActions.success, (state, action) => {
        return {
            ...state,
            milestones: [...state.milestones, action.payload],
        };
    })
    .handleAction(updateMilestoneApiActions.success, (state, action) => {
        return {
            ...state,
            milestones: state.milestones.map((milestone) => {
                if (milestone.id === action.payload.id) {
                    return action.payload;
                }

                return milestone;
            }),
        };
    })
    .handleAction(deleteMilestoneApiActions.success, (state, action) => {
        return {
            ...state,
            milestones: state.milestones.filter(
                (milestone) => milestone.id !== action.payload.id,
            ),
        };
    });
