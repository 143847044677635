import { put, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getOverviewTasksAsyncActions } from '../overview-tasks.actions';
import { fetchTasksApiActions } from '../../../../../api/tasks/api-tasks.actions';
import { TasksSelectors } from '../../../../../model/tasks/selectors/tasks.selectors';

export function* fetchOverviewTasksSaga() {
    const tasks = yield select(TasksSelectors.getTasks);

    if (tasks.length > 0) {
        yield put(getOverviewTasksAsyncActions.success(tasks));
        return;
    }

    yield put(fetchTasksApiActions.request());

    const responseAction:
        | ReturnType<typeof fetchTasksApiActions.success>
        | ReturnType<typeof fetchTasksApiActions.failure> = yield take([
        fetchTasksApiActions.success,
        fetchTasksApiActions.failure,
    ]);

    switch (responseAction.type) {
        case getType(fetchTasksApiActions.success):
            yield put(
                getOverviewTasksAsyncActions.success(responseAction.payload),
            );
            break;
        case getType(fetchTasksApiActions.failure):
            yield put(
                getOverviewTasksAsyncActions.failure(responseAction.payload),
            );
            break;
        default:
            yield;
    }
}
