import { SagaMiddleware } from 'redux-saga';

import { wizardSaga } from '../wizard/wizard.saga';
import { editorSaga } from '../editor/editor.saga';
import { apiSaga } from '../api/api.saga';
import { authSaga } from '../auth/auth.saga';
import { sharingSaga } from '../sharing/sharing.saga';
import { pageMetaSaga } from '../meta/model/saga/page-meta.saga';
import { condolencesSaga } from '../model/condolences/saga/condolences.saga';
import { invitationsSaga } from '../model/invitations/saga/invitations.saga';
import { suggestionsSaga } from '../model/suggestions/saga/suggestions.saga';
import { gallerySuggestionsSaga } from '../model/gallery-suggestions/saga/gallery-suggestions.saga';
import { configurationSaga } from '../model/configuration/saga/configuration.saga';
import { overviewSaga } from '../model/overview/saga/overview.saga';

export const runSagas = (middleware: SagaMiddleware) => {
    middleware.run(wizardSaga);
    middleware.run(editorSaga);
    middleware.run(apiSaga);
    middleware.run(authSaga);
    middleware.run(sharingSaga);
    middleware.run(pageMetaSaga);
    middleware.run(condolencesSaga);
    middleware.run(invitationsSaga);
    middleware.run(suggestionsSaga);
    middleware.run(gallerySuggestionsSaga);
    middleware.run(configurationSaga);
    middleware.run(overviewSaga);
};
