import cx from 'classnames';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import { compose, mapProps } from 'recompose';
import { ButtonProps } from '@material-ui/core/Button';

import { Colors } from '../../../styles/colors';

export const DangerButton = compose<ButtonProps, ButtonProps>(
    withStyles((theme) => ({
        button: {
            boxShadow: 'none',
            color: theme.palette.common.white,
            backgroundColor: Colors.VALENCIA,
            '&:hover, &:focus, &:active': {
                color: theme.palette.common.white,
                backgroundColor: Colors.VALENCIA,
            },
        },
    })),
    mapProps<ButtonProps, ButtonProps & WithStyles>(
        ({ classes, className, ...props }) => ({
            size: 'large',
            color: 'primary',
            variant: 'contained',
            className: cx(classes.button, className),
            ...props,
        }),
    ),
)(Button);
