import { spawn } from '@redux-saga/core/effects';

import { wizardPhotosSaga } from './photos-step/model/saga/wizard-photos.saga';
import { wizardDeceasedSaga } from './about-deceased-step/model/saga/wizard-deceased.saga';
import { wizardNotificationsSaga } from './notifications-step/model/saga/wizard-notifications.saga';
import { wizardVgSaga } from './vg-step/model/saga/wizard-vg.saga';

export function* wizardSaga() {
    yield spawn(wizardPhotosSaga);
    yield spawn(wizardDeceasedSaga);
    yield spawn(wizardNotificationsSaga);
    yield spawn(wizardVgSaga);
}
