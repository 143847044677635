import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BookIcon from '@material-ui/icons/Book';

import { EditorPath } from '../../../routing/editor-paths';
import { ReactComponent as OverviewIcon } from '../../../assets/icons/editor-overview.svg';
import { ReactComponent as DeceasedIcon } from '../../../assets/icons/editor-deceased.svg';
import { ReactComponent as MediaAndContentIcon } from '../../../assets/icons/editor-media-and-content.svg';
import { ReactComponent as NotificationsIcon } from '../../../assets/icons/editor-notifications.svg';
import { ReactComponent as CeremonyIcon } from '../../../assets/icons/editor-ceremony.svg';
import { ReactComponent as DigitalFootprintIcon } from '../../../assets/icons/editor-digital-footprint.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/editor-settings.svg';
import { ReactComponent as ParticipantsIcon } from '../../../assets/icons/editor-participants.svg';
import { usePageSlug } from '../../../meta/use-page-slug';
import { resolveRouteWithSlug } from '../../../routing/resolve-route-with-slug';
import { logoutRequested } from '../../../auth/logout/model/logout.actions';
import { getTheme } from '../../../model/theme/selectors/get-theme.selector';

import { IMenuItemProps, MenuItem } from './menu-item/menu-item';

const useStyles = makeStyles<Theme>((theme) => ({
    logoutButton: {
        fontWeight: 700,
        '& svg': {
            marginRight: theme.spacing(2),
        },
    },
    vg: {
        fontFamily: 'Raleway',
    },
}));

export const Menu = (props: BoxProps) => {
    const slug = usePageSlug()!;
    const replaceSlug = resolveRouteWithSlug(slug);
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const th = useSelector(getTheme);

    const menuItems: IMenuItemProps[] = [
        {
            title: t('menu.overview'),
            link: EditorPath.OVERVIEW,
            icon: OverviewIcon,
        },
        {
            title: t('menu.deceased'),
            link: EditorPath.DECEASED,
            icon: DeceasedIcon,
        },
        {
            title: t('menu.mediaAndContent'),
            link: EditorPath.MEDIA_AND_CONTENT,
            icon: MediaAndContentIcon,
        },
        {
            id: 'obituary',
            title: t('menu.obituary'),
            link: EditorPath.OBITUARY,
            icon: BookIcon,
        },
        {
            title: t('menu.notifications'),
            link: EditorPath.NOTIFICATIONS,
            icon: NotificationsIcon,
        },
        {
            title: t('menu.ceremonyAndReception'),
            link: EditorPath.CEREMONY_AND_RECEPTION,
            icon: CeremonyIcon,
        },
        {
            title: t('menu.participants'),
            link: EditorPath.PARTICIPANTS,
            icon: ParticipantsIcon,
        },
        {
            title: t('menu.digitalFootprint'),
            link: EditorPath.DIGITAL_FOOTPRINT,
            icon: DigitalFootprintIcon,
        },
        {
            title: t('menu.settings'),
            link: EditorPath.SETTINGS,
            icon: SettingsIcon,
        },
    ];

    const onLogout = () => {
        dispatch(logoutRequested());
    };

    return (
        <Box component="nav" display="flex" flexDirection="column" {...props}>
            {menuItems.map(({ link, ...item }) => (
                <MenuItem
                    key={item.title}
                    active={window.location.pathname === replaceSlug(link)}
                    link={replaceSlug(link)}
                    titleClassName={styles[th]}
                    {...item}
                />
            ))}
            <Box mt={2}>
                <Button
                    data-testid="logout-button"
                    onClick={onLogout}
                    className={styles.logoutButton}
                    variant="text"
                    color="primary"
                >
                    <LogoutIcon />
                    <span className={styles[th]}>{t('navigation.logout')}</span>
                </Button>
            </Box>
        </Box>
    );
};
