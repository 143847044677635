import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { overviewFetchRequested } from '../overview.actions';

import { fetchOverviewSaga } from './fetch-overview.saga';

export function* overviewSaga() {
    yield takeEvery(getType(overviewFetchRequested), fetchOverviewSaga);
}
