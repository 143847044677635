import { createAction } from 'typesafe-actions';

export const passwordsFormSubmitted = createAction(
    'auth/password-page/PASSWORDS_FORM_SUBMITTED',
    (action) => (payload: { password: string }) => action(payload),
);

export const passwordFormSubmitSucceed = createAction(
    `auth/password-page/PASSWORD_FORM_SUBMIT_SUCCEED`,
);
