import { utcToZonedTime } from 'date-fns-tz';

import { apiService } from '../api.service';
import { ITask } from '../../interfaces/task';

import { ITaskDTO } from './dto/created-task-dto';
import { TaskResponseDto } from './dto/task-response.dto';

export const createTask = (slug: string, dto: ITaskDTO) => {
    return apiService
        .post(`/tasks`, {
            json: dto,
            searchParams: {
                taskable_type: 'MemorialPage',
                taskable_id: slug,
            },
        })
        .then((result) => result.json())
        .then((response): TaskResponseDto => response.data)
        .then(
            (taskResponse): ITask => ({
                description: taskResponse.attributes.description,
                expirationDate: taskResponse.attributes.deadlineTimestamp
                    ? utcToZonedTime(
                          taskResponse.attributes.deadlineTimestamp,
                          taskResponse.attributes.timeZone,
                      )
                    : null,
                id: taskResponse.id,
                task: taskResponse.attributes.title,
                status: taskResponse.attributes.status,
            }),
        );
};
