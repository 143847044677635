import { createAction } from 'typesafe-actions';

import { IDictionaryEntry } from '../../interfaces/dictionary-entry';

import { NotificationMessageStatus } from './notification-message.store';

export const notificationMessageTextReceived = createAction(
    'model/notification-message/TEXT_RECEIVED',
    (action) => (payload: { text: string }) => action(payload),
);

export const notificationMessageExamplesReceived = createAction(
    'model/notification-message/EXAMPLES_RECEIVED',
    (action) => (payload: { examples: IDictionaryEntry[] }) => action(payload),
);

export const setNotificationMessageStatus = createAction(
    'model/notification-message/SET_MESSAGE_STATUS',
    (action) => (payload: NotificationMessageStatus | null) => action(payload),
);
