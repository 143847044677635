import { createAction } from 'typesafe-actions';

import { IOverview } from '../../interfaces/overview';

export const overviewFetchRequested = createAction(
    'model/overview/OVERVIEW_FETCH_REQUESTED',
);

export const overviewReceived = createAction(
    'model/overview/OVERVIEW_RECEIVED',
    (action) => (payload: IOverview) => action(payload),
);
