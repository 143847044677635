import { IUpdateTributeDto } from '../../api/tribute/dto/update-tribute.dto';

import { ITributeFormValues } from './tribute-form-values';

export const mapTributeFormToDTO = (formData: ITributeFormValues) => {
    const dto: IUpdateTributeDto = {
        tributeText: formData.tribute,
    };

    return dto;
};
