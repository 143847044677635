import { createAction } from 'typesafe-actions';

import { IParticipantData } from '../../interfaces/participant';

export const participantsFetchRequested = createAction(
    'model/participants/PARTICIPANTS_FETCH_REQUESTED',
);

export const participantsReceived = createAction(
    'model/participants/PARTICIPANTS_RECEIVED',
    (action) => (payload: IParticipantData) => action(payload),
);

export const participantsChangePage = createAction(
    'model/participants/PARTICIPANTS_NEXT_PAGE',
    (action) => (payload: { page: number }) => action(payload),
);
