import React from 'react';
import {
    Redirect,
    Route,
    Switch,
    withRouter,
    RouteComponentProps,
} from 'react-router';

import { routes } from './routes';
import { EditorPath } from './editor-paths';
import { AuthPath } from './auth-path';

const RoutingComponent = ({ location }: RouteComponentProps) => {
    return (
        <Switch>
            {routes.map((routeProps, i) => (
                <Route key={`${routeProps.path}-${i}`} {...routeProps} />
            ))}
            <Redirect to={EditorPath.OVERVIEW} path="/:slug" />
            {!location.search && (
                <Redirect to={AuthPath.LOGIN_WITHOUT_SLUG} from="/" exact />
            )}
        </Switch>
    );
};

export const Routing = withRouter(RoutingComponent);
