import React from 'react';
import { Box, Chip, Fade } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useTranslation } from 'react-i18next';

import { DeceasedPersonalQuality } from '../../../../model/obituary.types';

import { useDeceasedPersonalQualitiesSelectorStyles } from './deceased-personal-qualities-selector.styles';
import { useDeceasedPersonalQualitiesSelector } from './use-deceased-personal-qualities-selector';

export interface IDeceasedPersonalQualitiesSelectorProps {
    deceasedPersonalQualities: DeceasedPersonalQuality[];
    onDeceasedPersonalQualitiesChange: (
        deceasedPersonalQualities: DeceasedPersonalQuality[],
    ) => void;
}

export const DeceasedPersonalQualitiesSelector = (
    props: IDeceasedPersonalQualitiesSelectorProps,
) => {
    const { t } = useTranslation();
    const styles = useDeceasedPersonalQualitiesSelectorStyles();

    const {
        options,
        checkIfSelected,
        handleSelect,
        expanded,
        toggleExpanded,
    } = useDeceasedPersonalQualitiesSelector(props);

    const chipClasses = {
        root: styles.chip,
        label: styles.label,
        colorPrimary: styles.selected,
        deleteIcon: styles.icon,
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gridRowGap="0.75rem"
            gridColumnGap="1rem"
        >
            {options.map((option) => {
                const selected = checkIfSelected(option);
                const icon = selected ? <DoneIcon /> : <Add />;
                const variant = selected ? 'default' : 'outlined';
                const color = selected ? 'primary' : 'default';

                const onSelect = () => {
                    handleSelect(option);
                };

                return (
                    <Fade in timeout={500} key={option.value}>
                        <Chip
                            clickable
                            label={option.label}
                            variant={variant}
                            deleteIcon={icon}
                            onDelete={onSelect}
                            onClick={onSelect}
                            color={color}
                            classes={chipClasses}
                        />
                    </Fade>
                );
            })}
            <Chip
                clickable
                label={expanded ? t('common.showLess') : t('common.showAll')}
                deleteIcon={expanded ? <ChevronLeft /> : <ChevronRight />}
                onDelete={toggleExpanded}
                onClick={toggleExpanded}
                classes={chipClasses}
            />
        </Box>
    );
};
