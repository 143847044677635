import { createAction } from 'typesafe-actions';

import { ILanguage } from '../../interfaces/language';

export const languageChangeRequested = createAction(
    'model/configuration/LANGUAGE_CHANGE_REQUESTED',
    (action) => (payload: { language: ILanguage }) => action(payload),
);

export const languageChanged = createAction(
    'model/configuration/LANGUAGE_CHANGED',
    (action) => (payload: { language: ILanguage }) => action(payload),
);

export const languageRetrieved = createAction(
    'model/configuration/LANGUAGE_RETRIEVED',
    (action) => (payload: { language: ILanguage }) => action(payload),
);
