import { call, put, select } from 'redux-saga/effects';

import { rejectSuggestedContactApiActions } from '../contacts-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ICreatedContact } from '../../../interfaces/contact';
import { notificationSingleContactReceived } from '../../../model/notification-contacts/notification-contacts.actions';
import { rejectSuggestion } from '../../suggestions/http/reject-suggestion';
import { getNotificationContactById } from '../../../model/notification-contacts/selectors/get-notification-contact-by-id';
import { NotificationContactStatus } from '../../../interfaces/notification-contact-status';

export function* rejectSuggestedContactApiSaga(
    action: ReturnType<typeof rejectSuggestedContactApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const relatedContact: ICreatedContact = yield select(
        getNotificationContactById(action.payload.id),
    );

    if (!relatedContact.suggestionID) {
        console.error('Rejected contact without suggestion id...');
        return;
    }

    try {
        yield call(rejectSuggestion, {
            slug,
            id: relatedContact.suggestionID,
        });

        yield put(
            notificationSingleContactReceived({
                contact: {
                    ...relatedContact,
                    status: NotificationContactStatus.REJECTED,
                },
            }),
        );

        yield put(
            rejectSuggestedContactApiActions.success({
                ...relatedContact,
                status: NotificationContactStatus.REJECTED,
            }),
        );
    } catch (err) {
        yield put(rejectSuggestedContactApiActions.failure(err.statusText));
    }
}
