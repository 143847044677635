import { RouteProps } from 'react-router';

import { WizardDeceasedScreen } from '../wizard/about-deceased-step/wizard-deceased-screen';
import { ComposedWizardPhotosScreen } from '../wizard/photos-step/wizard-photos-screen';
import { WizardNotificationsScreen } from '../wizard/notifications-step/wizard-notifications-screen';
import { WizardVgScreen } from '../wizard/vg-step/wizard-vg-screen';

import { WizardPath } from './wizard-paths';

/**
 * Subroutes for wizard layout
 */
export const wizardRoutes: RouteProps[] = [
    {
        path: WizardPath.DECEASED,
        component: WizardDeceasedScreen,
    },
    {
        path: WizardPath.PHOTOS,
        component: ComposedWizardPhotosScreen,
    },
    {
        path: WizardPath.NOTIFICATION,
        component: WizardNotificationsScreen,
    },
    {
        path: WizardPath.VG,
        component: WizardVgScreen,
    },
];
