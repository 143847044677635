import { makeStyles } from '@material-ui/core';

import { hexToRgba } from '../../../../../utils/hex-to-rgba';
import { Colors } from '../../../../../../styles/colors';

export const useObituaryLoadingStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1.5rem',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1,
        backgroundColor: hexToRgba(theme.palette.background.default, 0.6),
    },
    loadingIcon: {
        color: theme.palette.primary.light,
    },
    successIcon: {
        color: theme.palette.success.main,
        width: '50px',
        height: '50px',
    },
    errorIcon: {
        color: Colors.VALENCIA,
        width: '50px',
        height: '50px',
    },
    status: {
        fontSize: '1.3rem',
        textTransform: 'uppercase',
        fontWeight: 600,
        opacity: 0.8,
        textAlign: 'center',
        maxWidth: '40rem',
        padding: '0 4rem',
    },
}));
