import { put, take } from '@redux-saga/core/effects';

import {
    changePrivacyModeRequested,
    changePrivacyModeSucceed,
} from '../settings.actions';
import { updateSettingsApiActions } from '../../../api/settings/settings.actions';
import { PrivacyMode } from '../../../interfaces/privacy-mode';

export function* updatePagePrivacySaga(
    action: ReturnType<typeof changePrivacyModeRequested>,
) {
    const mode = action.payload;

    yield put(
        updateSettingsApiActions.request({
            privacy: mode,
        }),
    );

    const {
        payload: { privacy },
    }: ReturnType<typeof updateSettingsApiActions.success> = yield take(
        updateSettingsApiActions.success,
    );

    yield put(changePrivacyModeSucceed(privacy as PrivacyMode));
}
