import { call, put, select } from '@redux-saga/core/effects';

import { fetchParticipantsApiActions } from '../participants-api.actions';
import { fetchParticipants } from '../http/fetch-participants';
import { ParticipantDto } from '../dto/participant.dto';
import { getDeceasedId } from '../../../model/deceased-person/selectors/get-deceased-id.selector';

export function* fetchParticipantsSaga(
    action: ReturnType<typeof fetchParticipantsApiActions.request>,
) {
    const deceasedId = yield select(getDeceasedId);

    try {
        const resp: ParticipantDto = yield call(fetchParticipants, {
            deceasedId,
            pageSize: action.payload.page.size,
            current: action.payload.page.number,
        });

        const participants = resp.data.map((participant) => {
            return {
                email: participant.attributes.email,
                phone: participant.attributes.phone,
                fullName: participant.attributes.fullName,
                sharingPersonalInfoWithBereavedAccepted:
                    participant.attributes
                        .sharingPersonalInfoWithBereavedAccepted,
                eventId: participant.attributes.eventId,
            };
        });

        yield put(
            fetchParticipantsApiActions.success({
                participants,
                pagination: {
                    total: resp.meta.total,
                    current: resp.meta.pagination.current,
                },
            }),
        );
    } catch (err) {
        yield put(fetchParticipantsApiActions.failure(err.statusText));
    }
}
