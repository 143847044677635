import { createReducer } from 'typesafe-actions';

import {
    nextPageRequested,
    perPageChanged,
    prevPageRequested,
    toggleAllContactsNotSelected,
    toggleContactSelected,
    toggleManyContactsSelected,
    pagingResetRequested,
} from './contacts.actions';

export interface IContactsStore {
    selectedContactsIds: string[];
    currentPage: number;
    perPage: number;
}

export const initialState: IContactsStore = {
    selectedContactsIds: [],
    currentPage: 1,
    perPage: 5,
};

type Actions =
    | ReturnType<typeof toggleContactSelected>
    | ReturnType<typeof perPageChanged>
    | ReturnType<typeof nextPageRequested>
    | ReturnType<typeof toggleManyContactsSelected>
    | ReturnType<typeof toggleAllContactsNotSelected>
    | ReturnType<typeof prevPageRequested>
    | ReturnType<typeof pagingResetRequested>;

export const contactsReducer = createReducer<IContactsStore, Actions>(
    initialState,
)
    .handleAction(toggleContactSelected, (state, action) => {
        return {
            ...state,
            selectedContactsIds: state.selectedContactsIds.includes(
                action.payload.id,
            )
                ? state.selectedContactsIds.filter(
                      (id) => id !== action.payload.id,
                  )
                : [...state.selectedContactsIds, action.payload.id],
        };
    })
    .handleAction(perPageChanged, (state, action) => {
        return {
            ...state,
            perPage: action.payload.perPage,
            currentPage: 1,
        };
    })
    .handleAction(nextPageRequested, (state) => {
        return {
            ...state,
            currentPage: state.currentPage + 1,
        };
    })
    .handleAction(prevPageRequested, (state) => {
        return {
            ...state,
            currentPage: state.currentPage - 1,
        };
    })
    .handleAction(toggleManyContactsSelected, (state, action) => {
        return {
            ...state,
            selectedContactsIds: action.payload.ids,
        };
    })
    .handleAction(toggleAllContactsNotSelected, (state) => {
        return {
            ...state,
            selectedContactsIds: [],
        };
    })
    .handleAction(pagingResetRequested, (state) => {
        return {
            ...state,
            currentPage: 1,
        };
    });
