import * as yup from 'yup';

import { ILoginFormValues } from './login-form-values';

export const loginFormValidation = () =>
    yup.object<ILoginFormValues>({
        email: yup
            .string()
            .email()
            .required(),
        password: yup.string().required(),
    });
