import { call, put, select } from '@redux-saga/core/effects';

import { fetchMilestonesApiActions } from '../milestones-api.actions';
import { IMilestoneResponseDto } from '../dto/milestone-response.dto';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { fetchMilestones } from '../http/fetch-milestones';

export function* fetchMilestonesApiSaga(
    action: ReturnType<typeof fetchMilestonesApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response: IMilestoneResponseDto[] = yield call(
            fetchMilestones,
            slug,
        );
        yield put(
            fetchMilestonesApiActions.success(
                response.map((milestone) => ({
                    title: milestone.attributes.title,
                    id: milestone.id,
                    description: milestone.attributes.description,
                    year: milestone.attributes.year,
                    month: milestone.attributes.month,
                })),
            ),
        );
    } catch (e) {
        yield put(fetchMilestonesApiActions.failure(e.statusText));
    }
}
