import ky from 'ky-universal';

export const refreshTokens = (
    refreshToken: string,
): Promise<{ accessToken: string; refreshToken: string }> => {
    console.log('Call refresh tokens request');
    return ky
        .post(`${process.env.REACT_APP_API_HOST as string}/refresh_tokens`, {
            json: {
                auth: {
                    refreshToken,
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
};
