import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../../components/section/section';
import { SectionHeadline } from '../../../components/section-headline/section-headline';
import { useIsMobile } from '../../../../styles/use-is-mobile';

import { useOverviewSection } from './use-overview-section';

interface IOverviewSectionProps extends ISectionProps {}

export const OverviewSection = (props: IOverviewSectionProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();

    const {
        currentCondolencesCount,
        currentInvitationsCount,
        currentSuggestionsCount,
    } = useOverviewSection();

    return (
        <Section
            title={t('overviewSection.title')}
            description={t('overviewSection.description')}
            {...props}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={mobile ? 'column' : 'row'}
            >
                <SectionHeadline>
                    {t('overviewSection.unreadCondolences')} -{' '}
                    {currentCondolencesCount}
                </SectionHeadline>
                <SectionHeadline>
                    {t('overviewSection.unreadInvitations')} -{' '}
                    {currentInvitationsCount}
                </SectionHeadline>
                <SectionHeadline>
                    {t('overviewSection.newSuggestions')} -{' '}
                    {currentSuggestionsCount}
                </SectionHeadline>
            </Box>
        </Section>
    );
};
