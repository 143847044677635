import { useVgOrderable } from './use-vg-orderable';

export const useWizardStep = () => {
    const { vgOrderableFlag } = useVgOrderable();

    if (vgOrderableFlag) {
        return 1;
    }
    return 0;
};
