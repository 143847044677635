import { put, take } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';

import { updateGalleryImageOnlineStatusActions } from '../gallery.actions';
import { updateGalleryItemOnlineStatusApiActions } from '../../../api/gallery/gallery-api.actions';

type PossibleApiResults =
    | ReturnType<typeof updateGalleryItemOnlineStatusApiActions.success>
    | ReturnType<typeof updateGalleryItemOnlineStatusApiActions.failure>;

export function* updateGalleryImageStatusSaga(
    action: ReturnType<typeof updateGalleryImageOnlineStatusActions.request>,
) {
    const { id, status } = action.payload;

    yield put(
        updateGalleryItemOnlineStatusApiActions.request({
            id,
            status,
        }),
    );

    const result: PossibleApiResults = yield take([
        updateGalleryItemOnlineStatusApiActions.success,
        updateGalleryItemOnlineStatusApiActions.failure,
    ]);

    switch (result.type) {
        case getType(updateGalleryItemOnlineStatusApiActions.success):
            yield put(
                updateGalleryImageOnlineStatusActions.success(result.payload),
            );
            break;
        case getType(updateGalleryItemOnlineStatusApiActions.failure):
            yield put(
                updateGalleryImageOnlineStatusActions.failure(result.payload),
            );
            break;
        default:
            yield;
    }
}
