import React from 'react';
import { RouteComponentProps } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StepHeadline } from '../components/step-headline/step-headline';
import { WizardContentContainer } from '../components/wizard-content-container/wizard-content-container';

import { ConnectedVgForm } from './vg-form/vg-form';
import { IWizardVgFormValues } from './model/wizard-vg-form-values';
import { vgFormSubmitted } from './model/wizard-vg.actions';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
}));

export const WizardVgScreen = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const styles = useStyles();

    const onSubmit = (values: IWizardVgFormValues) => {
        dispatch(vgFormSubmitted(values));
    };

    return (
        <WizardContentContainer>
            <StepHeadline
                data-testid="wizard-vg-step-headline"
                className={styles.headline}
                text={t('vg.headline')}
                stepNo={2}
            />
            <ConnectedVgForm onSubmit={onSubmit} />
        </WizardContentContainer>
    );
};
