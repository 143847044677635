import cx from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../editor/components/section/section';
import { ConfirmationModal } from '../components/confirmation-modal/confirmation-modal';

import { NewPhotoItem } from './components/new-photo-item/new-photo-item';
import { EditableGalleryItem } from './components/editable-gallery-item/editable-gallery-item';
import { useGallerySectionState } from './use-gallery-section-state';

interface IGalleryProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    item: {
        width: '100%',
        paddingBottom: '100%',
    },
    loaderBox: {
        position: 'relative',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export const Gallery = (props: IGalleryProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const newFileRef = useRef<HTMLInputElement>(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const photoToDelete = useRef<string | null>(null);

    const {
        onDelete,
        onTakeOffline,
        onPutOnline,
        galleryItems,
        onFileUploaded,
        uploadInProgress,
    } = useGallerySectionState();

    const onAddPhoto = () => {
        newFileRef.current!.click();
    };

    const onFileAdded = (event: ChangeEvent<HTMLInputElement>) => {
        if (!get(event, 'target.files.length')) {
            return;
        }

        const files = event.target.files!;

        for (let i = 0; i < files.length; i++) {
            onFileUploaded(files[i]);
        }
    };

    const renderNewImageButton = () => (
        <>
            <NewPhotoItem
                data-testid="gallery-add-photo"
                className={styles.item}
                onClick={onAddPhoto}
            />
            <input
                type="file"
                hidden
                ref={newFileRef}
                accept="image/jpeg,image/png"
                max={20}
                onChange={onFileAdded}
                multiple
            />
        </>
    );

    const renderLoaderBox = () => (
        <Box
            data-testid="gallery-photo-loader"
            className={cx(styles.loaderBox, styles.item)}
        >
            <div className={styles.loader}>
                <CircularProgress />
            </div>
        </Box>
    );

    return (
        <Section
            title={t('mediaAndContent.gallery')}
            description={t('mediaAndContent.galleryDescription')}
            {...props}
        >
            <Grid
                data-testid="gallery-pictures-container"
                container
                spacing={2}
            >
                {galleryItems.map(({ thumbSrc, published, id }) => (
                    <Grid
                        data-testid={`gallery-picture-${id}`}
                        item
                        xs={6}
                        sm={3}
                        key={id}
                    >
                        <EditableGalleryItem
                            className={styles.item}
                            src={thumbSrc}
                            onDelete={() => {
                                photoToDelete.current = id;
                                setConfirmationModalOpen(true);
                            }}
                            onTakeOffline={() => onTakeOffline(id)}
                            onPutOnline={() => onPutOnline(id)}
                            online={published}
                        />
                    </Grid>
                ))}

                <Grid item xs={6} sm={3}>
                    {uploadInProgress
                        ? renderLoaderBox()
                        : renderNewImageButton()}
                </Grid>
            </Grid>
            <ConfirmationModal
                open={confirmationModalOpen}
                onConfirm={() => {
                    onDelete(photoToDelete.current!);
                    setConfirmationModalOpen(false);
                    photoToDelete.current = null;
                }}
                onCancel={() => {
                    setConfirmationModalOpen(false);
                }}
            />
        </Section>
    );
};
