import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import {
    updateEventAsyncActions,
    updateEventFormSubmitted,
} from '../events.actions';
import { mapEventToDTO } from '../map-event-to-dto';
import { updateEventApiActions } from '../../../api/events/api-events.actions';

export function* updateEventSaga(
    action: ReturnType<typeof updateEventFormSubmitted>,
) {
    const { payload } = action;
    const dto = mapEventToDTO(payload.event);

    yield put(
        updateEventApiActions.request({
            ...dto,
            event: {
                ...dto.event,
                startTimestamp: dto.event.startTimestamp,
            },
            id: action.payload.id,
        }),
    );

    const resultAction:
        | ReturnType<typeof updateEventApiActions.success>
        | ReturnType<typeof updateEventApiActions.failure> = yield take([
        updateEventApiActions.success,
        updateEventApiActions.failure,
    ]);

    switch (resultAction.type) {
        case getType(updateEventApiActions.success):
            yield put(
                updateEventAsyncActions.success({
                    event: resultAction.payload,
                }),
            );
            break;
        case getType(updateEventApiActions.failure):
            yield put(updateEventAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
