const tokenKey = 'notify-token-key';

export const saveTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem(
        tokenKey,
        JSON.stringify({
            accessToken,
            refreshToken,
        }),
    );
};

export const getTokens = (): {
    accessToken: string;
    refreshToken: string;
} | null => {
    try {
        const tokensString = localStorage.getItem(tokenKey);

        if (tokensString) {
            return JSON.parse(tokensString);
        }

        return null;
    } catch (e) {
        return null;
    }
};

export const clearTokens = () => {
    localStorage.removeItem(tokenKey);
};
