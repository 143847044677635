import { put, call, select } from 'redux-saga/effects';

import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { updateVgData } from '../http/update-vg-data';
import { updateVgApiActions } from '../vg-api.actions';

export function* updateVgDataSaga(
    action: ReturnType<typeof updateVgApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response = yield call(updateVgData, action.payload, slug);
        yield put(updateVgApiActions.success(response));
    } catch (e) {
        yield put(updateVgApiActions.failure(e.statusText));
    }
}
