import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { CheckCircle, Error } from '@material-ui/icons';

import { StatusMessage } from '../../../../../components/status-message/status-message';
import { ObituaryStatus } from '../../../../../model/obituary/obituary.store';

import { useObituaryLoadingStyles } from './obituary-loading.styles';

export interface IObituaryLoadingProps {
    obituaryLoading: boolean;
    obituaryStatus: ObituaryStatus | null;
    obituaryStatusMessage: string | null;
}
export const ObituaryLoading = ({
    obituaryLoading,
    obituaryStatus,
    obituaryStatusMessage,
}: IObituaryLoadingProps) => {
    const styles = useObituaryLoadingStyles();

    const isObituaryInfoStatus = obituaryStatus === ObituaryStatus.GENERATING;

    const isObituaryErrorStatus =
        obituaryStatus === ObituaryStatus.GENERATE_ERROR ||
        obituaryStatus === ObituaryStatus.UPDATE_ERROR ||
        obituaryStatus === ObituaryStatus.INAPPROPRIATE_WORDS;

    const isObituarySuccessStatus =
        obituaryStatus === ObituaryStatus.SUCCESS ||
        obituaryStatus === ObituaryStatus.GENERATED;

    if (!obituaryLoading && !obituaryStatusMessage) {
        return null;
    }

    return (
        <Box className={styles.container}>
            {(!obituaryStatusMessage || isObituaryInfoStatus) && (
                <Box className={styles.loadingIcon}>
                    <CircularProgress size={45} color="inherit" />
                </Box>
            )}
            {obituaryStatusMessage && (
                <>
                    {isObituarySuccessStatus && (
                        <CheckCircle className={styles.successIcon} />
                    )}
                    {isObituaryErrorStatus && (
                        <Error className={styles.errorIcon} />
                    )}
                    <StatusMessage
                        info={isObituaryInfoStatus}
                        error={isObituaryErrorStatus}
                        className={styles.status}
                    >
                        {obituaryStatusMessage}
                    </StatusMessage>
                </>
            )}
        </Box>
    );
};
