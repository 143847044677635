import React from 'react';
import './phone-input.scss';
import 'react-phone-input-mui/dist/style.css';
import { TextFieldProps } from '@material-ui/core/TextField';
import ReactPhoneInput from 'react-phone-input-mui';

import { convertNorwegianLangCode } from '../../utils/convert-norwegian-lang-code';
import { FormTextField } from '../form-text-field/form-text-field';
import { useLanguage } from '../../configuration/use-language';
import { convertEnglishLangCode } from '../../utils/convert-english-lang-code';

type Country = {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
};

interface IOuterProps {
    onChange(value: string): unknown;
}

export const PhoneInput = ({
    onChange,
    value,
    name,
    error,
    inputProps,
    ...props
}: Omit<TextFieldProps, 'onChange'> & IOuterProps) => {
    const prefix = '+';

    const language = useLanguage();
    const code = convertNorwegianLangCode(
        convertEnglishLangCode(language.code),
    );

    const currentDialCode = React.useRef<string>('');

    const handleOnChange = (newValue: string, data: Country) => {
        currentDialCode.current = data.dialCode;

        const isEmpty = !newValue || newValue === data.dialCode;

        if (isEmpty) {
            onChange('');
        } else {
            onChange(prefix + newValue);
        }
    };

    const nonNullishValue =
        value && value !== '' ? value : `${prefix}${currentDialCode.current}`;

    return (
        <ReactPhoneInput
            prefix={prefix}
            buttonClass="flag-select"
            defaultCountry={code}
            value={nonNullishValue}
            onChange={handleOnChange}
            component={FormTextField}
            inputClass="phone-input"
            inputExtraProps={{
                name,
                error,
                ...inputProps,
            }}
            {...props}
        />
    );
};
