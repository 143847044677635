import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    gallerySuggestionsRequested,
    gallerySuggestionAcceptanceRequested,
    gallerySuggestionRejectionRequested,
} from '../gallery-suggestions.actions';

import { fetchGallerySuggestionsSaga } from './fetch-gallery-suggestions.saga';
import { acceptGallerySuggestionSaga } from './accept-gallery-suggestion.saga';
import { rejectGallerySuggestionSaga } from './reject-gallery-suggestion.saga';

export function* gallerySuggestionsSaga() {
    yield takeEvery(
        getType(gallerySuggestionsRequested),
        fetchGallerySuggestionsSaga,
    );
    yield takeEvery(
        getType(gallerySuggestionAcceptanceRequested),
        acceptGallerySuggestionSaga,
    );
    yield takeEvery(
        getType(gallerySuggestionRejectionRequested),
        rejectGallerySuggestionSaga,
    );
}
