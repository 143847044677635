import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    acceptGallerySuggestionApiActions,
    rejectGallerySuggestionApiActions,
} from '../gallery-suggestions.actions';

import { acceptGallerySuggestionApiSaga } from './accept-gallery-suggestion-api.saga';
import { rejectGallerySuggestionApiSaga } from './reject-gallery-suggestion-api.saga';

export function* gallerySuggestionsApiSaga() {
    yield takeEvery(
        getType(acceptGallerySuggestionApiActions.request),
        acceptGallerySuggestionApiSaga,
    );
    yield takeEvery(
        getType(rejectGallerySuggestionApiActions.request),
        rejectGallerySuggestionApiSaga,
    );
}
