import { put, call, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { updateObituaryTextApiActions } from '../obituary-api.actions';
import { updateObituary } from '../http/update-obituary';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ObituaryStatus } from '../../../model/obituary/obituary.store';

export function* updateObituaryApiSaga(
    action: ReturnType<typeof updateObituaryTextApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const resp = yield call(updateObituary, {
            slug,
            dto: action.payload,
        });

        if (!resp.errors) {
            yield put(
                updateObituaryTextApiActions.success({
                    obituaryText: resp.data.attributes.obituaryText || '',
                }),
            );
        } else if (find(resp.errors, { code: 'not_decent' })) {
            yield put(
                updateObituaryTextApiActions.failure({
                    obituaryStatus: ObituaryStatus.INAPPROPRIATE_WORDS,
                }),
            );
        } else {
            yield put(
                updateObituaryTextApiActions.failure({
                    obituaryStatus: ObituaryStatus.UPDATE_ERROR,
                }),
            );
        }
    } catch {
        yield put(
            updateObituaryTextApiActions.failure({
                obituaryStatus: ObituaryStatus.UPDATE_ERROR,
            }),
        );
    }
}
