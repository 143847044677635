import { put, take } from '@redux-saga/core/effects';

import { fetchCondolencesApiActions } from '../../../api/condolences/condolences-api.actions';
import { condolencesReceived } from '../condolences.actions';

export function* fetchCondolencesSaga() {
    yield put(fetchCondolencesApiActions.request());

    const resp: ReturnType<
        typeof fetchCondolencesApiActions.success
    > = yield take(fetchCondolencesApiActions.success);

    yield put(condolencesReceived(resp.payload));
}
