import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { updateDeceasedDataApiActions } from '../deceased-api.actions';

import { updateDeceasedDataSaga } from './update-deceased-data.saga';

export function* deceasedApiSaga() {
    yield takeEvery(
        getType(updateDeceasedDataApiActions.request),
        updateDeceasedDataSaga,
    );
}
