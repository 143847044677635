import { FormEvent, useEffect, useRef, useState } from 'react';

import { IRichTextEditorRef } from '../../../components/rich-text-editor/rich-text-editor';
import { createShouldShowError } from '../../../utils/should-show-error';
import { ObituaryFormValues } from '../../model/obituary.types';

import { ObituaryFormProps } from './obituary-form';

export const useObituaryForm = ({
    handleChange,
    setFieldValue,
    onSubmit,
    onGenerate,
    handleBlur,
    obituaryStatus,
    obituaryStatusMessage,
    values,
    errors,
    touched,
    isValid,
    obituaryLoading,
    obituaryText,
    generatedObituary,
}: ObituaryFormProps) => {
    const obituaryInputRef = useRef<IRichTextEditorRef>(null);

    const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(
        false,
    );

    useEffect(() => {
        setFieldValue('obituaryText', generatedObituary || obituaryText);
    }, [generatedObituary, obituaryText, setFieldValue]);

    const handleClickSubmit = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!obituaryLoading) {
            onSubmit(values);
        }
    };

    const handleConfirmGenerate = () => {
        if (!obituaryLoading && obituaryInputRef.current) {
            setIsConfirmationModalOpened(false);
            onGenerate(values);
        }
    };

    const handleCancelGenerate = () => {
        setIsConfirmationModalOpened(false);
    };

    const handleRequestGenerate = () => {
        if (!obituaryLoading) {
            if (!values.obituaryText) {
                handleConfirmGenerate();
            } else {
                setIsConfirmationModalOpened(true);
            }
        }
    };

    const handleValueChange = <T>(fieldName: keyof ObituaryFormValues) => {
        return (fieldValue: T) => {
            setFieldValue(fieldName, fieldValue);
        };
    };

    const shouldShowError = createShouldShowError(errors, touched);

    return {
        values,
        touched,
        isValid,
        errors,
        shouldShowError,

        handleChange,
        handleValueChange,
        handleBlur,

        obituaryStatus,
        obituaryStatusMessage,
        obituaryLoading,
        obituaryInputRef,

        isConfirmationModalOpened,
        handleClickSubmit,
        handleRequestGenerate,
        handleConfirmGenerate,
        handleCancelGenerate,
    };
};
