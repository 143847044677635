import { put } from 'redux-saga/effects';

import { suggestedContactRejectionRequested } from '../contacts.actions';
import { rejectSuggestedContactApiActions } from '../../../../../api/contacts/contacts-api.actions';

export function* rejectSuggestedContactSaga(
    action: ReturnType<typeof suggestedContactRejectionRequested>,
) {
    yield put(
        rejectSuggestedContactApiActions.request({ id: action.payload.id }),
    );
}
