import { delay, put } from '@redux-saga/core/effects';

import { setResetPasswordStatus } from '../reset-password-page.actions';
import { ResetPasswordStatus } from '../reset-password.store';

export function* handleResetPasswordEmailSentSaga() {
    yield put(setResetPasswordStatus(ResetPasswordStatus.MAIL_SEND_SUCCEEDED));
    yield delay(3000);
    yield put(setResetPasswordStatus(null));
}
