import { InvitationDto } from '../dto/invitation.dto';
import { IInvitation } from '../../../interfaces/invitation';

import { mapInvitationDtoToInvitation } from './map-invitation-dto-to-invitation';

export const mapInvitationsDtoToInvitations = (
    dtos: InvitationDto[],
): IInvitation[] => {
    return dtos.map(mapInvitationDtoToInvitation);
};
