import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../components/page-title/page-title';

import { DeleteOnlineServicesSection } from './delete-online-services-section/delete-online-services-section';

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(5),
    },
}));

export const DigitalFootprintScreen = () => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Box data-testid="digital-footprint-page-container" mb={3}>
            <PageTitle>{t('digitalFootprint.title')}</PageTitle>
            <DeleteOnlineServicesSection className={styles.section} />
        </Box>
    );
};
