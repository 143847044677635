import { push } from 'connected-react-router';
import { put, select, take } from 'redux-saga/effects';

import { wizardRestartRequested } from '../wizard-about-deceased.actions';
import { getPageSlug } from '../../../../meta/model/selectors/get-page-slug.selector';
import { updateMemorialPageCreationStepApiActions } from '../../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';

export function* wizardRestartSaga(
    action: ReturnType<typeof wizardRestartRequested>,
) {
    const slug = yield select(getPageSlug);

    yield put(
        updateMemorialPageCreationStepApiActions.request({
            step: MemorialPageCreationStep.DECEASED,
        }),
    );

    yield take(updateMemorialPageCreationStepApiActions.success);

    yield put(
        push(
            resolveRouteFromMemorialCreationStep(
                slug,
                MemorialPageCreationStep.DECEASED,
            ),
        ),
    );
}
