import { apiService } from '../../api.service';
import { MemorialPageCreationStep } from '../../../interfaces/memorial-page-creation-step';

/**
 * Tell server to update memorial page creation progress.
 * This allows us to restore wizard from proper step or redirect to editor
 * if wizard is finished
 */
export const sendNewMemorialCreationStep = ({
    slug,
    step,
}: {
    slug: string;
    step: MemorialPageCreationStep;
}) =>
    apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                memorialPage: {
                    currentWizardStep: step,
                },
            },
        })
        .then((resp) => resp.json());
