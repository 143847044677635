import React, { ChangeEvent } from 'react';
import Geocode from 'react-geocode';
import {
    GoogleMap,
    LoadScript,
    StandaloneSearchBox,
    Marker,
} from '@react-google-maps/api';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withTranslation } from 'react-i18next';

import { FormTextField } from '../form-text-field/form-text-field';

import { defaultMapConfig } from './default-map-config';
import { libraries, unnamedRoad } from './libraries';
import { IMapInputProps } from './@types/IMapInputProps';
import { IMapInputState } from './@types/IMapInputState';
import { IFromLatLngResponse } from './@types/IFromLatLngResponse';
import { TGoogleMapsAddressComponentType } from './@types/TGoogleMapsAddressComponentType';
import { TMapInputValue } from './@types/TMapInputValue';
import { ICustomPlaceResult } from './@types/CustomPlaceResult';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

export class _MapInput extends React.Component<IMapInputProps, IMapInputState> {
    searchBox: google.maps.places.SearchBox | null = null;

    static defaultProps = defaultMapConfig;

    constructor(props: IMapInputProps) {
        super(props);

        if (!props.value) {
            this.state = {
                lat: props.defaultLat!,
                lng: props.defaultLng!,
                locationName: '',
                errorMessage: null,
            };

            return;
        }

        this.state = {
            lat: props.value.latitude
                ? parseFloat(props.value.latitude!)
                : props.defaultLat!,
            lng: props.value.longitude
                ? parseFloat(props.value.longitude!)
                : props.defaultLng!,
            locationName:
                props.value && props.value.name ? props.value.name! : '',
            errorMessage: null,
        };
    }

    componentDidUpdate(prevProps: IMapInputProps) {
        const { value } = this.props;

        if (value && !prevProps.value) {
            this.applyValueToInputs(value!);
            return;
        }

        if (!value) {
            return;
        }

        if (
            value!.latitude !== prevProps!.value!.latitude ||
            value!.longitude !== prevProps!.value!.longitude ||
            value!.name !== prevProps!.value!.name
        ) {
            this.applyValueToInputs(value!);
        }
    }

    onDragMarkerEnd = (event: google.maps.MouseEvent) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        Geocode.fromLatLng(newLat, newLng).then(
            (response: IFromLatLngResponse) => {
                const [
                    placeAddressComponent,
                ] = response.results[0].address_components;

                const unnamedPlace =
                    placeAddressComponent.long_name || `${newLat},${newLng}`;

                this.setState({
                    lat: newLat,
                    lng: newLng,
                });
                if (unnamedPlace !== unnamedRoad) {
                    const { onChange } = this.props;
                    onChange(
                        this.createChangeEvent(
                            this.createValueObjectFromGeolocationResult(
                                (response
                                    .results[0] as unknown) as ICustomPlaceResult,
                            ),
                        ),
                    );
                }
            },
            (error: Error) => {
                const millisecondsOfErrorVisibility = 8000;

                this.setState({
                    errorMessage: error.message,
                });

                setTimeout(() => {
                    this.setState({
                        errorMessage: null,
                    });
                }, millisecondsOfErrorVisibility);
            },
        );
    };

    createValueObjectFromGeolocationResult = (
        place: ICustomPlaceResult,
    ): TMapInputValue => {
        const newLat = place.geometry.location.lat;
        const newLng = place.geometry.location.lng;
        const { name } = place;
        const address = place.formatted_address;

        const isNameInAddress =
            address.toLowerCase().indexOf(name.toLowerCase()) > -1;
        const formattedName = isNameInAddress ? address : `${name}, ${address}`;

        return {
            latitude: newLat.toString(),
            longitude: newLng.toString(),
            name: formattedName,
            ...this.generateLocationPropertiesFromAddressComponents(
                place.address_components!,
            ),
        };
    };

    onPlacesChange = () => {
        if (!this.searchBox) {
            return;
        }

        const newPlace = this.searchBox.getPlaces();

        if (!newPlace[0] || !newPlace[0].geometry) {
            return;
        }

        const newLat = newPlace[0].geometry.location.lat();
        const newLng = newPlace[0].geometry.location.lng();

        const { onChange } = this.props;
        onChange(
            this.createChangeEvent(
                this.createValueObjectFromPlaceResult(newPlace[0]),
            ),
        );

        this.setState({
            lat: newLat,
            lng: newLng,
        });
    };

    createValueObjectFromPlaceResult = (
        place: google.maps.places.PlaceResult,
    ): TMapInputValue => {
        const newLat = place.geometry!.location.lat();
        const newLng = place.geometry!.location.lng();
        const { name } = place;
        const address = place.formatted_address || '';

        const isNameInAddress =
            address.toLowerCase().indexOf(name.toLowerCase()) > -1;
        const formattedName = isNameInAddress ? address : `${name}, ${address}`;

        return {
            latitude: newLat.toString(),
            longitude: newLng.toString(),
            name: formattedName,
            ...this.generateLocationPropertiesFromAddressComponents(
                place.address_components!,
            ),
        };
    };

    getAddressComponentByType = (
        type: TGoogleMapsAddressComponentType,
        addressComponents?: google.maps.GeocoderAddressComponent[],
        shouldUseShortName: boolean = false,
    ) => {
        if (!addressComponents) {
            return null;
        }

        const foundComponent = addressComponents.find((item) => {
            return item.types.indexOf(type) !== -1;
        });

        if (!foundComponent) {
            return null;
        }

        return shouldUseShortName
            ? foundComponent.short_name
            : foundComponent.long_name;
    };

    onLoadSearchBox = (ref: google.maps.places.SearchBox) => {
        this.searchBox = ref;
    };

    onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            locationName: event.target.value,
        });
    };

    createValueObjectForUnknownLocation = (locationName: string) => {
        const { onChange } = this.props;

        const { lat, lng } = this.state;

        onChange(
            this.createChangeEvent({
                name: locationName,
                latitude: lat.toString(),
                longitude: lng.toString(),
            }),
        );
    };

    createChangeEvent = (value: TMapInputValue) => {
        return {
            target: {
                name: 'map',
                value,
            },
        };
    };

    generateLocationPropertiesFromAddressComponents(
        addressComponents?: google.maps.GeocoderAddressComponent[],
    ) {
        if (!this.getAddressComponentByType) {
            return {};
        }

        const zipCode = this.getAddressComponentByType(
            'postal_code',
            addressComponents,
        );
        const city = this.getAddressComponentByType(
            'locality',
            addressComponents,
        );
        const street = this.getAddressComponentByType(
            'route',
            addressComponents,
        );
        const buildingNo = this.getAddressComponentByType(
            'street_number',
            addressComponents,
        );

        const country = this.getAddressComponentByType(
            'country',
            addressComponents,
            true,
        );

        return {
            zipCode,
            city,
            street,
            country,
            buildingNo,
        };
    }

    applyValueToInputs(value: TMapInputValue) {
        this.setState({
            lat: parseFloat(value.latitude!),
            lng: parseFloat(value.longitude!),
            locationName: value.name!,
        });
    }

    render() {
        const {
            mapHeight,
            fullWidth,
            label,
            draggable,
            zoom,
            mapId,
            placeholder,
            t,
        } = this.props;
        const { lat, lng, locationName } = this.state;

        return (
            <>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    libraries={libraries}
                >
                    <StandaloneSearchBox
                        onLoad={this.onLoadSearchBox}
                        onPlacesChanged={this.onPlacesChange}
                    >
                        <FormTextField
                            disabled={this.props.disabled}
                            fullWidth={fullWidth}
                            id="address"
                            label={t(label!.toString())}
                            name="address"
                            onChange={this.onSearchChange}
                            placeholder={t(placeholder!.toString())}
                            type="text"
                            value={locationName}
                        />
                    </StandaloneSearchBox>
                    <GoogleMap
                        center={{
                            lat,
                            lng,
                        }}
                        id={mapId}
                        mapContainerStyle={{
                            height: mapHeight,
                            marginTop: '12px',
                        }}
                        zoom={zoom}
                    >
                        <Marker
                            draggable={!this.props.disabled && draggable}
                            onDragEnd={this.onDragMarkerEnd}
                            position={{
                                lat,
                                lng,
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
                <Snackbar
                    open={!!this.state.errorMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="error">
                        <AlertTitle>{this.state.errorMessage}</AlertTitle>
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

const MapInput = withTranslation()(_MapInput);
MapInput.defaultProps = defaultMapConfig;

export { MapInput };
