import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { MemorialPageCreationStep } from '../interfaces/memorial-page-creation-step';
import { WizardPath } from '../routing/wizard-paths';
import { resolveRouteWithSlug } from '../routing/resolve-route-with-slug';

import { getPageCreationStep } from './model/selectors/get-page-creation-state.selector';
import { getPageSlug } from './model/selectors/get-page-slug.selector';

export const withWizardCreationFinished = (Component: React.ComponentType) => (
    props: any,
) => {
    const dispatch = useDispatch();
    const slug = useSelector(getPageSlug);
    const wizardStep = useSelector(getPageCreationStep);
    const resolveRoute = resolveRouteWithSlug(slug!);

    useEffect(() => {
        if (wizardStep === MemorialPageCreationStep.DECEASED) {
            dispatch(push(resolveRoute(WizardPath.DECEASED)));
        } else if (wizardStep === MemorialPageCreationStep.NOTIFICATION) {
            dispatch(push(resolveRoute(WizardPath.NOTIFICATION)));
        } else if (wizardStep === MemorialPageCreationStep.PHOTOS) {
            dispatch(push(resolveRoute(WizardPath.PHOTOS)));
        }
    }, [slug, dispatch, wizardStep, resolveRoute]);

    return <Component {...props} />;
};
