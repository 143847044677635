import React from 'react';
import ReactDOM from 'react-dom';

import './setup/i18n';
import { initFB } from './setup/fb-init';
import './styles/base.scss';
import { ComposedApp } from './app/app';

initFB();

ReactDOM.render(<ComposedApp />, document.getElementById('root'));
