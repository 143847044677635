import { apiService } from '../../api.service';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';

export const updateMemorialBackground = (
    slug: string,
    id: string,
): Promise<IMemorialPageMetaDataResponse> =>
    apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                memorialPage: {
                    backgroundId: id,
                },
            },
        })
        .then((resp) => resp.json());
