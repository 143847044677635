import React from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import cx from 'classnames';

import { SectionHeadline } from '../../../../components/section-headline/section-headline';
import { Colors } from '../../../../../styles/colors';
import { ITask } from '../../../../interfaces/task';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { useDateFormatter } from '../../../../utils/use-date-formatter';

interface IProps {
    task: ITask;
}

const useStyles = makeStyles((theme) => ({
    bodyCell: {
        color: Colors.MINE_SHAFT,
        fontSize: '1.6rem',
        padding: theme.spacing(3, 2, 3, 0),
    },
    bodyCellWide: {
        width: '60%',
    },
    bodyCellLast: {
        color: Colors.MINE_SHAFT,
        '&, &:last-child': {
            paddingRight: 0,
        },
    },
    bodyCellTitle: {
        fontSize: '1.8rem',
    },
    icon: {
        color: theme.palette.primary.light,
        marginBottom: theme.spacing(1),
    },
}));

export const TaskTableRow = ({ task }: IProps) => {
    const styles = useStyles();
    const { getShortDate, getTime } = useDateFormatter();

    return (
        <TableRow>
            <TableCell className={cx(styles.bodyCell, styles.bodyCellWide)}>
                <SectionHeadline
                    data-testid="task-title"
                    className={styles.bodyCellTitle}
                >
                    {task.task}
                </SectionHeadline>
            </TableCell>
            <TableCell className={styles.bodyCell}>
                <Box
                    data-testid="task-date"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <CalendarIcon className={styles.icon} />
                    {task.expirationDate && getShortDate(task.expirationDate)}
                </Box>
            </TableCell>
            <TableCell className={styles.bodyCell}>
                <Box
                    data-testid="task-time"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <ClockIcon className={styles.icon} />
                    {task.expirationDate && getTime(task.expirationDate)}
                </Box>
            </TableCell>
        </TableRow>
    );
};
