import { createAsyncAction } from 'typesafe-actions';

import {
    IParticipantData,
    IAPIQueryParams,
} from '../../interfaces/participant';

export const fetchParticipantsApiActions = createAsyncAction(
    'api/participants/FETCH_PARTICIPANTS_REQUESTED',
    'api/participants/FETCH_PARTICIPANTS_SUCCEEDED',
    'api/participants/FETCH_PARTICIPANTS_FAILED',
)<IAPIQueryParams, IParticipantData, string>();
