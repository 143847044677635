import { put, take } from '@redux-saga/core/effects';

import { fetchSuggestionsApiActions } from '../../../api/suggestions/suggestions-api.actions';
import { gallerySuggestionsReceived } from '../gallery-suggestions.actions';
import { mapSuggestionsToGalleryItemSuggestions } from '../mappers/map-suggestions-to-gallery-item-suggestions';

export function* fetchGallerySuggestionsSaga() {
    yield put(fetchSuggestionsApiActions.request());

    const resp: ReturnType<
        typeof fetchSuggestionsApiActions.success
    > = yield take(fetchSuggestionsApiActions.success);

    const payload = mapSuggestionsToGalleryItemSuggestions(resp.payload);

    yield put(gallerySuggestionsReceived(payload));
}
