import React, { useRef, useEffect } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    Theme,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { FormTextField } from '../../../../components/form-text-field/form-text-field';
import { SectionHeadline } from '../../../../components/section-headline/section-headline';
import { PhoneMessagePreview } from '../../../../components/phone-message-preview/phone-message-preview';
import { IMessage } from '../model/message';
import { createShouldShowError } from '../../../../utils/should-show-error';
import { Colors } from '../../../../../styles/colors';
import { useIsMobile } from '../../../../../styles/use-is-mobile';
import { IDictionaryEntry } from '../../../../interfaces/dictionary-entry';
import { NotificationMessageStatus } from '../../../../model/notification-message/notification-message.store';
import { StatusMessage } from '../../../../components/status-message/status-message';

type OuterProps = {
    message: string | undefined;
    messageDictionaryEntries: IDictionaryEntry[];
    onChangeMessage(message: string): unknown;
    notificationMessageStatus: NotificationMessageStatus | null;
    statusMessage: string | null;
};

type Props = FormikProps<IMessage> & OuterProps;

const useStyles = makeStyles<Theme, { status: string | null }>((theme) => ({
    headline: {
        marginBottom: theme.spacing(3),
    },
    container: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text: {
        color: Colors.DOVE_GRAY,
        fontSize: '14px',
        margin: theme.spacing(3, 0),
        marginTop: ({ status }) =>
            status ? theme.spacing(1) : theme.spacing(4),
    },
}));

export const MessageForm = ({
    messageDictionaryEntries,
    onChangeMessage,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    statusMessage,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles({ status: statusMessage });
    const shouldShowError = createShouldShowError(errors, touched);
    const isMobile = useIsMobile();

    const inputLabel = useRef<HTMLLabelElement | null>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    useEffect(() => {
        if (inputLabel && inputLabel.current) {
            setLabelWidth(inputLabel.current.clientWidth);
        }
    }, []);

    const onChange = (e: any) => {
        handleChange(e);
        onChangeMessage(e.target.value);
    };

    return (
        <form data-testid="message-form-container" onSubmit={handleSubmit}>
            <Box
                mt={4}
                display={isMobile ? 'block' : 'flex'}
                justifyContent="space-between"
            >
                <Box className={styles.container}>
                    <SectionHeadline className={styles.headline}>
                        {t('notifications.messageLabel')}
                    </SectionHeadline>
                    <FormTextField
                        data-testid="message-form-textarea"
                        error={shouldShowError('text')}
                        multiline
                        name="text"
                        onChange={onChange}
                        onBlur={handleBlur}
                        placeholder={t('notifications.messagePlaceholder')}
                        rows={4}
                        value={values.text}
                    />
                    {statusMessage && (
                        <StatusMessage
                            data-testid="notification-message-form-message"
                            error
                        >
                            {statusMessage}
                        </StatusMessage>
                    )}
                    <Typography className={styles.text}>
                        {t('notifications.selectFromListBelowLabel')}
                    </Typography>
                    <FormControl
                        data-testid="message-choose-list"
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel
                            variant="outlined"
                            htmlFor="message-example-select"
                            ref={inputLabel}
                        >
                            {t('notifications.examplesDropdownLabel')}
                        </InputLabel>
                        <Select
                            onChange={onChange}
                            labelWidth={labelWidth}
                            name="text"
                            inputProps={{ id: 'message-example-select' }}
                            fullWidth
                            value={
                                messageDictionaryEntries.find(
                                    (e) => e.value === values.text,
                                )
                                    ? values.text
                                    : ''
                            }
                        >
                            {messageDictionaryEntries.map((entry) => (
                                <MenuItem key={entry.id} value={entry.value}>
                                    {entry.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <PhoneMessagePreview
                    data-testid="message-form-preview"
                    className={styles.container}
                    message={values.text || ''}
                />
            </Box>
        </form>
    );
};

export const ConnectedMessageForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IMessage>({
        handleSubmit() {},
        mapPropsToValues({ message }) {
            return {
                text: message || '',
            };
        },
    }),
)(MessageForm);
