import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';

import { logoutRequested } from '../logout.actions';
import { clearTokens } from '../../../../api/auth-token-storage';
import { AuthPath } from '../../../../routing/auth-path';
import { memorialPageSlugReceived } from '../../../../meta/model/meta.actions';
import { handleNativeMobileBackButton } from '../../../../native-mobile/native-mobile-features';

export function* handleLogoutRequestedSaga(
    action: ReturnType<typeof logoutRequested>,
) {
    yield call(clearTokens);
    yield put(memorialPageSlugReceived({ slug: null }));
    yield put(push(AuthPath.LOGIN_WITHOUT_SLUG));
    handleNativeMobileBackButton(false);
}
