import { call, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
    passwordFormSubmitSucceed,
    passwordsFormSubmitted,
} from '../password-page.actions';
import { passwordsSetApiActions } from '../../../../api/password/password.actions';
import { saveTokens } from '../../../../api/auth-token-storage';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { getPageSlug } from '../../../../meta/model/selectors/get-page-slug.selector';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';
import { memorialPageCreationStepReceived } from '../../../../meta/model/meta.actions';
import { fetchMemorialPageData } from '../../../../api/page/fetch-memorial-page-data';
import { IMemorialPageMetaDataResponse } from '../../../../api/page/dto/memorial-page-data-response.dto';

export function* submitPasswordsSaga(
    action: ReturnType<typeof passwordsFormSubmitted>,
) {
    const { password } = action.payload;

    yield put(
        passwordsSetApiActions.request({
            password,
        }),
    );

    const successResult: ReturnType<
        typeof passwordsSetApiActions.success
    > = yield take(passwordsSetApiActions.success);

    yield call(
        saveTokens,
        successResult.payload.accessToken,
        successResult.payload.refreshToken,
    );

    const slug: string = yield select(getPageSlug);

    const fetchMemorialPageResponse: IMemorialPageMetaDataResponse = yield call(
        fetchMemorialPageData,
        slug,
    );

    yield put(
        memorialPageCreationStepReceived({
            state: fetchMemorialPageResponse.data.attributes
                .currentWizardStep as MemorialPageCreationStep,
        }),
    );

    yield put(passwordFormSubmitSucceed());

    const routeToRedirect = resolveRouteFromMemorialCreationStep(
        slug,
        fetchMemorialPageResponse.data.attributes.currentWizardStep ||
            MemorialPageCreationStep.DECEASED,
    );

    yield put(push(routeToRedirect));

    /**
     * TODO: Handle error if update step failed
     */
}
