import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, makeStyles, Paper } from '@material-ui/core';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ShortDivider } from '../../components/short-divider/short-divider';
import { AuthPath } from '../../routing/auth-path';
import { LogoLangugageContainer } from '../../components/logo-language-container/logo-language-container';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    divider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    content: {
        maxWidth: '60rem',
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(8, 16),
        fontSize: '1.6rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            padding: theme.spacing(4),
        },
    },
    sectionHeadline: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
    },
}));

export const LinkExpiredPage = ({ match, history, ...props }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Container className={styles.container}>
            <LogoLangugageContainer />
            <ShortDivider className={styles.divider} />
            <Paper className={styles.content} elevation={1}>
                {t('linkExpired.thisTokenHasExpired')}
                <br />
                {t('linkExpired.ifYouAlreadyHasAnAccount')}{' '}
                <Link to={AuthPath.LOGIN_WITHOUT_SLUG}>
                    {t('loginScreen.title')}
                </Link>
            </Paper>
        </Container>
    );
};
