import { createAction, createAsyncAction } from 'typesafe-actions';

import { IUpdateDeceasedDto } from '../../../api/deceased/dto/update-deceased-dto';

import { IWizardAboutDeceasedFormValues } from './wizard-about-deceased-form-values';

export const aboutDeceasedFormSubmitted = createAction(
    'wizard/deceased/FORM_SUBMITTED',
    (action) => (payload: IWizardAboutDeceasedFormValues) => action(payload),
);

export const aboutDeceasedFormSubmitActions = createAsyncAction(
    'wizard/deceased/FORM_SUBMIT_REQUESTED',
    'wizard/deceased/FORM_SUBMIT_SUCCEED',
    'wizard/deceased/FORM_SUBMIT_FAILED',
)<IUpdateDeceasedDto, undefined, string>();

export const wizardSkipRequested = createAction(
    'wizard/deceased/SKIP_REQUESTED',
);

export const wizardRestartRequested = createAction(
    'wizard/deceased/RESTART_REQUESTED',
);
