import { memorialPageSlugReceived } from '../meta.actions';

export function* saveSlugInWindow(
    action: ReturnType<typeof memorialPageSlugReceived>,
) {
    /**
     * Save slug outside Redux, because API has to know what is the slug
     * to redirect outside store context
     */
    window.memorialPageSlug = action.payload.slug;

    yield;
}
