import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeceasedPersonalQuality } from '../../../../model/obituary.types';

import { IDeceasedPersonalQualitiesSelectorProps } from './deceased-personal-qualities-selector';

type DeceasedPersonalQualityOption = {
    value: DeceasedPersonalQuality;
    label: string;
};

export const useDeceasedPersonalQualitiesSelector = ({
    deceasedPersonalQualities,
    onDeceasedPersonalQualitiesChange,
}: IDeceasedPersonalQualitiesSelectorProps) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);

    const options: DeceasedPersonalQualityOption[] = Object.values(
        DeceasedPersonalQuality,
    ).map((deceasedPersonalQuality) => ({
        value: deceasedPersonalQuality,
        label: t(
            `obituary.deceased.personalQuality.${deceasedPersonalQuality}`,
        ),
    }));

    const visibleOptions = expanded ? options : options.slice(0, 10);

    const toggleExpanded = useCallback(() => {
        setExpanded((isExpanded) => !isExpanded);
    }, []);

    const checkIfSelected = useCallback(
        (option: DeceasedPersonalQualityOption) => {
            return deceasedPersonalQualities.includes(option.value);
        },
        [deceasedPersonalQualities],
    );

    const checkIfVisible = useCallback(
        (option: DeceasedPersonalQualityOption) => {
            return visibleOptions.includes(option);
        },

        [visibleOptions],
    );

    const handleSelect = (option: DeceasedPersonalQualityOption) => {
        const selected = checkIfSelected(option);

        if (selected) {
            onDeceasedPersonalQualitiesChange(
                deceasedPersonalQualities.filter(
                    (item) => item !== option.value,
                ),
            );
        } else {
            onDeceasedPersonalQualitiesChange([
                ...deceasedPersonalQualities,
                option.value,
            ]);
        }
    };

    const hiddenSelectedOptions = expanded
        ? []
        : options.filter((option) => {
              return checkIfSelected(option) && !checkIfVisible(option);
          });

    const allOptions: DeceasedPersonalQualityOption[] = [
        ...visibleOptions,
        ...hiddenSelectedOptions,
    ];

    return {
        options: allOptions,
        expanded,
        toggleExpanded,
        checkIfSelected,
        checkIfVisible,
        handleSelect,
        hiddenSelectedOptions,
    };
};
