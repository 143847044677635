import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { setNewResetPasswordRequested } from '../reset-password-page.actions';
import { sendPasswordResetEmailApiActions } from '../../../../api/password/password.actions';

import { requestSetNewResetPasswordSaga } from './request-set-new-reset-password.saga';
import { handleResetPasswordEmailSentSaga } from './handle-reset-password-email-sent.saga';

export function* resetPasswordPageSaga() {
    yield takeEvery(
        getType(setNewResetPasswordRequested),
        requestSetNewResetPasswordSaga,
    );
    yield takeEvery(
        getType(sendPasswordResetEmailApiActions.success),
        handleResetPasswordEmailSentSaga,
    );
}
