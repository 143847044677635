import { put, take } from 'redux-saga/effects';

import { getNotificationContacts } from '../contacts.actions';
import { fetchNotificationContactsApiActions } from '../../../../../api/contacts/contacts-api.actions';

export function* getNotificationContactsSaga() {
    try {
        yield put(fetchNotificationContactsApiActions.request());

        const {
            payload,
        }: ReturnType<
            typeof fetchNotificationContactsApiActions.success
        > = yield take(fetchNotificationContactsApiActions.success);

        yield put(getNotificationContacts.success(payload));
    } catch (err) {
        yield put(fetchNotificationContactsApiActions.failure(err));
    }
}
