import { createReducer } from 'typesafe-actions';

import {
    galleryNewImageUploadRefToApiActions,
    newGalleryImageAddedByUser,
} from './gallery.actions';

export interface IGalleryStore {
    uploadInProgress: boolean;
}

const initialState: IGalleryStore = {
    uploadInProgress: false,
};

type Actions =
    | ReturnType<typeof newGalleryImageAddedByUser>
    | ReturnType<typeof galleryNewImageUploadRefToApiActions.success>
    | ReturnType<typeof galleryNewImageUploadRefToApiActions.failure>;

export const gallerySectionReducer = createReducer<IGalleryStore, Actions>(
    initialState,
)
    .handleAction(newGalleryImageAddedByUser, (state) => ({
        ...state,
        uploadInProgress: true,
    }))
    .handleAction(
        [
            galleryNewImageUploadRefToApiActions.failure,
            galleryNewImageUploadRefToApiActions.success,
        ],
        (state) => ({
            ...state,
            uploadInProgress: false,
        }),
    );
