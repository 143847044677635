import { select } from 'redux-saga/effects';

import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { sharePageOnMessenger } from '../messenger/share-page-on-messenger';
import { getMemorialBaseUrl } from '../../utils/url-resolvers';

export function* messengerSharingSaga() {
    const slug = yield select(getPageSlug);

    const href = `${getMemorialBaseUrl(window.location.toString())}/${slug}`;

    sharePageOnMessenger(href);
}
