import { call, put } from '@redux-saga/core/effects';
import { take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { changeContext, fetchContexts } from '../../http/fetch-contexts';
import { IContextResponse } from '../../dto/context-response.dto';
import { ILoginSuccessResponseDto } from '../../../../api/login/dto/login-success-response.dto';
import { memorialPageSlugReceived } from '../../../../meta/model/meta.actions';
import { clearTokens, saveTokens } from '../../../../api/auth-token-storage';
import { fetchPageActions } from '../../../../api/page/page-api.actions';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';
import { browserHistory } from '../../../../routing/browser-history';
import { getLoginLink } from '../../../../api/api.service';
import { handleNativeMobileBackButton } from '../../../../native-mobile/native-mobile-features';

import {
    changeContextApiActions,
    fetchContextsApiActions,
    setContext,
} from './context.actions';

export function* fetchContextsSaga() {
    try {
        handleNativeMobileBackButton(false);

        const resp: IContextResponse = yield call(fetchContexts);

        yield put(fetchContextsApiActions.success(resp));
    } catch (err) {
        if (
            err.response &&
            err.response.status &&
            err.response.status === 401
        ) {
            clearTokens();
            browserHistory.push(getLoginLink());
        }
        yield put(fetchContextsApiActions.failure(err.statusText));
    }
}

export function* changeContextsSaga(action: ReturnType<typeof setContext>) {
    try {
        const resp: ILoginSuccessResponseDto = yield call(
            changeContext,
            parseInt(action.payload.id, 10),
        );

        yield put(
            memorialPageSlugReceived({
                slug: action.payload.slug,
            }),
        );

        yield saveTokens(resp.accessToken, resp.refreshToken);
        yield put(fetchPageActions.request({ slug: action.payload.slug }));
        yield take(fetchPageActions.success);
        const routeToRedirect = resolveRouteFromMemorialCreationStep(
            action.payload.slug,
            action.payload.creationStep,
        );
        yield put(push(routeToRedirect));
        handleNativeMobileBackButton(true);
    } catch (err) {
        yield put(changeContextApiActions.failure(err.statusText));
    }
}
