import { CondolenceDto } from '../dto/condolence.dto';
import { ICondolence } from '../../../interfaces/condolence';

import { mapCondolenceDtoToCondolence } from './map-condolence-dto-to-condolence';

export const mapCondolencesDtoToCondolences = (
    dtos: CondolenceDto[],
): ICondolence[] => {
    return dtos.map(mapCondolenceDtoToCondolence);
};
