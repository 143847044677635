import { apiService } from '../../api.service';
import { IUpdateObituaryDto } from '../dto/update-obituary.dto';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';

export const updateObituary = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IUpdateObituaryDto;
}): Promise<IMemorialPageMetaDataResponse> => {
    return apiService
        .patch(`/memorial_pages/${slug}/obituary/update`, {
            json: {
                memorial_page: dto,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
