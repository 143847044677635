import React from 'react';
import { Box, FormControl, makeStyles } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { FormTextField } from '../../../../components/form-text-field/form-text-field';
import { FormDoubleInputsContainer } from '../../../../components/form-double-inputs-container/form-double-inputs-container';
import { FieldErrorMessage } from '../../../../components/field-error-message/field-error-message';
import { SectionHeadline } from '../../../../components/section-headline/section-headline';
import { SecondaryButton } from '../../../../components/secondary-button/secondary-button';
import { StatusMessage } from '../../../../components/status-message/status-message';
import { createShouldShowError } from '../../../../utils/should-show-error';
import { IContact } from '../../../../interfaces/contact';
import { useIsMobile } from '../../../../../styles/use-is-mobile';
import { ContactSuggestionStatus } from '../../../../model/notification-contacts/notification-contacts.store';
import { PhoneInput } from '../../../../components/phone-input/phone-input';
import { OutlinedButton } from '../../../../components/outlined-button/outlined-button';
import { CenterBox } from '../../../../components/center-box/center-box';

import { contactFormValidation } from './contact-form-validation';

type OuterProps = {
    onSubmit(values: IContact): unknown;
    suggestionStatus: ContactSuggestionStatus | null;
    statusMessage: string | null;
};

type Props = FormikProps<IContact> & OuterProps;

const useStyles = makeStyles((theme) => ({
    headline: {
        marginBottom: theme.spacing(3),
    },
    statusMessage: {
        marginTop: theme.spacing(1),
    },
    button: {
        textTransform: 'none',
    },
    addContactButton: {
        position: 'static',
    },
}));

export const ContactForm = ({
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    suggestionStatus,
    statusMessage,
    setFieldValue,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const mobile = useIsMobile();
    const shouldShowError = createShouldShowError(errors, touched);

    return (
        <form data-testid="contacts-form-container" onSubmit={handleSubmit}>
            <Box mb={3}>
                <SectionHeadline className={styles.headline}>
                    {t('notifications.newContact')}
                </SectionHeadline>
                {window.ReactNativeWebView && (
                    <CenterBox mb={6}>
                        {/* do not change href value - mobile app intercepts on this url */}
                        <OutlinedButton
                            className={styles.button}
                            href="https://memcare.import"
                        >
                            {t('notification.importContacts')}
                        </OutlinedButton>
                    </CenterBox>
                )}
                <FormControl error={shouldShowError('name')} fullWidth>
                    <FormTextField
                        error={shouldShowError('name')}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={t('notifications.firstAndLastNameLabel')}
                        value={values.name}
                        data-testid="contact-form-name-input"
                    />
                    <FieldErrorMessage
                        data-testid="contact-form-name-error"
                        name="name"
                    />
                </FormControl>
            </Box>
            <FormDoubleInputsContainer>
                <FormControl error={shouldShowError('email')} fullWidth>
                    <FormTextField
                        error={shouldShowError('email')}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={t('notifications.emailLabel')}
                        value={values.email}
                        data-testid="contact-form-email-input"
                    />
                    <FieldErrorMessage
                        data-testid="contact-form-email-error"
                        name="email"
                    />
                </FormControl>
                <FormControl
                    data-testid="contact-form-phone-container"
                    error={shouldShowError('phone')}
                    fullWidth
                >
                    <PhoneInput
                        error={shouldShowError('phone')}
                        name="phone"
                        onChange={(phone: string) => {
                            setFieldValue('phone', phone);
                        }}
                        onBlur={(event) => {
                            handleBlur(event);

                            contactFormValidation()
                                .validateAt('phone', values)
                                .then((phoneValue: any) => {
                                    if (!phoneValue.length) {
                                        setFieldValue('phone', '');
                                    }
                                });
                        }}
                        label={t('notifications.phoneLabel')}
                        placeholder={t('notifications.phoneLabel')}
                        inputProps={{
                            'data-testid': 'contact-form-phone-input',
                        }}
                        value={values.phone}
                    />
                    <FieldErrorMessage
                        data-testid="contact-form-phone-error"
                        name="phone"
                    />
                </FormControl>
            </FormDoubleInputsContainer>
            {statusMessage && (
                <StatusMessage
                    data-testid="contact-form-message"
                    className={styles.statusMessage}
                    error={suggestionStatus !== ContactSuggestionStatus.SUCCESS}
                >
                    {statusMessage}
                </StatusMessage>
            )}
            <Box
                mt={statusMessage ? 1 : 3}
                display="flex"
                justifyContent="flex-end"
            >
                <SecondaryButton
                    data-testid="contact-form-submit"
                    fullWidth={mobile}
                    type="submit"
                    className={styles.addContactButton}
                >
                    {t('notifications.addContactButton')}
                </SecondaryButton>
            </Box>
        </form>
    );
};

export const ConnectedContactForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IContact>({
        enableReinitialize: true,
        handleSubmit(values, { props }) {
            props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                name: '',
                email: '',
                phone: '',
            };
        },
        validationSchema: () => contactFormValidation(),
    }),
)(ContactForm);
