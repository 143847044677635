import { call, put, select } from '@redux-saga/core/effects';

import { fetchOverviewApiActions } from '../overview-api.actions';
import { fetchOverview } from '../http/fetch-overview';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { OverviewDto } from '../dto/overview';
import { mapOverviewDtoToOverview } from '../mappers/map-overview-dto-to-overview';

export function* fetchOverviewSaga() {
    const slug = yield select(getPageSlug);

    try {
        const resp: OverviewDto = yield call(fetchOverview, {
            slug,
        });

        const payload = mapOverviewDtoToOverview(resp);

        yield put(fetchOverviewApiActions.success(payload));
    } catch (err) {
        yield put(fetchOverviewApiActions.failure(err.statusText));
    }
}
