import { call, put, select } from '@redux-saga/core/effects';

import { fetchCondolencesApiActions } from '../condolences-api.actions';
import { fetchCondolences } from '../http/fetch-condolences';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { CondolenceDto } from '../dto/condolence.dto';
import { mapCondolencesDtoToCondolences } from '../mappers/map-condolences-dto-to-condolences';

export function* fetchCondolencesSaga() {
    const slug = yield select(getPageSlug);

    try {
        const resp: CondolenceDto[] = yield call(fetchCondolences, {
            slug,
        });

        const payload = mapCondolencesDtoToCondolences(resp);

        yield put(fetchCondolencesApiActions.success(payload));
    } catch (err) {
        yield put(fetchCondolencesApiActions.failure(err.statusText));
    }
}
