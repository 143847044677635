import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../interfaces/notification-contact-status';

import { getAllNotificationContacts } from './get-all-notification-contacts';

export const getNotInformedNotificationContacts = createSelector(
    getAllNotificationContacts,
    (contacts) =>
        contacts.filter(
            (contact) =>
                contact.status === NotificationContactStatus.NOT_INFORMED,
        ),
);
