import { apiService } from '../../api.service';

export const sendResetPasswordMail = ({
    email,
    redirectUrl,
}: {
    email: string;
    redirectUrl: string;
}) =>
    apiService.post('/reset_passwords', {
        json: {
            auth: {
                email,
                redirectUrl,
            },
            redirectUrl,
        },
    });
