import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../../components/section/section';

import { ConnectedMessageForm } from './message-form/message-form';
import { useEditorNotificationMessage } from './use-editor-notification-message';

export interface IMessageProps extends ISectionProps {}

export const Message = (props: IMessageProps) => {
    const { t } = useTranslation();
    const {
        notificationText,
        notificationExamples,
        notificationMessageUpdated,
        notificationMessageStatus,
        notificationMessageStatusMessage,
    } = useEditorNotificationMessage();

    return (
        <Section
            title={t('notifications.message')}
            description={t('notifications.messageDescription')}
            {...props}
        >
            <ConnectedMessageForm
                message={notificationText}
                messageDictionaryEntries={notificationExamples}
                onChangeMessage={notificationMessageUpdated}
                notificationMessageStatus={notificationMessageStatus}
                statusMessage={notificationMessageStatusMessage}
            />
        </Section>
    );
};
