import { getType } from 'typesafe-actions';
import { put, take, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
    aboutDeceasedFormSubmitActions,
    aboutDeceasedFormSubmitted,
} from '../wizard-about-deceased.actions';
import { mapDeceasedFormToDTO } from '../../../../model/deceased-person/mappers/map-deceased-form-to-dto';
import { memorialPageSlugReceived } from '../../../../meta/model/meta.actions';
import { updateDeceasedDataApiActions } from '../../../../api/deceased/deceased-api.actions';
import { updateMemorialPageCreationStepApiActions } from '../../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';
import { getTokens, saveTokens } from '../../../../api/auth-token-storage';
import { getTheme } from '../../../../model/theme/selectors/get-theme.selector';
import { getVgOrderable } from '../../../../model/vg/selectors/get-vg-orderable.selector';
import { THEME } from '../../../../utils/get-theme';

export function* submitAboutDeceasedFormSaga(
    action: ReturnType<typeof aboutDeceasedFormSubmitted>,
) {
    const formData = action.payload;
    const dto = mapDeceasedFormToDTO(formData);

    const theme = yield select(getTheme);
    const vgOrderable = yield select(getVgOrderable);
    const vgOrderableFlag = vgOrderable && theme !== THEME.VG;

    yield put(updateDeceasedDataApiActions.request(dto));

    const result:
        | ReturnType<typeof updateDeceasedDataApiActions.success>
        | ReturnType<typeof updateDeceasedDataApiActions.failure> = yield take([
        updateDeceasedDataApiActions.success,
        updateDeceasedDataApiActions.failure,
    ]);

    /**
     * Save old tokens, which are namespaced to stale slug
     */
    const tokens = yield call(getTokens);

    switch (result.type) {
        case getType(updateDeceasedDataApiActions.success):
            // eslint-disable-next-line no-case-declarations
            const newSlug = result.payload.data.attributes.slug;

            yield put(
                memorialPageSlugReceived({
                    slug: newSlug,
                }),
            );

            yield call(saveTokens, tokens.accessToken, tokens.refreshToken);

            yield put(aboutDeceasedFormSubmitActions.success());

            if (vgOrderableFlag) {
                yield put(
                    updateMemorialPageCreationStepApiActions.request({
                        step: MemorialPageCreationStep.VG,
                    }),
                );

                yield take(updateMemorialPageCreationStepApiActions.success);

                yield put(
                    push(
                        resolveRouteFromMemorialCreationStep(
                            newSlug,
                            MemorialPageCreationStep.VG,
                        ),
                    ),
                );
            } else {
                yield put(
                    updateMemorialPageCreationStepApiActions.request({
                        step: MemorialPageCreationStep.PHOTOS,
                    }),
                );

                yield take(updateMemorialPageCreationStepApiActions.success);

                yield put(
                    push(
                        resolveRouteFromMemorialCreationStep(
                            newSlug,
                            MemorialPageCreationStep.PHOTOS,
                        ),
                    ),
                );
            }

            /**
             * TODO: Handle failed step update
             */
            break;
        case getType(updateDeceasedDataApiActions.failure):
            yield put(aboutDeceasedFormSubmitActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
