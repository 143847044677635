import { createAction } from 'typesafe-actions';

import { MemorialPageCreationStep } from '../../interfaces/memorial-page-creation-step';

export const memorialPageSlugReceived = createAction(
    `meta/MEMORIAL_PAGE_SLUG_RECEIVED`,
    (action) => (payload: { slug: string | null }) => action(payload),
);

export const memorialPageCreationStepReceived = createAction(
    `meta/MEMORIAL_PAGE_CREATION_STEP_RECEIVED`,
    (action) => (payload: { state: MemorialPageCreationStep }) =>
        action(payload),
);
