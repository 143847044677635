import { call, put } from 'redux-saga/effects';

import { invitationCheckApiActions } from '../invitation-check.actions';
import { getSessionFromInvitationToken } from '../get-session-from-invitation-token';
import { memorialPageCreationStepReceived } from '../../../meta/model/meta.actions';
import { IInvitationCheckDto } from '../dto/invitation-check.dto';

export function* checkInvitationTokenSaga(
    action: ReturnType<typeof invitationCheckApiActions.request>,
) {
    try {
        const resp: IInvitationCheckDto = yield call(
            getSessionFromInvitationToken,
            action.payload.token,
        );

        yield put(invitationCheckApiActions.success(resp));
        yield put(
            memorialPageCreationStepReceived({
                state: resp.memorialPageCurrentWizardStep,
            }),
        );
    } catch (e) {
        yield put(
            invitationCheckApiActions.failure('Error checking token in API'),
        );
    }
}
