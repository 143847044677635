import { compose, mapProps } from 'recompose';
import { TextFieldProps } from '@material-ui/core/TextField';
import { TextField } from '@material-ui/core';

export const FormTextField = compose<TextFieldProps, TextFieldProps>(
    mapProps<TextFieldProps, TextFieldProps>((props) => ({
        fullWidth: true,
        variant: 'outlined',
        ...props,
    })),
)(TextField);
