import { createSelector } from 'reselect';

import { getEditorContactsStore } from '../../../editor/notifications/contacts/model/selectors/get-editor-contacts-store.selector';

import { getNotInformedNotificationContacts } from './get-not-informed-notification-contacts';

export const getEditorNotInformedNotificationContacts = createSelector(
    getNotInformedNotificationContacts,
    getEditorContactsStore,
    (contacts, editorContacts) =>
        editorContacts.selectedContactsIds.filter((id) =>
            contacts.map((contact) => contact.id).includes(id),
        ),
);
