import { call, put, select } from 'redux-saga/effects';

import { updateEventApiActions } from '../api-events.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { updateEvent } from '../http/update-event';

export function* updateEventApiSaga(
    action: ReturnType<typeof updateEventApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const dto = action.payload;

    try {
        const response = yield call(updateEvent, dto, slug, dto.id);
        yield put(updateEventApiActions.success(response));
    } catch (err) {
        yield put(updateEventApiActions.failure(err.statusText));
    }
}
