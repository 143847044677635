import { createSelector } from 'reselect';
import i18n from 'i18next';

import { ObituaryStatus } from '../obituary.store';

import { getObituaryStatus } from './get-obituary-status.selector';

export const getObituaryStatusMessage = createSelector(
    getObituaryStatus,
    (obituaryStatus) => {
        const ObituaryStatusMessages = {
            [ObituaryStatus.SUCCESS]: i18n.t('obituary.obituaryStatus.success'),
            [ObituaryStatus.INAPPROPRIATE_WORDS]: i18n.t(
                'obituary.obituaryStatus.inappropriateWords',
            ),
            [ObituaryStatus.GENERATING]: i18n.t(
                'obituary.obituaryStatus.generating',
            ),
            [ObituaryStatus.GENERATED]: i18n.t(
                'obituary.obituaryStatus.generated',
            ),
            [ObituaryStatus.GENERATE_ERROR]: i18n.t(
                'obituary.obituaryStatus.generateError',
            ),
            [ObituaryStatus.UPDATE_ERROR]: i18n.t(
                'obituary.obituaryStatus.updateError',
            ),
        };

        return obituaryStatus !== null
            ? ObituaryStatusMessages[obituaryStatus]
            : null;
    },
);
