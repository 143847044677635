import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Section, ISectionProps } from '../../components/section/section';
import { OutlinedButton } from '../../../components/outlined-button/outlined-button';
import { Colors } from '../../../../styles/colors';
import {
    facebookSharingRequested,
    messengerSharingRequested,
    whatsappSharingRequested,
} from '../../../sharing/sharing.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as MessengerIcon } from '../../../assets/icons/messenger.svg';
import { ReactComponent as WhatsappIcon } from '../../../assets/icons/whatsapp.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { getMemorialBaseUrl } from '../../../utils/url-resolvers';

import { ShareLinkPopover } from './share-link-popover/share-link-popover';

interface IOverviewNotifyingSectionProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    button: {
        color: Colors.MINE_SHAFT,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        textTransform: 'none',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    mobileButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    buttonIcon: {
        marginRight: theme.spacing(2),
    },
}));

export const OverviewNotifyingSection = (
    props: IOverviewNotifyingSectionProps,
) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const slug = useSelector(getPageSlug);
    const [
        shareLinkAnchor,
        setShareLinkAnchor,
    ] = useState<HTMLButtonElement | null>(null);

    return (
        <Section
            title={t('overviewNotifyingSection.title')}
            description={t('overviewNotifyingSection.description')}
            {...props}
        >
            <Box className={styles.buttonsContainer}>
                <OutlinedButton
                    className={styles.button}
                    onClick={() => {
                        dispatch(facebookSharingRequested());
                    }}
                >
                    <FacebookIcon className={styles.buttonIcon} />
                    {t('overviewNotifyingSection.publishButton')}
                </OutlinedButton>
                <OutlinedButton
                    className={cx(styles.button, styles.mobileButton)}
                    onClick={() => {
                        dispatch(messengerSharingRequested());
                    }}
                >
                    <MessengerIcon className={styles.buttonIcon} />
                    {t('overviewNotifyingSection.sendMessageButton')}
                </OutlinedButton>
                <OutlinedButton
                    className={cx(styles.button, styles.mobileButton)}
                    onClick={() => {
                        dispatch(whatsappSharingRequested());
                    }}
                >
                    <WhatsappIcon className={styles.buttonIcon} />
                    {t('overviewNotifyingSection.shareButton')}
                </OutlinedButton>
                <OutlinedButton
                    aria-describedby="share-link-anchor"
                    className={styles.button}
                    onClick={(event) => {
                        setShareLinkAnchor(event.currentTarget);
                    }}
                >
                    <ShareIcon className={styles.buttonIcon} />
                    {t('overviewNotifyingSection.shareLinkButton')}
                </OutlinedButton>
            </Box>
            <ShareLinkPopover
                anchorEl={shareLinkAnchor}
                href={`${getMemorialBaseUrl(
                    window.location.toString(),
                )}/${slug}`}
                id="share-link-anchor"
                onClose={() => setShareLinkAnchor(null)}
                open={Boolean(shareLinkAnchor)}
            />
        </Section>
    );
};
