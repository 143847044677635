import * as yup from 'yup';

import { IContact } from '../../../../interfaces/contact';

export const contactFormValidation = () =>
    yup.object().shape<IContact>(
        {
            name: yup.string().required(),
            email: yup
                .string()
                .email()
                .when(['phone'], {
                    is: (phone) => !phone,
                    then: yup
                        .string()
                        .email()
                        .required(),
                }),
            phone: yup
                .string()
                .transform((value: string, originalValue) => {
                    /**
                     * Trim "+" or "+1" or "+48" etc
                     */
                    if (value.indexOf('+') === 0 && value.length <= 4) {
                        return '';
                    }

                    return value;
                })
                .when(['email'], {
                    is: (email) => !email,
                    then: yup.string(),
                }),
        },
        [['email', 'phone']],
    );
