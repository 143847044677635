import { put, select } from 'redux-saga/effects';

import {
    toggleAllContactsNotSelected,
    toggleAllContactsSelected,
    toggleManyContactsSelected,
} from '../contacts.actions';
import { makeGetAreAllContactsSelected } from '../selectors/get-are-all-contacts-selected.selector';
import { makeGetContacts } from '../selectors/get-contacts.selector';
import { ICreatedContact } from '../../../../../interfaces/contact';

export function* selectAllContactsSaga(
    action: ReturnType<typeof toggleAllContactsSelected>,
) {
    const { filter } = action.payload;

    const areAllSelected = yield select(makeGetAreAllContactsSelected(filter));

    if (areAllSelected) {
        yield put(toggleAllContactsNotSelected());
    } else {
        const visibleContacts: ICreatedContact[] = yield select(
            makeGetContacts(filter),
        );

        const ids = visibleContacts.map((contact) => contact.id);

        yield put(
            toggleManyContactsSelected({
                ids,
            }),
        );
    }
}
