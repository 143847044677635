import { call, put, select } from '@redux-saga/core/effects';

import { acceptGallerySuggestionApiActions } from '../gallery-suggestions.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { acceptSuggestion } from '../../suggestions/http/accept-suggestion';

export function* acceptGallerySuggestionApiSaga(
    action: ReturnType<typeof acceptGallerySuggestionApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id } = action.payload;

    try {
        yield call(acceptSuggestion, { slug, id });

        yield put(acceptGallerySuggestionApiActions.success());
    } catch (err) {
        yield put(acceptGallerySuggestionApiActions.failure(err.statusText));
    }
}
