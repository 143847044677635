import { delay, put, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { addNotificationContact } from '../contacts.actions';
import { setContactSuggestionStatus } from '../../../../../model/notification-contacts/notification-contacts.actions';
import { addNotificationContactApiActions } from '../../../../../api/contacts/contacts-api.actions';

export function* addNotificationContactSaga(
    action: ReturnType<typeof addNotificationContact.request>,
) {
    const { name, email, phone } = action.payload;

    yield put(
        addNotificationContactApiActions.request({
            displayName: name,
            email: email!,
            phone: phone!,
        }),
    );

    const result = yield take([
        addNotificationContactApiActions.success,
        addNotificationContactApiActions.failure,
    ]);

    if (isActionOf(addNotificationContactApiActions.success, result)) {
        yield put(addNotificationContact.success(result.payload.contact));
    }

    yield put(setContactSuggestionStatus(result.payload.status));
    yield delay(3000);
    yield put(setContactSuggestionStatus(null));
}
