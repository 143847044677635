import { delay, put, take } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';
import i18next from 'i18next';

import { generateObituaryRequested } from '../obituary.actions';
import {
    generatedObituaryReceived,
    setObituaryStatus,
} from '../../../model/obituary/obituary-model.actions';
import { ObituaryStatus } from '../../../model/obituary/obituary.store';
import { generateObituaryTextApiActions } from '../../../api/obituary/obituary-api.actions';
import { mapObituaryFormToGenerateObituaryDto } from '../mappers/map-obituary-form-to-generate-obituary-dto';
import { richTextToHtml } from '../../../utils/rich-text-editor-helpers';

export function* handleGenerateObituarySaga(
    action: ReturnType<typeof generateObituaryRequested>,
) {
    const formData = action.payload;

    const dto = mapObituaryFormToGenerateObituaryDto(formData);

    dto.language = i18next.language;

    yield put(generateObituaryTextApiActions.request(dto));

    yield put(setObituaryStatus(ObituaryStatus.GENERATING));

    const result = yield take([
        generateObituaryTextApiActions.success,
        generateObituaryTextApiActions.failure,
    ]);

    if (isActionOf(generateObituaryTextApiActions.success, result)) {
        const obituaryText = richTextToHtml(result.payload.obituaryText || '');
        yield put(
            generatedObituaryReceived({
                obituaryText,
            }),
        );
        yield put(setObituaryStatus(ObituaryStatus.GENERATED));
        yield delay(3000);
        yield put(setObituaryStatus(null));
    } else if (isActionOf(generateObituaryTextApiActions.failure, result)) {
        yield put(setObituaryStatus(result.payload.obituaryStatus));
        yield delay(3000);
        yield put(setObituaryStatus(null));
    }
}
