import ky, { Input, Options, HTTPError } from 'ky-universal';

import { browserHistory } from '../routing/browser-history';
import { AuthPath } from '../routing/auth-path';
import { resolveRouteWithSlug } from '../routing/resolve-route-with-slug';

import { clearTokens, getTokens, saveTokens } from './auth-token-storage';
import { refreshTokens } from './refresh-tokens';

export interface IApiServiceOptions {
    host: string;
    mapHeaders(headers: Headers): Headers;
    mapOptions(options: Options): Options;
}

/**
 * Simple proxy on HTTP library
 */
export class ApiService {
    private readonly host: string = '';

    private readonly options: IApiServiceOptions = {
        host: '',
        mapHeaders(headers: Headers) {
            return headers;
        },
        mapOptions(options: Options) {
            return options;
        },
    };

    constructor(options: Partial<IApiServiceOptions>) {
        this.options = { ...this.options, ...options };
        this.host = options.host || '';
        this.options.mapOptions = options.mapOptions || this.options.mapOptions;
    }

    get(input: Input, options?: Options) {
        return ky.get(this.host + input, this.mapOptions(options));
    }

    post(input: Input, options?: Options) {
        return ky.post(this.host + input, this.mapOptions(options));
    }

    put(input: Input, options?: Options) {
        return ky.put(this.host + input, this.mapOptions(options));
    }

    patch(input: Input, options?: Options) {
        return ky.patch(this.host + input, this.mapOptions(options));
    }

    delete(input: Input, options?: Options) {
        return ky.delete(this.host + input, this.mapOptions(options));
    }

    private mapOptions(options?: Options) {
        const newOptions: Options = options || {};
        let clonedHeaders: Headers = new Headers(newOptions.headers || {});

        clonedHeaders = this.options.mapHeaders(clonedHeaders);

        const mappedOptions = {
            ...newOptions,
            headers: clonedHeaders,
        };

        return this.options.mapOptions(mappedOptions);
    }
}

const attachAuthHeader = (headers: Headers) => {
    const newHeaders = new Headers(headers);

    const tokens = getTokens();

    if (!tokens) {
        return headers;
    }

    newHeaders.append('Authorization', `JWT ${tokens.accessToken}`);

    return newHeaders;
};

export const getLoginLink = () => {
    const slug = window.memorialPageSlug;
    const resolveRoute = resolveRouteWithSlug(slug);

    return resolveRoute(AuthPath.LOGIN);
};

export const apiService = new ApiService({
    mapHeaders: attachAuthHeader,
    host: process.env.REACT_APP_API_HOST as string,
    mapOptions: (options) => {
        return {
            ...options,
            retry: {
                statusCodes: [403, 404],
                methods: ['patch', 'get', 'delete', 'post'],
            },
            hooks: {
                beforeRetry: [
                    async (input, opts, errors, retryCount) => {
                        /**
                         * TODO: Check if error code is wrong token
                         *   Currently ky doesnt expose it to errors
                         *   so it has to be stored outside.
                         */

                        if (retryCount > 1) {
                            /**
                             * Assume that if refreshing token fails, both tokens
                             * are stale and redirect user to login.
                             */
                            clearTokens();
                            browserHistory.push(getLoginLink());
                            return;
                        }
                        if (errors instanceof HTTPError) {
                            if (errors.response.status === 404) {
                                browserHistory.push(
                                    AuthPath.LOGIN_WITHOUT_SLUG,
                                );
                                return;
                            }
                        }
                        const tokens = getTokens();

                        if (!(tokens && tokens.refreshToken)) {
                            browserHistory.push(getLoginLink());
                            return;
                        }

                        try {
                            const freshTokens = await refreshTokens(
                                tokens.refreshToken,
                            );

                            saveTokens(
                                freshTokens.accessToken,
                                freshTokens.refreshToken,
                            );

                            opts.headers.set(
                                'Authorization',
                                `JWT ${freshTokens.accessToken}`,
                            );
                        } catch (e) {
                            clearTokens();
                            browserHistory.push(getLoginLink());
                        }
                    },
                ],
            },
        };
    },
});
