import { call, put, select } from 'redux-saga/effects';

import { deleteGalleryItemApiActions } from '../gallery-api.actions';
import { deleteImageFromGallery } from '../http/delete-image-from-gallery';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';

export function* deleteGalleryItemApiSaga(
    action: ReturnType<typeof deleteGalleryItemApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id } = action.payload;

    try {
        yield call(deleteImageFromGallery, slug, id);
        yield put(deleteGalleryItemApiActions.success({ id }));
    } catch (e) {
        yield put(deleteGalleryItemApiActions.failure(e.statusText));
    }
}
