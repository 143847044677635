import { createReducer } from 'typesafe-actions';

import { IContext } from '../context-selection';

import { fetchContextsApiActions } from './saga/context.actions';

export interface IContextStore {
    contexts: IContext[];
}

const initialState: IContextStore = {
    contexts: [],
};

type Actions = ReturnType<typeof fetchContextsApiActions.success>;

export const contextReducer = createReducer<IContextStore, Actions>(
    initialState,
).handleAction(fetchContextsApiActions.success, (state, action) => {
    const contexts: IContext[] = [];
    for (let i = 0; i < action.payload.data.length; i += 1) {
        const context: IContext = {
            birth: action.payload.included[i].attributes.birthDate,
            death: action.payload.included[i].attributes.deathDate,
            name: `${action.payload.included[i].attributes.firstName} 
                ${action.payload.included[i].attributes.lastName}`,
            id: action.payload.data[i].id,
            image: action.payload.included[i].attributes.photoOriginalUrl,
            slug: action.payload.data[i].attributes.slug,
            creationStep: action.payload.data[i].attributes.currentWizardStep,
        };
        contexts.push(context);
    }
    return { ...state, contexts };
});
