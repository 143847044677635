import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    gallerySuggestionsRequested,
    gallerySuggestionAcceptanceRequested,
    gallerySuggestionRejectionRequested,
} from '../model/gallery-suggestions/gallery-suggestions.actions';
import { getPendingGalleryItems } from '../model/gallery-suggestions/selectors/get-pending-gallery-items.selector';

export const useGallerySuggestions = () => {
    const dispatch = useDispatch();

    const galleryItems = useSelector(getPendingGalleryItems);

    useEffect(() => {
        dispatch(gallerySuggestionsRequested());
    }, [dispatch]);

    return {
        galleryItems,
        acceptGallerySuggestion(id: string) {
            dispatch(gallerySuggestionAcceptanceRequested({ id }));
        },
        rejectGallerySuggestion(id: string) {
            dispatch(gallerySuggestionRejectionRequested({ id }));
        },
    };
};
