import { put } from 'redux-saga/effects';

import { contactDeletionRequested } from '../contacts.actions';
import { deleteContactApiActions } from '../../../../../api/contacts/contacts-api.actions';

export function* deleteContactSaga(
    action: ReturnType<typeof contactDeletionRequested>,
) {
    yield put(deleteContactApiActions.request({ id: action.payload.id }));
}
