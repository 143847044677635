import { THEME, THEME_DETAILS } from './get-theme';

export const getThemeBeforeLogIn = (receivedTheme: string) => {
    let th;
    const url = window.location.href;
    if (receivedTheme) {
        th = receivedTheme;
    } else if (url.includes(THEME_DETAILS.vg.url)) {
        th = THEME.VG;
    } else {
        th = THEME.DEFAULT;
    }

    return th;
};
