import { ISuggestionDto } from '../dto/suggestion.dto';
import { ISuggestion } from '../../../interfaces/suggestion';

export const mapSuggestionDtoToSuggestion = (
    dto: ISuggestionDto,
): ISuggestion => {
    const suggestion: ISuggestion = {
        id: dto.id,
        status: dto.attributes.status,
    };

    return suggestion;
};
