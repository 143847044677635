import { call, put, select } from 'redux-saga/effects';

import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { fetchGalleryItemsApiActions } from '../gallery-api.actions';
import { fetchGalleryItems } from '../http/fetch-gallery-items';
import { IGalleryResponseDto } from '../dto/gallery-response.dto';

export function* fetchGalleryItemsApiSaga(
    action: ReturnType<typeof fetchGalleryItemsApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response: IGalleryResponseDto[] = yield call(
            fetchGalleryItems,
            slug,
        );
        yield put(
            fetchGalleryItemsApiActions.success(
                response.map((galleryItemDto) => ({
                    id: galleryItemDto.id,
                    published: galleryItemDto.attributes.published,
                    thumbSrc: galleryItemDto.attributes.thumbUrl,
                    originalSrc: galleryItemDto.attributes.originalUrl,
                })),
            ),
        );
    } catch (e) {
        yield put(fetchGalleryItemsApiActions.failure(e.statusText));
    }
}
