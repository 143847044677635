import { utcToZonedTime } from 'date-fns-tz';

import { apiService } from '../api.service';
import { ITask } from '../../interfaces/task';

import { TaskResponseDto } from './dto/task-response.dto';

export const fetchTasks = (slug: string): Promise<ITask[]> => {
    const url = `/tasks`;

    return (
        apiService
            .get(url, {
                searchParams: {
                    taskable_type: 'MemorialPage',
                    taskable_id: slug,
                },
            })
            .then((result) => result.json())
            .then((response): TaskResponseDto[] => response.data)
            /**
             * Move mapper to function
             */
            .then((tasks) => {
                return tasks.map(
                    (rawTask): ITask => ({
                        description: rawTask.attributes.description,
                        expirationDate: rawTask.attributes.deadlineTimestamp
                            ? utcToZonedTime(
                                  rawTask.attributes.deadlineTimestamp,
                                  rawTask.attributes.timeZone,
                              )
                            : null,
                        id: rawTask.id,
                        task: rawTask.attributes.title,
                        status: rawTask.attributes.status,
                    }),
                );
            })
    );
};
