import { delay, put, take } from 'redux-saga/effects';

import {
    setNewResetPasswordRequested,
    setResetPasswordStatus,
} from '../reset-password-page.actions';
import { passwordResetApiActions } from '../../../../api/password/password.actions';
import { ResetPasswordStatus } from '../reset-password.store';

export function* requestSetNewResetPasswordSaga(
    action: ReturnType<typeof setNewResetPasswordRequested>,
) {
    const { password, token } = action.payload;

    yield put(
        passwordResetApiActions.request({
            password,
            token,
        }),
    );

    if (yield take(passwordResetApiActions.success)) {
        yield put(
            setResetPasswordStatus(ResetPasswordStatus.PASSWORD_SET_SUCCEEDED),
        );
        yield delay(3000);
        yield put(setResetPasswordStatus(null));
    }
}
