import { apiService } from '../../api.service';
import { IUpdateTributeDto } from '../dto/update-tribute.dto';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';

export const updateTributeText = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IUpdateTributeDto;
}): Promise<IMemorialPageMetaDataResponse> => {
    return apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                memorial_page: dto,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
