import { useSelector } from 'react-redux';

import { getTheme } from '../model/theme/selectors/get-theme.selector';
import { getVgOrderable } from '../model/vg/selectors/get-vg-orderable.selector';
import { THEME } from '../utils/get-theme';

export const useVgOrderable = () => {
    const theme = useSelector(getTheme);
    const vgOrderable = useSelector(getVgOrderable);
    const vgOrderableFlag = vgOrderable && theme !== THEME.VG;

    return { vgOrderableFlag };
};
