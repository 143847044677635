import { apiService } from '../api.service';

import { IInvitationCheckDto } from './dto/invitation-check.dto';

export const getSessionFromInvitationToken = (
    token: string,
): Promise<IInvitationCheckDto> => {
    return (
        apiService
            .get(`/auth/invitations/${token}`)
            .then((r) => r.json())
            /**
             * Extract meaningful data
             */
            .then((resp) => resp.data.attributes)
    );
};
