import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';
import { getInformedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-informed-notification-contacts';
import { getNotInformedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-not-informed-notification-contacts';
import { getSuggestedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-suggested-notification-contacts';
import { getAllNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-all-notification-contacts';

export const resolveContactsSelector = (
    filter: NotificationContactStatus | null,
) => {
    switch (filter) {
        case NotificationContactStatus.INFORMED:
            return getInformedNotificationContacts;

        case NotificationContactStatus.NOT_INFORMED:
            return getNotInformedNotificationContacts;

        case NotificationContactStatus.SUGGESTED:
            return getSuggestedNotificationContacts;

        case null:
        default:
            return getAllNotificationContacts;
    }
};
