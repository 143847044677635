import { createAction } from 'typesafe-actions';

export const deceasedDisplayImagesReceived = createAction(
    'model/deceased-display-images/RECEIVED',
    (action) => (payload: {
        avatarSrc: string | null;
        backgroundID: string | null;
    }) => action(payload),
);

export const deceasedDisplayImagesAvatarReceived = createAction(
    'model/deceased-display-images/AVATAR_RECEIVED',
    (action) => (payload: string) => action(payload),
);
