import { put, call } from '@redux-saga/core/effects';
import i18next from 'i18next';

import { languageChanged, languageRetrieved } from '../configuration.actions';
import { getLanguageFromStorage } from '../../../utils/language-storage-utils';

export function* handleLanguageRetrieveFromMemorialPageSaga(
    action: ReturnType<typeof languageRetrieved>,
) {
    const { language } = action.payload;
    const languageFromStorage = getLanguageFromStorage();
    const lang = languageFromStorage || language;

    yield put(languageChanged({ language: lang }));

    yield call(
        {
            context: i18next,
            fn: i18next.changeLanguage,
        },
        lang.code,
    );
}
