import { createReducer } from 'typesafe-actions';

import { ICreatedContact } from '../../../interfaces/contact';

import {
    wizardNotificationContactAddActions,
    wizardSetNotificationMessageStatus,
} from './wizard-notifications.actions';

export enum NotificationMessageStatus {
    INAPPROPRIATE_WORDS = 'INAPPROPRIATE_WORDS',
}

export interface IWizardNotificationsStore {
    createdContacts: ICreatedContact[];
    messageStatus: NotificationMessageStatus | null;
}

const initialState: IWizardNotificationsStore = {
    createdContacts: [],
    messageStatus: null,
};

type Actions =
    | ReturnType<typeof wizardNotificationContactAddActions.success>
    | ReturnType<typeof wizardSetNotificationMessageStatus>;

export const wizardNotificationsReducer = createReducer<
    IWizardNotificationsStore,
    Actions
>(initialState)
    .handleAction(
        wizardNotificationContactAddActions.success,
        (state, action) => {
            const contacts: ICreatedContact[] = [
                ...state.createdContacts,
                {
                    name: action.payload.name,
                    email: action.payload.email,
                    phone: action.payload.phone,
                    id: action.payload.id,
                },
            ];

            return {
                ...state,
                createdContacts: contacts,
            };
        },
    )
    .handleAction(wizardSetNotificationMessageStatus, (state, action) => ({
        ...state,
        messageStatus: action.payload,
    }));
