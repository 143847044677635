import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { loginPageActions, resetPasswordActions } from '../login-page.actions';

import { requestUserLoginSaga } from './request-user-login.saga';
import { requestPasswordResetSaga } from './request-password-reset.saga';

export function* loginPageSaga() {
    yield takeEvery(getType(loginPageActions.request), requestUserLoginSaga);

    yield takeEvery(
        getType(resetPasswordActions.request),
        requestPasswordResetSaga,
    );
}
