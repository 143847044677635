import cx from 'classnames';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import { compose, defaultProps, mapProps } from 'recompose';
import { ButtonProps } from '@material-ui/core/Button';

import { Colors } from '../../../styles/colors';

interface IWithLinkTarget {
    target?: string;
}

export const OutlinedButton = compose<
    ButtonProps & IWithLinkTarget,
    ButtonProps & IWithLinkTarget
>(
    withStyles(() => ({
        button: {
            boxShadow: 'none',
            color: Colors.MINE_SHAFT,
            fontWeight: 'bold',
        },
    })),
    defaultProps({
        variant: 'outlined',
    }),
    mapProps<
        ButtonProps & IWithLinkTarget,
        ButtonProps & WithStyles & IWithLinkTarget
    >(({ classes, ...props }) => ({
        ...props,
        className: cx(classes.button, props.className),
    })),
)(Button);
