import { createAction } from 'typesafe-actions';

import { ISuggestion } from '../../interfaces/suggestion';

export const suggestionsFetchRequested = createAction(
    'model/suggestions/SUGGESTIONS_FETCH_REQUESTED',
);

export const suggestionsReceived = createAction(
    'model/suggestions/SUGGESTIONS_RECEIVED',
    (action) => (payload: ISuggestion[]) => action(payload),
);
