import { apiService } from '../../api.service';
import { IMemorialEvent } from '../../../interfaces/memorial-event';
import { IEventRequestDto } from '../dto/event-request.dto';
import { mapEventResponseToEvents } from '../map-event-response-to-events';

import { buildSchedulableEventsUrl } from './helpers';

export const updateEvent = (
    dto: Partial<IEventRequestDto>,
    slug: string,
    id: string,
): Promise<IMemorialEvent> => {
    const url = buildSchedulableEventsUrl(slug, id);

    return apiService
        .patch(url, {
            json: dto,
        })
        .then((resp) => resp.json())
        .then((resp) => mapEventResponseToEvents(resp) as IMemorialEvent);
};
