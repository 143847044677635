import { createReducer } from 'typesafe-actions';

import { ITask } from '../../interfaces/task';

import {
    closeTaskCreateModalRequested,
    closeTaskUpdateModalRequested,
    createTaskAsyncActions,
    deleteTaskAsyncActions,
    taskCreationRequested,
    taskUpdateRequested,
    updateTaskAsyncActions,
} from './tasks.actions';

export interface ITasksStore {
    createModalOpen: boolean;
    updateModalOpen: boolean;
    editedTask: ITask | null;
}

const initialState: ITasksStore = {
    createModalOpen: false,
    updateModalOpen: false,
    editedTask: null,
};

type Actions =
    | ReturnType<typeof createTaskAsyncActions.success>
    | ReturnType<typeof updateTaskAsyncActions.success>
    | ReturnType<typeof deleteTaskAsyncActions.success>
    | ReturnType<typeof taskCreationRequested>
    | ReturnType<typeof closeTaskCreateModalRequested>
    | ReturnType<typeof taskUpdateRequested>
    | ReturnType<typeof closeTaskUpdateModalRequested>;

export const tasksSectionReducer = createReducer<ITasksStore, Actions>(
    initialState,
)
    .handleAction(taskCreationRequested, (state) => {
        return {
            ...state,
            createModalOpen: true,
        };
    })
    .handleAction(closeTaskCreateModalRequested, (state) => {
        return {
            ...state,
            createModalOpen: false,
        };
    })
    .handleAction(taskUpdateRequested, (state, action) => {
        return {
            ...state,
            updateModalOpen: true,
            editedTask: action.payload.editedTask,
        };
    })
    .handleAction(closeTaskUpdateModalRequested, (state) => {
        return {
            ...state,
            updateModalOpen: false,
            editedTask: null,
        };
    })
    .handleAction(createTaskAsyncActions.success, (state, action) => {
        return {
            ...state,
            createModalOpen: false,
        };
    })
    .handleAction(updateTaskAsyncActions.success, (state, { payload }) => {
        return {
            ...state,
            updateModalOpen: false,
        };
    });
