import { call, put, select } from '@redux-saga/core/effects';

import { sendPasswordResetEmailApiActions } from '../password.actions';
import { sendResetPasswordMail } from '../http/send-password-reset-mail';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { AuthPath } from '../../../routing/auth-path';
import { getNotifyBaseUrl } from '../../../utils/url-resolvers';

export function* sendPasswordResetEmailSaga(
    action: ReturnType<typeof sendPasswordResetEmailApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const resetPasswordUrl = AuthPath.RESET_PASSWORD.replace('/:slug', '');

    let redirectUrl;
    if (slug) {
        redirectUrl = `${getNotifyBaseUrl(
            window.location.toString(),
        )}/${slug}${resetPasswordUrl}?token={{token}}`;
    } else {
        redirectUrl = `${getNotifyBaseUrl(
            window.location.toString(),
        )}${resetPasswordUrl}?token={{token}}`;
    }
    try {
        yield call(sendResetPasswordMail, {
            email: action.payload.email,
            redirectUrl,
        });

        yield put(sendPasswordResetEmailApiActions.success());
    } catch (e) {
        yield put(sendPasswordResetEmailApiActions.failure(e.statusText));
    }
}
