import { WizardPath } from '../../routing/wizard-paths';

export const resolveWizardHeadlineText = (path: WizardPath) => {
    switch (path) {
        case WizardPath.DECEASED:
            return 'wizard.deceased.subHeadlineText';
        case WizardPath.PHOTOS:
            return 'wizard.photos.subHeadlineText';
        case WizardPath.NOTIFICATION:
            return 'wizard.notification.subHeadlineText';
        default:
            return '';
    }
};
