import React, { ChangeEvent, useRef } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Typography,
    Theme,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { StepHeadline } from '../components/step-headline/step-headline';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { Colors } from '../../../styles/colors';
import { hexToRgba } from '../../utils/hex-to-rgba';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { WizardContentContainer } from '../components/wizard-content-container/wizard-content-container';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { ImageCropModal } from '../../components/image-crop-modal/image-crop-modal';
import { useDeceasedPersonData } from '../../model/deceased-person/use-deceased-person-data';
import { ReactComponent as CandleIcon } from '../../assets/icons/candle.svg';
import { getTheme } from '../../model/theme/selectors/get-theme.selector';
import { useWizardStep } from '../use-wizard-step';

import { PhotoButton } from './components/photo-button/photo-button';
import { SelectBackgroundModal } from './components/select-background-modal/select-background-modal';
import { useWizardPhotosScreenState } from './use-wizard-photos-screen-state';

type Props = RouteComponentProps;

const useStyles = makeStyles<Theme>((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
    uploadBox: {
        alignItems: 'center',
        backgroundColor: Colors.ATHENS_GRAY,
        backgroundSize: 'cover',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(14),
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(8),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            paddingTop: theme.spacing(14),
        },
    },
    infoContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: `0 3px 40px -5px ${Colors.IRON}`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 2),
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3, 6),
        },
        width: '100%',
        '&$vg': {
            backgroundColor: `${Colors.WHITE}b3`,
            borderRadius: 10,
        },
    },
    nameBox: {
        alignItems: 'center',
        backgroundColor: Colors.MYSTIC,
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        boxShadow: `0 5px 9px -3px ${Colors.RHINO}`,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        padding: theme.spacing(1, 4),
        position: 'absolute',
        right: 0,
        top: '100%',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            borderRadius: '3px',
            bottom: '-4.6rem',
            left: '50%',
            right: 'initial',
            top: 'initial',
            transform: 'translate(-50%, -50%)',
        },
        '&$vg': {
            borderRadius: 0,
            boxShadow: 'none',
            backgroundColor: Colors.VG_DARK,
            [theme.breakpoints.up('md')]: {
                fontSize: '18pt',
            },
        },
    },
    name: {
        color: Colors.RHINO,
        fontSize: '1.6rem',
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
        '&$vg': {
            textTransform: 'uppercase',
            color: Colors.WHITE,
            [theme.breakpoints.up('sm')]: {
                fontSize: '18pt',
            },
        },
    },
    dateType: {
        color: Colors.TONYS_PINK,
        fontSize: '1rem',
        fontWeight: 'bold',
        position: 'relative',
        textTransform: 'uppercase',
        '&:after': {
            content: '""',
            width: '0.6rem',
            height: '1px',
            backgroundColor: Colors.TONYS_PINK,
            position: 'absolute',
            right: '-1rem',
            top: '50%',
            transform: 'translateY(-50%)',
            [theme.breakpoints.up('sm')]: {
                right: '-2rem',
                width: '1rem',
            },
        },
        '&:before': {
            content: '""',
            width: '0.6rem',
            height: '1px',
            backgroundColor: Colors.TONYS_PINK,
            position: 'absolute',
            left: '-1rem',
            top: '50%',
            transform: 'translateY(-50%)',
            [theme.breakpoints.up('sm')]: {
                left: '-2rem',
                width: '1rem',
            },
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem',
        },
        '&$vg': {
            fontFamily: 'Raleway',
            fontWeight: 400,
            color: Colors.BLACK,
            [theme.breakpoints.up('sm')]: {
                fontSize: '11pt',
            },
            '&:after, &:before': {
                display: 'none',
            },
        },
    },
    date: {
        color: Colors.MINE_SHAFT,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
    },
    dateBox: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    avatar: {
        alignItems: 'center',
        borderRadius: '18rem',
        backgroundColor: Colors.BLACK,
        display: 'flex',
        height: '10rem',
        justifyContent: 'center',
        left: '50%',
        position: 'absolute',
        top: '-1.5rem',
        transform: 'translate(-50%, -50%)',
        width: '10rem',
        objectFit: 'contain',
        [theme.breakpoints.up('sm')]: {
            height: '16rem',
            width: '16rem',
        },
    },
    candleContainer: {
        backgroundColor: Colors.MYSTIC,
    },
    avatarButton: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginRight: theme.spacing(2),
        },
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3),
        },
    },
    photoButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    line: {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${hexToRgba(
            Colors.TONYS_PINK,
            0.79,
        )}, rgba(0, 0, 0, 0))`,
        border: 0,
        display: 'none',
        height: '1px',
        left: 0,
        position: 'absolute',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    lineTop: {
        top: '1rem',
        '&$vg': {
            display: 'none',
        },
    },
    lineBottom: {
        bottom: '1rem',
    },
    vg: {},
}));

export const WizardPhotosScreen = () => {
    const { t } = useTranslation();
    const {
        closeBgModal,
        openBgModal,
        bgModalOpen,
        setSelectedBackgroundID,
        selectedBackgroundID,
        availableBackgrounds,
        avatarPreview,
        skipStep,
        submit,
        imageCropModalOpen,
        setImageCropModalOpen,
        selectedFile,
        setSelectedFile,
    } = useWizardPhotosScreenState();
    const th = useSelector(getTheme);

    const { deceasedPersonData } = useDeceasedPersonData();
    const wizardStepProgress = useWizardStep();

    const { getShortDate } = useDateFormatter();

    const styles = useStyles();
    const avatarUploadRef = useRef<HTMLInputElement>(null);

    const mobile = useIsMobile();

    const onChangeAvatarClick = () => {
        avatarUploadRef.current!.click();
    };

    const onAvatarUploaded = (event: ChangeEvent<HTMLInputElement>) => {
        if (!get(event, 'target.files.length')) {
            return;
        }

        const file = event.target!.files![0] as File;

        setSelectedFile(file);
        setImageCropModalOpen(true);

        // eslint-disable-next-line
        event.target.value = ''; // to prevent blocking same file upload, any cleaner solution?
    };

    const getBackgroundImageSource = () => {
        const selectedBgObject = availableBackgrounds.find(
            (image) => image.id === selectedBackgroundID,
        );

        return selectedBgObject ? `url("${selectedBgObject.src}")` : '';
    };

    return (
        <WizardContentContainer>
            <StepHeadline
                data-testid="wizard-photos-step-headline"
                text={t('photos.headline')}
                stepNo={wizardStepProgress + 2}
                className={styles.headline}
            />
            <Box
                mb={2}
                data-testid="wizard-photos-background-box"
                className={styles.uploadBox}
                style={{
                    backgroundImage: getBackgroundImageSource(),
                }}
            >
                <Box className={cx(styles.infoContainer, styles[th])}>
                    <Box
                        className={cx(styles.line, styles.lineTop, styles[th])}
                    />
                    <Box className={cx(styles.line, styles.lineBottom)} />
                    <Box className={styles.dateBox}>
                        <Typography className={cx(styles.dateType, styles[th])}>
                            {t('common.birth')}
                        </Typography>
                        <Typography
                            data-testid="wizard-photos-step-birth"
                            className={styles.date}
                        >
                            {deceasedPersonData.birthDate
                                ? getShortDate(deceasedPersonData.birthDate)
                                : '-'}
                        </Typography>
                    </Box>
                    <Box className={styles.dateBox}>
                        <Typography className={cx(styles.dateType, styles[th])}>
                            {t('common.death')}
                        </Typography>
                        <Typography
                            data-testid="wizard-photos-step-death"
                            className={styles.date}
                        >
                            {deceasedPersonData.deathDate
                                ? getShortDate(deceasedPersonData.deathDate)
                                : '-'}
                        </Typography>
                    </Box>
                    {avatarPreview ? (
                        <img
                            data-testid="wizard-photos-avatar-preview"
                            className={styles.avatar}
                            src={avatarPreview}
                            alt=""
                        />
                    ) : (
                        <Box
                            data-testid="wizard-photos-step-avatar-candle"
                            className={cx(
                                styles.avatar,
                                styles.candleContainer,
                            )}
                        >
                            <CandleIcon />
                        </Box>
                    )}
                    <Box className={cx(styles.nameBox, styles[th])}>
                        <Typography
                            data-testid="wizard-photos-step-name"
                            className={cx(styles.name, styles[th])}
                        >
                            {deceasedPersonData.name}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <input
                ref={avatarUploadRef}
                type="file"
                hidden
                accept="image/jpeg,image/png"
                max={1}
                onChange={onAvatarUploaded}
            />
            <Box className={styles.photoButtonsContainer}>
                <PhotoButton
                    data-testid="wizard-photos-step-change-photo"
                    active={Boolean(selectedBackgroundID)}
                    className={styles.avatarButton}
                    fullWidth={mobile}
                    onClick={onChangeAvatarClick}
                >
                    {t('photos.changePicture')}
                </PhotoButton>
                <PhotoButton
                    active={Boolean(selectedBackgroundID)}
                    onClick={openBgModal}
                    data-testid="wizard-photos-change-background-button"
                    fullWidth={mobile}
                >
                    {t('photos.selectBackground')}
                </PhotoButton>
            </Box>
            <Grid
                container
                justify="flex-end"
                className={styles.buttonsContainer}
            >
                <Grid
                    item
                    xs={mobile ? 12 : undefined}
                    style={{
                        order: mobile ? 1 : undefined,
                    }}
                >
                    <Button
                        fullWidth={mobile}
                        data-testid="wizard-photos-skip-button"
                        size="large"
                        color="primary"
                        onClick={skipStep}
                    >
                        {t('common.skipThisStep')}
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={mobile ? 12 : undefined}
                    style={{
                        order: mobile ? 0 : undefined,
                    }}
                >
                    <PrimaryButton
                        data-testid="wizard-photos-step-submit"
                        onClick={submit}
                        type="submit"
                        fullWidth={mobile}
                    >
                        {t('common.continue')}
                    </PrimaryButton>
                </Grid>
            </Grid>
            <SelectBackgroundModal
                fullScreen={mobile}
                data-testid="wizard-photos-bg-modal"
                backgroundImages={availableBackgrounds}
                onSelected={(id) => {
                    closeBgModal();
                    setSelectedBackgroundID(id);
                }}
                open={bgModalOpen}
                selectedID={selectedBackgroundID}
                onCancel={closeBgModal}
            />
            <ImageCropModal
                cancellable
                fileName={get(selectedFile, 'name', '')}
                fullScreen={mobile}
                image={avatarPreview}
                onCancel={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onClose={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onCrop={(file) => {
                    setSelectedFile(file);
                    setImageCropModalOpen(false);
                }}
                onSkipCropping={() => setImageCropModalOpen(false)}
                open={imageCropModalOpen}
            />
        </WizardContentContainer>
    );
};

export const ComposedWizardPhotosScreen = compose<Props, Props>()(
    WizardPhotosScreen,
);
