import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StepHeadline } from '../components/step-headline/step-headline';
import { WizardContentContainer } from '../components/wizard-content-container/wizard-content-container';
import { useDeceasedPersonData } from '../../model/deceased-person/use-deceased-person-data';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { editorGetEventsAsyncActions } from '../../events/model/events.actions';

import {
    aboutDeceasedFormSubmitted,
    wizardSkipRequested,
} from './model/wizard-about-deceased.actions';
import { IWizardAboutDeceasedFormValues } from './model/wizard-about-deceased-form-values';
import { ConnectedAboutDeceasedForm } from './about-deceased-form/about-deceased-form';
import { getWizardTermsAccepted } from './model/selectors/get-wizard-terms-accepted.selector';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
}));

/**
 * Consider moving forming to HOC and form to another component if complexity grows
 */
export const WizardDeceasedScreen = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const styles = useStyles();
    const termsAccepted = useSelector(getWizardTermsAccepted);
    const slug = useSelector(getPageSlug);

    useEffect(() => {
        if (slug) {
            dispatch(editorGetEventsAsyncActions.request());
        }
    }, [dispatch, slug]);

    const onSubmit = (values: IWizardAboutDeceasedFormValues) => {
        dispatch(aboutDeceasedFormSubmitted(values));
    };

    const onSkip = () => {
        dispatch(wizardSkipRequested());
    };

    const { deceasedPersonData } = useDeceasedPersonData();

    return (
        <WizardContentContainer>
            <StepHeadline
                data-testid="wizard-deceased-step-headline"
                className={styles.headline}
                text={t('deceased.headline')}
                stepNo={1}
            />
            <ConnectedAboutDeceasedForm
                deceasedData={deceasedPersonData}
                onSubmit={onSubmit}
                onSkipRequested={onSkip}
                termsAndConditionAccepted={termsAccepted}
            />
        </WizardContentContainer>
    );
};
