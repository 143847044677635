import { createSelector } from 'reselect';

import { mapLanguagesToFlags } from '../map-languages-to-flags';

import { getLanguages } from './get-languages.selector';

export const getLanguagesWithFlags = createSelector(
    getLanguages,
    (languages) => mapLanguagesToFlags(languages),
);
