import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import {
    Box,
    ButtonBase,
    Container,
    IconButton,
    makeStyles,
    SwipeableDrawer,
} from '@material-ui/core';
import NavIcon from '@material-ui/icons/Menu';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { ArrowRightAlt } from '@material-ui/icons';

import { Logo } from '../components/logo/logo';
import { Menu } from '../components/menu/menu';
import { ComposedLanguageSelector } from '../../components/language-selector/language-selector';
import { editorRoutes } from '../../routing/editor-routes';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { withPageSlugDetecting } from '../../meta/with-page-slug-detecting';
import { withPageMetaFetching } from '../../meta/with-page-meta-fetching';
import { withWizardCreationFinished } from '../../meta/with-wizard-creation-finished';
import { getIsPageFetched } from '../../meta/model/selectors/get-is-page-fetched';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    topContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
    },
    menu: {
        paddingRight: theme.spacing(5),
        marginTop: theme.spacing(5),
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            width: 'auto',
        },
    },
    contentContainer: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export const EditorLayout = ({ location, ...props }: Props) => {
    const styles = useStyles();
    const mobile = useIsMobile();
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const pageFetched = useSelector(getIsPageFetched);

    useEffect(() => {
        if (mobile) {
            setMobileNavOpen(false);
        }
    }, [mobile, location.pathname]);

    if (!pageFetched) {
        return null; // TODO: Loading
    }

    return (
        <Container className={styles.container}>
            <Box className={styles.topContainer}>
                <Logo inNavbar />
                <ComposedLanguageSelector />
                {mobile && (
                    <ButtonBase onClick={() => setMobileNavOpen(true)}>
                        <NavIcon />
                    </ButtonBase>
                )}
            </Box>
            <SwipeableDrawer
                anchor="right"
                onOpen={() => setMobileNavOpen(true)}
                open={mobileNavOpen}
                onClose={() => setMobileNavOpen(false)}
            >
                <div>
                    <IconButton onClick={() => setMobileNavOpen(false)}>
                        <ArrowRightAlt />
                    </IconButton>
                </div>
                <Menu className={styles.menu} />
            </SwipeableDrawer>
            <Box display="flex">
                {!mobile && <Menu className={styles.menu} />}
                <Box className={styles.contentContainer}>
                    <Switch>
                        {editorRoutes.map((route) => (
                            <Route
                                key={
                                    Array.isArray(route.path)
                                        ? route.path[0]
                                        : route.path
                                }
                                {...route}
                            />
                        ))}
                    </Switch>
                </Box>
            </Box>
        </Container>
    );
};

export const ComposedEditorLayout = compose<Props, Props>(
    withPageSlugDetecting,
    withPageMetaFetching,
    withWizardCreationFinished,
)(EditorLayout);
