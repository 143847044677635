import React, { useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Container, makeStyles, Paper, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { ShortDivider } from '../../components/short-divider/short-divider';
import { BlueHeadline } from '../../components/blue-headline/blue-headline';
import { ButtonLink } from '../../components/button-link/button-link';
import { CenterBox } from '../../components/center-box/center-box';
import { AuthPath } from '../../routing/auth-path';
import { withPageSlugDetecting } from '../../meta/with-page-slug-detecting';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { resolveRouteWithSlug } from '../../routing/resolve-route-with-slug';
import { getDoesUserExist } from '../invitation/model/selectors/get-does-user-exist.selector';
import { LogoLangugageContainer } from '../../components/logo-language-container/logo-language-container';

import { ILoginFormValues } from './components/login-form/login-form-values';
import {
    loginFormStateChanged,
    loginPageActions,
    resetPasswordActions,
} from './model/login-page.actions';
import { getLoginError } from './model/selectors/get-login-error.selector';
import { ComposedLoginForm } from './components/login-form/login-form';
import { ComposedForgotPasswordForm } from './components/forget-password-form/forget-password-form';
import { IForgotPasswordFormValues } from './components/forget-password-form/forgot-password-form-values';
import { loginErrorsTranslation } from './login-errors-translation';

type Props = RouteComponentProps;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    divider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    content: {
        maxWidth: '60rem',
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(8, 16),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            padding: theme.spacing(4),
        },
    },
    sectionHeadline: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
    },
    contentUserExist: {
        maxWidth: '60rem',
        marginBottom: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2),
    },
}));

export const LoginPage = ({ match, history, ...props }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const error = useSelector(getLoginError);
    const slug = useSelector(getPageSlug);
    const userExists = useSelector(getDoesUserExist);

    const resolveRoute = resolveRouteWithSlug(slug);

    const onLoginSubmit = ({ password, email }: ILoginFormValues) => {
        dispatch(
            loginPageActions.request({
                email,
                password,
            }),
        );
    };

    /**
     * If error exists and user starts typing, emit action which should
     * eventually clear errors
     */
    const onLoginFormChange = useCallback(
        (values: ILoginFormValues) => {
            if (error) {
                dispatch(loginFormStateChanged());
            }
        },
        [dispatch, error],
    );

    const onForgotPasswordFormSubmitted = (
        values: IForgotPasswordFormValues,
    ) => {
        dispatch(
            resetPasswordActions.request({
                email: values.email,
            }),
        );
    };

    const getLoginErrorTranslation = (): string | null => {
        if (!error) {
            return null;
        }

        return t(loginErrorsTranslation[error]);
    };

    const redirectToLoginForm = () =>
        history.push(resolveRoute(AuthPath.LOGIN));
    const redirectToForgotPassword = () =>
        history.push(resolveRoute(AuthPath.FORGOT_PASSWORD));

    const renderLoginFormContent = () => (
        <>
            {userExists && (
                <Paper className={styles.contentUserExist} elevation={1}>
                    {t('loginScreen.userExists')}
                    <br />
                    {t('loginScreen.resetPasswordSuggestion')}
                </Paper>
            )}
            <BlueHeadline className={styles.sectionHeadline}>
                {t('loginScreen.title')}
            </BlueHeadline>
            <ComposedLoginForm
                onSubmit={onLoginSubmit}
                onChange={onLoginFormChange}
                globalError={getLoginErrorTranslation()}
            />
            <CenterBox mt={4}>
                <ButtonLink
                    data-testid="login-page-forgot-password-link"
                    onClick={redirectToForgotPassword}
                >
                    {t('loginScreen.forgotPasswordLink')}
                </ButtonLink>
            </CenterBox>
        </>
    );

    const renderForgetPasswordFormContent = () => (
        <>
            <BlueHeadline className={styles.sectionHeadline}>
                {t('loginScreen.restorePasswordHeadline')}
            </BlueHeadline>
            <ComposedForgotPasswordForm
                onSubmit={onForgotPasswordFormSubmitted}
            />
            <CenterBox mt={4}>
                <ButtonLink
                    data-testid="forgot-page-back-to-login"
                    onClick={redirectToLoginForm}
                >
                    {t('loginScreen.backToLogInLink')}
                </ButtonLink>
            </CenterBox>
        </>
    );

    return (
        <Container className={styles.container}>
            <LogoLangugageContainer />
            <ShortDivider className={styles.divider} />
            <Paper
                data-testid="login-page-form-container"
                className={styles.content}
                elevation={1}
            >
                <Switch>
                    <Route
                        path={[AuthPath.LOGIN, AuthPath.LOGIN_WITHOUT_SLUG]}
                        render={() => renderLoginFormContent()}
                        exact
                    />
                    <Route
                        path={[
                            AuthPath.FORGOT_PASSWORD,
                            AuthPath.FORGOT_PASSWORD_WITHOUT_SLUG,
                        ]}
                        render={() => renderForgetPasswordFormContent()}
                        exact
                    />
                </Switch>
            </Paper>
        </Container>
    );
};

export const ComposedLoginPage = compose<Props, Props>(withPageSlugDetecting)(
    LoginPage,
);
