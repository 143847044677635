import cx from 'classnames';
import { compose, mapProps } from 'recompose';
import { Box, WithStyles, withStyles } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';

export const WizardContentContainer = compose<BoxProps, BoxProps>(
    withStyles((theme) => ({
        container: {
            padding: theme.spacing(8, 10),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(4),
            },
        },
    })),
    mapProps<BoxProps, BoxProps & WithStyles>(
        ({ classes, className, ...props }) => ({
            ...props,
            className: cx(className, classes.container),
        }),
    ),
)(Box);
