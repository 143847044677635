import React from 'react';
import {
    Box,
    Checkbox,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconRounded } from '../../../../components/icon-rounded/icon-rounded';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { useIsMobile } from '../../../../../styles/use-is-mobile';

const IS_ADMIN_MARK = 'Admin';

interface IContactData {
    id: string;
    name: string;
    email: string | null;
    phone: string | null;
    isAdmin: boolean;
}

export interface IProps {
    contact: IContactData;
    selected: boolean;
    onDelete?(): unknown;
    toggleContactSelected(id: string): unknown;
}

const useStyles = makeStyles((theme) => ({
    row: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateAreas: `"checkbox name name name" "checkbox email email email" "checkbox phone phone phone" "checkbox trash trash trash"`,
        },
    },
    bodyCell: {
        fontSize: '1.6rem',
        paddingLeft: 0,
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    checkboxCell: {
        gridArea: 'checkbox',
    },
    emailCell: {
        gridArea: 'email',
    },
    trashCell: {
        gridArea: 'trash',
    },
    phoneCell: { gridArea: 'phone' },
    nameCell: {
        gridArea: 'name',
        wordBreak: 'break-all',
    },
    checkbox: {
        marginRight: theme.spacing(1),
    },
    link: {
        color: theme.palette.primary.light,
        display: 'block',
        maxWidth: '30rem',
        overflowWrap: 'break-word',
        wordBreak: 'break-all',
        textDecoration: 'none',
    },
}));

export const ContactTableRow = ({
    contact,
    selected,
    toggleContactSelected,
    onDelete,
}: IProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const isMobile = useIsMobile();

    const nameLabel = (
        <Typography variant="caption">
            {t('notifications.tableColumns.name')}
        </Typography>
    );

    const emailLabel = (
        <Typography variant="caption">
            {t('notifications.tableColumns.email')}
        </Typography>
    );

    const phoneLabel = (
        <Typography variant="caption">
            {t('notifications.tableColumns.phone')}
        </Typography>
    );

    const deleteCell = (
        <TableCell className={cx(styles.bodyCell, styles.trashCell)}>
            <IconButton data-testid="contact-delete" onClick={onDelete}>
                <IconRounded icon={DeleteIcon} />
            </IconButton>
        </TableCell>
    );
    const adminCell = (
        <TableCell
            data-testid="contact-admin"
            className={cx(styles.bodyCell, styles.trashCell)}
        >
            {IS_ADMIN_MARK}
        </TableCell>
    );

    const renderEmailCell = () => {
        if (isMobile && !contact.email) {
            return null;
        }
        if (!isMobile && !contact.email) {
            return <TableCell />;
        }
        return (
            <TableCell
                data-testid="contact-email"
                className={cx(styles.bodyCell, styles.emailCell)}
            >
                {isMobile && emailLabel}
                <a className={styles.link} href={`mailto:${contact.email}`}>
                    {contact.email}
                </a>
            </TableCell>
        );
    };

    const renderPhoneCell = () => {
        if (isMobile && !contact.phone) {
            return null;
        }
        if (!isMobile && !contact.phone) {
            return <TableCell />;
        }
        return (
            <TableCell
                data-testid="contact-phone"
                className={cx(styles.bodyCell, styles.phoneCell)}
            >
                {isMobile && phoneLabel}
                <Typography>{contact.phone}</Typography>
            </TableCell>
        );
    };

    return (
        <TableRow className={styles.row}>
            <TableCell className={cx(styles.bodyCell, styles.checkboxCell)}>
                <Box display="flex" alignItems="center">
                    <Checkbox
                        data-testid="contact-checkbox"
                        checked={selected}
                        color="primary"
                        className={styles.checkbox}
                        onClick={() => toggleContactSelected(contact.id)}
                    />
                </Box>
            </TableCell>
            <TableCell
                data-testid="contact-name"
                className={cx(styles.bodyCell, styles.nameCell)}
            >
                {isMobile && nameLabel}
                <Typography>{contact.name}</Typography>
            </TableCell>
            {renderEmailCell()}
            {renderPhoneCell()}
            {!contact.isAdmin && deleteCell}
            {contact.isAdmin && adminCell}
        </TableRow>
    );
};
