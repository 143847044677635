export enum TaskStatus {
    TODO = 'todo',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export interface ITask {
    id: string;
    task: string;
    expirationDate: Date | null;
    status: TaskStatus;
    description?: string;
}

export type ITaskNotYetCreated = Omit<ITask, 'id'>;
