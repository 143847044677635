import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useDeceasedDisplayImages } from '../../model/deceased-display-images/use-deceased-display-images';
import { getPreviewFromFile } from '../../utils/get-preview-from-file';
import { getDeceasedAvatarImage } from '../../model/deceased-display-images/selectors/get-deceased-avatar-image.selector';

import {
    photosStepSkipRequested,
    wizardPhotosStepSubmitted,
} from './model/wizard-photos.actions';

export const useWizardPhotosScreenState = () => {
    const dispatch = useDispatch();
    const {
        availableBackgrounds,
        selectedBackground,
    } = useDeceasedDisplayImages();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedBackgroundID, setSelectedBackgroundID] = useState<string>();
    const [bgModalOpen, setBgModalOpen] = useState(false);
    const [imageCropModalOpen, setImageCropModalOpen] = useState(false);

    const avatar = useSelector(getDeceasedAvatarImage);

    useEffect(() => {
        if (availableBackgrounds.length && selectedBackground) {
            setSelectedBackgroundID(selectedBackground);

            return;
        }

        if (availableBackgrounds.length) {
            setSelectedBackgroundID(availableBackgrounds[0].id);
        }
    }, [availableBackgrounds, selectedBackground]);

    const avatarPreview =
        (selectedFile && getPreviewFromFile(selectedFile)) || avatar;

    return {
        avatarPreview,
        availableBackgrounds,
        selectedBackgroundID,
        setSelectedBackgroundID,
        bgModalOpen,
        imageCropModalOpen,
        setImageCropModalOpen,
        selectedFile,
        setSelectedFile,
        openBgModal() {
            setBgModalOpen(true);
        },
        closeBgModal() {
            setBgModalOpen(false);
        },
        skipStep() {
            dispatch(photosStepSkipRequested());
        },
        submit() {
            dispatch(
                wizardPhotosStepSubmitted({
                    avatar: selectedFile,
                    backgroundID: selectedBackgroundID as string,
                }),
            );
        },
    };
};
