import React from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { IParticipant } from '../../../interfaces/participant';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { useEvents } from '../../../events/use-events';
import { IMemorialEvent } from '../../../interfaces/memorial-event';

import { ParticipantsTableRow } from './participants-table-row';

type Props = {
    participants: IParticipant[];
    redirectToEventsPage: () => void;
};

const useStyles = makeStyles((theme) => ({
    headCell: {
        color: theme.palette.common.black,
        fontSize: '1.6rem',
        fontWeight: 'bold',
        paddingLeft: 0,
    },
}));

export const ParticipantsTable = ({
    participants,
    redirectToEventsPage,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const isMobile = useIsMobile();

    const { events, eventUpdateRequested } = useEvents();

    const onEventTitleClick = (event: IMemorialEvent) => {
        eventUpdateRequested(event);
        redirectToEventsPage();
    };

    return (
        <>
            <Table data-testid="participants-table-container">
                {!isMobile && (
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.headCell}>
                                {t('participants.tableColumns.name')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('participants.tableColumns.email')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('participants.tableColumns.phone')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('participants.tableColumns.event')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {participants.map((participant, index) => {
                        const event = events.find(
                            ({ id }) =>
                                Number(id) === Number(participant.eventId),
                        );

                        return (
                            <ParticipantsTableRow
                                key={index}
                                {...participant}
                                event={event}
                                onEventTitleClick={onEventTitleClick}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};
