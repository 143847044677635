import React from 'react';
import { Box, ButtonBase, makeStyles, Theme } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import { Done as DoneIcon, Clear as ClearIcon } from '@material-ui/icons';
import cx from 'classnames';

import { Colors } from '../../../../styles/colors';

export interface ISuggestedGalleryItemProps extends BoxProps {
    onAcceptClick(): void;
    onRejectClick(): void;
    src: string;
}

const useStyles = makeStyles<Theme, { src: string }>((theme) => ({
    root: {
        backgroundImage: ({ src }) => `url("${src}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 4,
        position: 'relative',
    },
    iconsContainer: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    iconWrapper: {
        backgroundColor: Colors.WHISPER,
        borderRadius: '50%',
        '&:first-of-type': {
            marginRight: theme.spacing(0.5),
        },
    },
    approveIcon: {
        color: theme.palette.success.main,
    },
    rejectIcon: {
        color: Colors.VALENCIA,
    },
}));

export const SuggestedGalleryItem = ({
    className,
    onAcceptClick,
    onRejectClick,
    src,
}: ISuggestedGalleryItemProps) => {
    const styles = useStyles({ src });

    return (
        <Box className={cx(styles.root, className)}>
            <Box className={styles.iconsContainer}>
                <ButtonBase
                    data-testid="picture-accept-button"
                    className={styles.iconWrapper}
                    onClick={onAcceptClick}
                >
                    <DoneIcon className={styles.approveIcon} />
                </ButtonBase>
                <ButtonBase
                    data-testid="picture-reject-button"
                    className={styles.iconWrapper}
                    onClick={onRejectClick}
                >
                    <ClearIcon className={styles.rejectIcon} />
                </ButtonBase>
            </Box>
        </Box>
    );
};
