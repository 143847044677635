import { apiService } from '../../api.service';
import { ISuggestionsResponse } from '../dto/suggestions-response.dto';

export const fetchSuggestions = ({
    slug,
}: {
    slug: string;
}): Promise<ISuggestionsResponse[]> =>
    apiService
        .get(`/memorial_pages/${slug}/suggestions`)
        .then((resp) => resp.json());
