import { call, put, select } from 'redux-saga/effects';

import { deleteContactApiActions } from '../contacts-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ICreatedContact } from '../../../interfaces/contact';
import { notificationSingleContactReceived } from '../../../model/notification-contacts/notification-contacts.actions';
import { deleteContact } from '../../contacts/http/delete-notification-contact';
import { getNotificationContactById } from '../../../model/notification-contacts/selectors/get-notification-contact-by-id';
import { NotificationContactStatus } from '../../../interfaces/notification-contact-status';

export function* deleteContactApiSaga(
    action: ReturnType<typeof deleteContactApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const relatedContact: ICreatedContact = yield select(
        getNotificationContactById(action.payload.id),
    );

    if (!relatedContact.id) {
        return;
    }

    try {
        yield call(deleteContact, {
            slug,
            id: relatedContact.id,
        });

        yield put(
            notificationSingleContactReceived({
                contact: {
                    ...relatedContact,
                    status: NotificationContactStatus.DELETED,
                },
            }),
        );

        yield put(
            deleteContactApiActions.success({
                ...relatedContact,
                status: NotificationContactStatus.DELETED,
            }),
        );
    } catch (err) {
        yield put(deleteContactApiActions.failure(err.statusText));
    }
}
