import { apiService } from '../../api.service';
import { CondolenceDto } from '../dto/condolence.dto';

export const fetchCondolences = ({
    slug,
}: {
    slug: string;
}): Promise<CondolenceDto[]> =>
    apiService
        .get(`/public/memorial_pages/${slug}/condolences`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
