import { call, put, select } from 'redux-saga/effects';

import { deleteEventApiActions } from '../api-events.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { deleteEvent } from '../http/delete-event';

export function* deleteEventApiSaga(
    action: ReturnType<typeof deleteEventApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        yield call(deleteEvent, slug, action.payload.id);
        yield put(deleteEventApiActions.success());
    } catch (err) {
        yield put(deleteEventApiActions.failure(err.statusText));
    }
}
