import { createSelector } from 'reselect';

import { getGalleryItems } from './get-gallery-items.selector';

export const getGalleryItemSuggestionById = (suggestionId: string) => {
    return createSelector(
        getGalleryItems,
        (items) => items.find((item) => item.suggestionId === suggestionId),
    );
};
