import { spawn } from '@redux-saga/core/effects';

import { eventsSaga } from '../events/model/saga/events.saga';
import { tasksSaga } from '../tasks/model/saga/tasks.saga';
import { deceasedSaga } from '../deceased/model/saga/deceased.saga';
import { gallerySaga } from '../gallery/model/saga/gallery.saga';
import { milestonesSaga } from '../milestones/model/saga/milestones.saga';
import { tributeSaga } from '../tribute/model/saga/tribute.saga';
import { obituarySaga } from '../obituary/model/saga/obituary.saga';
import { settingsSaga } from '../settings/model/saga/settings.saga';

import { photosSaga } from './overview/photos-section/model/saga/photos.saga';
import { contactsSaga } from './notifications/contacts/model/saga/contacts.saga';
import { overviewEventsSaga } from './overview/overview-events-section/model/saga/overview-events.saga';
import { overviewTasksSaga } from './overview/overview-tasks-section/model/saga/overview-tasks.saga';
import { editorNotificationMessageSaga } from './notifications/message/model/saga/editor-notification-message.saga';

export function* editorSaga() {
    yield spawn(deceasedSaga);
    yield spawn(tributeSaga);
    yield spawn(obituarySaga);
    yield spawn(milestonesSaga);
    yield spawn(eventsSaga);
    yield spawn(tasksSaga);
    yield spawn(gallerySaga);
    yield spawn(photosSaga);
    yield spawn(settingsSaga);
    yield spawn(contactsSaga);
    yield spawn(overviewEventsSaga);
    yield spawn(overviewTasksSaga);
    yield spawn(editorNotificationMessageSaga);
}
