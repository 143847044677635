import React from 'react';
import { Box, FormControl } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { IMilestoneFormValues } from '../model/milestone-form-values';
import { FormTextField } from '../../components/form-text-field/form-text-field';
import { FormDoubleInputsContainer } from '../../components/form-double-inputs-container/form-double-inputs-container';
import { RichTextEditor } from '../../components/rich-text-editor/rich-text-editor';
import { FieldErrorMessage } from '../../components/field-error-message/field-error-message';
import { createShouldShowError } from '../../utils/should-show-error';
import {
    useDateFormatter,
    getDateFromMonthYear,
} from '../../utils/use-date-formatter';
import { useDeceasedPersonData } from '../../model/deceased-person/use-deceased-person-data';

import { milestoneFormValidation } from './milestone-form-validations';

type OuterProps = {
    initialValues?: IMilestoneFormValues & { id?: string } | null;
    onCreate?: (values: IMilestoneFormValues) => unknown;
    onUpdate?: (values: IMilestoneFormValues, id: string) => unknown;
};

type Props = FormikProps<IMilestoneFormValues> & OuterProps;

export const MilestoneForm = ({
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
}: Props) => {
    const { t } = useTranslation();
    const {
        formatMonthYearPickerDate,
        getYearsBeforeDate,
        getYearsAfterDate,
    } = useDateFormatter();
    const shouldShowError = createShouldShowError(errors, touched);

    const { deceasedPersonData } = useDeceasedPersonData();

    const minDate = deceasedPersonData.birthDate
        ? getYearsBeforeDate(deceasedPersonData.birthDate, 10)
        : undefined;

    const maxDate = deceasedPersonData.deathDate
        ? getYearsAfterDate(deceasedPersonData.deathDate, 10)
        : undefined;

    return (
        <form data-testid="milestone-form-container" onSubmit={handleSubmit}>
            <Box mt={4}>
                <FormDoubleInputsContainer>
                    <FormControl
                        data-testid="milestone-title"
                        error={shouldShowError('title')}
                        fullWidth
                    >
                        <FormTextField
                            error={shouldShowError('title')}
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            label={t('mediaAndContent.titleLabel')}
                        />
                        <FieldErrorMessage
                            data-testid="milestone-title-error"
                            name="title"
                        />
                    </FormControl>
                    <FormControl
                        data-testid="milestone-date"
                        error={shouldShowError('date')}
                        fullWidth
                    >
                        <DatePicker
                            openTo="year"
                            views={['year', 'month']}
                            error={shouldShowError('date')}
                            inputVariant="outlined"
                            fullWidth
                            maxDate={maxDate}
                            minDate={minDate}
                            name="date"
                            onYearChange={(date) => {
                                if (date) {
                                    setFieldValue('year', date.getFullYear());
                                    setFieldValue('date', date.toISOString());
                                }
                            }}
                            onMonthChange={(date) => {
                                if (date) {
                                    setFieldValue('month', date.getMonth() + 1);
                                    setFieldValue('date', date.toISOString());
                                }
                            }}
                            onChange={(date) => {
                                if (date) {
                                    setFieldValue('date', date.toISOString());
                                    return;
                                }

                                setFieldValue('date', null);
                            }}
                            onBlur={handleBlur}
                            value={values.date}
                            label={t('mediaAndContent.dateLabel')}
                            cancelLabel={t('common.cancel')}
                            clearLabel={t('common.delete')}
                            format={formatMonthYearPickerDate(values.month)}
                        />
                        <FieldErrorMessage
                            data-testid="milestone-date-error"
                            name="date"
                        />
                    </FormControl>
                </FormDoubleInputsContainer>
            </Box>
            <Box data-testid="milestone-editor" mt={3}>
                <RichTextEditor
                    minHeight="20rem"
                    minWidth="60rem"
                    name="description"
                    onChange={handleChange}
                    placeholder={t('mediaAndContent.descriptionPlaceholder')}
                    value={values.description}
                />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <SecondaryButton data-testid="milestone-save" type="submit">
                    {t('common.save')}
                </SecondaryButton>
            </Box>
        </form>
    );
};

export const ConnectedMilestoneForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IMilestoneFormValues>({
        enableReinitialize: true,
        handleSubmit(values, { props }) {
            if (
                props.onUpdate &&
                props.initialValues &&
                props.initialValues.id
            ) {
                props.onUpdate(values, props.initialValues.id);
            } else if (props.onCreate) {
                props.onCreate(values);
            }
        },
        mapPropsToValues({ initialValues }) {
            if (initialValues) {
                return {
                    ...initialValues,
                    date: getDateFromMonthYear(
                        initialValues.year,
                        initialValues.month,
                    ),
                };
            }
            return {
                date: null,
                title: '',
                description: null,
                year: null,
            };
        },
        validationSchema: () => milestoneFormValidation(),
    }),
)(MilestoneForm);
