import React, { useState } from 'react';
import { Box, makeStyles, Popover, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { PopoverProps } from '@material-ui/core/Popover';
import copy from 'copy-to-clipboard';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Colors } from '../../../../../styles/colors';

type Props = {
    href: string;
    onClose(): unknown;
} & PopoverProps;

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        margin: theme.spacing(1.5),
    },
    linkContainer: {
        border: `1px solid ${Colors.IRON}`,
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
        maxWidth: '250px',
        overflowX: 'scroll',
        padding: theme.spacing(1),
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    icon: {
        color: Colors.MINE_SHAFT,
        cursor: 'pointer',
        transition: 'color .3s',
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    iconBox: {
        alignItems: 'center',
        border: `1px solid ${Colors.IRON}`,
        borderLeft: 0,
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
        display: 'flex',
        height: '38px',
        justifyContent: 'center',
        minWidth: '40px',
    },
    iconBoxCopied: {
        padding: theme.spacing(0, 1),
    },
    copiedText: {
        color: theme.palette.primary.light,
        fontWeight: 'bold',
    },
}));

export const ShareLinkPopover = ({ href, onClose, open, ...props }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const [copied, setCopied] = useState<boolean>(false);

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={() => {
                onClose();
                setCopied(false);
            }}
            open={open}
            {...props}
        >
            <Box className={styles.container}>
                <Box className={styles.linkContainer}>{href}</Box>
                <Box
                    className={cx(styles.iconBox, {
                        [styles.iconBoxCopied]: copied,
                    })}
                    onClick={() => {
                        if (!copied) {
                            copy(href);
                            setCopied(true);
                        }
                    }}
                >
                    {copied ? (
                        <Typography className={styles.copiedText}>
                            {t('common.copied')}
                        </Typography>
                    ) : (
                        <FileCopy className={styles.icon} />
                    )}
                </Box>
            </Box>
        </Popover>
    );
};
