import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { SecondaryButton } from '../components/secondary-button/secondary-button';
import { RichTextEditor } from '../components/rich-text-editor/rich-text-editor';
import { UpdateTributeStatus } from '../model/tribute/tribute.store';
import { Colors } from '../../styles/colors';
import { useIsMobile } from '../../styles/use-is-mobile';

import { ITributeFormValues } from './model/tribute-form-values';

type OuterProps = {
    initialContent: string;
    onSubmit(values: ITributeFormValues): unknown;
    updateStatus: UpdateTributeStatus | null;
    updateStatusMessage: string | null;
};

type Props = FormikProps<ITributeFormValues> & OuterProps;

const useStyles = makeStyles((theme) => ({
    statusMessage: {
        marginTop: theme.spacing(2),
    },
    statusMessageSuccess: {
        color: theme.palette.success.main,
    },
    statusMessageError: {
        color: Colors.VALENCIA,
    },
}));

export const TributeForm = ({
    handleChange,
    handleSubmit,
    updateStatus,
    updateStatusMessage,
    values,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const mobile = useIsMobile();

    const statusMessageClass = cx(styles.statusMessage, {
        [styles.statusMessageSuccess]:
            updateStatus === UpdateTributeStatus.SUCCESS,
        [styles.statusMessageError]:
            updateStatus === UpdateTributeStatus.INAPPROPRIATE_WORDS,
    });

    return (
        <form data-testid="tribute-form-container" onSubmit={handleSubmit}>
            <RichTextEditor
                name="tribute"
                onChange={handleChange}
                value={values.tribute}
            />
            {updateStatusMessage && (
                <Typography
                    data-testid="tribute-form-save-message"
                    className={statusMessageClass}
                >
                    {updateStatusMessage}
                </Typography>
            )}
            <Box
                display="flex"
                justifyContent="flex-end"
                mt={updateStatusMessage ? 0 : 5}
            >
                <SecondaryButton
                    data-testid="tribute-form-save"
                    fullWidth={mobile}
                    type="submit"
                >
                    {t('common.save')}
                </SecondaryButton>
            </Box>
        </form>
    );
};

export const ConnectedTributeForm = compose<Props, OuterProps>(
    withFormik<OuterProps, ITributeFormValues>({
        handleSubmit(values, { props }) {
            props.onSubmit(values);
        },
        enableReinitialize: true,
        mapPropsToValues: ({ initialContent }) => ({
            tribute: initialContent,
        }),
    }),
)(TributeForm);
