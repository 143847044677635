import { delay, put, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { deceasedFormSubmitted } from '../deceased.actions';
import { mapDeceasedFormToDTO } from '../../../model/deceased-person/mappers/map-deceased-form-to-dto';
import { updateDeceasedDataApiActions } from '../../../api/deceased/deceased-api.actions';
import { resetDateTimezone } from '../../../utils/reset-date-timezone';
import { UpdateDeceasedDataStatus } from '../../../model/deceased-person/deceased-person.store';
import { setUpdateDeceasedStatus } from '../../../model/deceased-person/deceased-person.actions';

export function* submitDeceasedFormSaga(
    action: ReturnType<typeof deceasedFormSubmitted>,
) {
    const formData = action.payload;
    const dto = mapDeceasedFormToDTO(formData);

    yield put(
        updateDeceasedDataApiActions.request({
            ...dto,
            birthDate: dto.birthDate
                ? resetDateTimezone(dto.birthDate).toISOString()
                : null,
            deathDate: dto.deathDate
                ? resetDateTimezone(dto.deathDate).toISOString()
                : null,
        }),
    );

    const result = yield take([
        updateDeceasedDataApiActions.success,
        updateDeceasedDataApiActions.failure,
    ]);

    if (isActionOf(updateDeceasedDataApiActions.success, result)) {
        yield put(setUpdateDeceasedStatus(UpdateDeceasedDataStatus.SUCCESS));
        yield delay(3000);
        yield put(setUpdateDeceasedStatus(null));
    } else if (isActionOf(updateDeceasedDataApiActions.failure, result)) {
        yield put(setUpdateDeceasedStatus(UpdateDeceasedDataStatus.ERROR));
        yield delay(3000);
        yield put(setUpdateDeceasedStatus(null));
    }
}
