export const THEME = {
    DEFAULT: 'default',
    VG: 'vg',
};

export const THEME_DETAILS = {
    vg: {
        logo: 'VG_logo.png',
        additionalText: 'MINNESIDER',
        url: 'vg.no',
    },
};
