export enum AuthPath {
    LOGIN = '/:slug/login/',
    LOGIN_WITHOUT_SLUG = '/login/',
    FORGOT_PASSWORD = '/:slug/login/forgot-password',
    FORGOT_PASSWORD_WITHOUT_SLUG = '/login/forgot-password',
    SET_PASSWORD = '/:slug/set-password',
    /**
     * Landing route when user opens email link. Will have ?token param
     */
    RESET_PASSWORD = '/:slug/reset-password',
    RESET_PASSWORD_WITHOUT_SLUG = '/reset-password',
    CONTEXTS = '/contexts/',
    LINK_EXPIRED = '/link-expired',
}
