import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchInvitationsApiActions } from '../invitations-api.actions';

import { fetchInvitationsSaga } from './fetch-invitations.saga';

export function* invitationsApiSaga() {
    yield takeEvery(
        getType(fetchInvitationsApiActions.request),
        fetchInvitationsSaga,
    );
}
