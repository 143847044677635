import { apiService } from '../../api.service';
import { IPasswordResetResponseDto } from '../dto/password-reset-response.dto';

/**
 * TODO: Handle errors (403):
 *   - wrong_token
 *   - token_expired
 */
export const resetAccountPassword = ({
    token,
    password,
}: {
    token: string;
    password: string;
}): Promise<IPasswordResetResponseDto> =>
    apiService
        .patch('/reset_passwords', {
            json: {
                token,
                auth: {
                    password,
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
