import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { memorialPageSlugReceived } from '../meta.actions';
import { updateMemorialPageCreationStepApiActions } from '../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';

import { saveSlugInWindow } from './save-slug-in-window.saga';
import { saveCurrentWizardStepSaga } from './save-current-wizard-step.saga';

export function* pageMetaSaga() {
    yield takeEvery(getType(memorialPageSlugReceived), saveSlugInWindow);
    yield takeEvery(
        getType(updateMemorialPageCreationStepApiActions.success),
        saveCurrentWizardStepSaga,
    );
}
