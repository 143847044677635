import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { updateSettingsApiActions } from '../settings.actions';

import { updateSettingsSaga } from './update-settings.saga';

export function* settingsSaga() {
    yield takeEvery(
        getType(updateSettingsApiActions.request),
        updateSettingsSaga,
    );
}
