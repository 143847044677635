import { put, take } from '@redux-saga/core/effects';

import { fetchSuggestionsApiActions } from '../../../api/suggestions/suggestions-api.actions';
import { suggestionsReceived } from '../suggestions.actions';
import { mapSuggestionsDtoToSuggestions } from '../../../api/suggestions/mappers/map-suggestions-dto-to-suggestions';

export function* fetchSuggestionsSaga() {
    yield put(fetchSuggestionsApiActions.request());

    const resp: ReturnType<
        typeof fetchSuggestionsApiActions.success
    > = yield take(fetchSuggestionsApiActions.success);

    const payload = mapSuggestionsDtoToSuggestions(resp.payload.data);

    yield put(suggestionsReceived(payload));
}
