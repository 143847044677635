import React, { ChangeEventHandler, HTMLAttributes } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';

import { Colors } from '../../../../styles/colors';

interface IProps extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
    headline: string;
    description: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    checked: boolean;
    inputProps?: HTMLAttributes<HTMLInputElement>;
    value?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: Colors.ATHENS_GRAY,
        padding: theme.spacing(2),
        cursor: 'pointer',
        display: 'block',
    },
    headline: {
        color: Colors.MINE_SHAFT,
        fontWeight: 700,
        fontSize: '1.8rem',
        marginBottom: theme.spacing(3),
    },
    radio: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        border: `2px solid ${Colors.MINE_SHAFT}`,
        borderRadius: '50%',
        flex: `0 0 ${theme.spacing(3)}px`,
        marginRight: theme.spacing(2),
    },
    checked: {
        borderColor: theme.palette.primary.light,
        position: 'relative',
        '&:after': {
            width: theme.spacing(2) - 4,
            height: theme.spacing(2) - 4,
            position: 'absolute',
            top: 4,
            left: 4,
            content: '""',
            background: theme.palette.primary.light,
            borderRadius: '50%',
        },
    },
}));

export const SettingOptionBlock = ({
    headline,
    description,
    checked,
    onChange,
    inputProps,
    className,
    value,
    ...props
}: IProps) => {
    const styles = useStyles();

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={cx(className, styles.root)} {...props}>
            <Box display="flex">
                <div
                    className={cx(styles.radio, {
                        [styles.checked]: checked,
                    })}
                />
                <div data-testid="single-privacy-type">
                    <Box mb={2}>
                        <Typography className={styles.headline}>
                            {headline}
                        </Typography>
                    </Box>
                    <Typography paragraph>{description}</Typography>
                </div>
                <input
                    {...inputProps}
                    hidden
                    type="radio"
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
            </Box>
        </label>
    );
};
