import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../secondary-button/secondary-button';
import { BlueHeadline } from '../blue-headline/blue-headline';
import { DangerButton } from '../danger-button/danger-button';
import { SecondaryTextButton } from '../secondary-text-button/secondary-text-button';

type Props = DialogProps & {
    onConfirm(): unknown;
    onCancel(): unknown;
    confirmButtonType?: 'danger' | 'secondary';
    cancelButtonType?: 'secondary' | 'secondaryText';
    description?: string;
};

const getConfirmButtonComponent = (
    confirmButtonType: Props['confirmButtonType'],
) => {
    switch (confirmButtonType) {
        case 'secondary':
            return SecondaryButton;
        default:
            return DangerButton;
    }
};

const getCancelButtonComponent = (
    cancelButtonType: Props['cancelButtonType'],
) => {
    switch (cancelButtonType) {
        case 'secondaryText':
            return SecondaryTextButton;
        default:
            return SecondaryButton;
    }
};

/**
 * TODO: Later consider injecting custom strings
 */
export const ConfirmationModal = ({
    open,
    onConfirm,
    onCancel,
    confirmButtonType = 'danger',
    cancelButtonType = 'secondary',
    description,
    ...props
}: Props) => {
    const { t } = useTranslation();

    const ConfirmButton = getConfirmButtonComponent(confirmButtonType);
    const CancelButton = getCancelButtonComponent(cancelButtonType);

    return (
        <Dialog open={open} fullWidth maxWidth="xs" {...props}>
            <DialogContent data-testid="confirmation-modal">
                <BlueHeadline align="center">
                    {t('confirmationModal.headline')}
                </BlueHeadline>
                {!!description && (
                    <Box mt={3}>
                        <Typography>{description}</Typography>
                    </Box>
                )}
                <Box
                    mt={description ? 3 : 4}
                    py={2}
                    display="flex"
                    justifyContent="space-between"
                >
                    <CancelButton fullWidth onClick={onCancel}>
                        {t('confirmationModal.cancelButton')}
                    </CancelButton>
                    <Box mx={2} />
                    <ConfirmButton fullWidth onClick={onConfirm}>
                        {t('confirmationModal.confirmButton')}
                    </ConfirmButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
