import { put, call, select } from 'redux-saga/effects';

import { createEventApiActions } from '../api-events.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { createEvent } from '../http/create-event';

export function* createEventApiSaga(
    action: ReturnType<typeof createEventApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const dto = action.payload;

    try {
        const response = yield call(createEvent, dto, slug);
        yield put(createEventApiActions.success(response));
    } catch (err) {
        yield put(createEventApiActions.failure(err.statusText));
    }
}
