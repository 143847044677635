import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { condolencesFetchRequested } from '../condolences.actions';

import { fetchCondolencesSaga } from './fetch-condolences.saga';

export function* condolencesSaga() {
    yield takeEvery(getType(condolencesFetchRequested), fetchCondolencesSaga);
}
