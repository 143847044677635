import { createAction } from 'typesafe-actions';

import { ObituaryFormValues } from './obituary.types';

export const updateObituaryRequested = createAction(
    'editor/obituary/UPDATE_REQUESTED',
    (action) => (payload: ObituaryFormValues) => action(payload),
);

export const generateObituaryRequested = createAction(
    'editor/obituary/GENERATE_REQUESTED',
    (action) => (payload: ObituaryFormValues) => action(payload),
);

export const resetObituaryStore = createAction(
    'editor/obituary/RESET_STORE',
    (action) => () => action(),
);
