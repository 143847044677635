import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOverview } from '../../../model/overview/selectors/get-overview.selector';
import { overviewFetchRequested } from '../../../model/overview/overview.actions';

export const useOverviewSection = () => {
    const dispatch = useDispatch();

    const overview = useSelector(getOverview);

    const currentCondolencesCount = overview.condolencesCount;
    const currentInvitationsCount = overview.invitationsCount;
    const currentSuggestionsCount = overview.suggestionsCount;

    useEffect(() => {
        dispatch(overviewFetchRequested());
    }, [dispatch]);

    return {
        currentCondolencesCount,
        currentInvitationsCount,
        currentSuggestionsCount,
    };
};
