import React from 'react';
import { Box, FormControl, makeStyles, Typography } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cx from 'classnames';

import { FormTextField } from '../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../components/field-error-message/field-error-message';
import { FormDoubleInputsContainer } from '../components/form-double-inputs-container/form-double-inputs-container';
import { createShouldShowError } from '../utils/should-show-error';
import { BlueHeadline } from '../components/blue-headline/blue-headline';
import { IDeceasedPersonData } from '../interfaces/deceased-person-data';
import { useDateFormatter } from '../utils/use-date-formatter';
import { UpdateDeceasedDataStatus } from '../model/deceased-person/deceased-person.store';
import { Colors } from '../../styles/colors';
import { backYearsLimitForBirthDate } from '../utils/back-years-limit-for-birth-date';

import { IDeceasedFormValues } from './model/deceased-form-values';
import { deceasedFormValidation } from './model/deceased-form-validation';

type OuterProps = {
    deceasedData: IDeceasedPersonData;
    updateDeceasedStatus: UpdateDeceasedDataStatus | null;
    updateDeceasedStatusMessage: string | null;
};

type Props = FormikProps<IDeceasedFormValues> & OuterProps;

const useStyles = makeStyles((theme) => ({
    description: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    statusMessage: {
        marginTop: theme.spacing(1),
    },
    statusMessageSuccess: {
        color: theme.palette.success.main,
    },
    statusMessageError: {
        color: Colors.VALENCIA,
    },
}));

export const DeceasedForm = ({
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    touched,
    updateDeceasedStatus,
    updateDeceasedStatusMessage,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { formatSelectedPickerDate, getYearsBeforeDate } = useDateFormatter();

    const shouldShowError = createShouldShowError(errors, touched);

    const handleDateFieldChange = (
        date: MaterialUiPickersDate,
        field: 'birthDate' | 'deathDate',
    ) => {
        if (date) {
            setFieldValue(field, date.toISOString());
            return;
        }

        setFieldValue(field, null);
    };

    const statusMessageClass = cx(styles.statusMessage, {
        [styles.statusMessageSuccess]:
            updateDeceasedStatus === UpdateDeceasedDataStatus.SUCCESS,
        [styles.statusMessageError]:
            updateDeceasedStatus === UpdateDeceasedDataStatus.ERROR,
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit}>
            <BlueHeadline variant="h2">{t('deceased.name')}</BlueHeadline>
            <Typography className={styles.description}>
                {t('deceased.nameDescription')}
            </Typography>
            <Box mb={4}>
                <FormControl
                    data-testid="deceased-info-full-name"
                    fullWidth
                    error={shouldShowError('name')}
                >
                    <FormTextField
                        disabled
                        error={shouldShowError('name')}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        label={t('deceased.firstAndLastNameLabel')}
                    />
                    <FieldErrorMessage name="name" />
                </FormControl>
            </Box>
            <BlueHeadline variant="h2">
                {t('deceased.dateAndPlaceOfBirthAndDeath')}
            </BlueHeadline>
            <Typography className={styles.description}>
                {t('deceased.dateAndPlaceDescription')}
            </Typography>
            <Box mb={2}>
                <FormDoubleInputsContainer>
                    <DatePicker
                        disabled
                        data-testid="deceased-info-birth-date"
                        error={shouldShowError('birthDate')}
                        clearable
                        inputVariant="outlined"
                        fullWidth
                        disableFuture
                        minDate={getYearsBeforeDate(
                            new Date(),
                            backYearsLimitForBirthDate,
                        )}
                        maxDate={values.deathDate || undefined}
                        name="birthDate"
                        onChange={(date) => {
                            handleDateFieldChange(date, 'birthDate');
                        }}
                        onBlur={handleBlur}
                        value={values.birthDate}
                        label={t('deceased.dateOfBirthLabel')}
                        labelFunc={formatSelectedPickerDate}
                        cancelLabel={t('common.cancel')}
                        clearLabel={t('common.delete')}
                    />
                    <DatePicker
                        disabled
                        data-testid="deceased-info-death-date"
                        error={shouldShowError('deathDate')}
                        inputVariant="outlined"
                        fullWidth
                        clearable
                        disableFuture
                        minDate={values.birthDate || undefined}
                        name="deathDate"
                        onChange={(date) => {
                            handleDateFieldChange(date, 'deathDate');
                        }}
                        onBlur={handleBlur}
                        value={values.deathDate}
                        label={t('deceased.dateOfDeathLabel')}
                        labelFunc={formatSelectedPickerDate}
                        cancelLabel={t('common.cancel')}
                        clearLabel={t('common.delete')}
                    />
                </FormDoubleInputsContainer>
            </Box>
            <Box mb={updateDeceasedStatusMessage ? 0 : 4}>
                <FormDoubleInputsContainer>
                    <FormControl
                        data-testid="deceased-info-birth-place"
                        error={shouldShowError('birthPlace')}
                        fullWidth
                    >
                        <FormTextField
                            disabled
                            error={shouldShowError('birthPlace')}
                            name="birthPlace"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birthPlace}
                            label={t('deceased.placeOfBirthLabel')}
                        />
                        <FieldErrorMessage name="birthPlace" />
                    </FormControl>
                    <FormControl
                        data-testid="deceased-info-death-place"
                        error={shouldShowError('deathPlace')}
                        fullWidth
                    >
                        <FormTextField
                            disabled
                            error={shouldShowError('deathPlace')}
                            name="deathPlace"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.deathPlace}
                            label={t('deceased.placeOfDeathLabel')}
                        />
                        <FieldErrorMessage name="deathPlace" />
                    </FormControl>
                </FormDoubleInputsContainer>
            </Box>
            {updateDeceasedStatusMessage && (
                <Typography
                    data-testid="deceased-info-save-message"
                    className={statusMessageClass}
                >
                    {updateDeceasedStatusMessage}
                </Typography>
            )}
        </form>
    );
};

export const ConnectedDeceasedForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IDeceasedFormValues>({
        handleSubmit() {},
        mapPropsToValues({ deceasedData }) {
            return {
                name: deceasedData.name || '',
                birthPlace: deceasedData.birthPlace || '',
                deathPlace: deceasedData.deathPlace || '',
                birthDate: deceasedData.birthDate || null,
                deathDate: deceasedData.deathDate || null,
            };
        },
        validationSchema: () => deceasedFormValidation(),
        enableReinitialize: true,
    }),
)(DeceasedForm);
