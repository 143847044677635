import { MemorialPageCreationStep } from '../interfaces/memorial-page-creation-step';

import { WizardPath } from './wizard-paths';
import { EditorPath } from './editor-paths';
import { resolveRouteWithSlug } from './resolve-route-with-slug';

export const resolveRouteFromMemorialCreationStep = (
    slug: string,
    step: MemorialPageCreationStep | null,
) => {
    const replaceSlug = resolveRouteWithSlug(slug);

    switch (step) {
        case MemorialPageCreationStep.VG:
            return replaceSlug(WizardPath.VG);

        case MemorialPageCreationStep.PHOTOS:
            return replaceSlug(WizardPath.PHOTOS);

        case MemorialPageCreationStep.NOTIFICATION:
            return replaceSlug(WizardPath.NOTIFICATION);

        case MemorialPageCreationStep.FINISHED:
            return replaceSlug(EditorPath.OVERVIEW);
        case MemorialPageCreationStep.DECEASED:
        case null:
        default:
            return replaceSlug(WizardPath.DECEASED);
    }
};
