import { getHours, getMinutes, set, subMinutes } from 'date-fns';

/**
 * TODO: Test
 */
export const mergeDateWithTime = (date: Date, time: Date) => {
    const timeDate = new Date(time);

    const dateWithTime = set(date, {
        hours: getHours(timeDate),
        minutes: getMinutes(timeDate),
    });

    /**
     * This is kind of workaround, which resets timezone and send to server "time which user sees".
     * However it should rather send normal time + timezone.
     * TODO: Send user timezone to server and dont manipulate time like this
     */
    const dateWithoutOffset = subMinutes(
        dateWithTime,
        dateWithTime.getTimezoneOffset(),
    );

    return dateWithoutOffset;
};
