import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPageActions } from '../api/page/page-api.actions';

import { getPageSlug } from './model/selectors/get-page-slug.selector';

export const withPageMetaFetching = <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const slug = useSelector(getPageSlug);
    const dispatch = useDispatch();

    useEffect(() => {
        if (slug) {
            dispatch(fetchPageActions.request({ slug }));
        }
    }, [slug, dispatch]);

    return <Component {...props} />;
};
