import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchCondolencesApiActions } from '../condolences-api.actions';

import { fetchCondolencesSaga } from './fetch-condolences.saga';

export function* condolencesApiSaga() {
    yield takeEvery(
        getType(fetchCondolencesApiActions.request),
        fetchCondolencesSaga,
    );
}
