import React from 'react';
import {
    Route,
    RouteComponentProps,
    RouteProps,
    Switch,
    useRouteMatch,
} from 'react-router';
import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { wizardRoutes as routes } from '../../routing/wizard-routes';
import { ShortDivider } from '../../components/short-divider/short-divider';
import { withPageSlugDetecting } from '../../meta/with-page-slug-detecting';
import { withPageMetaFetching } from '../../meta/with-page-meta-fetching';
import { WizardPath } from '../../routing/wizard-paths';
import { LogoLangugageContainer } from '../../components/logo-language-container/logo-language-container';

import { resolveWizardHeadlineText } from './resolve-wizard-headline-text';

type Props = RouteComponentProps & { wizardRoutes?: RouteProps[] };

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    divider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    introText: {
        margin: '0 auto',
        maxWidth: '50%',
        textAlign: 'center',
    },
    content: {
        maxWidth: '80rem',
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
}));

export const WizardLayout = ({ wizardRoutes = routes, ...props }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const match = useRouteMatch()!;

    return (
        <Container className={styles.container}>
            <LogoLangugageContainer />
            <ShortDivider className={styles.divider} />
            <Typography className={styles.introText}>
                {t(resolveWizardHeadlineText(match.path as WizardPath))}
            </Typography>
            <Paper className={styles.content} elevation={1}>
                <Switch>
                    {wizardRoutes.map((route) => (
                        <Route
                            key={
                                Array.isArray(route.path)
                                    ? route.path[0]
                                    : route.path
                            }
                            {...route}
                        />
                    ))}
                </Switch>
            </Paper>
        </Container>
    );
};

export const ComposedWizardLayout = compose<Props, Props>(
    withPageSlugDetecting,
    withPageMetaFetching,
)(WizardLayout);
