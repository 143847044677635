import { createSelector } from 'reselect';

import { RootStore } from '../../../store/store';

export const getGallerySectionStore = (state: RootStore) =>
    state.gallerySection;

export const getIsItemUploading = createSelector(
    getGallerySectionStore,
    (store) => store.uploadInProgress,
);

export const GallerySectionSelectors = {
    getIsItemUploading,
};
