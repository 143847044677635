import { createAction } from 'typesafe-actions';

import { ICondolence } from '../../interfaces/condolence';

export const condolencesFetchRequested = createAction(
    'model/condolences/CONDOLENCES_FETCH_REQUESTED',
);

export const condolencesReceived = createAction(
    'model/condolences/CONDOLENCES_RECEIVED',
    (action) => (payload: ICondolence[]) => action(payload),
);
