import { call, put, select } from '@redux-saga/core/effects';

import {
    galleryNewImageUploadRefToApiActions,
    newGalleryImageAddedByUser,
} from '../gallery.actions';
import { IImageUploadSessionResponseDto } from '../../../api/image-upload/dto/image-upload-session-response.dto';
import { getImageUploadSession } from '../../../api/image-upload/http/get-image-upload-session';
import { IConfirmImageOnServerDto } from '../../../api/image-upload/dto/confirm-image-on-server.dto';
import { uploadFileToS3 } from '../../../api/image-upload/http/upload-file-to-s3';
import { sendNewGalleryItemToApi } from '../../../api/gallery/http/send-new-gallery-item-to-api';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { IGalleryResponseDto } from '../../../api/gallery/dto/gallery-response.dto';

/**
 * TODO: Move calls to API actions
 */
export function* newGalleryImageSaga(
    action: ReturnType<typeof newGalleryImageAddedByUser>,
) {
    const { file } = action.payload;

    const slug = yield select(getPageSlug);

    try {
        const sessionData: IImageUploadSessionResponseDto = yield call(
            getImageUploadSession,
            file,
        );
        const imageRef: IConfirmImageOnServerDto = yield call(
            uploadFileToS3,
            sessionData,
            file,
        );

        yield put(galleryNewImageUploadRefToApiActions.request());
        const response: IGalleryResponseDto = yield call(
            sendNewGalleryItemToApi,
            slug,
            imageRef,
        );
        yield put(
            galleryNewImageUploadRefToApiActions.success({
                originalSrc: response.attributes.originalUrl,
                thumbSrc: response.attributes.thumbUrl,
                published: response.attributes.published,
                id: response.id,
            }),
        );
    } catch (err) {
        yield put(galleryNewImageUploadRefToApiActions.failure(err));
    }
}
