import { createReducer } from 'typesafe-actions';

import { wizardRestartRequested } from './wizard-about-deceased.actions';

export interface IWizardAboutDeceasedStore {
    termsAndConditionsAccepted: boolean;
}

const initialState: IWizardAboutDeceasedStore = {
    termsAndConditionsAccepted: false,
};

type Actions = ReturnType<typeof wizardRestartRequested>;

export const wizardAboutDeceasedReducer = createReducer<
    IWizardAboutDeceasedStore,
    Actions
>(initialState).handleAction(wizardRestartRequested, (state, action) => ({
    ...state,
    termsAndConditionsAccepted: true,
}));
