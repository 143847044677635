import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        background: 'none',
        fontSize: 'inherit',
        textDecoration: 'underline',
        outline: 'none',
        border: 'none',
        padding: 0,
        display: 'inline-block',
        cursor: 'pointer',
    },
});

export const ButtonLink = ({
    className,
    children,
    ...props
}: HTMLAttributes<HTMLButtonElement>) => {
    const styles = useStyles();

    return (
        <button
            type="button"
            className={cx(className, styles.button)}
            {...props}
        >
            {children}
        </button>
    );
};
