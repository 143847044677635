import { createReducer } from 'typesafe-actions';

import { fetchParticipantsApiActions } from '../../api/participants/participants-api.actions';
import { IParticipant } from '../../interfaces/participant';

import { participantsChangePage } from './participants.actions';

export interface IParticipantsStore {
    participants: IParticipant[];
    page: number;
    pageSize: number;
    totalCount: number | null;
}

const initialState: IParticipantsStore = {
    participants: [],
    page: 0,
    pageSize: 25,
    totalCount: null,
};

type Actions = ReturnType<
    typeof fetchParticipantsApiActions.success | typeof participantsChangePage
>;

export const participantsReducer = createReducer<IParticipantsStore, Actions>(
    initialState,
)
    .handleAction(fetchParticipantsApiActions.success, (state, action) => {
        return {
            ...state,
            participants: action.payload.participants,
            page: action.payload.pagination.current,
            totalCount: action.payload.pagination.total,
        };
    })
    .handleAction(participantsChangePage, (state, action) => {
        return {
            ...state,
            pageSize: action.payload.page,
            page: 1,
        };
    });
