import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography, Theme } from '@material-ui/core';
import cx from 'classnames';

export interface IMenuItemProps {
    id?: string;
    title: string;
    link: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    titleClassName?: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        color: theme.palette.primary.main,
        alignItems: 'center',
        borderRadius: '3px',
        display: 'flex',
        minWidth: '28rem',
        padding: theme.spacing(1, 2),
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& $title': {
                color: theme.palette.common.white,
            },
            '& $icon path': {
                fill: theme.palette.common.white,
            },
        },
    },
    rootActive: {
        backgroundColor: theme.palette.primary.main,
        '& $title': {
            color: theme.palette.common.white,
        },
        '& $icon path': {
            fill: theme.palette.common.white,
        },
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    obituaryIcon: {
        marginLeft: '-2px',
        marginRight: '14px',
        maxWidth: '20px',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.6rem',
        fontWeight: 'bold',
    },
}));

export const MenuItem = ({
    id,
    title,
    link,
    icon,
    active = false,
    titleClassName,
}: IMenuItemProps & { active?: boolean }) => {
    const styles = useStyles();

    const Icon: React.FC<React.SVGProps<SVGSVGElement>> = icon;

    const linkClass = cx(styles.root, {
        [styles.rootActive]: active,
    });

    const iconClass = cx(styles.icon, {
        [styles.obituaryIcon]: id === 'obituary',
    });

    return (
        <Link className={linkClass} to={link}>
            <Icon className={iconClass} />
            <Typography className={cx(styles.title, titleClassName)}>
                {title}
            </Typography>
        </Link>
    );
};
