import { createReducer } from 'typesafe-actions';

import { themeReceived } from './theme.actions';

export interface IThemeStore {
    theme: string;
}

const initialState: IThemeStore = {
    theme: '',
};

type Actions = ReturnType<typeof themeReceived>;

export const themeReducer = createReducer<IThemeStore, Actions>(
    initialState,
).handleAction(themeReceived, (state, action) => ({
    ...state,
    theme: action.payload,
}));
