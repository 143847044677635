import { put, take, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getOverviewEventsAsyncActions } from '../overview-events.actions';
import { fetchEventsApiActions } from '../../../../../api/events/api-events.actions';
import { getEvents } from '../../../../../events/model/selectors/get-events.selector';

export function* fetchOverviewEventsSaga() {
    const events = yield select(getEvents);

    if (events.length > 0) {
        yield put(getOverviewEventsAsyncActions.success(events));
        return;
    }

    yield put(fetchEventsApiActions.request());

    const responseAction:
        | ReturnType<typeof fetchEventsApiActions.success>
        | ReturnType<typeof fetchEventsApiActions.failure> = yield take([
        fetchEventsApiActions.success,
        fetchEventsApiActions.failure,
    ]);

    switch (responseAction.type) {
        case getType(fetchEventsApiActions.success):
            yield put(
                getOverviewEventsAsyncActions.success(responseAction.payload),
            );
            break;
        case getType(fetchEventsApiActions.failure):
            yield put(
                getOverviewEventsAsyncActions.failure(responseAction.payload),
            );
            break;
        default:
            yield;
    }
}
