import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';

import { getPerPage } from './get-per-page.selector';
import { resolveContactsSelector } from './resolve-contacts-selector';

export const makeGetPagesCount = (
    filter: NotificationContactStatus | null = null,
) =>
    createSelector(
        resolveContactsSelector(filter),
        getPerPage,
        (contacts, perPage) => Math.ceil(contacts.length / perPage),
    );
