import { createReducer } from 'typesafe-actions';

import { ILanguage } from '../../interfaces/language';

import { languageChanged } from './configuration.actions';
import { availableLanguages } from './available-languages';

export interface IConfigurationStore {
    language: ILanguage;
    languages: ILanguage[];
}

const initialState: IConfigurationStore = {
    /**
     * It's a fallback, because just after page or wizard gets loaded,
     * proper language its detected.
     *
     * Set 'en' because its default set in i18n setup
     */
    language: availableLanguages[0], // { code: 'en' },
    languages: availableLanguages,
};

type Actions = ReturnType<typeof languageChanged>;

export const configurationReducer = createReducer<IConfigurationStore, Actions>(
    initialState,
).handleAction(languageChanged, (state, action) => ({
    ...state,
    language: action.payload.language,
}));
