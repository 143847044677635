import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { updateTributeTextApiActions } from '../tribute-api.actions';

import { updateTributeApiSaga } from './update-tribute-api.saga';

export function* tributeApiSaga() {
    yield takeEvery(
        getType(updateTributeTextApiActions.request),
        updateTributeApiSaga,
    );
}
