import { createAction } from 'typesafe-actions';

import {
    IGalleryItem,
    IGalleryItemSuggestion,
} from '../../interfaces/gallery-item';

export const gallerySuggestionsRequested = createAction(
    'model/gallery-suggestions/REQUESTED',
);

export const gallerySuggestionsReceived = createAction(
    'model/gallery-suggestions/RECEIVED',
    (action) => (payload: IGalleryItemSuggestion[]) => action(payload),
);

export const gallerySuggestionAcceptanceRequested = createAction(
    'model/gallery-suggestions/ACCEPTANCE_REQUESTED',
    (action) => (payload: { id: string }) => action(payload),
);

export const gallerySuggestionRejectionRequested = createAction(
    'model/gallery-suggestions/REJECTION_REQUESTED',
    (action) => (payload: { id: string }) => action(payload),
);

export const removeGallerySuggestion = createAction(
    'model/gallery-suggestions/REMOVE',
    (action) => (payload: { id: string }) => action(payload),
);

export const moveGallerySuggestionToGallery = createAction(
    'model/gallery-suggestions/MOVE_TO_GALLERY',
    (action) => (payload: IGalleryItem) => action(payload),
);
