import { createSelector } from 'reselect';

import { RootStore } from '../../store/store';

const getMilestonesSectionStore = (store: RootStore) => store.milestonesSection;

const getUpdateModalOpen = createSelector(
    getMilestonesSectionStore,
    (store) => store.updateModalOpen,
);

const getCreateModalOpen = createSelector(
    getMilestonesSectionStore,
    (store) => store.createModalOpen,
);

const getEditedMilestone = createSelector(
    getMilestonesSectionStore,
    (store) => store.editedMilestone,
);

export const MilestoneSectionSelectors = {
    getUpdateModalOpen,
    getCreateModalOpen,
    getEditedMilestone,
};
