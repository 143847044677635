import { createSelector } from 'reselect';

import { getEditorContactsStore } from '../../../editor/notifications/contacts/model/selectors/get-editor-contacts-store.selector';

import { getInformedNotificationContacts } from './get-informed-notification-contacts';

export const getEditorInformedNotificationContacts = createSelector(
    getInformedNotificationContacts,
    getEditorContactsStore,
    (contacts, editorContacts) =>
        editorContacts.selectedContactsIds.filter((id) =>
            contacts.map((contact) => contact.id).includes(id),
        ),
);
