import { delay, put, take } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';

import { tributeFormSubmitted } from '../tribute.actions';
import { mapTributeFormToDTO } from '../map-tribute-form-to-dto';
import {
    tributeContentReceived,
    setUpdateTributeStatus,
} from '../../../model/tribute/tribute-model.actions';
import { UpdateTributeStatus } from '../../../model/tribute/tribute.store';
import { updateTributeTextApiActions } from '../../../api/tribute/tribute-api.actions';

export function* handleTributeFormSubmitSaga(
    action: ReturnType<typeof tributeFormSubmitted>,
) {
    const formData = action.payload;

    const dto = mapTributeFormToDTO(formData);

    yield put(updateTributeTextApiActions.request(dto));

    const result = yield take([
        updateTributeTextApiActions.success,
        updateTributeTextApiActions.failure,
    ]);

    if (isActionOf(updateTributeTextApiActions.success, result)) {
        yield put(
            tributeContentReceived({
                content: result.payload.tributeText || '',
            }),
        );
        yield put(setUpdateTributeStatus(UpdateTributeStatus.SUCCESS));
        yield delay(3000);
        yield put(setUpdateTributeStatus(null));
    } else if (isActionOf(updateTributeTextApiActions.failure, result)) {
        yield put(setUpdateTributeStatus(result.payload.status));
        yield delay(3000);
        yield put(setUpdateTributeStatus(null));
    }
}
