import { apiService } from '../../api.service';
import { ParticipantDto } from '../dto/participant.dto';

export const fetchParticipants = ({
    deceasedId,
    pageSize,
    current,
}: {
    deceasedId: string;
    pageSize?: number;
    current?: number;
}): Promise<ParticipantDto[]> => {
    return apiService
        .get(
            `/attendance_registrations?filter[deceased_person_id_eq]=${deceasedId}&page[size]=${pageSize}&page[number]=${current}`,
        )
        .then((resp) => resp.json());
};
