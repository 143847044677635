import React from 'react';
import { makeStyles, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../../components/section/section';

import { TaskTableRow } from './task-table-row/task-table-row';
import { useOverviewTasks } from './use-overview-tasks';

interface IOverviewTasksSectionProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: theme.spacing(2),
    },
}));

export const OverviewTasksSection = (props: IOverviewTasksSectionProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { tasks } = useOverviewTasks();

    if (!tasks.length) {
        return null;
    }

    return (
        <Section title={t('overviewTasksSection.title')} {...props}>
            <Table className={styles.table}>
                <TableBody>
                    {tasks.map((task) => (
                        <TaskTableRow key={task.id} task={task} />
                    ))}
                </TableBody>
            </Table>
        </Section>
    );
};
