import { subMinutes } from 'date-fns';

/**
 * Parsing date on frontend will by default include local timezone.
 * So if browser is in GMT+5 and creates new Date() at 15:00, the ISOString
 * will be 10:00 UTC.
 *
 * This logic adjusts date to apply timezone so the result string will point
 * exactly at time set by user for user's timezone
 *
 * Sending date to backend should adjust timezone so backend gets local time instead of
 * UTC time.
 *
 * Later we should implement timezones properly
 */
export const resetDateTimezone = (date: Date | string): Date => {
    const theDate = new Date(date);
    const timeOffsetMinutes = theDate.getTimezoneOffset();
    const localDateWithoutTimezone = subMinutes(theDate, timeOffsetMinutes);

    return localDateWithoutTimezone;
};
