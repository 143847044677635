import React from 'react';
import {
    Box,
    Checkbox,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ContactTableRow } from '../contact-table-row/contact-table-row';
import { Filter } from '../../../components/filter/filter';
import { ICreatedContact } from '../../../../interfaces/contact';
import { NotificationContactStatus } from '../../../../interfaces/notification-contact-status';
import {
    suggestedContactRejectionRequested,
    contactDeletionRequested,
} from '../model/contacts.actions';
import { useIsMobile } from '../../../../../styles/use-is-mobile';

type Props = {
    contacts: ICreatedContact[];
    contactsCount: number;
    selectedContactsIds: string[];
    allContactsSelected: boolean;
    selectedFilter: NotificationContactStatus;
    onFilterChange(filter: NotificationContactStatus): unknown;
    toggleAllContactsSelected(): unknown;
    toggleContactSelected(id: string): unknown;
};

const useStyles = makeStyles((theme) => ({
    headCell: {
        color: theme.palette.common.black,
        fontSize: '1.6rem',
        fontWeight: 'bold',
        paddingLeft: 0,
    },
    filter: {
        [theme.breakpoints.up('lg')]: {
            marginRight: theme.spacing(15),
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    checkbox: {
        marginRight: theme.spacing(1),
    },
}));

export const ContactsTable = ({
    contacts,
    contactsCount,
    selectedContactsIds,
    allContactsSelected,
    toggleAllContactsSelected,
    onFilterChange,
    selectedFilter,
    toggleContactSelected,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const filters = [
        {
            onClick: () => onFilterChange(NotificationContactStatus.SUGGESTED),
            name: t('notifications.suggestedFilter'),
            count: contactsCount,
            active: selectedFilter === NotificationContactStatus.SUGGESTED,
            className: styles.filter,
        },
        {
            onClick: () =>
                onFilterChange(NotificationContactStatus.NOT_INFORMED),
            name: t('notifications.notInformedFilter'),
            count: contactsCount,
            active: selectedFilter === NotificationContactStatus.NOT_INFORMED,
            className: styles.filter,
        },
        {
            onClick: () => onFilterChange(NotificationContactStatus.INFORMED),
            name: t('notifications.notifiedFilter'),
            count: contactsCount,
            active: selectedFilter === NotificationContactStatus.INFORMED,
            className: styles.filter,
        },
    ];

    const onSuggestedContactDeleted = (id: string) => {
        dispatch(suggestedContactRejectionRequested({ id }));
    };

    const onContactDeleted = (id: string) => {
        dispatch(contactDeletionRequested({ id }));
    };

    return (
        <>
            <Box
                data-testid="contacts-types-labels"
                display={isMobile ? 'block' : 'flex'}
                justifyContent="space-between"
                mb={4}
            >
                {filters.map((filterProps) => (
                    <Filter {...filterProps} key={filterProps.name} />
                ))}
            </Box>
            <Table data-testid="contacts-table-container">
                {isMobile && (
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={allContactsSelected}
                            color="primary"
                            className={styles.checkbox}
                            onClick={toggleAllContactsSelected}
                        />
                        <Typography>
                            {allContactsSelected
                                ? t('notifications.unselectAll')
                                : t('notifications.selectAll')}
                        </Typography>
                    </Box>
                )}
                {!isMobile && (
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.headCell}>
                                <Box display="flex" alignItems="center">
                                    <Checkbox
                                        checked={allContactsSelected}
                                        color="primary"
                                        className={styles.checkbox}
                                        onClick={toggleAllContactsSelected}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('notifications.tableColumns.name')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('notifications.tableColumns.email')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('notifications.tableColumns.phone')}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {contacts.map((contact) => (
                        <ContactTableRow
                            onDelete={() => {
                                return selectedFilter ===
                                    NotificationContactStatus.SUGGESTED
                                    ? onSuggestedContactDeleted(contact.id)
                                    : onContactDeleted(contact.id);
                            }}
                            key={contact.id}
                            contact={{
                                ...contact,
                                isAdmin: contact.isAdmin || false,
                            }}
                            selected={selectedContactsIds.includes(contact.id)}
                            toggleContactSelected={toggleContactSelected}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
