import { apiService } from '../../api.service';
import { IBackgroundImageDto } from '../dto/background-image.dto';

export const fetchBackgroundImages = (
    slug: string,
): Promise<IBackgroundImageDto[]> => {
    return apiService
        .get(`/memorial_pages/${slug}/backgrounds`)
        .then((result) => result.json())
        .then((result) => result.data);
};
