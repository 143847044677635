import { createReducer } from 'typesafe-actions';

import { ISuggestion } from '../../interfaces/suggestion';

import { suggestionsReceived } from './suggestions.actions';

export interface ISuggestionsStore {
    suggestions: ISuggestion[];
}

const initialState: ISuggestionsStore = {
    suggestions: [],
};

type Actions = ReturnType<typeof suggestionsReceived>;

export const suggestionsReducer = createReducer<ISuggestionsStore, Actions>(
    initialState,
).handleAction(suggestionsReceived, (state, action) => ({
    ...state,
    suggestions: action.payload,
}));
