import React, { HTMLAttributes } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

interface Props extends HTMLAttributes<HTMLDivElement> {
    text: string;
    stepNo: number;
}

const desktopSize = '4rem';
const mobileSize = '3rem';

const useStyles = makeStyles((theme) => ({
    headline: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        },
    },
    text: {
        color: theme.palette.primary.light,
        verticalAlign: 'middle',
    },
    number: {
        background: theme.palette.primary.light,
        width: desktopSize,
        height: desktopSize,
        display: 'inline-block',
        marginRight: theme.spacing(2),
        borderRadius: desktopSize,
        textAlign: 'center',
        color: theme.palette.common.white,
        lineHeight: desktopSize,
        [theme.breakpoints.down('sm')]: {
            width: mobileSize,
            height: mobileSize,
            borderRadius: mobileSize,
            lineHeight: mobileSize,
            fontSize: '0.8em',
        },
    },
}));

export const StepHeadline = ({ text, stepNo, ...props }: Props) => {
    const styles = useStyles();

    return (
        <div {...props}>
            <Typography variant="h2" className={styles.headline}>
                <span className={styles.number}>{stepNo}</span>
                <span className={styles.text}>{text}</span>
            </Typography>
        </div>
    );
};
