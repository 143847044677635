import * as yup from 'yup';

import { IEventFormData } from '../event-form-data';
import { ILocationAttributes } from '../../api/events/dto/event-response.dto';
import { EventType } from '../../interfaces/event-type';

export const EventFormValidationSchema = () =>
    yup.object().shape<Omit<IEventFormData, 'id' | 'type'>>(
        {
            date: yup.date().when(['type', 'time'], {
                is: (type, time) =>
                    type === EventType.CEREMONY || time !== null,
                then: yup
                    .date()
                    .nullable()
                    .required(),
                otherwise: yup.date().nullable(),
            }),
            time: yup.date().when(['type', 'date'], {
                is: (type, date) =>
                    type === EventType.CEREMONY || date !== null,
                then: yup
                    .date()
                    .nullable()
                    .required(),
                otherwise: yup.date().nullable(),
            }),
            title: yup.string().required(),
            description: yup.string(),
            location: yup.object<ILocationAttributes>().nullable(),
            isReadOnly: yup.boolean(),
        },
        [['time', 'date']],
    );
