import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import { deleteMilestoneAsyncActions } from '../milestones.actions';
import { deleteMilestoneApiActions } from '../../../api/milestones/milestones-api.actions';

type PossibleAPIResultActions =
    | ReturnType<typeof deleteMilestoneApiActions.success>
    | ReturnType<typeof deleteMilestoneApiActions.failure>;

export function* deleteMilestoneSaga(
    action: ReturnType<typeof deleteMilestoneAsyncActions.request>,
) {
    yield put(deleteMilestoneApiActions.request({ id: action.payload.id }));

    const result: PossibleAPIResultActions = yield take([
        deleteMilestoneApiActions.success,
        deleteMilestoneApiActions.failure,
    ]);

    switch (result.type) {
        case getType(deleteMilestoneApiActions.success):
            yield put(deleteMilestoneAsyncActions.success(action.payload.id));
            return;
        case getType(deleteMilestoneApiActions.failure):
            yield put(deleteMilestoneAsyncActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
