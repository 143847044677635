import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { deleteTaskAsyncActions } from '../tasks.actions';
import { deleteTaskApiActions } from '../../../api/tasks/api-tasks.actions';

export function* deleteTaskSaga(
    action: ReturnType<typeof deleteTaskAsyncActions.request>,
) {
    yield put(deleteTaskApiActions.request({ id: action.payload.id }));

    const resultAction:
        | ReturnType<typeof deleteTaskApiActions.success>
        | ReturnType<typeof deleteTaskApiActions.failure> = yield take([
        deleteTaskApiActions.success,
        deleteTaskApiActions.request,
    ]);

    switch (resultAction.type) {
        case getType(deleteTaskApiActions.success):
            yield put(
                deleteTaskAsyncActions.success({
                    id: action.payload.id,
                }),
            );
            break;
        case getType(deleteTaskApiActions.failure):
            yield put(deleteTaskAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
