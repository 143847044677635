import { call, put, select } from '@redux-saga/core/effects';

import { fetchInvitationsApiActions } from '../invitations-api.actions';
import { fetchInvitations } from '../http/fetch-invitations';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { InvitationDto } from '../dto/invitation.dto';
import { mapInvitationsDtoToInvitations } from '../mappers/map-invitations-dto-to-invitations';

export function* fetchInvitationsSaga() {
    const slug = yield select(getPageSlug);

    try {
        const resp: InvitationDto[] = yield call(fetchInvitations, {
            slug,
        });

        const payload = mapInvitationsDtoToInvitations(resp);

        yield put(fetchInvitationsApiActions.success(payload));
    } catch (err) {
        yield put(fetchInvitationsApiActions.failure(err.statusText));
    }
}
