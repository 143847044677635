import React, { useState } from 'react';
import { Box, Dialog, IconButton, makeStyles } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop, { Crop } from 'react-image-crop';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../secondary-button/secondary-button';
import { SecondaryTextButton } from '../secondary-text-button/secondary-text-button';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { getCroppedImage } from '../../utils/get-cropped-image';

import 'react-image-crop/lib/ReactCrop.scss';

type Props = Omit<DialogProps, 'onSubmit' & 'onClose'> & {
    aspect?: number;
    cancellable?: boolean;
    skippable?: boolean;
    fileName: string;
    image: string | null;
    onCancel?: () => unknown;
    onClose(): unknown;
    onCrop(file: File): unknown;
    onSkipCropping?(): unknown;
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    button: {
        marginRight: theme.spacing(2),
    },
}));

export const ImageCropModal = ({
    aspect = 1,
    cancellable = false,
    skippable = false,
    fileName,
    image,
    onCancel,
    onClose,
    onCrop,
    onSkipCropping,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    const styles = useStyles();
    const [crop, setCrop] = useState<Crop>({ aspect, unit: '%', width: 50 });
    const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(
        null,
    );

    const onCropClick = async () => {
        if (loadedImage) {
            const croppedImageFile = await getCroppedImage(
                loadedImage,
                crop,
                fileName,
            );

            if (croppedImageFile) {
                onCrop(croppedImageFile);
            }
        }
    };

    const croppingDisabled = crop.width === 0 || crop.height === 0;

    return (
        <Dialog fullScreen={mobile} maxWidth="md" {...props} onClose={onClose}>
            <Box
                data-testid="image-crop-modal"
                px={mobile ? 4 : 12}
                pt={6.5}
                pb={3}
                position="relative"
            >
                <ReactCrop
                    crop={crop}
                    onChange={(cropped) => setCrop(cropped)}
                    onImageLoaded={(img) => setLoadedImage(img)}
                    src={image || ''}
                />
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Box display="flex" justifyContent="flex-end" mt={3}>
                    {cancellable && (
                        <SecondaryTextButton
                            data-testid="image-crop-cancel"
                            className={styles.button}
                            onClick={() => {
                                if (onCancel) {
                                    onCancel();
                                }
                            }}
                        >
                            {t('common.cancel')}
                        </SecondaryTextButton>
                    )}
                    {skippable && (
                        <SecondaryTextButton
                            className={styles.button}
                            onClick={onSkipCropping}
                        >
                            {t('common.skipCropping')}
                        </SecondaryTextButton>
                    )}
                    <SecondaryButton
                        data-testid="image-crop-save"
                        disabled={croppingDisabled}
                        onClick={onCropClick}
                    >
                        {t('common.crop')}
                    </SecondaryButton>
                </Box>
            </Box>
        </Dialog>
    );
};
