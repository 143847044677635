import { createSelector } from 'reselect';
import i18n from 'i18next';

import { ContactSuggestionStatus } from '../notification-contacts.store';

import { getContactSuggestionStatus } from './get-contact-suggestion-status.selector';

export const getContactSuggestionStatusMessage = createSelector(
    getContactSuggestionStatus,
    (status) => {
        const ContactSuggestionStatusMessage = {
            [ContactSuggestionStatus.SUCCESS]: i18n.t(
                'contactSuggestionStatus.success',
            ),
            [ContactSuggestionStatus.ALREADY_EXISTS]: i18n.t(
                'contactSuggestionStatus.alreadyExists',
            ),
            [ContactSuggestionStatus.ERROR]: i18n.t(
                'contactSuggestionStatus.error',
            ),
        };
        return status ? ContactSuggestionStatusMessage[status] : null;
    },
);
