import { getType } from 'typesafe-actions';
import { put, select, take } from 'redux-saga/effects';

import {
    updateTaskAsyncActions,
    updateTaskFormSubmitted,
} from '../tasks.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { mapTaskToCreateDTO } from '../map-task-to-create-dto';
import { updateTaskApiActions } from '../../../api/tasks/api-tasks.actions';

export function* updateTaskSaga(
    action: ReturnType<typeof updateTaskFormSubmitted>,
) {
    const { payload } = action;
    const slug = yield select(getPageSlug);
    const dto = mapTaskToCreateDTO(payload.task, slug);

    yield put(
        updateTaskApiActions.request({
            ...dto,
            id: action.payload.id,
        }),
    );

    const resultAction:
        | ReturnType<typeof updateTaskApiActions.success>
        | ReturnType<typeof updateTaskApiActions.failure> = yield take([
        updateTaskApiActions.success,
        updateTaskApiActions.failure,
    ]);

    switch (resultAction.type) {
        case getType(updateTaskApiActions.success):
            yield put(
                updateTaskAsyncActions.success({
                    task: resultAction.payload,
                }),
            );
            break;
        case getType(updateTaskApiActions.failure):
            yield put(updateTaskAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
