import { createAction, createAsyncAction } from 'typesafe-actions';

import { IWizardNotificationsFormValues } from '../components/wizard-notifications-form/wizard-notifications-form-values';
import { IContact, ICreatedContact } from '../../../interfaces/contact';

import { NotificationMessageStatus } from './wizard-notifications.store';

export const wizardNotificationsFormSubmitted = createAction(
    'wizard/notifications/FORM_SUBMITTED',
    (action) => (payload: IWizardNotificationsFormValues) => action(payload),
);

export const wizardNotificationsFormSkipRequested = createAction(
    'wizard/notifications/SKIP_REQUESTED',
);

export const wizardNotificationsMessageFieldChanged = createAction(
    'wizard/notifications/MESSAGE_FIELD_CHANGED',
    (action) => (payload: { message: string }) => action(payload),
);

export const wizardNotificationContactAddActions = createAsyncAction(
    'wizard/notifications/NEW_CONTACT_ADD_REQUESTED',
    'wizard/notifications/NEW_CONTACT_ADD_SUCCEED',
    'wizard/notifications/NEW_CONTACT_ADD_FAILED',
)<IContact, ICreatedContact, string>();

export const wizardSetNotificationMessageStatus = createAction(
    'model/notifications/SET_MESSAGE_STATUS',
    (action) => (payload: NotificationMessageStatus | null) => action(payload),
);
