import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    facebookSharingRequested,
    messengerSharingRequested,
    whatsappSharingRequested,
} from './sharing.actions';
import { facebookSharingSaga } from './saga/facebook-sharing.saga';
import { messengerSharingSaga } from './saga/messenger-sharing.saga';
import { whatsappSharingSaga } from './saga/whatsapp-sharing.saga';

export function* sharingSaga() {
    yield takeEvery(getType(facebookSharingRequested), facebookSharingSaga);
    yield takeEvery(getType(messengerSharingRequested), messengerSharingSaga);
    yield takeEvery(getType(whatsappSharingRequested), whatsappSharingSaga);
}
