import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { getOverviewEventsAsyncActions } from '../overview-events.actions';

import { fetchOverviewEventsSaga } from './get-overview-events.saga';

export function* overviewEventsSaga() {
    yield takeEvery(
        getType(getOverviewEventsAsyncActions.request),
        fetchOverviewEventsSaga,
    );
}
