import { call, put } from '@redux-saga/core/effects';

import { passwordResetApiActions } from '../password.actions';
import { resetAccountPassword } from '../http/reset-account-password';
import { IPasswordResetResponseDto } from '../dto/password-reset-response.dto';

export function* resetUserPasswordSaga(
    action: ReturnType<typeof passwordResetApiActions.request>,
) {
    try {
        const response: IPasswordResetResponseDto = yield call(
            resetAccountPassword,
            {
                token: action.payload.token,
                password: action.payload.password,
            },
        );

        yield put(passwordResetApiActions.success(response));
    } catch (e) {
        yield put(passwordResetApiActions.failure(e.statusText));
    }
}
