import React, { useState } from 'react';
import cx from 'classnames';
import {
    ClickAwayListener,
    ListItemIcon,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import { Delete, PowerSettingsNew } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { GalleryItem, IGalleryItemProps } from '../gallery-item/gallery-item';

interface IProps
    extends Omit<IGalleryItemProps, 'onEditClicked' | 'renderMenu'> {
    onTakeOffline(): unknown;
    onPutOnline(): unknown;
    onDelete(): unknown;
    online: boolean;
}

const useStyles = makeStyles((theme) => ({
    onTop: {
        zIndex: 10,
    },
    offline: {
        filter: 'grayscale(1)',
    },
    menu: {
        marginTop: theme.spacing(1),
    },
}));

export const EditableGalleryItem = ({
    src,
    className,
    onTakeOffline,
    onPutOnline,
    onDelete,
    online,
    ...props
}: IProps) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const styles = useStyles();
    const onToggle = () => setMenuOpen((open) => !open);

    return (
        <GalleryItem
            className={cx(className, {
                [styles.onTop]: menuOpen,
                [styles.offline]: !online,
            })}
            src={src}
            onEditClicked={onToggle}
            renderMenu={(anchor: HTMLElement | null) => {
                return (
                    <Popper open={menuOpen} anchorEl={anchor} disablePortal>
                        <Paper data-testid="picture-edit-tooltip">
                            <ClickAwayListener
                                onClickAway={() => setMenuOpen(false)}
                            >
                                <MenuList className={styles.menu}>
                                    <MenuItem
                                        onClick={() => {
                                            if (online) {
                                                onTakeOffline();
                                            } else {
                                                onPutOnline();
                                            }
                                            setMenuOpen(false);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <PowerSettingsNew />
                                        </ListItemIcon>
                                        {online
                                            ? t('common.takeOffline')
                                            : t('common.putOnline')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            onDelete();
                                            setMenuOpen(false);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Delete />
                                        </ListItemIcon>
                                        {t('common.delete')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                );
            }}
            {...props}
        />
    );
};
