import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from '@material-ui/core/Select/Select';
import { sortBy } from 'lodash';

import { DeceasedReligion } from '../../../model/obituary.types';

export const DeceasedReligionSelectInput = ({
    name,
    value,
    onChange,
    onBlur,
}: Pick<SelectProps, 'name' | 'value' | 'onChange' | 'onBlur'>) => {
    const { t } = useTranslation();

    const options = sortBy(
        Object.values(DeceasedReligion).map((option) => ({
            value: option,
            label: t(`obituary.deceased.religion.${option}`),
        })),
        'label',
    );

    const inputProps = {
        id: name,
    };

    const label = t('obituary.deceasedReligion');

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={label}
                inputProps={inputProps}
            >
                <MenuItem value="">
                    {t('obituary.deceased.religion.preferNotToSay')}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
