import { apiService } from '../../api.service';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';
import { IGenerateObituaryDto } from '../dto/generate-obituary.dto';

export const generateObituary = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IGenerateObituaryDto;
}): Promise<IMemorialPageMetaDataResponse> => {
    return apiService
        .post(`/memorial_pages/${slug}/obituary/generate`, {
            json: dto,
            timeout: 60 * 1000 * 5, // 5 minutes
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
