import * as yup from 'yup';

export const getPasswordValidation = () =>
    yup
        .string()
        .test({
            name: 'oneUpper',
            message: 'errors.oneUpper',
            test: (v) => RegExp(/[A-Z]/).test(v),
        })
        .test({
            name: 'oneLower',
            message: 'errors.oneLower',
            test: (v) => RegExp(/[a-z]/).test(v),
        })
        .test({
            name: 'digit',
            message: 'errors.oneDigit',
            test: (v) => RegExp(/[0-9]/).test(v),
        })
        .min(6);
