import { createReducer } from 'typesafe-actions';

import { ITask } from '../../../../interfaces/task';

import { getOverviewTasksAsyncActions } from './overview-tasks.actions';

export interface IOverviewTasksStore {
    tasks: ITask[];
}

const initialState: IOverviewTasksStore = {
    tasks: [],
};

type Actions = ReturnType<typeof getOverviewTasksAsyncActions.success>;

export const overviewTasksReducer = createReducer<IOverviewTasksStore, Actions>(
    initialState,
).handleAction(getOverviewTasksAsyncActions.success, (state, action) => {
    return {
        ...state,
        tasks: action.payload,
    };
});
