import { createReducer } from 'typesafe-actions';

import {
    updateObituaryRequested,
    generateObituaryRequested,
    resetObituaryStore,
} from '../../obituary/model/obituary.actions';

import {
    generatedObituaryReceived,
    obituaryReceived,
    setObituaryStatus,
} from './obituary-model.actions';

export enum ObituaryStatus {
    SUCCESS,
    GENERATING,
    GENERATED,
    INAPPROPRIATE_WORDS,
    GENERATE_ERROR,
    UPDATE_ERROR,
}

export interface IObituaryModelStore {
    obituaryText: string;
    generatedObituary: string;
    obituaryStatus: ObituaryStatus | null;
    obituaryLoading: boolean;
}

const initialState: IObituaryModelStore = {
    obituaryText: '',
    generatedObituary: '',
    obituaryStatus: null,
    obituaryLoading: false,
};

type Actions =
    | ReturnType<typeof generateObituaryRequested>
    | ReturnType<typeof updateObituaryRequested>
    | ReturnType<typeof obituaryReceived>
    | ReturnType<typeof generatedObituaryReceived>
    | ReturnType<typeof setObituaryStatus>
    | ReturnType<typeof resetObituaryStore>;

const getObituaryLoadingBasedOnObituaryStatus = (
    obituaryStatus: ObituaryStatus | null,
) => {
    switch (obituaryStatus) {
        case ObituaryStatus.GENERATING: {
            return true;
        }
        default:
            return false;
    }
};
export const obituaryModelReducer = createReducer<IObituaryModelStore, Actions>(
    initialState,
)
    .handleAction(updateObituaryRequested, (state) => ({
        ...state,
        obituaryLoading: true,
    }))
    .handleAction(generateObituaryRequested, (state) => ({
        ...state,
        obituaryLoading: true,
    }))
    .handleAction(generatedObituaryReceived, (state, action) => ({
        ...state,
        generatedObituary: action.payload.obituaryText,
    }))
    .handleAction(obituaryReceived, (state, action) => ({
        ...state,
        obituaryText: action.payload.obituaryText,
        generatedObituary: '',
    }))
    .handleAction(setObituaryStatus, (state, action) => ({
        ...state,
        obituaryStatus: action.payload,
        obituaryLoading: getObituaryLoadingBasedOnObituaryStatus(
            action.payload,
        ),
    }))
    .handleAction(resetObituaryStore, (state) => ({
        ...initialState,
        obituaryText: state.obituaryText,
    }));
