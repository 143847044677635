import { put, take } from 'redux-saga/effects';

import {
    changePagePublishedStatusRequested,
    changePagePublishedStatusSucceed,
} from '../settings.actions';
import { updateSettingsApiActions } from '../../../api/settings/settings.actions';

export function* updatePagePublishedStatusSaga(
    action: ReturnType<typeof changePagePublishedStatusRequested>,
) {
    yield put(
        updateSettingsApiActions.request({
            published: action.payload.published,
        }),
    );

    const {
        payload: { published },
    }: ReturnType<typeof updateSettingsApiActions.success> = yield take(
        updateSettingsApiActions.success,
    );

    yield put(changePagePublishedStatusSucceed({ published }));
}
