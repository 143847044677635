import { createAsyncAction } from 'typesafe-actions';

import { ISubmitPasswordResponseDto } from './dto/submit-password-response.dto';
import { IPasswordResetResponseDto } from './dto/password-reset-response.dto';

export const passwordsSetApiActions = createAsyncAction(
    'api/password/SET_PASSWORDS_REQUESTED',
    'api/password/SET_PASSWORDS_SUCCEED',
    'api/password/SET_PASSWORDS_FAILED',
)<{ password: string }, ISubmitPasswordResponseDto, string>();

/**
 * TODO: Pass errors
 */
export const passwordResetApiActions = createAsyncAction(
    'api/password/RESET_REQUESTED',
    'api/password/RESET_SUCCESS',
    'api/password/RESET_FAILED',
)<{ token: string; password: string }, IPasswordResetResponseDto, string>();

export const sendPasswordResetEmailApiActions = createAsyncAction(
    'api/password/SEND_RESET_EMAIL_REQUESTED',
    'api/password/SEND_RESET_EMAIL_SUCCEED',
    'api/password/SEND_RESET_EMAIL_FAILED',
)<{ email: string }, undefined, string>();
