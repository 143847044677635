import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { deleteEventAsyncActions } from '../events.actions';
import { deleteEventApiActions } from '../../../api/events/api-events.actions';

export function* deleteEventSaga(
    action: ReturnType<typeof deleteEventAsyncActions.request>,
) {
    yield put(
        deleteEventApiActions.request({
            id: action.payload.id,
        }),
    );

    const resultAction:
        | ReturnType<typeof deleteEventApiActions.success>
        | ReturnType<typeof deleteEventApiActions.failure> = yield take([
        deleteEventApiActions.success,
        deleteEventApiActions.failure,
    ]);

    switch (resultAction.type) {
        case getType(deleteEventApiActions.success):
            yield put(deleteEventAsyncActions.success(action.payload.id));
            break;
        case getType(deleteEventApiActions.failure):
            yield put(deleteEventAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
