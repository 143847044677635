import React from 'react';
import cx from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';

import phoneImage from '../../assets/images/phone-wireframe.png';
import { hexToRgba } from '../../utils/hex-to-rgba';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    message: {
        background: hexToRgba(theme.palette.primary.light, 0.1),
        borderRadius: '10px',
        fontSize: '1.2rem',
        left: theme.spacing(9),
        margin: theme.spacing(0, 1.5),
        maxHeight: theme.spacing(20),
        overflowY: 'auto',
        padding: theme.spacing(1),
        position: 'absolute',
        right: theme.spacing(7),
        top: theme.spacing(9),
        wordBreak: 'break-all',
        [theme.breakpoints.down('sm')]: {
            left: theme.spacing(20),
            right: theme.spacing(15),
            top: theme.spacing(17),
        },
        [theme.breakpoints.down('xs')]: {
            left: '25%',
            right: '20%',
            top: '25%',
            maxHeight: '70%',
        },
    },
}));

type Props = BoxProps & {
    message: string;
};

export const PhoneMessagePreview = ({
    message,
    className,
    ...props
}: Props) => {
    const styles = useStyles();

    return (
        <Box
            data-testid="message-form-preview"
            className={cx(styles.container, className)}
            {...props}
        >
            <img src={phoneImage} alt="" />

            {message.length > 0 && (
                <Typography
                    data-testid="phone-message-preview-text"
                    className={styles.message}
                >
                    {message}
                </Typography>
            )}
        </Box>
    );
};
