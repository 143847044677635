import React from 'react';
import { Box, Container } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps & {
    status?: string | number;
    location?: {
        state?: {
            error?: any;
        } | null;
    };
};

export const ErrorPage = (props: Props) => {
    return (
        <Container>
            <Box my={5}>
                {props.status ||
                    (props.location.state && props.location.state.error) ||
                    404}
            </Box>
        </Container>
    );
};
