import { createReducer } from 'typesafe-actions';

import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';
import { deceasedDataReceived } from '../../api/deceased/deceased-api.actions';

import { setUpdateDeceasedStatus } from './deceased-person.actions';

export enum UpdateDeceasedDataStatus {
    SUCCESS,
    ERROR,
}

export interface IDeceasedPersonModelStore {
    data: IDeceasedPersonData;
    updateDeceasedStatus: UpdateDeceasedDataStatus | null;
}

const initialState: IDeceasedPersonModelStore = {
    data: {
        name: '',
        firstName: '',
        faith: '',
        deathPlace: null,
        deathDate: null,
        birthPlace: null,
        birthDate: null,
        id: '',
    },
    updateDeceasedStatus: null,
};

type Actions =
    | ReturnType<typeof deceasedDataReceived>
    | ReturnType<typeof setUpdateDeceasedStatus>;

export const deceasedPersonModelReducer = createReducer<
    IDeceasedPersonModelStore,
    Actions
>(initialState)
    .handleAction(deceasedDataReceived, (state, action) => ({
        ...state,
        data: action.payload,
    }))
    .handleAction(setUpdateDeceasedStatus, (state, action) => ({
        ...state,
        updateDeceasedStatus: action.payload,
    }));
