import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { ReactComponent as MemcareNotifyLogo } from '../../../assets/icons/memcare_notify.svg';
import { getTheme } from '../../../model/theme/selectors/get-theme.selector';
import { THEME, THEME_DETAILS } from '../../../utils/get-theme';
import { getPublicUrl } from '../../../utils/url-resolvers';
import { getThemeBeforeLogIn } from '../../../utils/get-theme-before-log-in';

const useStyles = makeStyles<Theme>(() => ({
    logo: {
        height: '4.2rem',
        width: 'auto',
        '&$vg': {
            height: '3rem',
        },
    },
    logoCentered: {
        display: 'block',
        margin: '0 auto',
        marginTop: 32,
        '&$vg': {
            textAlign: 'center',
            marginTop: 32,
        },
    },
    title: {
        fontFamily: 'Raleway',
        fontSize: '12pt',
        height: '3rem',
        marginLeft: '0.5rem',
    },
    logoVG: {
        height: '100%',
    },
    vg: {},
}));

export const Logo = (props: { inNavbar?: boolean }) => {
    const styles = useStyles();

    const receivedTheme = useSelector(getTheme);
    const th = getThemeBeforeLogIn(receivedTheme);

    return th === THEME.VG ? (
        <div
            className={cx(styles[th], {
                [styles.logo]: true,
                [styles.logoCentered]: !props.inNavbar,
            })}
        >
            <img
                className={styles.logoVG}
                src={`${getPublicUrl()}/${
                    THEME_DETAILS[th as keyof typeof THEME_DETAILS].logo
                }`}
                alt="company logo"
            />
            <span className={styles.title}>
                {THEME_DETAILS[th as keyof typeof THEME_DETAILS].additionalText}
            </span>
        </div>
    ) : (
        <MemcareNotifyLogo
            className={cx({
                [styles.logo]: true,
                [styles.logoCentered]: !props.inNavbar,
            })}
        />
    );
};
