import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import {
    updateMilestoneAsyncActions,
    updateMilestoneFormSubmitted,
} from '../milestones.actions';
import { mapMilestoneFormToDto } from '../map-milestone-form-to-dto';
import { updateMilestoneApiActions } from '../../../api/milestones/milestones-api.actions';

type PossibleAPIResultActions =
    | ReturnType<typeof updateMilestoneApiActions.success>
    | ReturnType<typeof updateMilestoneApiActions.failure>;

export function* updateMilestoneSaga(
    action: ReturnType<typeof updateMilestoneFormSubmitted>,
) {
    const { payload } = action;
    const dto = mapMilestoneFormToDto(payload.milestone);

    yield put(
        updateMilestoneApiActions.request({
            ...dto,
            id: payload.id,
        }),
    );

    const result: PossibleAPIResultActions = yield take([
        updateMilestoneApiActions.success,
        updateMilestoneApiActions.failure,
    ]);

    switch (result.type) {
        case getType(updateMilestoneApiActions.success):
            yield put(updateMilestoneAsyncActions.success(result.payload));
            return;
        case getType(updateMilestoneApiActions.failure):
            yield put(updateMilestoneAsyncActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
