import { createAsyncAction } from 'typesafe-actions';

import { IMilestone } from '../../interfaces/milestone';

import { IMilestoneCreationDto } from './dto/milestone-creation.dto';

export const createMilestoneApiActions = createAsyncAction(
    'api/milestones/CREATE_MILESTONE_REQUESTED',
    'api/milestones/CREATE_MILESTONE_SUCCEED',
    'api/milestones/CREATE_MILESTONE_FAILED',
)<IMilestoneCreationDto, IMilestone, string>();

export const updateMilestoneApiActions = createAsyncAction(
    'api/milestones/UPDATE_MILESTONE_REQUESTED',
    'api/milestones/UPDATE_MILESTONE_SUCCEED',
    'api/milestones/UPDATE_MILESTONE_FAILED',
)<Partial<IMilestoneCreationDto> & { id: string }, IMilestone, string>();

export const deleteMilestoneApiActions = createAsyncAction(
    'api/milestones/DELETE_MILESTONE_REQUESTED',
    'api/milestones/DELETE_MILESTONE_SUCCEED',
    'api/milestones/DELETE_MILESTONE_FAILED',
)<{ id: string }, { id: string }, string>();

export const fetchMilestonesApiActions = createAsyncAction(
    'api/milestones/FETCH_MILESTONES_REQUESTED',
    'api/milestones/FETCH_MILESTONES_SUCCEED',
    'api/milestones/FETCH_MILESTONES_FAILED',
)<undefined, IMilestone[], string>();
