import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    updateObituaryRequested,
    generateObituaryRequested,
} from '../obituary.actions';

import { handleUpdateObituarySaga } from './handle-update-obituary.saga';
import { handleGenerateObituarySaga } from './handle-generate-obituary.saga';

export function* obituarySaga() {
    yield takeEvery(getType(updateObituaryRequested), handleUpdateObituarySaga);
    yield takeEvery(
        getType(generateObituaryRequested),
        handleGenerateObituarySaga,
    );
}
