import React from 'react';
import {
    Box,
    makeStyles,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconRounded } from '../../components/icon-rounded/icon-rounded';
import { SectionHeadline } from '../../components/section-headline/section-headline';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { Colors } from '../../../styles/colors';
import { TaskStatus } from '../../interfaces/task';
import { useDateFormatter } from '../../utils/use-date-formatter';

export interface ITaskData {
    task: string;
    expirationDate: Date | null;
    expirationTime: Date | null;
    status: TaskStatus;
    description?: string;
}

export interface ITaskProps {
    task: ITaskData;
    onDelete: () => unknown;
    onEdit(): unknown;
}

const useStyles = makeStyles((theme) => ({
    row: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateAreas: `"task date status" "task action action"`,
            borderBottom: `1px solid ${Colors.SILVER}`,
        },
    },
    bodyCell: {
        fontSize: '1.5rem',
        paddingLeft: 0,
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        border: 0,
    },
    bodyCellTask: {
        width: '40%',
        gridArea: 'task',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    bodyCellDate: {
        gridArea: 'date',
    },
    bodyCellStatus: {
        gridArea: 'status',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    bodyCellAction: {
        gridArea: 'action',
    },
    bodyCellLast: {
        '&, &:last-child': {
            paddingRight: 0,
        },
    },
    bodyCellTitle: {
        fontSize: '1.7rem',
    },
    status: {
        backgroundColor: theme.palette.common.black,
        borderRadius: '3px',
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        padding: theme.spacing(0.5, 1),
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    statusCompleted: {
        backgroundColor: theme.palette.success.main,
    },
    statusInProgress: {
        backgroundColor: Colors.GOLD_DROP,
    },
    statusTodo: {
        backgroundColor: Colors.VALENCIA,
    },
    icon: {
        color: theme.palette.primary.light,
    },
}));

export const Task = ({ task, onDelete, onEdit }: ITaskProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { getTime, getShortDate } = useDateFormatter();

    const statusTranslation = {
        [TaskStatus.DONE]: t('ceremonyAndReception.taskDone'),
        [TaskStatus.IN_PROGRESS]: t('ceremonyAndReception.taskInProgress'),
        [TaskStatus.TODO]: t('ceremonyAndReception.taskTodo'),
    };

    const statusClass = (status: string) =>
        cx(styles.status, {
            [styles.statusCompleted]: status === TaskStatus.DONE,
            [styles.statusInProgress]: status === TaskStatus.IN_PROGRESS,
            [styles.statusTodo]: status === TaskStatus.TODO,
        });

    let date = '';

    if (task.expirationDate) {
        date = getShortDate(task.expirationDate);
    }

    if (task.expirationTime) {
        date += `, ${getTime(task.expirationTime)}`;
    }

    return (
        <TableRow className={cx(styles.row)}>
            <TableCell className={cx(styles.bodyCell, styles.bodyCellTask)}>
                <SectionHeadline
                    data-testid="request-title"
                    className={styles.bodyCellTitle}
                >
                    {task.task}
                </SectionHeadline>
            </TableCell>
            <TableCell
                data-testid="request-date-time"
                className={cx(styles.bodyCell, styles.bodyCellDate)}
            >
                {date}
            </TableCell>
            <TableCell
                data-testid="request-status"
                className={cx(styles.bodyCell, styles.bodyCellStatus)}
            >
                <Typography className={statusClass(task.status)}>
                    {statusTranslation[task.status]}
                </Typography>
            </TableCell>
            <TableCell
                className={cx(
                    styles.bodyCell,
                    styles.bodyCellLast,
                    styles.bodyCellAction,
                )}
            >
                <Box
                    data-testid="request-edit-delete"
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconRounded
                        className={styles.icon}
                        icon={EditIcon}
                        onClick={onEdit}
                    />
                    <IconRounded icon={DeleteIcon} ml={3} onClick={onDelete} />
                </Box>
            </TableCell>
        </TableRow>
    );
};
