import { put, take } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { getType } from 'typesafe-actions';

import { invitationCheckApiActions } from '../../../../api/invitation-check/invitation-check.actions';
import { AuthPath } from '../../../../routing/auth-path';
import { invitationTokenValidationActions } from '../invitation.actions';
import { memorialPageSlugReceived } from '../../../../meta/model/meta.actions';
import { resolveRouteWithSlug } from '../../../../routing/resolve-route-with-slug';
import { languageRetrieved } from '../../../../model/configuration/configuration.actions';

export function* invitationTokenValidationSaga(
    action: ReturnType<typeof invitationTokenValidationActions.request>,
) {
    yield put(
        invitationCheckApiActions.request({
            token: action.payload.token,
        }),
    );
    const resp:
        | ReturnType<typeof invitationCheckApiActions.success>
        | ReturnType<typeof invitationCheckApiActions.failure> = yield take([
        invitationCheckApiActions.success,
        invitationCheckApiActions.failure,
    ]);

    switch (resp.type) {
        case getType(invitationCheckApiActions.success): {
            yield put(invitationTokenValidationActions.success(resp.payload));

            const userExist = resp.payload.isUserPersisted;
            const slug = resp.payload.memorialPageSlug;

            yield put(memorialPageSlugReceived({ slug }));

            const resolveRoute = resolveRouteWithSlug(slug);

            const loginRoute = resolveRoute(AuthPath.LOGIN);
            const passwordRoute = resolveRoute(AuthPath.SET_PASSWORD);

            if (userExist) {
                yield put(push(loginRoute));
            } else {
                yield put(push(passwordRoute));
            }

            const langCode = resp.payload.locale || 'en';

            yield put(
                languageRetrieved({
                    language: {
                        code: langCode,
                    },
                }),
            );
            break;
        }
        case getType(invitationCheckApiActions.failure): {
            yield put(invitationCheckApiActions.failure(resp.payload));
            yield put(push(AuthPath.LINK_EXPIRED));
            break;
        }
        default: {
            yield;
        }
    }
}
