import React from 'react';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../editor/components/page-title/page-title';
import { useDeceasedPersonData } from '../model/deceased-person/use-deceased-person-data';

import { ConnectedDeceasedForm } from './deceased-form';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(5, 10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
}));

export const DeceasedScreen = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    const {
        deceasedPersonData,
        updateDeceasedStatus,
        updateDeceasedStatusMessage,
    } = useDeceasedPersonData();

    return (
        <Box data-testid="deceased-info-page-container">
            <PageTitle>{t('deceased.title')}</PageTitle>
            <Paper
                data-testid="deceased-info-page-form"
                elevation={1}
                className={styles.root}
            >
                <ConnectedDeceasedForm
                    deceasedData={deceasedPersonData}
                    updateDeceasedStatus={updateDeceasedStatus}
                    updateDeceasedStatusMessage={updateDeceasedStatusMessage}
                />
            </Paper>
        </Box>
    );
};
