import React, { MouseEvent, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Typography,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../../../components/primary-button/primary-button';
import { ButtonLink } from '../../../components/button-link/button-link';
import { TermsAndConditionsModal } from '../terms-and-conditions-modal/terms-and-conditions-modal';
import { IWizardAboutDeceasedFormValues } from '../model/wizard-about-deceased-form-values';
import { wizardAboutDeceasedFormValidation } from '../wizard-about-deceased-form-validation';
import { FormTextField } from '../../../components/form-text-field/form-text-field';
import { FormDoubleInputsContainer } from '../../../components/form-double-inputs-container/form-double-inputs-container';
import { CenterBox } from '../../../components/center-box/center-box';
import { FieldErrorMessage } from '../../../components/field-error-message/field-error-message';
import { createShouldShowError } from '../../../utils/should-show-error';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { useDateFormatter } from '../../../utils/use-date-formatter';
import { backYearsLimitForBirthDate } from '../../../utils/back-years-limit-for-birth-date';
import { IDeceasedPersonData } from '../../../interfaces/deceased-person-data';

type OuterProps = {
    onSubmit(values: IWizardAboutDeceasedFormValues): unknown;
    deceasedData: IDeceasedPersonData;
    onSkipRequested(): unknown;
    termsAndConditionAccepted: boolean;
};

type Props = FormikProps<IWizardAboutDeceasedFormValues> & OuterProps;

export const AboutDeceasedForm = ({
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    onSkipRequested,
    setStatus,
    status,
}: Props) => {
    /**
     * TODO:
     * Consider putting it away to either HOC with state or to parent,
     * but then exposing setFieldValue action is required
     */
    const { t } = useTranslation();
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const closeTermsModal = () => setTermsModalOpen(false);
    const { formatSelectedPickerDate } = useDateFormatter();

    const mobile = useIsMobile();

    const shouldShowError = createShouldShowError(errors, touched);
    const { getYearsBeforeDate } = useDateFormatter();

    const onTermsButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTermsModalOpen(true);
    };

    const onSkipCliked = (e: MouseEvent<HTMLButtonElement>) => {
        if (values.termsAccepted) {
            setStatus({
                termsAccepted: null,
            });
            onSkipRequested();
        } else {
            setStatus({
                termsAccepted: 'errors.required',
            });
        }
    };

    const handleChangeTerms = (e: React.ChangeEvent<any>) => {
        setStatus({
            termsAccepted: null,
        });
        handleChange(e);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box mb={2}>
                <FormControl
                    data-testid="wizard-deceased-step-name"
                    fullWidth
                    error={shouldShowError('name')}
                >
                    <FormTextField
                        disabled
                        error={shouldShowError('name')}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        label={t('wizardDeceased.firstAndLastNameLabel')}
                    />
                    <FieldErrorMessage
                        data-testid="wizard-deceased-step-name-error"
                        name="name"
                    />
                </FormControl>
            </Box>
            <Box mb={0.5}>
                <FormDoubleInputsContainer>
                    <DatePicker
                        disabled
                        data-testid="wizard-deceased-step-birth"
                        error={shouldShowError('birthDate')}
                        clearable
                        inputVariant="outlined"
                        fullWidth
                        maxDate={values.deathDate || new Date()}
                        minDate={getYearsBeforeDate(
                            new Date(),
                            backYearsLimitForBirthDate,
                        )}
                        name="birthDate"
                        onChange={(date) => {
                            if (date) {
                                setFieldValue('birthDate', date);
                                return;
                            }

                            setFieldValue('birthDate', null);
                        }}
                        onBlur={handleBlur}
                        value={values.birthDate}
                        labelFunc={formatSelectedPickerDate}
                        label={t('wizardDeceased.dateOfBirthLabel')}
                    />
                    <DatePicker
                        disabled
                        data-testid="wizard-deceased-step-death"
                        error={shouldShowError('deathDate')}
                        inputVariant="outlined"
                        fullWidth
                        clearable
                        disableFuture
                        minDate={values.birthDate || undefined}
                        name="deathDate"
                        onChange={(date) => {
                            if (date) {
                                setFieldValue('deathDate', date);
                                return;
                            }

                            setFieldValue('deathDate', null);
                        }}
                        onBlur={handleBlur}
                        value={values.deathDate}
                        label={t('wizardDeceased.dateOfDeathLabel')}
                        labelFunc={formatSelectedPickerDate}
                    />
                </FormDoubleInputsContainer>
            </Box>
            <Box mb={4}>
                <FormDoubleInputsContainer>
                    <FormControl
                        data-testid="wizard-deceased-step-birth-place"
                        error={shouldShowError('birthPlace')}
                        fullWidth
                    >
                        <FormTextField
                            disabled
                            error={shouldShowError('birthPlace')}
                            name="birthPlace"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birthPlace}
                            label={t('wizardDeceased.placeOfBirthLabel')}
                        />
                        <FieldErrorMessage name="birthPlace" />
                    </FormControl>
                    <FormControl
                        data-testid="wizard-deceased-step-death-place"
                        error={shouldShowError('deathPlace')}
                        fullWidth
                    >
                        <FormTextField
                            disabled
                            error={shouldShowError('deathPlace')}
                            name="deathPlace"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.deathPlace}
                            label={t('wizardDeceased.placeOfDeathLabel')}
                        />
                        <FieldErrorMessage name="deathPlace" />
                    </FormControl>
                </FormDoubleInputsContainer>
            </Box>
            <Grid container justify="center" spacing={2}>
                <Grid
                    item
                    style={{
                        order: mobile ? 1 : undefined,
                    }}
                    xs={mobile ? 12 : 'auto'}
                >
                    <Button
                        size="large"
                        color="primary"
                        onClick={onSkipCliked}
                        fullWidth={mobile}
                        data-testid="wizard-skip-button"
                    >
                        {t('common.skipWizard')}
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={mobile ? 12 : 'auto'}
                    style={{
                        order: mobile ? 0 : undefined,
                    }}
                >
                    <PrimaryButton
                        data-testid="wizard-deceased-step-submit"
                        type="submit"
                        fullWidth={mobile}
                    >
                        {t('common.continue')}
                    </PrimaryButton>
                </Grid>
            </Grid>
            <CenterBox mt={4}>
                <FormControl
                    data-testid="wizard-deceased-step-terms"
                    error={shouldShowError('termsAccepted')}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.termsAccepted}
                                onChange={handleChangeTerms}
                                name="termsAccepted"
                                color="primary"
                            />
                        }
                        label={
                            <Typography>
                                {t('decased.byClickingContinue')}{' '}
                                <ButtonLink
                                    data-testid="wizard-deceased-step-terms-link"
                                    onClick={onTermsButtonClicked}
                                >
                                    {t('deceased.termsAndContitionsLink')}
                                </ButtonLink>
                            </Typography>
                        }
                    />
                    <FieldErrorMessage
                        data-testid="wizard-deceased-step-terms-error"
                        name="termsAccepted"
                    >
                        {t(`${errors.termsAccepted}`)}
                    </FieldErrorMessage>
                    {status &&
                        status.termsAccepted &&
                        !errors.termsAccepted && (
                            <FormHelperText error>
                                {t(`${status.termsAccepted}`)}
                            </FormHelperText>
                        )}
                </FormControl>
            </CenterBox>
            <TermsAndConditionsModal
                open={termsModalOpen}
                onClose={closeTermsModal}
                onAccepted={() => {
                    setFieldValue('termsAccepted', true);
                    closeTermsModal();
                }}
            />
        </form>
    );
};

export const ConnectedAboutDeceasedForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IWizardAboutDeceasedFormValues>({
        handleSubmit(values, bag) {
            bag.props.onSubmit(values);
        },
        mapPropsToValues({ deceasedData, termsAndConditionAccepted }) {
            return {
                name: deceasedData.name || '',
                birthPlace: deceasedData.birthPlace || '',
                deathPlace: deceasedData.deathPlace || '',
                birthDate: deceasedData.birthDate || null,
                deathDate: deceasedData.deathDate || null,
                termsAccepted: termsAndConditionAccepted,
            };
        },
        validationSchema: () => wizardAboutDeceasedFormValidation(),
        enableReinitialize: true,
    }),
)(AboutDeceasedForm);
