/**
 * Base color names generated with
 * http://chir.ag/projects/name-that-color/
 *
 * later they can be aliased to primary, secondary etc.
 */
export enum Colors {
    WHISPER = '#F0F0F7',
    TOREA_BAY = '#0B3799',
    DODGER_BLUE = '#3482FF',
    ATHENS_GRAY = '#FAFAFB',
    DOVE_GRAY = '#646464',
    IRON = '#DADCE0',
    MINE_SHAFT = '#373737',
    APPLE = '#43A047',
    GOLD_DROP = '#F57C00',
    VALENCIA = '#D74A3A',
    SILVER = '#CCCCCC',
    TONYS_PINK = '#E4A47D',
    MYSTIC = '#E6ECF0',
    RHINO = '#314165',
    VG_DARK = '#54565a',
    VG_LIGHT = '#9ba569',
    VG_BACKGROUND = '#eff0ed',
    WHITE = '#ffffff',
    BLACK = '#000000',
}
