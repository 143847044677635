import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';

import { resolveContactsSelector } from './resolve-contacts-selector';

export const makeGetContacts = (filter: NotificationContactStatus | null) => {
    return createSelector(
        resolveContactsSelector(filter),
        (contacts) => contacts,
    );
};
