import { apiService } from '../../api.service';
import { IMemorialEvent } from '../../../interfaces/memorial-event';
import { mapEventResponseToEvents } from '../map-event-response-to-events';
import { IEventResponseDto } from '../dto/event-response.dto';

import { buildSchedulableEventsUrl } from './helpers';

export const fetchEvents = (slug: string): Promise<IMemorialEvent[]> => {
    const url = buildSchedulableEventsUrl(slug);

    return apiService
        .get(url)
        .then((result) => result.json())
        .then(
            (res: IEventResponseDto) =>
                (mapEventResponseToEvents(res) as unknown) as IMemorialEvent[],
        );
};
