import { pl, nb, enGB, de, fr, sv } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

import { SupportedLanguageCode } from '../app/interfaces/supported-language-code';

class PlLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: Date) {
        return format(date, 'LLLL yyyy', { locale: this.locale });
    }

    getMonthText(date: Date) {
        return format(date, 'LLLL', { locale: this.locale });
    }
}

const languageUtils = {
    en: DateFnsUtils,
    nb: DateFnsUtils,
    pl: PlLocalizedUtils,
    de: DateFnsUtils,
    fr: DateFnsUtils,
    sv: DateFnsUtils,
};

const languagesDict = {
    en: enGB,
    pl,
    nb,
    de,
    fr,
    sv,
};

export const getDateLocale = (languageCode: SupportedLanguageCode) =>
    languagesDict[languageCode] || languagesDict.en;

export const getDateFnsUtils = (languageCode: SupportedLanguageCode) =>
    languageUtils[languageCode] || languageUtils.en;
