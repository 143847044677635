import React, { useEffect } from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router';
import {
    Box,
    Container,
    FormControl,
    makeStyles,
    Paper,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { getQueryParam } from '../../utils/get-query-param';
import { ErrorsPath } from '../../routing/errors-path';
import { BlueHeadline } from '../../components/blue-headline/blue-headline';
import { FormTextField } from '../../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../../components/field-error-message/field-error-message';
import { createShouldShowError } from '../../utils/should-show-error';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { CenterBox } from '../../components/center-box/center-box';
import { getPasswordValidation } from '../../utils/password-validation';
import { AuthPath } from '../../routing/auth-path';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { resolveRouteWithSlug } from '../../routing/resolve-route-with-slug';

import { getResetPasswordStatus } from './model/selectors/get-reset-password-status.selector';
import { ResetPasswordStatus } from './model/reset-password.store';
import { setNewResetPasswordRequested } from './model/reset-password-page.actions';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    content: {
        padding: theme.spacing(4),
        maxWidth: '60rem',
        marginTop: theme.spacing(10),
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
}));

interface IFormValues {
    password: string;
}

const validationSchema = yup.object<IFormValues>({
    password: getPasswordValidation().required(),
});

const initialValues = {
    password: '',
};

export const ResetPasswordPage = ({ history, match }: Props) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const resetPasswordStatus = useSelector(getResetPasswordStatus);
    const slug = useSelector(getPageSlug) || get(match, 'params.slug');

    const token = getQueryParam(window.location.href, 'token');

    useEffect(() => {
        if (
            resetPasswordStatus === ResetPasswordStatus.PASSWORD_SET_SUCCEEDED
        ) {
            history.push(resolveRouteWithSlug(slug)(AuthPath.LOGIN));
        }
    }, [resetPasswordStatus, slug, history]);

    /**
     * Check if token exists. Redirect to some error
     */
    if (!token) {
        history.push(ErrorsPath.NOT_FOUND);
        return null;
    }

    const onSubmit = (values: IFormValues) => {
        const { password } = values;

        dispatch(setNewResetPasswordRequested({ password, token }));
    };

    return (
        <Container className={styles.container}>
            <Paper className={styles.content}>
                <BlueHeadline align="center">
                    {t('resetPasswordForm.headline')}
                </BlueHeadline>
                <Box mt={4}>
                    <Formik<IFormValues>
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                        }) => {
                            const shouldShowError = createShouldShowError(
                                errors,
                                touched,
                            );

                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormControl
                                        fullWidth
                                        error={shouldShowError('password')}
                                    >
                                        <FormTextField
                                            error={shouldShowError('password')}
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            label={t(
                                                'resetPasswordForm.passwordLabel',
                                            )}
                                            type="password"
                                        />
                                        <FieldErrorMessage name="password" />
                                    </FormControl>
                                    <CenterBox mt={4}>
                                        <PrimaryButton type="submit">
                                            {t('resetPasswordForm.submit')}
                                        </PrimaryButton>
                                    </CenterBox>
                                </form>
                            );
                        }}
                    </Formik>
                </Box>
            </Paper>
        </Container>
    );
};
