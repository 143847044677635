import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import {
    createMilestoneAsyncActions,
    createMilestoneFormSubmitted,
} from '../milestones.actions';
import { mapMilestoneFormToDto } from '../map-milestone-form-to-dto';
import { createMilestoneApiActions } from '../../../api/milestones/milestones-api.actions';

type PossibleAPIResultActions =
    | ReturnType<typeof createMilestoneApiActions.success>
    | ReturnType<typeof createMilestoneApiActions.failure>;

export function* createMilestoneSaga(
    action: ReturnType<typeof createMilestoneFormSubmitted>,
) {
    const { payload } = action;
    const dto = mapMilestoneFormToDto(payload.milestone);

    yield put(createMilestoneApiActions.request(dto));

    const result: PossibleAPIResultActions = yield take([
        createMilestoneApiActions.success,
        createMilestoneApiActions.failure,
    ]);

    switch (result.type) {
        case getType(createMilestoneApiActions.success):
            yield put(createMilestoneAsyncActions.success(result.payload));
            return;
        case getType(createMilestoneApiActions.failure):
            yield put(createMilestoneAsyncActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
