import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { fetchGalleryItemsActions } from '../gallery.actions';
import { fetchGalleryItemsApiActions } from '../../../api/gallery/gallery-api.actions';

type PossibleApiResults =
    | ReturnType<typeof fetchGalleryItemsApiActions.success>
    | ReturnType<typeof fetchGalleryItemsApiActions.failure>;

export function* fetchGalleryItemsSaga(
    action: ReturnType<typeof fetchGalleryItemsActions.request>,
) {
    yield put(fetchGalleryItemsApiActions.request());

    const result: PossibleApiResults = yield take([
        fetchGalleryItemsApiActions.success,
        fetchGalleryItemsApiActions.failure,
    ]);

    switch (result.type) {
        case getType(fetchGalleryItemsApiActions.success):
            yield put(fetchGalleryItemsActions.success(result.payload));
            break;
        case getType(fetchGalleryItemsApiActions.failure):
            yield put(fetchGalleryItemsActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
