import { createReducer } from 'typesafe-actions';

import { IInvitation } from '../../interfaces/invitation';

import { invitationsReceived } from './invitations.actions';

export interface IInvitationsStore {
    invitations: IInvitation[];
}

const initialState: IInvitationsStore = {
    invitations: [],
};

type Actions = ReturnType<typeof invitationsReceived>;

export const invitationsReducer = createReducer<IInvitationsStore, Actions>(
    initialState,
).handleAction(invitationsReceived, (state, action) => ({
    ...state,
    invitations: action.payload,
}));
