import React from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { SecondaryTextButton } from '../../components/secondary-text-button/secondary-text-button';
import { FormTextField } from '../../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../../components/field-error-message/field-error-message';
import { FormDoubleInputsContainer } from '../../components/form-double-inputs-container/form-double-inputs-container';
import { RichTextEditor } from '../../components/rich-text-editor/rich-text-editor';
import { createShouldShowError } from '../../utils/should-show-error';
import { EventType } from '../../interfaces/event-type';
import { IEventFormData } from '../event-form-data';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { MapInput } from '../../components/map-input/map-input';
import { COUNTRY_CODES } from '../../utils/get-country-codes';

import { EventFormValidationSchema } from './event-form-validation-schema';

type OuterProps = {
    edit: boolean;
    initialValues?: (IEventFormData & { id?: string }) | null;
    onClose(): unknown;
    onCreate?: (values: IEventFormData) => unknown;
    onUpdate?: (values: IEventFormData, id: string) => unknown;
    isCeremonyPrivate: boolean;
};

type Props = FormikProps<IEventFormData> & OuterProps;

const useStyles = makeStyles((theme) => ({
    subheader: {
        textAlign: 'center',
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    radioGroupLabel: {
        color: theme.palette.common.black,
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    cancel: {
        marginRight: theme.spacing(2),
    },
}));

const eventTypeOptions = [EventType.RECEPTION, EventType.CUSTOM];

export const EventForm = ({
    edit,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    onClose,
    isCeremonyPrivate,
}: Props) => {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const { formatSelectedPickerDate } = useDateFormatter();
    const shouldShowError = createShouldShowError(errors, touched);

    const eventTypesLabels = {
        [EventType.CEREMONY]: t('ceremonyAndReception.typeCeremony'),
        [EventType.RECEPTION]: t('ceremonyAndReception.typeReception'),
        [EventType.CUSTOM]: t('ceremonyAndReception.typeCustom'),
    };

    const isEventReadonly = values.isReadOnly;

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (isEventReadonly) {
            e.preventDefault();
            return;
        }

        handleSubmit(e);
    };

    return (
        <form data-testid="event-form-container" onSubmit={handleFormSubmit}>
            {isEventReadonly && (
                <Typography className={styles.subheader}>
                    {t('ceremonyAndReception.createdInPartner')}
                </Typography>
            )}
            {!edit && (
                <FormControl
                    data-testid="event-types"
                    component="fieldset"
                    fullWidth
                >
                    <FormLabel className={styles.radioGroupLabel}>
                        {t('ceremonyAndReception.selectCeremonyType')}
                    </FormLabel>
                    <RadioGroup
                        className={styles.radioGroup}
                        name="type"
                        onChange={handleChange}
                        value={values.type}
                    >
                        {eventTypeOptions.map((option) => (
                            <FormControlLabel
                                data-testid={`event-type-${option.toLowerCase()}`}
                                control={<Radio color="primary" />}
                                key={option}
                                label={eventTypesLabels[option]}
                                value={option}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
            <Box mt={4}>
                <FormControl
                    data-testid="event-title"
                    error={shouldShowError('title')}
                    fullWidth
                >
                    <FormTextField
                        disabled={isEventReadonly}
                        error={shouldShowError('title')}
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        label={t('ceremonyAndReception.titleLabel')}
                    />
                    <FieldErrorMessage name="title" />
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormDoubleInputsContainer spacing={2}>
                    <FormControl
                        data-testid="event-date"
                        error={shouldShowError('date')}
                        fullWidth
                    >
                        <DatePicker
                            disabled={isEventReadonly}
                            clearable
                            error={shouldShowError('date')}
                            inputVariant="outlined"
                            fullWidth
                            name="date"
                            onClose={() => {
                                handleBlur({ target: { name: 'date' } });
                            }}
                            onChange={(date) => {
                                if (date) {
                                    setFieldValue('date', date);
                                    return;
                                }

                                setFieldValue('date', null);
                            }}
                            value={values.date}
                            label={t('ceremonyAndReception.dateLabel')}
                            labelFunc={formatSelectedPickerDate}
                            cancelLabel={t('common.cancel')}
                            clearLabel={t('common.delete')}
                        />
                        <FieldErrorMessage name="date" />
                    </FormControl>
                    <FormControl
                        data-testid="event-time"
                        error={shouldShowError('time')}
                        fullWidth
                    >
                        <TimePicker
                            disabled={isEventReadonly}
                            ampm={i18n.language === COUNTRY_CODES.EN}
                            clearable
                            error={shouldShowError('time')}
                            inputVariant="outlined"
                            fullWidth
                            name="time"
                            onClose={() => {
                                handleBlur({ target: { name: 'time' } });
                            }}
                            onChange={(time) => {
                                if (time) {
                                    setFieldValue('time', time);
                                    return;
                                }

                                setFieldValue('time', null);
                            }}
                            value={values.time}
                            label={t('ceremonyAndReception.timeLabel')}
                            cancelLabel={t('common.cancel')}
                            clearLabel={t('common.delete')}
                        />
                        <FieldErrorMessage name="time" />
                    </FormControl>
                </FormDoubleInputsContainer>
            </Box>
            <Box data-testid="event-address-map" mt={2}>
                <MapInput
                    disabled={isEventReadonly}
                    onChange={(event) => {
                        setFieldValue('location', event.target.value);
                    }}
                    value={values.location}
                />
            </Box>
            {isCeremonyPrivate && (
                <Box data-testid="event-public-message" mt={2}>
                    <FormTextField
                        disabled={isEventReadonly}
                        error={shouldShowError(
                            'publicMessageForPrivateCeremony',
                        )}
                        name="publicMessageForPrivateCeremony"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.publicMessageForPrivateCeremony}
                        label={t(
                            'ceremonyAndReception.publicMessageForPrivateCeremonyLabel',
                        )}
                    />
                </Box>
            )}
            <Box data-testid="event-editor" mt={2}>
                {isEventReadonly ? (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: values.description }}
                    />
                ) : (
                    <RichTextEditor
                        minHeight="20rem"
                        minWidth="60rem"
                        name="description"
                        onChange={handleChange}
                        placeholder={t(
                            'ceremonyAndReception.descriptionPlaceholder',
                        )}
                        value={values.description}
                    />
                )}
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <SecondaryTextButton
                    data-testid="event-cancel"
                    className={styles.cancel}
                    onClick={onClose}
                >
                    {t('common.cancel')}
                </SecondaryTextButton>
                <SecondaryButton
                    disabled={isEventReadonly}
                    data-testid="event-save"
                    type="submit"
                >
                    {t('common.save')}
                </SecondaryButton>
            </Box>
        </form>
    );
};

export const ConnectedEventForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IEventFormData>({
        enableReinitialize: true,
        handleSubmit(values, { props }) {
            if (
                props.onUpdate &&
                props.initialValues &&
                props.initialValues.id
            ) {
                props.onUpdate(values, props.initialValues.id);
            } else if (props.onCreate) {
                props.onCreate(values);
            }
        },
        validationSchema: EventFormValidationSchema,
        mapPropsToValues({ initialValues }) {
            return (
                initialValues || {
                    id: '0',
                    title: '',
                    description: '',
                    date: null,
                    time: null,
                    type: EventType.RECEPTION,
                    location: null,
                    publicMessageForPrivateCeremony: '',
                    isReadOnly: false,
                }
            );
        },
    }),
)(EventForm);
