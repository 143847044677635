import { call, select, put } from '@redux-saga/core/effects';

import { passwordsSetApiActions } from '../password.actions';
import { ISubmitPasswordResponseDto } from '../dto/submit-password-response.dto';
import { setUserPassword } from '../http/set-account-password';
import { getInvitationToken } from '../../../auth/invitation/model/selectors/get-invitation-token.selector';

export function* setUserPasswordSaga(
    action: ReturnType<typeof passwordsSetApiActions.request>,
) {
    const token = yield select(getInvitationToken);

    try {
        const submitPasswordResponse: ISubmitPasswordResponseDto = yield call(
            setUserPassword,
            action.payload.password,
            token,
        );

        yield put(passwordsSetApiActions.success(submitPasswordResponse));
    } catch (e) {
        yield put(passwordsSetApiActions.failure(e.statusText));
    }
}
