import { getType } from 'typesafe-actions';
import { put, select, take } from 'redux-saga/effects';

import {
    createTaskAsyncActions,
    createTaskFormSubmitted,
} from '../tasks.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { createTaskApiActions } from '../../../api/tasks/api-tasks.actions';
import { mapTaskToCreateDTO } from '../map-task-to-create-dto';

export function* createTaskSaga(
    action: ReturnType<typeof createTaskFormSubmitted>,
) {
    const { payload } = action;
    const slug = yield select(getPageSlug);
    const dto = mapTaskToCreateDTO(payload.task, slug);

    yield put(createTaskApiActions.request(dto));

    const resultAction:
        | ReturnType<typeof createTaskApiActions.success>
        | ReturnType<typeof createTaskApiActions.failure> = yield take([
        createTaskApiActions.success,
        createTaskApiActions.request,
    ]);

    switch (resultAction.type) {
        case getType(createTaskApiActions.success):
            yield put(
                createTaskAsyncActions.success({
                    task: resultAction.payload,
                }),
            );
            break;
        case getType(createTaskApiActions.failure):
            yield put(createTaskAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
