import { apiService } from '../../api.service';
import { IGalleryResponseDto } from '../dto/gallery-response.dto';

export const fetchGalleryItems = (
    slug: string,
): Promise<IGalleryResponseDto[]> =>
    apiService
        .get(`/memorial_pages/${slug}/gallery_photos`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
