import { FormikErrors } from 'formik';

import {
    ObituaryFormValues,
    ObituaryUnforgettableQuoteTheme,
    ObituaryWritingStyle,
} from '../../model/obituary.types';

import { ObituaryFormOuterProps } from './obituary-form';

export const mapObituaryFormPropsToObituaryFormValues = ({
    obituaryText,
    generatedObituary,
    deceasedName,
}: ObituaryFormOuterProps): ObituaryFormValues => ({
    obituaryText: generatedObituary || obituaryText,
    deceasedPreferredName: deceasedName,
    obituaryWritingStyle: ObituaryWritingStyle.TRADITIONAl,
    obituaryUnforgettableQuote: {
        include: false,
        theme: ObituaryUnforgettableQuoteTheme.RELIGION,
    },
    obituaryQuestionsAndAnswers: [],
});

export const validateObituaryFormValues = (
    values: ObituaryFormValues,
): FormikErrors<ObituaryFormValues> => {
    const errors: FormikErrors<ObituaryFormValues> = {};

    const isNoDeceasedPreferredNameAdded = !values.deceasedPreferredName;
    if (isNoDeceasedPreferredNameAdded) {
        errors.deceasedPreferredName = 'error.requiredField';
    }

    const isNoQuestionsAnswered =
        values.obituaryQuestionsAndAnswers.length === 0 ||
        !values.obituaryQuestionsAndAnswers.some(
            (obituaryQuestionAndAnswer) =>
                !!obituaryQuestionAndAnswer.answer ||
                !!obituaryQuestionAndAnswer.info,
        );
    if (isNoQuestionsAnswered) {
        errors.obituaryText = 'error.requiredField';
    }

    const isNoUnforgettableQuoteThemeSelected =
        values.obituaryUnforgettableQuote.include &&
        !values.obituaryUnforgettableQuote.theme;
    if (isNoUnforgettableQuoteThemeSelected) {
        errors.obituaryUnforgettableQuote = {
            ...(errors.obituaryUnforgettableQuote || {}),
            theme: 'error.requiredField',
        };
    }

    const isNoCustomUnforgettableThemeAdded =
        values.obituaryUnforgettableQuote.include &&
        values.obituaryUnforgettableQuote.theme ===
            ObituaryUnforgettableQuoteTheme.CUSTOM &&
        !values.obituaryUnforgettableQuote.info;
    if (isNoCustomUnforgettableThemeAdded) {
        errors.obituaryUnforgettableQuote = {
            ...(errors.obituaryUnforgettableQuote || {}),
            info: 'error.requiredField',
        };
    }

    return errors;
};
