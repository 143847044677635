import { getType } from 'typesafe-actions';
import { debounce, takeEvery } from 'redux-saga/effects';

import {
    wizardNotificationContactAddActions,
    wizardNotificationsFormSkipRequested,
    wizardNotificationsFormSubmitted,
    wizardNotificationsMessageFieldChanged,
} from '../wizard-notifications.actions';

import { wizardUpdateNotificationMessageSaga } from './wizard-update-notification-message.saga';
import { wizardAddContactSaga } from './wizard-add-contact.saga';
import { wizardNotificationsSkipSaga } from './wizard-notifications-skip.saga';
import { wizardNotificationFormSubmitSaga } from './wizard-notifications-send-notify.saga';

export function* wizardNotificationsSaga() {
    /**
     * Run action after 500ms from latest invocation.
     */
    yield debounce(
        500,
        getType(wizardNotificationsMessageFieldChanged),
        wizardUpdateNotificationMessageSaga,
    );

    yield takeEvery(
        getType(wizardNotificationContactAddActions.request),
        wizardAddContactSaga,
    );

    yield takeEvery(
        getType(wizardNotificationsFormSkipRequested),
        wizardNotificationsSkipSaga,
    );

    yield takeEvery(
        getType(wizardNotificationsFormSubmitted),
        wizardNotificationFormSubmitSaga,
    );
}
