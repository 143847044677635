import { call, put } from 'redux-saga/effects';

import { fetchDictionaryApiActions } from '../dictionaries-api.actions';
import { fetchDictionary } from '../http/fetch-dictionary';
import { notificationMessageExamplesReceived } from '../../../model/notification-message/notification-messages-model.actions';
import { IDictionaryEntryDto } from '../dto/dictionary-entry.dto';
import { mapDictionaryEntryDtoToDictionaryEntry } from '../mappers/map-dictionary-entry-dto-to-dictionary-entry';

export function* fetchDictionarySaga(
    action: ReturnType<typeof fetchDictionaryApiActions.request>,
) {
    const { type } = action.payload;

    try {
        const response: IDictionaryEntryDto[] = yield call(fetchDictionary, {
            type,
        });

        const examples = response.map((entry) =>
            mapDictionaryEntryDtoToDictionaryEntry(entry),
        );

        yield put(fetchDictionaryApiActions.success(examples));

        if (examples) {
            yield put(notificationMessageExamplesReceived({ examples }));
        }
    } catch (err) {
        yield put(fetchDictionaryApiActions.failure(err));
    }
}
