import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { Logo } from '../../editor/components/logo/logo';
import { ComposedLanguageSelector } from '../language-selector/language-selector';
import { getTheme } from '../../model/theme/selectors/get-theme.selector';
import { getThemeBeforeLogIn } from '../../utils/get-theme-before-log-in';

const useStyles = makeStyles<Theme>((theme) => ({
    logoLanguageContainer: {
        width: '100%',
        position: 'relative',

        '&$vg': {
            [theme.breakpoints.down('xs')]: {
                paddingRight: 76,
            },
        },
    },
    languageContainer: {
        position: 'absolute',
        top: -10,
        right: 0,
    },
    vg: {},
}));

export const LogoLangugageContainer = () => {
    const styles = useStyles();
    const receivedTheme = useSelector(getTheme);
    const th = getThemeBeforeLogIn(receivedTheme);

    return (
        <div className={cx(styles.logoLanguageContainer, styles[th])}>
            <Logo />
            <div className={styles.languageContainer}>
                <ComposedLanguageSelector />
            </div>
        </div>
    );
};
