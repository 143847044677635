import { setLocale } from 'yup';
import { TFunction } from 'i18next';

export const setupValidationMessages = (t: TFunction) => {
    setLocale({
        mixed: {
            required: t('errors.required'),
        },
        boolean: {
            required: t('errors.checked'),
        },
        string: {
            email: t('errors.email'),
        },
    });
};
