import { call, put, select } from 'redux-saga/effects';

import { sendNotificationsToContactsApiActions } from '../contacts-api.actions';
import { sendInvitations } from '../http/send-invitations';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { setContactsStatuses } from '../../../model/notification-contacts/notification-contacts.actions';
import { ISendInvitationDto } from '../dto/send-invitation.dto';
import { toggleAllContactsNotSelected } from '../../../editor/notifications/contacts/model/contacts.actions';

export function* sendNotificationsSaga(
    action: ReturnType<typeof sendNotificationsToContactsApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { ids } = action.payload;

    try {
        const response: ISendInvitationDto[] = yield call(
            sendInvitations,
            slug,
            ids,
        );

        yield put(
            setContactsStatuses(
                response.map((invitation: ISendInvitationDto) => ({
                    id: invitation.contactId,
                    status: invitation.status!,
                })),
            ),
        );

        yield put(toggleAllContactsNotSelected());

        yield put(sendNotificationsToContactsApiActions.success());
    } catch (e) {
        yield put(sendNotificationsToContactsApiActions.failure(e));
    }
}
