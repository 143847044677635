import React from 'react';
import {
    Box,
    Container,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { ShortDivider } from '../../components/short-divider/short-divider';
import { Colors } from '../../../styles/colors';
import { ButtonLink } from '../../components/button-link/button-link';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { logoutRequested } from '../logout/model/logout.actions';
import { MemorialPageCreationStep } from '../../interfaces/memorial-page-creation-step';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { hexToRgba } from '../../utils/hex-to-rgba';
import { Logo } from '../../editor/components/logo/logo';
import { ComposedLanguageSelector } from '../../components/language-selector/language-selector';
import { ReactComponent as CandleIcon } from '../../assets/icons/candle.svg';
import { getThemeBeforeLogIn } from '../../utils/get-theme-before-log-in';
import { getTheme } from '../../model/theme/selectors/get-theme.selector';

import { useContextState } from './use-context-state';
import { setContext } from './model/saga/context.actions';

const IMG_SIZE = 142;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    divider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    line: {
        position: 'relative',
        left: `${IMG_SIZE / 2}px`,
        height: '1px',
        [theme.breakpoints.down('sm')]: {
            left: `${IMG_SIZE / 4}px`,
            width: '90%',
        },
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${hexToRgba(
            Colors.TONYS_PINK,
            0.79,
        )}, rgba(0, 0, 0, 0))`,
        width: '95%',
    },
    topDivider: {
        top: '9px',
    },
    bottomDivider: {
        top: '-9px',
    },
    logout: {
        color: Colors.DOVE_GRAY,
        fontSize: '14px',
        alignSelf: 'center',
    },
    description: {
        marginBottom: '80px',
    },
    accountCircle: {
        borderRadius: `${IMG_SIZE / 2}px`,
        alignSelf: 'start',
        backgroundColor: Colors.BLACK,
        marginLeft: '30px',
        height: `${IMG_SIZE}px`,
        width: `${IMG_SIZE}px`,
        minWidth: `${IMG_SIZE}px`,
        minHeight: `${IMG_SIZE}px`,
        transform: 'scale(1.33)',
        zIndex: 1,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            height: `${IMG_SIZE / 2}px`,
            width: `${IMG_SIZE / 2}px`,
            minWidth: `${IMG_SIZE / 2}px`,
            minHeight: `${IMG_SIZE / 2}px`,
            borderRadius: `${IMG_SIZE / 4}px`,
            marginLeft: '0px',
        },
    },
    candleCircle: {
        backgroundColor: Colors.MYSTIC,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                transform: 'scale(0.7)',
            },
        },
    },
    birthDeathLabel: {
        fontSize: '12px',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: '14px',
        letterSpacing: '0.6px',
        lineHeight: '15px',
        color: Colors.TONYS_PINK,
    },
    date: {
        color: Colors.RHINO,
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    name: {
        color: Colors.RHINO,
        fontWeight: 'bold',
        fontSize: '26px',
        textAlign: 'center',
        flex: 1,
        lineHeight: '33px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        backgroundColor: Colors.MYSTIC,
        direction: 'rtl',
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        marginLeft: '44px',
        boxShadow: `0 2px 4px -1px ${Colors.RHINO}`,
        padding: theme.spacing(1, 6),
        [theme.breakpoints.down('sm')]: {
            lineHeight: '100%',
            fontSize: '2rem',
            padding: theme.spacing(1, 1),
            marginLeft: '22px',
        },
    },
    dateContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    contentContainerLeft: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            width: '65%',
        },
    },
    contentContainerRight: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
    },
    itemContainer: {
        background: 'white',
        marginBottom: '83px',
        boxShadow: `0 3px 40px -5px ${Colors.IRON}`,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
        },
    },
    buttonsContainer: {
        position: 'absolute',
        top: -10,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    logoLanguageContainer: {
        width: '100%',
        position: 'relative',

        '&$vg': {
            [theme.breakpoints.down('xs')]: {
                paddingRight: 76,
            },
        },
    },
    vg: {},
}));

export interface IContext {
    id: string;
    name: string;
    birth: string;
    death: string;
    image: string;
    slug: string;
    creationStep: MemorialPageCreationStep | null;
}

export const ContextSelection = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { getShortDate } = useDateFormatter();
    const { contextItems } = useContextState();
    const receivedTheme = useSelector(getTheme);
    const th = getThemeBeforeLogIn(receivedTheme);

    const onLogout = () => {
        dispatch(logoutRequested());
    };

    const ContextItem = ({
        context,
        onClick,
    }: {
        context: IContext;
        onClick: () => void;
    }) => {
        return (
            <>
                <div className={styles.itemContainer}>
                    <div className={cx(styles.line, styles.topDivider)} />
                    <Box
                        data-testid="context-deceased-container"
                        display="flex"
                        flexDirection="row"
                    >
                        <Box className={styles.contentContainerLeft}>
                            {context.image ? (
                                <img
                                    data-testid="context-deceased-picture"
                                    alt=""
                                    className={styles.accountCircle}
                                    src={context.image}
                                />
                            ) : (
                                <Box
                                    data-testid="context-deceased-candle"
                                    className={cx(
                                        styles.accountCircle,
                                        styles.candleCircle,
                                    )}
                                >
                                    <CandleIcon />
                                </Box>
                            )}
                            <Typography
                                data-testid="context-deceased-name"
                                className={styles.name}
                            >
                                {context.name}
                            </Typography>
                        </Box>
                        <Box className={styles.contentContainerRight}>
                            <Box className={styles.dateContainer}>
                                <Typography className={styles.birthDeathLabel}>
                                    — {t('common.birth').toUpperCase()} —
                                </Typography>
                                <Typography
                                    data-testid="context-deceased-birth-date"
                                    className={styles.date}
                                >
                                    {getShortDate(context.birth)}
                                </Typography>
                            </Box>
                            <Box className={styles.dateContainer}>
                                <Typography className={styles.birthDeathLabel}>
                                    — {t('common.death').toUpperCase()} —
                                </Typography>
                                <Typography
                                    data-testid="context-deceased-death-date"
                                    className={styles.date}
                                >
                                    {getShortDate(context.death)}
                                </Typography>
                            </Box>
                            <PrimaryButton
                                data-testid="context-deceased-select"
                                onClick={onClick}
                                type="submit"
                            >
                                {t('common.select')}
                            </PrimaryButton>
                        </Box>
                    </Box>
                    <div className={cx(styles.line, styles.bottomDivider)} />
                </div>
            </>
        );
    };

    return (
        <Container>
            <Box className={cx(styles.logoLanguageContainer, styles[th])}>
                <Logo />
                <div className={styles.buttonsContainer}>
                    <ComposedLanguageSelector />
                    <ButtonLink
                        data-testid="context-selection-logout"
                        className={styles.logout}
                        onClick={onLogout}
                    >
                        {t('navigation.logout')}
                    </ButtonLink>
                </div>
            </Box>
            <ShortDivider className={styles.divider} />
            <Typography align="center" className={styles.description}>
                {t('contextSelection.description')}
            </Typography>
            {contextItems.map((context) => (
                <ContextItem
                    key={context.id}
                    context={context}
                    onClick={() => {
                        dispatch(
                            setContext({
                                slug: context.slug,
                                creationStep: context.creationStep,
                                id: context.id,
                            }),
                        );
                    }}
                />
            ))}
        </Container>
    );
};
