import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../../components/section/section';
import { ServiceSection } from '../service-section/service-section';
import { ReactComponent as FacebookServiceIcon } from '../../../assets/icons/facebook-service-icon.svg';
import { ReactComponent as InstagramServiceIcon } from '../../../assets/icons/instagram-service-icon.svg';
import { ReactComponent as LinkedinServiceIcon } from '../../../assets/icons/linkedin-service-icon.svg';
import { ReactComponent as TwitterServiceIcon } from '../../../assets/icons/twitter-service-icon.svg';
import { ReactComponent as GmailServiceIcon } from '../../../assets/icons/gmail-service-icon.svg';
import { ReactComponent as OnlineBankingIcon } from '../../../assets/icons/online-banking-icon.svg';

const useStyles = makeStyles((theme) => ({
    description: {
        marginTop: theme.spacing(2),
    },
}));

export const DeleteOnlineServicesSection = (props: ISectionProps) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Section title={t('deleteOnlineServices.title')} {...props}>
            <Typography className={styles.description}>
                {t('deleteOnlineServices.description1')}
            </Typography>
            <Typography className={styles.description}>
                {t('deleteOnlineServices.description2')}
            </Typography>
            <Typography className={styles.description}>
                {t('deleteOnlineServices.description3')}
            </Typography>
            <Typography className={styles.description}>
                {t('deleteOnlineServices.description4')}
            </Typography>

            <ServiceSection
                description={t('deleteOnlineServices.facebookDesc')}
                icon={FacebookServiceIcon}
                link="https://www.facebook.com/help/contact/228813257197480"
                title={t('deleteOnlineServices.facebookTitle')}
            />

            <ServiceSection
                description={t('deleteOnlineServices.instagramDesc')}
                icon={InstagramServiceIcon}
                link="https://help.instagram.com/contact/1474899482730688"
                title={t('deleteOnlineServices.instagramTitle')}
            />

            <ServiceSection
                description={t('deleteOnlineServices.linkedinDesc')}
                icon={LinkedinServiceIcon}
                link="https://www.linkedin.com/help/linkedin/ask/ts-rdmlp"
                title={t('deleteOnlineServices.linkedinTitle')}
            />

            <ServiceSection
                description={t('deleteOnlineServices.twitterDesc')}
                icon={TwitterServiceIcon}
                link="https://support.twitter.com/forms/privacy"
                title={t('deleteOnlineServices.twitterTitle')}
            />

            <ServiceSection
                description={t('deleteOnlineServices.gmailDesc')}
                icon={GmailServiceIcon}
                link="https://support.google.com/accounts/troubleshooter/6357590"
                title={t('deleteOnlineServices.gmailTitle')}
            />

            <ServiceSection
                description={t('deleteOnlineServices.onlineBankingDesc')}
                icon={OnlineBankingIcon}
                title={t('deleteOnlineServices.onlineBankingTitle')}
            />
        </Section>
    );
};
