import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { deceasedFormSubmitted } from '../deceased.actions';

import { submitDeceasedFormSaga } from './submit-deceased-form.saga';

export function* deceasedSaga() {
    yield takeEvery(getType(deceasedFormSubmitted), submitDeceasedFormSaga);
}
