import { IDeceasedFormValues } from '../../../deceased/model/deceased-form-values';
import { IWizardAboutDeceasedFormValues } from '../../../wizard/about-deceased-step/model/wizard-about-deceased-form-values';
import { IUpdateDeceasedDto } from '../../../api/deceased/dto/update-deceased-dto';
import { resetDateTimezone } from '../../../utils/reset-date-timezone';

export const mapDeceasedFormToDTO = (
    formData: IDeceasedFormValues | IWizardAboutDeceasedFormValues,
): IUpdateDeceasedDto => {
    const dto: IUpdateDeceasedDto = {
        displayName: formData.name,
    };

    dto.birthDate = formData.birthDate
        ? resetDateTimezone(formData.birthDate).toISOString()
        : null;
    dto.deathDate = formData.deathDate
        ? resetDateTimezone(formData.deathDate).toISOString()
        : null;

    dto.birthLocationName =
        formData.birthPlace.length > 0 ? formData.birthPlace : null;

    dto.deathLocationName =
        formData.deathPlace.length > 0 ? formData.deathPlace : null;

    return dto;
};
