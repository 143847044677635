import { getType } from 'typesafe-actions';
import { debounce } from 'redux-saga/effects';

import { notificationMessageUpdated } from '../message.actions';

import { updateEditorNotificationMessageSaga } from './update-editor-notification-message.saga';

export function* editorNotificationMessageSaga() {
    yield debounce(
        500,
        getType(notificationMessageUpdated),
        updateEditorNotificationMessageSaga,
    );
}
