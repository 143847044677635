import { OverviewDto } from '../dto/overview';
import { IOverview } from '../../../interfaces/overview';

export const mapOverviewDtoToOverview = (dto: OverviewDto): IOverview => {
    const overview: IOverview = {
        ...dto.attributes,
    };

    return overview;
};
