import { apiService } from '../../api.service';
import { OverviewDto } from '../dto/overview';

export const fetchOverview = ({
    slug,
}: {
    slug: string;
}): Promise<OverviewDto> =>
    apiService
        .get(`/memorial_pages/${slug}/overview`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
