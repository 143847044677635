import { ITaskDTO } from '../../api/tasks/dto/created-task-dto';
import { ITaskNotYetCreated } from '../../interfaces/task';
import { resetDateTimezone } from '../../utils/reset-date-timezone';

export const mapTaskToCreateDTO = (
    task: ITaskNotYetCreated,
    slug: string,
): ITaskDTO => {
    return {
        task: {
            title: task.task,
            description: task.description,
            deadlineTimestamp: task.expirationDate
                ? resetDateTimezone(task.expirationDate).toISOString()
                : null,
            status: task.status,
        },
        taskableId: slug,
        taskableType: 'MemorialPage',
    };
};
