import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../../../components/primary-button/primary-button';
import { IWizardVgFormValues } from '../model/wizard-vg-form-values';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { createShouldShowError } from '../../../utils/should-show-error';
import { CenterBox } from '../../../components/center-box/center-box';
import { FieldErrorMessage } from '../../../components/field-error-message/field-error-message';
import { wizardVgFormValidation } from '../wizard-vg-form-validation';

type OuterProps = {
    onSubmit(values: IWizardVgFormValues): unknown;
};

type Props = FormikProps<IWizardVgFormValues> & OuterProps;

export const AboutDeceasedForm = ({
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
}: Props) => {
    const { t } = useTranslation();

    const mobile = useIsMobile();

    const shouldShowError = createShouldShowError(errors, touched);

    return (
        <form onSubmit={handleSubmit}>
            <Box mb={4}>
                <FormControl
                    data-testid="wizard-vg-step-ordered"
                    error={shouldShowError('vgOrdered')}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.vgOrdered}
                                onChange={handleChange}
                                name="vgOrdered"
                                color="primary"
                            />
                        }
                        label={
                            <Typography>
                                {t('wizardVg.vgOrderedLabel')}
                            </Typography>
                        }
                    />
                    <FieldErrorMessage
                        data-testid="wizard-vg-ordered-error"
                        name="vgOrdered"
                    >
                        {t(`${errors.vgOrdered}`)}
                    </FieldErrorMessage>
                </FormControl>
            </Box>
            <CenterBox>
                <PrimaryButton
                    data-testid="wizard-vg-step-submit"
                    fullWidth={mobile}
                    type="submit"
                >
                    {t('common.continue')}
                </PrimaryButton>
            </CenterBox>
        </form>
    );
};

export const ConnectedVgForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IWizardVgFormValues>({
        handleSubmit(values, bag) {
            bag.props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                vgOrdered: false,
            };
        },
        validationSchema: () => wizardVgFormValidation(),
        enableReinitialize: true,
    }),
)(AboutDeceasedForm);
