import { createReducer } from 'typesafe-actions';

import { IGalleryItem } from '../../interfaces/gallery-item';
import {
    deleteGalleryItemApiActions,
    fetchGalleryItemsApiActions,
    updateGalleryItemOnlineStatusApiActions,
} from '../../api/gallery/gallery-api.actions';
import { galleryNewImageUploadRefToApiActions } from '../../gallery/model/gallery.actions';
import { moveGallerySuggestionToGallery } from '../../model/gallery-suggestions/gallery-suggestions.actions';

export interface IGalleryStore {
    galleryItems: IGalleryItem[];
}

const initialState: IGalleryStore = {
    galleryItems: [],
};

type Actions =
    | ReturnType<typeof galleryNewImageUploadRefToApiActions.success>
    | ReturnType<typeof fetchGalleryItemsApiActions.success>
    | ReturnType<typeof deleteGalleryItemApiActions.success>
    | ReturnType<typeof updateGalleryItemOnlineStatusApiActions.success>
    | ReturnType<typeof moveGallerySuggestionToGallery>;

export const galleryReducer = createReducer<IGalleryStore, Actions>(
    initialState,
)
    .handleAction(
        [
            galleryNewImageUploadRefToApiActions.success,
            moveGallerySuggestionToGallery,
        ],
        (state, action) => ({
            ...state,
            galleryItems: [...state.galleryItems, action.payload],
        }),
    )
    .handleAction(fetchGalleryItemsApiActions.success, (state, action) => ({
        ...state,
        galleryItems: action.payload,
    }))
    .handleAction(deleteGalleryItemApiActions.success, (state, action) => ({
        ...state,
        galleryItems: state.galleryItems.filter(
            (item) => item.id !== action.payload.id,
        ),
    }))
    .handleAction(
        updateGalleryItemOnlineStatusApiActions.success,
        (state, action) => ({
            ...state,
            galleryItems: state.galleryItems.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }

                return item;
            }),
        }),
    );
