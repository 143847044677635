import { makeStyles } from '@material-ui/core';

import { Colors } from '../../../../../../styles/colors';

export const useObituaryQuestionsAndAnswersStyles = makeStyles((theme) => ({
    questionsAndAnswers: {
        '& textarea': {
            // simulate minRows prop which is not supported in material v4
            minHeight: '76px',
            [theme.breakpoints.down('xs')]: {
                minHeight: '152px',
            },
        },
    },
    question: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '1.7rem',
    },
    questionNumber: {
        fontWeight: 600,
        fontSize: '2.25rem',
        marginRight: '1rem',
        color: theme.palette.primary.light,
    },
    example: {
        color: Colors.VG_DARK,
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    answerExample: {
        color: Colors.VG_DARK,
        fontSize: '1.5rem',
    },
}));
