import cx from 'classnames';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import { compose, defaultProps, mapProps } from 'recompose';
import { ButtonProps } from '@material-ui/core/Button';

export const SecondaryButton = compose<ButtonProps, ButtonProps>(
    withStyles((theme) => ({
        button: {
            color: theme.palette.common.white,
            boxShadow: 'none',
            '&, &:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            fontWeight: 'bold',
        },
    })),
    defaultProps({
        variant: 'contained',
    }),
    mapProps<ButtonProps, ButtonProps & WithStyles>(
        ({ classes, ...props }) => ({
            ...props,
            className: cx(classes.button, props.className),
        }),
    ),
)(Button);
