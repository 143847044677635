import { createMuiTheme } from '@material-ui/core';

import { Colors } from './colors';

export const theme = {
    default: createMuiTheme({
        typography: {
            h1: {
                fontWeight: 700,
                fontSize: '3.2rem',
            },
            h2: {
                fontWeight: 400,
                fontSize: '2.8rem',
            },
            htmlFontSize: 10,
            allVariants: {
                fontFamily: 'Lato',
            },
        },
        palette: {
            background: {
                default: Colors.WHISPER,
            },
            primary: {
                main: Colors.TOREA_BAY,
                light: Colors.DODGER_BLUE,
            },
            success: {
                main: Colors.APPLE,
            },
        },
    }),
    vg: createMuiTheme({
        typography: {
            h1: {
                fontWeight: 700,
                fontSize: '3.2rem',
            },
            h2: {
                fontWeight: 400,
                fontSize: '2.8rem',
            },
            htmlFontSize: 10,
            allVariants: {
                fontFamily: 'Minion Pro',
            },
        },
        palette: {
            background: {
                default: Colors.VG_BACKGROUND,
            },
            primary: {
                main: Colors.VG_DARK,
                light: Colors.VG_LIGHT,
            },
            success: {
                main: Colors.VG_LIGHT,
            },
        },
    }),
};
