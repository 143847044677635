import * as yup from 'yup';

export const TaskFormValidationSchema = () =>
    yup.object({
        task: yup.string().required(),
        expirationDate: yup
            .date()
            .required()
            .nullable(),
        expirationTime: yup
            .date()
            .required()
            .nullable(),
    });
