import { createAction, createAsyncAction } from 'typesafe-actions';

import { IMemorialPageMetaDataResponse } from '../page/dto/memorial-page-data-response.dto';
import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';
import { parseDateString } from '../../utils/use-date-formatter';

import { IUpdateDeceasedDto } from './dto/update-deceased-dto';
import { IDeceasedPersonResponseDto } from './dto/deceased-person-response.dto';

export const updateDeceasedDataApiActions = createAsyncAction(
    'api/deceased/UPDATE_DECEASED_DATA_REQUESTED',
    'api/deceased/UPDATE_DECEASED_DATA_SUCCEED',
    'api/deceased/UPDATE_DECEASED_DATA_FAILED',
)<IUpdateDeceasedDto, IMemorialPageMetaDataResponse, string>();

/**
 * Dispatch this action when page data is examplesFetched
 */
export const deceasedDataReceived = createAction(
    'api/deceased/DATA_RECEIVED',
    (action) => (memorialPageData: IMemorialPageMetaDataResponse) => {
        const deceasedData = memorialPageData.included.find(
            (inclusion) => inclusion.type === 'deceased_person',
        )! as IDeceasedPersonResponseDto;

        const payload: IDeceasedPersonData = {
            birthDate: deceasedData.attributes.birthDate
                ? parseDateString(deceasedData.attributes.birthDate)
                : null,
            birthPlace: deceasedData.attributes.birthLocationName,
            deathDate: deceasedData.attributes.deathDate
                ? parseDateString(deceasedData.attributes.deathDate)
                : null,
            deathPlace: deceasedData.attributes.deathLocationName,
            name: deceasedData.attributes.displayName,
            firstName: deceasedData.attributes.firstName,
            faith: deceasedData.attributes.faith,
            id: deceasedData.id,
        };

        return action(payload);
    },
);
