import React from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconRounded } from '../../components/icon-rounded/icon-rounded';
import { SectionHeadline } from '../../components/section-headline/section-headline';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { IEventDisplay } from '../event-display';
import { EventType } from '../../interfaces/event-type';
import { useIsMobile } from '../../../styles/use-is-mobile';

export interface IEventProps {
    event: IEventDisplay;
    onDelete: (id: string) => unknown;
    onEdit(): unknown;
}

const useStyles = makeStyles((theme) => ({
    bodyCell: {
        fontSize: '1.5rem',
        paddingLeft: 0,
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    bodyCellWide: {
        width: '40%',
    },
    bodyCellLast: {
        '&, &:last-child': {
            paddingRight: 0,
        },
    },
    bodyCellTitle: {
        fontSize: '1.7rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    editIcon: {
        color: theme.palette.primary.light,
    },
}));

export const Event = ({ event, onDelete, onEdit }: IEventProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const styles = useStyles();

    const eventTypesLabels = {
        [EventType.CEREMONY]: t('ceremonyAndReception.typeCeremony'),
        [EventType.RECEPTION]: t('ceremonyAndReception.typeReception'),
        [EventType.CUSTOM]: t('ceremonyAndReception.typeCustom'),
    };

    if (isMobile) {
        return (
            <div>
                <SectionHeadline
                    data-testid="event-title"
                    className={styles.bodyCellTitle}
                >
                    {event.title}
                </SectionHeadline>
                <Box ml={2}>
                    {event.dateDisplay && (
                        <Box data-testid="event-date-time">{`${event.dateDisplay}, ${event.timeDisplay}`}</Box>
                    )}
                    <Box data-testid="event-type">
                        {eventTypesLabels[event.type]}
                    </Box>
                    <Box
                        data-testid="event-edit-delete"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <IconRounded
                            icon={event.isReadOnly ? EyeIcon : EditIcon}
                            onClick={onEdit}
                            className={styles.editIcon}
                        />
                        {!event.isReadOnly && (
                            <IconRounded
                                icon={DeleteIcon}
                                ml={3}
                                onClick={() => onDelete(event.id)}
                            />
                        )}
                    </Box>
                </Box>
            </div>
        );
    }

    return (
        <TableRow>
            <TableCell className={cx(styles.bodyCell, styles.bodyCellWide)}>
                <SectionHeadline
                    data-testid="event-title"
                    className={styles.bodyCellTitle}
                >
                    {event.title}
                </SectionHeadline>
            </TableCell>
            <TableCell
                data-testid="event-date-time"
                className={styles.bodyCell}
            >
                {event.dateDisplay
                    ? `${event.dateDisplay}, ${event.timeDisplay}`
                    : ''}
            </TableCell>
            <TableCell data-testid="event-type" className={styles.bodyCell}>
                {eventTypesLabels[event.type]}
            </TableCell>
            <TableCell className={cx(styles.bodyCell, styles.bodyCellLast)}>
                <Box
                    data-testid="event-edit-delete"
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconRounded
                        icon={event.isReadOnly ? EyeIcon : EditIcon}
                        onClick={onEdit}
                        className={cx(styles.editIcon)}
                    />
                    {!event.isReadOnly && (
                        <IconRounded
                            icon={DeleteIcon}
                            ml={3}
                            onClick={() => onDelete(event.id)}
                        />
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};
