import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { PageTitle } from '../components/page-title/page-title';
import { useRollbar } from '../../use-rollbar';
import { OutlinedButton } from '../../components/outlined-button/outlined-button';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { getMemorialBaseUrl } from '../../utils/url-resolvers';

import { PhotosSection } from './photos-section/photos-section';
import { OverviewNotifyingSection } from './overview-notifying-section/overview-notifying-section';
import { OverviewSection } from './overview-section/overview-section';
import { OverviewEventsSection } from './overview-events-section/overview-events-section';
import { OverviewTasksSection } from './overview-tasks-section/overview-tasks-section';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        marginTop: theme.spacing(5),
        width: '100%',
    },
    buttonIcon: {
        marginRight: theme.spacing(2),
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export const OverviewScreen = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { rollbar } = useRollbar();
    const slug = useSelector(getPageSlug);

    useEffect(() => {
        rollbar.info('User opens Editor Overview Page');
    }, [rollbar]);

    return (
        <Box
            data-testid="overview-page-container"
            className={styles.root}
            mb={3}
        >
            <PageTitle>{t('overview.title')}</PageTitle>
            <PhotosSection
                className={styles.section}
                topContent={
                    <Box
                        data-testid="overview-goto-memorial"
                        mb={5}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <OutlinedButton
                            className={styles.button}
                            target="_blank"
                            href={`${getMemorialBaseUrl(
                                window.location.toString(),
                            )}/${slug}`}
                        >
                            <OpenInNew className={styles.buttonIcon} />
                            {t('overview.goToMemorialPage')}
                        </OutlinedButton>
                    </Box>
                }
            />
            <OverviewNotifyingSection className={styles.section} />
            <OverviewSection className={styles.section} />
            <OverviewEventsSection className={styles.section} />
            <OverviewTasksSection className={styles.section} />
        </Box>
    );
};
