import { IEventRequestDto } from '../../api/events/dto/event-request.dto';
import { IEventFormData } from '../event-form-data';
import { mergeDateWithTime } from '../../utils/merge-date-with-time';

export const mapEventToDTO = (formData: IEventFormData): IEventRequestDto => {
    const date = formData.date
        ? mergeDateWithTime(formData.date!, formData.time!).toISOString()
        : null;

    return {
        event: {
            startTimestamp: date,
            title: formData.title,
            description: formData.description,
        },
        location: formData.location,
        schedulableType: formData.type,
        schedulable: {
            publicMessageForPrivateCeremony:
                formData.publicMessageForPrivateCeremony || null,
        },
    };
};
