import { createSelector } from 'reselect';

import { RootStore } from '../../../store/store';

const getTasksStore = (store: RootStore) => store.tasks;

const getTasks = createSelector(
    getTasksStore,
    (store) => store.tasks.sort((t1, t2) => (t1.id > t2.id ? 1 : -1)),
);

export const TasksSelectors = {
    getTasks,
};
