import { createAction } from 'typesafe-actions';

import { IInvitation } from '../../interfaces/invitation';

export const invitationsFetchRequested = createAction(
    'model/invitations/INVITATIONS_FETCH_REQUESTED',
);

export const invitationsReceived = createAction(
    'model/invitations/INVITATIONS_RECEIVED',
    (action) => (payload: IInvitation[]) => action(payload),
);
