import { put, call, select } from 'redux-saga/effects';

import {
    deceasedDataReceived,
    updateDeceasedDataApiActions,
} from '../deceased-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { updateDeceasedData } from '../http/update-deceased-data';

export function* updateDeceasedDataSaga(
    action: ReturnType<typeof updateDeceasedDataApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response = yield call(updateDeceasedData, action.payload, slug);
        yield put(updateDeceasedDataApiActions.success(response));
        yield put(deceasedDataReceived(response));
    } catch (e) {
        yield put(updateDeceasedDataApiActions.failure(e.statusText));
    }
}
