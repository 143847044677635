import React, { useState } from 'react';
import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { isEqual } from 'lodash';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core';

import { ILanguageWithFlag, ILanguage } from '../../interfaces/language';
import { withLanguageSelect } from '../../meta/with-language-select';
import { mapLanguageWithFlag } from '../../model/configuration/map-languages-to-flags';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

type Props = {
    selectedLanguage: ILanguage;
    languages: ILanguageWithFlag[];
    onChange(language: ILanguage): unknown;
};

type OuterProps = {
    mobile?: boolean;
};

const useStyles = makeStyles((theme) => ({
    LanguageSelector: {
        marginRight: '1rem',
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        padding: 0,
        outline: 'none',
    },
    SelectedLanguage: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: '1rem',
        margin: '1rem 0',
    },
    SelectedLanguageExpanded: {
        backgroundColor: '#fff',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        cursor: 'initial',
    },
    LanguageIcon: {
        width: '2rem',
    },
    LanguageName: {
        display: 'block',
        marginLeft: '0.5rem',
        textTransform: 'uppercase',
    },
    Arrow: {
        color: '#000',
        marginLeft: '1.1rem',
        width: '1.5rem',
    },
    LanguageSelectorDropdown: {
        backgroundColor: '#fff',
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        boxShadow: '5px 10px 30px -10px #ddd',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 'calc(100% - 1.3rem)',
        zIndex: 1,
    },
    Language: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: '1rem',
        outline: 'none',
    },
}));

export const LanguageSelector = ({
    selectedLanguage,
    languages,
    onChange,
}: Props & OuterProps) => {
    const [expanded, setExpanded] = useState(false);
    const languageSelectorRef = React.useRef(null);
    const styles = useStyles();

    const language = mapLanguageWithFlag(selectedLanguage);

    useOnClickOutside(languageSelectorRef, () => {
        if (expanded) {
            setExpanded(false);
        }
    });

    const SelectedLanguageIcon = language.icon;

    const filteredLanguages = languages.filter((l) => !isEqual(l, language));

    return (
        <div
            className={styles.LanguageSelector}
            onClick={() => setExpanded(!expanded)}
            onKeyDown={() => setExpanded(!expanded)}
            ref={languageSelectorRef}
            role="button"
            tabIndex={0}
        >
            <div
                data-testid="language-selected"
                className={cx(styles.SelectedLanguage, {
                    [styles.SelectedLanguageExpanded]: expanded,
                })}
            >
                <SelectedLanguageIcon className={styles.LanguageIcon} />
                {expanded ? (
                    <span className={styles.LanguageName}>{language.code}</span>
                ) : (
                    <ArrowDown className={styles.Arrow} />
                )}
            </div>
            {expanded && (
                <div
                    data-testid="language-selector-dropdown"
                    className={styles.LanguageSelectorDropdown}
                >
                    {filteredLanguages.map((lang) => {
                        const Icon = lang.icon;

                        return (
                            <div
                                className={styles.Language}
                                key={lang.code}
                                onClick={() => {
                                    onChange({ code: lang.code });
                                }}
                                onKeyDown={() => {
                                    onChange({ code: lang.code });
                                }}
                                role="button"
                                tabIndex={0}
                            >
                                <Icon className={styles.LanguageIcon} />
                                <span className={styles.LanguageName}>
                                    {lang.code}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export const ComposedLanguageSelector = compose<Props, OuterProps>(
    withLanguageSelect(),
)(LanguageSelector);
