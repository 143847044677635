import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { store } from './store';

export const withStoreProvider = (appStore: Store = store) => <
    Props extends {}
>(
    Component: React.ComponentType<Props>,
) => (props: Props) => (
    <Provider store={appStore}>
        <Component {...props} />
    </Provider>
);
