import { createAction, createAsyncAction } from 'typesafe-actions';

import { IUpdateDeceasedDto } from '../../api/deceased/dto/update-deceased-dto';

import { IDeceasedFormValues } from './deceased-form-values';

export const deceasedFormSubmitted = createAction(
    'editor/deceased/FORM_SUBMITTED',
    (action) => (payload: IDeceasedFormValues) => action(payload),
);

export const deceasedFormSubmitActions = createAsyncAction(
    'editor/deceased/FORM_SUBMIT_REQUESTED',
    'editor/deceased/FORM_SUBMIT_SUCCEED',
    'editor/deceased/FORM_SUBMIT_FAILED',
)<IUpdateDeceasedDto, undefined, string>();
