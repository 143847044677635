import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';

import { resolveEditorContactsSelector } from './resolve-editor-contacts-selector';

export const getFilteredSelectedContactsIds = (
    filter: NotificationContactStatus | null,
) => {
    return createSelector(
        resolveEditorContactsSelector(filter),
        (contacts) => contacts,
    );
};
