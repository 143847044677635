import { createReducer } from 'typesafe-actions';

import { MemorialPageCreationStep } from '../../interfaces/memorial-page-creation-step';
import { fetchPageActions } from '../../api/page/page-api.actions';

import {
    memorialPageCreationStepReceived,
    memorialPageSlugReceived,
} from './meta.actions';

/**
 * Store here globally accessible meta data
 */
export interface IMetaStore {
    slug: string | null;
    creationStep: MemorialPageCreationStep | null;
    fetched: boolean;
}

const initialState: IMetaStore = {
    slug: null,
    creationStep: null,
    fetched: false,
};

type Actions =
    | ReturnType<typeof fetchPageActions.success>
    | ReturnType<typeof memorialPageSlugReceived>
    | ReturnType<typeof memorialPageCreationStepReceived>;

export const metaReducer = createReducer<IMetaStore, Actions>(initialState)
    .handleAction(memorialPageSlugReceived, (state, action) => ({
        ...state,
        slug: action.payload.slug,
    }))
    .handleAction(memorialPageCreationStepReceived, (state, action) => ({
        ...state,
        creationStep: action.payload.state,
    }))
    .handleAction(fetchPageActions.success, (state, action) => ({
        ...state,
        fetched: true,
    }));
