import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Section,
    ISectionProps,
} from '../../editor/components/section/section';
import { changeSettingsRequested } from '../model/settings.actions';
import { getIsPlaceOfBirthVisible } from '../model/selectors/get-is-place-of-birth-visible.selector';
import { getIsPlaceOfDeathVisible } from '../model/selectors/get-is-place-of-death-visible.selector';
import { getIsCondolenceImagesEnabledSelector } from '../model/selectors/get-is-condolence-images-enabled.selector';

export interface IDisplaySettingsProps extends ISectionProps {}

export const DisplaySettings = (props: IDisplaySettingsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const placeOfBirthVisible = useSelector(getIsPlaceOfBirthVisible);
    const placeOfDeathVisible = useSelector(getIsPlaceOfDeathVisible);
    const allowPhotosOnMemorialPage = useSelector(
        getIsCondolenceImagesEnabledSelector,
    );

    const onPlaceOfBirthChanged = () => {
        dispatch(
            changeSettingsRequested({
                birth: !placeOfBirthVisible,
            }),
        );
    };
    const onPlaceOfDeathChanged = () => {
        dispatch(
            changeSettingsRequested({
                death: !placeOfDeathVisible,
            }),
        );
    };
    const onAllowImagesOnMemorialPageChanges = () => {
        dispatch(
            changeSettingsRequested({
                images: !allowPhotosOnMemorialPage,
            }),
        );
    };

    return (
        <Section title={t('settings.displayingInformation')} {...props}>
            <Box mt={4}>
                <FormControlLabel
                    data-testid="display-info-birth-place"
                    control={
                        <Checkbox
                            checked={placeOfBirthVisible}
                            onChange={onPlaceOfBirthChanged}
                            color="primary"
                        />
                    }
                    label={
                        <Typography>
                            {t('settings.showPlaceOfBirthLabel')}
                        </Typography>
                    }
                />
            </Box>

            <Box mt={1}>
                <FormControlLabel
                    data-testid="display-info-death-place"
                    control={
                        <Checkbox
                            checked={placeOfDeathVisible}
                            onChange={onPlaceOfDeathChanged}
                            color="primary"
                        />
                    }
                    label={
                        <Typography>
                            {t('settings.showPlaceOfDeathLabel')}
                        </Typography>
                    }
                />
            </Box>

            <Box mt={1}>
                <FormControlLabel
                    data-testid="allow-photos-on-memorial-page"
                    control={
                        <Checkbox
                            checked={allowPhotosOnMemorialPage}
                            onChange={onAllowImagesOnMemorialPageChanges}
                            color="primary"
                        />
                    }
                    label={
                        <Typography>
                            {t('settings.allowPhotosOnMemorialPage')}
                        </Typography>
                    }
                />
            </Box>
        </Section>
    );
};
