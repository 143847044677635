import React from 'react';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import {
    Box,
    Container,
    FormControl,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FormDoubleInputsContainer } from '../../components/form-double-inputs-container/form-double-inputs-container';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { FormTextField } from '../../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../../components/field-error-message/field-error-message';
import { createShouldShowError } from '../../utils/should-show-error';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { LogoLangugageContainer } from '../../components/logo-language-container/logo-language-container';

import { accountFormValidationSchema } from './password-form-validation';
import { IPasswordFormValues } from './password-form-values';
import { passwordsFormSubmitted } from './model/password-page.actions';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        paddingTop: theme.spacing(4),
    },
    headline: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
        },
    },
    content: {
        maxWidth: '80rem',
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(8, 16),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
            padding: theme.spacing(4),
        },
    },
}));

const initialValues: IPasswordFormValues = {
    password: '',
    repeatPassword: '',
};

/**
 * TODO: Move form to component
 */
export const PasswordPage = (props: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const mobile = useIsMobile();

    const onSubmit = (values: IPasswordFormValues) => {
        dispatch(
            passwordsFormSubmitted({
                password: values.password,
            }),
        );
    };

    return (
        <Container className={styles.container}>
            <LogoLangugageContainer />
            <Typography className={styles.headline} variant="h2" align="center">
                {t('setPasswordScreen.mainHeadline')}
            </Typography>
            <Box mb={6}>
                <Typography paragraph align="center">
                    {t('setPasswordScreen.primaryHeadline')}
                </Typography>
                <Typography paragraph align="center">
                    {t('setPasswordScreen.secondaryHeadline')}
                </Typography>
            </Box>
            <Paper className={styles.content}>
                <Formik<IPasswordFormValues>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validationSchema={accountFormValidationSchema}
                >
                    {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                    }) => {
                        const shouldShowError = createShouldShowError(
                            errors,
                            touched,
                        );

                        return (
                            <form onSubmit={handleSubmit}>
                                <FormDoubleInputsContainer>
                                    <FormControl
                                        fullWidth
                                        error={shouldShowError('password')}
                                    >
                                        <FormTextField
                                            data-testid="wizard-account-password-field"
                                            error={shouldShowError('password')}
                                            label={t(
                                                'setPasswordScreen.passwordLabel',
                                            )}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="password"
                                            type="password"
                                        />
                                        <FieldErrorMessage name="password" />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        error={shouldShowError(
                                            'repeatPassword',
                                        )}
                                    >
                                        <FormTextField
                                            data-testid="wizard-account-repeat-password-field"
                                            error={shouldShowError(
                                                'repeatPassword',
                                            )}
                                            label={t(
                                                'setPasswordScreen.repeatPasswordLabel',
                                            )}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="repeatPassword"
                                            value={values.repeatPassword}
                                            type="password"
                                        />
                                        <FieldErrorMessage
                                            data-testid="wizard-account-repeat-password-error"
                                            name="repeatPassword"
                                        />
                                    </FormControl>
                                </FormDoubleInputsContainer>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    mt={3}
                                >
                                    <PrimaryButton
                                        fullWidth={mobile}
                                        type="submit"
                                    >
                                        {t('common.saveAndContinue')}
                                    </PrimaryButton>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </Paper>
        </Container>
    );
};

export const ComposedPasswordPage = compose<Props, Props>()(PasswordPage);
