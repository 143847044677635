import { createAsyncAction, createAction } from 'typesafe-actions';

export const fetchBackgroundPhotosApiActions = createAsyncAction(
    'api/photos/BACKGROUND_PHOTOS_FETCH_REQUESTED',
    'api/photos/BACKGROUND_PHOTOS_FETCH_SUCCEED',
    'api/photos/BACKGROUND_PHOTOS_FETCH_FAILED',
)<
    undefined,
    Array<{
        src: string;
        id: string;
    }>,
    string
>();

export const sendDeceasedPhotoRefToBackendActions = createAsyncAction(
    'api/photos/SEND_DECEASED_PHOTO_TO_BACKEND_REQUESTED',
    'api/photos/SEND_DECEASED_PHOTO_TO_BACKEND_SUCCEED',
    'api/photos/SEND_DECEASED_PHOTO_TO_BACKEND_FAILED',
)<undefined, undefined, Error>();

export const updateDeceasedPhotoAction = createAction(
    'api/photos/UPDATE_DECEASED_PHOTO',
    (action) => (payload: string) => action(payload),
);
