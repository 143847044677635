import { call, put } from 'redux-saga/effects';

import { deleteTaskApiActions } from '../api-tasks.actions';
import { deleteTask } from '../delete-task';

export function* deleteTaskApiSaga(
    action: ReturnType<typeof deleteTaskApiActions.request>,
) {
    try {
        yield call(deleteTask, action.payload.id);
        yield put(
            deleteTaskApiActions.success({
                id: action.payload.id,
            }),
        );
    } catch (e) {
        yield put(deleteTaskApiActions.failure(e.statusText));
    }
}
