const SIGN_TO_BE_CROSS = '+';
const SIGN_TO_BE_SPACE = '_';
const DBL_SIGN_TO_BE_LONG_DASH = '-';
const DBL_SIGN_TO_MARK_BOLD = '*';
const DBL_SIGN_TO_MARK_ITALIC = '!';

const getCrossRegExp = () => {
    return new RegExp(`[${SIGN_TO_BE_CROSS}]`, 'g');
};

const getSpaceRegExp = () => {
    return new RegExp(`[${SIGN_TO_BE_SPACE}]`, 'g');
};

const getLongDashRegExp = () => {
    return new RegExp(`[${DBL_SIGN_TO_BE_LONG_DASH}]{2}`, 'g');
};

const getDblSignRegExp = (type: string) => {
    return new RegExp(`[${type}]{2}`, 'g');
};

const getBoldOrItalicRegExp = (type: string) => {
    return new RegExp(`[${type}]{2}(.*?)[${type}]{2}`, 'g');
};

const convertTextToType = (text: string, type: string): string => {
    const matchesForSigns: string[] =
        text.match(getBoldOrItalicRegExp(type)) || [];
    let convertedText = text;

    if (matchesForSigns.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const textPart of matchesForSigns) {
            let newTextPart = '';
            if (type === DBL_SIGN_TO_MARK_BOLD) {
                newTextPart = `<b>${textPart.replace(
                    getDblSignRegExp(type),
                    '',
                )}</b>`;
            } else if (type === DBL_SIGN_TO_MARK_ITALIC) {
                newTextPart = `<i>${textPart.replace(
                    getDblSignRegExp(type),
                    '',
                )}</i>`;
            }
            convertedText = convertedText.replace(textPart, newTextPart);
        }
    }

    return convertedText;
};
export function richTextToHtml(value: string): string {
    return convertTextToType(
        convertTextToType(String(value), DBL_SIGN_TO_MARK_ITALIC),
        DBL_SIGN_TO_MARK_BOLD,
    )
        .trim()
        .replace(/\n+\s+\n+/g, '\n\n')
        .replace(/\n\n+/g, '\n\n')
        .replace(/\n/g, '<br>')
        .replace(/\s+/g, ' ')
        .replace(/<\/?span[^>]*>/g, '')
        .replace(getCrossRegExp(), '†')
        .replace(getLongDashRegExp(), '&#8213')
        .replace(getSpaceRegExp(), '&nbsp;');
}

export function htmlToRichText(value: string): string {
    return (
        value
            // Convert `&amp;` to `&`.
            .replace(/&amp;/gi, '&')
            // Replace spaces.
            .replace(/\s+/g, ' ')
            // Remove "<b>".
            .replace(/<b>/gi, '')
            .replace(/<\/b>/gi, '')
            // Remove "<strong>".
            .replace(/<strong>/gi, '')
            .replace(/<\/strong>/gi, '')
            // Remove "<i>".
            .replace(/<i>/gi, '')
            .replace(/<\/i>/gi, '')
            // Remove "<em>".
            .replace(/<em>/gi, '')
            .replace(/<\/em>/gi, '')
            // Remove "<u>".
            .replace(/<u>/gi, '')
            .replace(/<\/u>/gi, '')
            // Tighten up "<" and ">".
            .replace(/>\s+/g, '>')
            .replace(/\s+</g, '<')
            // Replace "<br>".
            .replace(/<br>/gi, '\n')
            // Replace "<div>" (from Chrome).
            .replace(/<div>/gi, '\n')
            .replace(/<\/div>/gi, '')
            // Replace "<p>" (from IE).
            .replace(/<p>/gi, '\n')
            .replace(/<\/p>/gi, '')
            // No more than 2x newline, per "paragraph".
            .replace(/\n\n+/g, '\n\n')
            // Whitespace before/after.
            .trim()
    );
}
