import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';
import { getEditorInformedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-editor-informed-notification-contacts';
import { getEditorNotInformedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-editor-not-informed-notification-contacts';
import { getEditorSuggestedNotificationContacts } from '../../../../../model/notification-contacts/selectors/get-editor-suggested-notification-contacts';

import { getSelectedContactsIds } from './get-selected-contacts-ids.selector';

export const resolveEditorContactsSelector = (
    filter: NotificationContactStatus | null,
) => {
    switch (filter) {
        case NotificationContactStatus.INFORMED:
            return getEditorInformedNotificationContacts;

        case NotificationContactStatus.NOT_INFORMED:
            return getEditorNotInformedNotificationContacts;

        case NotificationContactStatus.SUGGESTED:
            return getEditorSuggestedNotificationContacts;

        case null:
        default:
            return getSelectedContactsIds;
    }
};
