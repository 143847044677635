import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchParticipantsApiActions } from '../../api/participants/participants-api.actions';
import { participantsChangePage } from '../../model/participants/participants.actions';
import { getParticipantsPageSize } from '../../model/participants/selectors/get-participants-page-size.selector';
import { getParticipantsPage } from '../../model/participants/selectors/get-participants-page.selector';
import { getParticipantsTotalCount } from '../../model/participants/selectors/get-participants-total-count.selector';
import { getParticipants } from '../../model/participants/selectors/get-participants.selector';

export const useParticipants = () => {
    const dispatch = useDispatch();
    const participants = useSelector(getParticipants);
    const currentPage = useSelector(getParticipantsPage);
    const pageSize = useSelector(getParticipantsPageSize);
    const total = useSelector(getParticipantsTotalCount);
    const pageCount = total ? Math.ceil(total / pageSize) : 1;

    useEffect(() => {
        dispatch(
            fetchParticipantsApiActions.request({
                page: { size: pageSize },
            }),
        );
    }, [dispatch, pageSize]);

    return {
        participants,
        currentPage,
        pageCount,
        pageSize,
        perPageChanged(count: number) {
            dispatch(participantsChangePage({ page: count }));
            dispatch(
                fetchParticipantsApiActions.request({
                    page: { size: count, number: currentPage },
                }),
            );
        },
        nextPageRequested() {
            dispatch(
                fetchParticipantsApiActions.request({
                    page: { size: pageSize, number: currentPage + 1 },
                }),
            );
        },
        prevPageRequested() {
            dispatch(
                fetchParticipantsApiActions.request({
                    page: { size: pageSize, number: currentPage - 1 },
                }),
            );
        },
    };
};
