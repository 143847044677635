import { call, put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { updateNotificationMessageApiActions } from '../contacts-api.actions';
import { setNotificationMessage } from '../http/set-notification-message';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { notificationMessageTextReceived } from '../../../model/notification-message/notification-messages-model.actions';
import { NotificationMessageStatus } from '../../../model/notification-message/notification-message.store';

/**
 * TODO: Move out of "contacts" to some "message" module
 * @see https://bitbucket.org/memcareteam/notify-editor/issues/16/divide-update-notification-message-and
 */
export function* updateNotificationMessageSaga(
    action: ReturnType<typeof updateNotificationMessageApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const response = yield call(
        setNotificationMessage,
        slug,
        action.payload.message,
    );

    if (!response.errors) {
        yield put(
            notificationMessageTextReceived({
                text: response.data.attributes.notificationText || '',
            }),
        );
        yield put(updateNotificationMessageApiActions.success());
    } else if (find(response.errors, { code: 'not_decent' })) {
        yield put(
            updateNotificationMessageApiActions.failure({
                status: NotificationMessageStatus.INAPPROPRIATE_WORDS,
            }),
        );
    } else {
        yield put(
            updateNotificationMessageApiActions.failure({
                status: response.errors[0].title,
            }),
        );
    }
}
