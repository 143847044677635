import { apiService } from '../../api.service';
import { ISubmitPasswordResponseDto } from '../dto/submit-password-response.dto';

export const setUserPassword = (
    password: string,
    token: string,
): Promise<ISubmitPasswordResponseDto> =>
    apiService
        .post(`/auth/invitations/${token}/users`, {
            json: {
                user: {
                    password,
                    terms_and_conditions: true,
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
