import { createReducer } from 'typesafe-actions';

import { IMemorialEvent } from '../../interfaces/memorial-event';

import {
    closeEventCreateModalRequested,
    closeEventUpdateModalRequested,
    createEventInEditorSucceed,
    deleteEventAsyncActions,
    eventCreationRequested,
    eventUpdateRequested,
    editorGetEventsAsyncActions,
    updateEventAsyncActions,
} from './events.actions';

export interface IEventsStore {
    createModalOpen: boolean;
    updateModalOpen: boolean;
    editedEvent: IMemorialEvent | null;
    events: IMemorialEvent[];
}

const initialState: IEventsStore = {
    createModalOpen: false,
    updateModalOpen: false,
    editedEvent: null,
    events: [],
};

type Actions =
    | ReturnType<typeof createEventInEditorSucceed>
    | ReturnType<typeof updateEventAsyncActions.success>
    | ReturnType<typeof deleteEventAsyncActions.success>
    | ReturnType<typeof editorGetEventsAsyncActions.success>
    | ReturnType<typeof eventCreationRequested>
    | ReturnType<typeof closeEventCreateModalRequested>
    | ReturnType<typeof eventUpdateRequested>
    | ReturnType<typeof closeEventUpdateModalRequested>;

export const eventsReducer = createReducer<IEventsStore, Actions>(initialState)
    .handleAction(editorGetEventsAsyncActions.success, (state, action) => {
        return {
            ...state,
            events: action.payload,
        };
    })
    .handleAction(eventCreationRequested, (state) => {
        return {
            ...state,
            createModalOpen: true,
        };
    })
    .handleAction(closeEventCreateModalRequested, (state) => {
        return {
            ...state,
            createModalOpen: false,
        };
    })
    .handleAction(eventUpdateRequested, (state, action) => {
        return {
            ...state,
            updateModalOpen: true,
            editedEvent: action.payload.editedEvent,
        };
    })
    .handleAction(closeEventUpdateModalRequested, (state) => {
        return {
            ...state,
            updateModalOpen: false,
            editedEvent: null,
        };
    })
    .handleAction(createEventInEditorSucceed, (state, action) => {
        return {
            ...state,
            createModalOpen: false,
            events: [...state.events, action.payload.event],
        };
    })
    .handleAction(updateEventAsyncActions.success, (state, action) => {
        return {
            ...state,
            updateModalOpen: false,
            events: state.events.map((event) => {
                if (event.id === action.payload.event.id) {
                    return action.payload.event;
                }

                return event;
            }),
        };
    })
    .handleAction(deleteEventAsyncActions.success, (state, action) => {
        return {
            ...state,
            events: state.events.filter((event) => event.id !== action.payload),
        };
    });
