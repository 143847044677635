import { makeStyles } from '@material-ui/core';

export const useObituaryWritingStyleSelectorStyles = makeStyles((theme) => ({
    chip: {
        height: '34px',
        borderRadius: '17px',
        fontFamily: 'Raleway',
        [theme.breakpoints.down('xs')]: {
            height: '40px',
            borderRadius: '20px',
            width: '100%',
            justifyContent: 'space-between',
            margin: '0.125rem 0',
        },
    },
    label: {
        padding: '0 14px',
        fontSize: '1.4rem',
    },
    selected: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        outline: `1px solid ${theme.palette.primary.light}`,
        border: `1px solid ${theme.palette.common.white}`,
    },
}));
