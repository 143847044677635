import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    createMilestoneFormSubmitted,
    deleteMilestoneAsyncActions,
    fetchMilestonesAsyncActions,
    updateMilestoneFormSubmitted,
} from '../milestones.actions';

import { fetchMilestonesSaga } from './fetch-milestones.saga';
import { createMilestoneSaga } from './create-milestone.saga';
import { updateMilestoneSaga } from './update-milestone.saga';
import { deleteMilestoneSaga } from './delete-milestone.saga';

export function* milestonesSaga() {
    yield takeEvery(
        getType(fetchMilestonesAsyncActions.request),
        fetchMilestonesSaga,
    );
    yield takeEvery(getType(createMilestoneFormSubmitted), createMilestoneSaga);
    yield takeEvery(getType(updateMilestoneFormSubmitted), updateMilestoneSaga);
    yield takeEvery(
        getType(deleteMilestoneAsyncActions.request),
        deleteMilestoneSaga,
    );
}
