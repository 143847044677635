import { createAsyncAction } from 'typesafe-actions';

import { IMemorialEvent } from '../../interfaces/memorial-event';

import { IEventRequestDto } from './dto/event-request.dto';

export const createEventApiActions = createAsyncAction(
    'api/events/CREATE_EVENT_REQUESTED',
    'api/events/CREATE_EVENT_SUCCEED',
    'api/events/CREATE_EVENT_FAILED',
)<IEventRequestDto, IMemorialEvent, string>();

export const deleteEventApiActions = createAsyncAction(
    'api/events/DELETE_EVENT_REQUESTED',
    'api/events/DELETE_EVENT_SUCCEED',
    'api/events/DELETE_EVENT_FAILED',
)<{ id: string }, undefined, string>();

export const updateEventApiActions = createAsyncAction(
    'api/events/UPDATE_EVENT_REQUESTED',
    'api/events/UPDATE_EVENT_SUCCEED',
    'api/events/UPDATE_EVENT_FAILED',
)<Partial<IEventRequestDto> & { id: string }, IMemorialEvent, string>();

export const fetchEventsApiActions = createAsyncAction(
    'api/events/FETCH_EVENT_REQUESTED',
    'api/events/FETCH_EVENT_SUCCEED',
    'api/events/FETCH_EVENT_FAILED',
)<undefined, IMemorialEvent[], string>();
