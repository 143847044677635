import { createReducer } from 'typesafe-actions';

import {
    backgroundPhotoChangeRequested,
    closeBackgroundPhotoChangeModalRequested,
} from './photos.actions';

export interface IPhotosStore {
    backgroundModalOpen: boolean;
}

const initialState: IPhotosStore = {
    backgroundModalOpen: false,
};

type Actions =
    | ReturnType<typeof backgroundPhotoChangeRequested>
    | ReturnType<typeof closeBackgroundPhotoChangeModalRequested>;

export const photosReducer = createReducer<IPhotosStore, Actions>(initialState)
    .handleAction(backgroundPhotoChangeRequested, (state) => {
        return {
            ...state,
            backgroundModalOpen: true,
        };
    })
    .handleAction(closeBackgroundPhotoChangeModalRequested, (state) => {
        return {
            ...state,
            backgroundModalOpen: false,
        };
    });
