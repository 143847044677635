import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

import noTranslations from '../locales/no/translations.json';
import enTranslations from '../locales/en/translations.json';
import plTranslations from '../locales/pl/translations.json';
import deTranslations from '../locales/de/translations.json';
import frTranslations from '../locales/fr/translations.json';
import svTranslations from '../locales/sv/translations.json';

setLocale({
    mixed: {
        default: 'error.invalidField',
        required: 'error.requiredField',
    },
    string: {
        email: 'error.invalidEmail',
        max: 'error.tooLong',
        min: 'error.tooShort',
    },
});

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    /**
     * Ensure language codes match SupportedLanguageCode type
     */
    resources: {
        en: {
            translation: enTranslations,
        },
        nb: {
            translation: noTranslations,
        },
        // Just in case
        no: {
            translation: noTranslations,
        },
        pl: {
            translation: plTranslations,
        },
        de: {
            translation: deTranslations,
        },
        fr: {
            translation: frTranslations,
        },
        sv: {
            translation: svTranslations,
        },
    },
});
