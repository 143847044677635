import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';

import { getFilteredSelectedContactsIds } from './get-filtered-selected-contacts-ids.selector';
import { makeGetContacts } from './get-contacts.selector';

export const makeGetAreAllContactsSelected = (
    filter: NotificationContactStatus | null = null,
) => {
    return createSelector(
        makeGetContacts(filter),
        getFilteredSelectedContactsIds(filter),
        (contacts, selectedContactsIds) => {
            if (!contacts.length) {
                return false;
            }

            return contacts.length === selectedContactsIds.length;
        },
    );
};
