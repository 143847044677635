import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { Box, Button, ButtonBase, Dialog, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../../../../components/secondary-button/secondary-button';
import { BlueHeadline } from '../../../../components/blue-headline/blue-headline';
import { IBackgroundImage } from '../../../../interfaces/background-image';

type Props = {
    onSelected(id: string | undefined): unknown;
    onCancel(): unknown;
    backgroundImages: IBackgroundImage[];
    selectedID: string | undefined;
} & DialogProps;

const useStyles = makeStyles((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
    item: {
        marginBottom: theme.spacing(2),
        '&:last-child': {
            marginBottom: 0,
        },
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    activeItem: {
        border: `5px solid ${theme.palette.primary.light}`,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        maxHeight: '50rem',
        overflow: 'auto',
        marginBottom: theme.spacing(3),
    },
    container: {
        padding: theme.spacing(6.5, 3),
    },
    cancelButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.primary.light,
    },
}));

export const SelectBackgroundModal = ({
    onSelected,
    backgroundImages,
    selectedID,
    onCancel,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const [selectedBgID, setSelectedBgID] = useState<string | undefined>(
        selectedID,
    );
    const styles = useStyles();

    useEffect(() => {
        setSelectedBgID(selectedID);
    }, [selectedID]);

    return (
        <Dialog maxWidth="md" onClose={onCancel} {...props}>
            <Box
                data-testid="background-change-modal"
                className={styles.container}
            >
                <BlueHeadline align="center" className={styles.headline}>
                    {t('selectBackgroundModal.selectBackground')}
                </BlueHeadline>
                <ul className={styles.list}>
                    {backgroundImages.map((bg) => (
                        <li
                            key={bg.id}
                            className={cx(styles.item, {
                                [styles.activeItem]: bg.id === selectedBgID,
                            })}
                        >
                            <ButtonBase onClick={() => setSelectedBgID(bg.id)}>
                                <img src={bg.src} alt="" />
                            </ButtonBase>
                        </li>
                    ))}
                </ul>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        data-testid="background-change-cancel"
                        className={styles.cancelButton}
                        onClick={onCancel}
                    >
                        {t('common.cancel')}
                    </Button>
                    <SecondaryButton
                        data-testid="change-background-photo-modal-save-button"
                        onClick={() => onSelected(selectedBgID)}
                    >
                        {t('common.save')}
                    </SecondaryButton>
                </Box>
            </Box>
        </Dialog>
    );
};
