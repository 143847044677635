import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../editor/components/section/section';
import { getTributeContent } from '../model/tribute/selectors/get-tribute-content.selector';
import { getUpdateTributeStatus } from '../model/tribute/selectors/get-update-tribute-status.selector';
import { getUpdateTributeStatusMessage } from '../model/tribute/selectors/get-update-tribute-status-message.selector';

import { ConnectedTributeForm } from './tribute-form';
import { ITributeFormValues } from './model/tribute-form-values';
import { tributeFormSubmitted } from './model/tribute.actions';

interface ITributeProps extends ISectionProps {}

export const Tribute = (props: ITributeProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tributeContent = useSelector(getTributeContent);
    const updateTributeStatus = useSelector(getUpdateTributeStatus);
    const updateTributeStatusMessage = useSelector(
        getUpdateTributeStatusMessage,
    );

    const onSubmit = (values: ITributeFormValues) => {
        dispatch(tributeFormSubmitted(values));
    };

    return (
        <Section
            title={t('mediaAndContent.tribute')}
            description={t('mediaAndContent.tributeDescription')}
            {...props}
        >
            <ConnectedTributeForm
                initialContent={tributeContent}
                onSubmit={onSubmit}
                updateStatus={updateTributeStatus}
                updateStatusMessage={updateTributeStatusMessage}
            />
        </Section>
    );
};
