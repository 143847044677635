import { CondolenceDto } from '../dto/condolence.dto';
import { ICondolence } from '../../../interfaces/condolence';

export const mapCondolenceDtoToCondolence = (
    dto: CondolenceDto,
): ICondolence => {
    const condolence: ICondolence = {
        id: dto.id,
        author: dto.attributes.displayName,
        date: dto.attributes.createdAt,
        hasCandle: dto.attributes.candle,
        hasHeart: dto.attributes.heart,
    };

    if (dto.attributes.content) {
        condolence.content = dto.attributes.content;
    }

    return condolence;
};
