import React from 'react';
import { compose, mapProps } from 'recompose';
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { FormHelperText } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

export const FieldErrorMessage = compose<ErrorMessageProps, ErrorMessageProps>(
    withTranslation(),
    mapProps<ErrorMessageProps, ErrorMessageProps & WithTranslation>(
        ({
            t,
            children,
            component,
            name,
            className,
            tReady,
            render,
            ...props
        }) => ({
            children: (message: string) => {
                return (
                    <FormHelperText className={className} {...props}>
                        {t(message)}
                    </FormHelperText>
                );
            },
            name,
        }),
    ),
)(ErrorMessage);
