import { put, call, select } from 'redux-saga/effects';

import { createTaskApiActions } from '../api-tasks.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { createTask } from '../create-task';

export function* createTaskApiSaga(
    action: ReturnType<typeof createTaskApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response = yield call(createTask, slug, action.payload);
        yield put(createTaskApiActions.success(response));
    } catch (e) {
        yield put(createTaskApiActions.failure(e.statusText));
    }
}
