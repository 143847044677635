import { createReducer } from 'typesafe-actions';

import { IMilestone } from '../../interfaces/milestone';

import {
    closeMilestoneCreateModalRequested,
    closeMilestoneUpdateModalRequested,
    createMilestoneAsyncActions,
    deleteMilestoneAsyncActions,
    milestoneCreationRequested,
    milestoneUpdateRequested,
    updateMilestoneAsyncActions,
} from './milestones.actions';

export interface IMilestonesSectionStore {
    createModalOpen: boolean;
    updateModalOpen: boolean;
    editedMilestone: IMilestone | null;
}

const initialState: IMilestonesSectionStore = {
    createModalOpen: false,
    updateModalOpen: false,
    editedMilestone: null,
};

type Actions =
    | ReturnType<typeof createMilestoneAsyncActions.success>
    | ReturnType<typeof updateMilestoneAsyncActions.success>
    | ReturnType<typeof deleteMilestoneAsyncActions.success>
    | ReturnType<typeof milestoneCreationRequested>
    | ReturnType<typeof closeMilestoneCreateModalRequested>
    | ReturnType<typeof milestoneUpdateRequested>
    | ReturnType<typeof closeMilestoneUpdateModalRequested>;

export const milestonesSectionReducer = createReducer<
    IMilestonesSectionStore,
    Actions
>(initialState)
    .handleAction(milestoneCreationRequested, (state) => {
        return {
            ...state,
            createModalOpen: true,
        };
    })
    .handleAction(closeMilestoneCreateModalRequested, (state) => {
        return {
            ...state,
            createModalOpen: false,
        };
    })
    .handleAction(milestoneUpdateRequested, (state, action) => {
        return {
            ...state,
            updateModalOpen: true,
            editedMilestone: action.payload.editedMilestone,
        };
    })
    .handleAction(closeMilestoneUpdateModalRequested, (state) => {
        return {
            ...state,
            updateModalOpen: false,
            editedMilestone: null,
        };
    })
    .handleAction(createMilestoneAsyncActions.success, (state, action) => {
        return {
            ...state,
            createModalOpen: false,
        };
    })
    .handleAction(updateMilestoneAsyncActions.success, (state, action) => {
        return {
            ...state,
            updateModalOpen: false,
        };
    })
    .handleAction(deleteMilestoneAsyncActions.success, (state, action) => {
        return {
            ...state,
        };
    });
