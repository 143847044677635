import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { fetchBackgroundPhotosApiActions } from '../background-photos.actions';

import { fetchBackgroundPhotosSaga } from './fetch-background-photos-api.saga';

export function* backgroundPhotosSaga() {
    yield takeEvery(
        getType(fetchBackgroundPhotosApiActions.request),
        fetchBackgroundPhotosSaga,
    );
}
