import { createAction } from 'typesafe-actions';

import { UpdateTributeStatus } from './tribute.store';

export const tributeContentReceived = createAction(
    'model/tribute/CONTENT_RECEIVED',
    (action) => (payload: { content: string }) => action(payload),
);

export const setUpdateTributeStatus = createAction(
    'model/tribute/SET_UPDATE_TRIBUTE_STATUS',
    (action) => (payload: UpdateTributeStatus | null) => action(payload),
);
