import { call, put, select } from '@redux-saga/core/effects';

import { fetchSuggestionsApiActions } from '../suggestions-api.actions';
import { fetchSuggestions } from '../http/fetch-suggestions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ISuggestionsResponse } from '../dto/suggestions-response.dto';

export function* fetchSuggestionsSaga() {
    const slug = yield select(getPageSlug);

    try {
        const resp: ISuggestionsResponse = yield call(fetchSuggestions, {
            slug,
        });

        yield put(fetchSuggestionsApiActions.success(resp));
    } catch (err) {
        yield put(fetchSuggestionsApiActions.failure(err.statusText));
    }
}
