import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import cx from 'classnames';

type Props = BoxProps & {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    iconClassName?: string;
};

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '1.7rem',
    },
    iconOverlay: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        boxShadow: `0 2px 6px 0 rgba(2, 56, 153, 0.09)`,
        cursor: 'pointer',
        display: 'flex',
        width: theme.spacing(5),
        height: theme.spacing(5),
        justifyContent: 'center',
    },
}));

export const IconRounded = ({
    className,
    icon,
    iconClassName,
    ...props
}: Props) => {
    const styles = useStyles();

    const Icon = icon;

    return (
        <Box className={cx(styles.iconOverlay, className)} {...props}>
            <Icon className={cx(styles.icon, iconClassName)} />
        </Box>
    );
};
