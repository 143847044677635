import React from 'react';
import { makeStyles, Typography, Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import cx from 'classnames';

import { Colors } from '../../../styles/colors';

type IProps = TypographyProps & {
    error?: boolean;
    warning?: boolean;
    info?: boolean;
};

const useStyles = makeStyles<Theme>((theme) => ({
    statusMessageSuccess: {
        color: theme.palette.success.main,
    },
    statusMessageError: {
        color: Colors.VALENCIA,
    },
    statusMessageWarning: {
        color: Colors.GOLD_DROP,
    },
}));

export const StatusMessage = ({
    children,
    className,
    error = false,
    warning = false,
    info = false,
    ...props
}: IProps) => {
    const styles = useStyles();

    const statusMessageClass = cx(className, {
        [styles.statusMessageSuccess]: !error && !warning && !info,
        [styles.statusMessageError]: error,
        [styles.statusMessageWarning]: warning,
    });

    return (
        <Typography className={statusMessageClass} {...props}>
            {children}
        </Typography>
    );
};
