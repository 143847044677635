import { createReducer } from 'typesafe-actions';

import { setResetPasswordStatus } from './reset-password-page.actions';

export enum ResetPasswordStatus {
    MAIL_SEND_SUCCEEDED = 'MAIL_SEND_SUCCEEDED',
    PASSWORD_SET_SUCCEEDED = 'PASSWORD_SET_SUCCEEDED',
}

export interface IResetPasswordStore {
    resetPasswordStatus: ResetPasswordStatus | null;
}

const initialState: IResetPasswordStore = {
    resetPasswordStatus: null,
};

type Actions = ReturnType<typeof setResetPasswordStatus>;

export const resetPasswordReducer = createReducer<IResetPasswordStore, Actions>(
    initialState,
).handleAction(setResetPasswordStatus, (state, action) => ({
    ...state,
    resetPasswordStatus: action.payload,
}));
