import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { updateMemorialPageCreationStepApiActions } from '../memorial-page-creation-step-api.actions';

import { updateMemorialCreationStepApiSaga } from './update-memorial-creation-step-api.saga';

export function* memorialCreationStepApiSaga() {
    yield takeEvery(
        getType(updateMemorialPageCreationStepApiActions.request),
        updateMemorialCreationStepApiSaga,
    );
}
