import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextSelectors } from './model/selectors/context.selector';
import { fetchContextsApiActions } from './model/saga/context.actions';

export const useContextState = () => {
    const dispatch = useDispatch();
    const contextItems = useSelector(ContextSelectors.getContexts);

    useEffect(() => {
        dispatch(fetchContextsApiActions.request());
    }, [dispatch]);

    return {
        contextItems,
    };
};
