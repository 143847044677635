import { createAction, createAsyncAction } from 'typesafe-actions';

import { IMilestone } from '../../interfaces/milestone';

import { IMilestoneFormValues } from './milestone-form-values';

export const fetchMilestonesAsyncActions = createAsyncAction(
    'milestones/MILESTONES_FETCH_REQUESTED',
    'milestones/MILESTONES_FETCH_SUCCEED',
    'milestones/MILESTONES_FETCH_FAILED',
)<undefined, IMilestone[], string>();

export const createMilestoneFormSubmitted = createAction(
    'milestones/CREATE_MILESTONE_FORM_SUBMITTED',
    (action) => (payload: { milestone: IMilestoneFormValues }) =>
        action(payload),
);

export const updateMilestoneFormSubmitted = createAction(
    'milestones/UPDATE_MILESTONE_FORM_SUBMITTED',
    (action) => (payload: { milestone: IMilestoneFormValues; id: string }) =>
        action(payload),
);

export const createMilestoneAsyncActions = createAsyncAction(
    'milestones/CREATE_MILESTONE_REQUESTED',
    'milestones/CREATE_MILESTONE_SUCCEED',
    'milestones/CREATE_MILESTONE_FAILED',
)<{ milestone: IMilestoneFormValues }, IMilestone, string>();

export const updateMilestoneAsyncActions = createAsyncAction(
    'milestones/UPDATE_MILESTONE_REQUESTED',
    'milestones/UPDATE_MILESTONE_SUCCEED',
    'milestones/UPDATE_MILESTONE_FAILED',
)<
    {
        milestone: IMilestoneFormValues;
        id: string;
    },
    IMilestone,
    string
>();

export const deleteMilestoneAsyncActions = createAsyncAction(
    'milestones/DELETE_MILESTONE_REQUESTED',
    'milestones/DELETE_MILESTONE_SUCCEED',
    'milestones/DELETE_MILESTONE_FAILED',
)<{ id: string }, string, string>();

export const milestoneCreationRequested = createAction(
    'milestones/MILESTONE_CREATION_REQUESTED',
);

export const closeMilestoneCreateModalRequested = createAction(
    'milestones/CLOSE_MILESTONE_CREATE_MODAL_REQUESTED',
);

export const milestoneUpdateRequested = createAction(
    'milestones/MILESTONE_UPDATE_REQUESTED',
    (action) => (payload: { editedMilestone: IMilestone }) => action(payload),
);

export const closeMilestoneUpdateModalRequested = createAction(
    'milestones/CLOSE_MILESTONE_UPDATE_MODAL_REQUESTED',
);
