import React from 'react';
import { compose } from 'recompose';
import { get, isEmpty } from 'lodash';
import { FormikProps, withFormik } from 'formik';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SectionHeadline } from '../../../../components/section-headline/section-headline';
import { FormDoubleInputsContainer } from '../../../../components/form-double-inputs-container/form-double-inputs-container';
import { FormTextField } from '../../../../components/form-text-field/form-text-field';
import { SecondaryButton } from '../../../../components/secondary-button/secondary-button';
import { ContactsList } from '../contacts-list/contacts-list';
import { PrimaryButton } from '../../../../components/primary-button/primary-button';
import { StatusMessage } from '../../../../components/status-message/status-message';
import { createShouldShowError } from '../../../../utils/should-show-error';
import { FieldErrorMessage } from '../../../../components/field-error-message/field-error-message';
import { PhoneMessagePreview } from '../../../../components/phone-message-preview/phone-message-preview';
import { useIsMobile } from '../../../../../styles/use-is-mobile';
import { useBreakpoint } from '../../../../../styles/use-breakpoint';
import { IContact, ICreatedContact } from '../../../../interfaces/contact';
import { IDictionaryEntry } from '../../../../interfaces/dictionary-entry';
import { ContactSuggestionStatus } from '../../../../model/notification-contacts/notification-contacts.store';
import { PhoneInput } from '../../../../components/phone-input/phone-input';
import { contactFormValidation } from '../../../../editor/notifications/contacts/contact-form/contact-form-validation';

import { wizardNotificationsFormValidation } from './wizard-notifications-form-validation';
import {
    IWizardNotificationsFormPayload,
    IWizardNotificationsFormValues,
} from './wizard-notifications-form-values';

type OuterProps = {
    onSubmit(values: IWizardNotificationsFormPayload): unknown;
    onSkipRequested(): unknown;
    onMessageChanged?(newValue: string): unknown;
    onContactAdded?(contact: IContact): unknown;
    contacts: ICreatedContact[];
    examples: IDictionaryEntry[];
    suggestionStatus?: ContactSuggestionStatus | null;
    statusMessage?: string | null;
    notificationStatusMessage?: string | null;
};

export type Props = FormikProps<IWizardNotificationsFormValues> & OuterProps;

const useStyles = makeStyles((theme) => ({
    control: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    sectionHeadline: {
        marginBottom: theme.spacing(3),
    },
    section: {
        marginBottom: theme.spacing(6),
    },
    statusMessage: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(-1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(0),
            marginTop: theme.spacing(-3),
        },
    },
    button: {
        position: 'static',
    },
}));

export const WizardNotificationsForm = ({
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    setValues,
    errors,
    setFieldError,
    touched,
    onSkipRequested,
    onMessageChanged,
    onContactAdded,
    contacts,
    examples,
    suggestionStatus,
    statusMessage,
    setFieldValue,
    notificationStatusMessage,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const shouldShowError = createShouldShowError(errors, touched);
    const mobile = useIsMobile();
    const xs = useBreakpoint('xs');

    const onChange = (e: any) => {
        handleChange(e);
        if (onMessageChanged) {
            onMessageChanged(e.target.value);
        }
    };

    const onContactAddRequest = () => {
        if (values.newContact.name.length === 0) {
            setFieldError(
                'newContact.name',
                t('notification.provideContactName'),
            );
            return;
        }

        if (onContactAdded) {
            onContactAdded({
                name: values.newContact.name,
                phone: values.newContact.phone,
                email: values.newContact.email,
            });
        }

        setValues({
            ...values,
            newContact: {
                name: '',
                email: '',
                phone: '',
            },
        });
    };

    return (
        <form
            data-testid="wizard-notification-step-form"
            onSubmit={handleSubmit}
        >
            <SectionHeadline className={styles.sectionHeadline}>
                {t('notification.messageLabel')}
            </SectionHeadline>
            <FormDoubleInputsContainer className={styles.section}>
                <div>
                    <FormControl
                        data-testid="wizard-notification-step-message-text"
                        error={shouldShowError('message')}
                        fullWidth
                        className={styles.control}
                    >
                        <FormTextField
                            rows={4}
                            error={shouldShowError('message')}
                            multiline
                            name="message"
                            placeholder={t('notification.messagePlaceholder')}
                            onChange={onChange}
                            value={values.message}
                            onBlur={handleBlur}
                        />
                        <FieldErrorMessage name="message" />
                    </FormControl>
                    {notificationStatusMessage && (
                        <StatusMessage
                            data-testid="wizard-notification-message-status"
                            className={styles.statusMessage}
                            error
                        >
                            {notificationStatusMessage}
                        </StatusMessage>
                    )}
                    <FormControl
                        data-testid="wizard-notification-step-message-select"
                        variant="outlined"
                        fullWidth
                        className={styles.control}
                    >
                        <Select
                            onChange={handleChange}
                            name="message"
                            value=""
                            fullWidth
                        >
                            {examples.map((entry) => (
                                <MenuItem key={entry.id} value={entry.value}>
                                    {entry.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <PhoneMessagePreview message={values.message} />
            </FormDoubleInputsContainer>
            <SectionHeadline className={styles.sectionHeadline}>
                {t('notification.contactsHeadline')}
            </SectionHeadline>

            <Box className={styles.section}>
                <FormControl
                    data-testid="wizard-notification-step-contact-name"
                    fullWidth
                    className={styles.control}
                    error={Boolean(get(errors, 'newContact.name'))}
                >
                    <FormTextField
                        error={Boolean(get(errors, 'newContact.name'))}
                        name="newContact.name"
                        value={values.newContact.name}
                        onChange={handleChange}
                        label={t('notification.firstAndLastNameLabel')}
                        onBlur={handleBlur}
                    />
                    {get(errors, 'newContact.name') && (
                        <FormHelperText>
                            {errors.newContact!.name}
                        </FormHelperText>
                    )}
                </FormControl>

                <FormDoubleInputsContainer spacing={xs ? 0 : 3}>
                    <FormControl
                        data-testid="wizard-notification-step-contact-email"
                        className={styles.control}
                        fullWidth
                        error={shouldShowError('newContact.email' as any)}
                    >
                        <FormTextField
                            error={shouldShowError('newContact.email' as any)}
                            name="newContact.email"
                            value={values.newContact.email}
                            onChange={handleChange}
                            label={t('notification.emailLabel')}
                            onBlur={handleBlur}
                        />
                        <FieldErrorMessage
                            data-testid="wizard-notification-step-contact-email-error"
                            name="newContact.email"
                        />
                    </FormControl>
                    <FormControl
                        data-testid="wizard-notification-step-contact-phone"
                        fullWidth
                        className={styles.control}
                        error={shouldShowError('newContact.phone' as any)}
                    >
                        <PhoneInput
                            error={shouldShowError('newContact.phone' as any)}
                            name="newContact.phone"
                            value={values.newContact.phone}
                            onChange={(phone: string) => {
                                setFieldValue('newContact.phone', phone);
                            }}
                            label={t('notification.phoneNumberLabel')}
                            onBlur={(event) => {
                                handleBlur(event);

                                contactFormValidation()
                                    .validateAt('phone', values.newContact)
                                    .then((phoneValue: any) => {
                                        if (!phoneValue.length) {
                                            setFieldValue('phone', '');
                                        }
                                    });
                            }}
                        />
                    </FormControl>
                </FormDoubleInputsContainer>
                {statusMessage && (
                    <StatusMessage
                        data-testid="wizard-notification-step-contact-add-status"
                        className={styles.statusMessage}
                        error={
                            suggestionStatus !== ContactSuggestionStatus.SUCCESS
                        }
                    >
                        {statusMessage}
                    </StatusMessage>
                )}
                <Grid
                    container
                    justify={xs ? 'flex-start' : 'flex-end'}
                    direction={xs ? 'column-reverse' : 'row'}
                    spacing={2}
                >
                    {contacts.length === 0 && (
                        <Grid xs={12} sm="auto" item>
                            <Button
                                color="primary"
                                data-testid="wizard-notifications-form-skip-button"
                                fullWidth={xs}
                                onClick={onSkipRequested}
                                className={styles.button}
                            >
                                {t('common.skipThisStep')}
                            </Button>
                        </Grid>
                    )}
                    <Grid xs={12} sm="auto" item>
                        <SecondaryButton
                            data-testid="wizard-notifications-form-add-contact-button"
                            disabled={!isEmpty(errors.newContact)}
                            fullWidth={xs}
                            onClick={onContactAddRequest}
                            type="button"
                            className={styles.button}
                        >
                            {t('notification.addContactButton')}
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Box>

            {contacts.length > 0 && (
                <>
                    <Box mb={3}>
                        <SectionHeadline className={styles.sectionHeadline}>
                            {t('notification.contactList')}
                        </SectionHeadline>
                        <ContactsList contacts={contacts} />
                    </Box>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid
                            item
                            style={{
                                order: mobile ? 1 : undefined,
                            }}
                            xs={mobile ? 12 : 'auto'}
                        >
                            <Button
                                size="large"
                                color="primary"
                                onClick={onSkipRequested}
                                fullWidth={mobile}
                                data-testid="wizard-notifications-form-skip-button"
                            >
                                {t('common.skipThisStep')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={mobile ? 12 : 'auto'}
                            style={{
                                order: mobile ? 0 : undefined,
                            }}
                        >
                            <PrimaryButton
                                data-testid="wizard-notifications-contact-send-message"
                                type="submit"
                                fullWidth={mobile}
                            >
                                {t('notification.sendNotificationButton')}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </>
            )}
        </form>
    );
};

export const ComposedWizardNotificationsForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IWizardNotificationsFormValues>({
        handleSubmit(values, bag) {
            bag.props.onSubmit({
                message: values.message,
            });
        },
        validationSchema: () => wizardNotificationsFormValidation(),
        mapPropsToValues() {
            return {
                message: '',
                newContact: {
                    email: '',
                    name: '',
                    phone: '',
                },
            };
        },
    }),
)(WizardNotificationsForm);
