import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    aboutDeceasedFormSubmitted,
    wizardSkipRequested,
    wizardRestartRequested,
} from '../wizard-about-deceased.actions';

import { submitAboutDeceasedFormSaga } from './submit-about-deceased-form.saga';
import { wizardSkipSaga } from './skip-wizard.saga';
import { wizardRestartSaga } from './restart-wizard.saga';

export function* wizardDeceasedSaga() {
    yield takeEvery(
        getType(aboutDeceasedFormSubmitted),
        submitAboutDeceasedFormSaga,
    );
    yield takeEvery(getType(wizardSkipRequested), wizardSkipSaga);
    yield takeEvery(getType(wizardRestartRequested), wizardRestartSaga);
}
