import { apiService } from '../../api.service';
import { InvitationDto } from '../dto/invitation.dto';

export const fetchInvitations = ({
    slug,
}: {
    slug: string;
}): Promise<InvitationDto[]> =>
    apiService
        .get(`/memorial_pages/${slug}/invitations`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
