import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOverviewTasksAsyncActions } from './model/overview-tasks.actions';
import { getOverviewTasks } from './model/selectors/get-overview-tasks.selector';

export const useOverviewTasks = () => {
    const dispatch = useDispatch();
    const tasks = useSelector(getOverviewTasks);

    useEffect(() => {
        dispatch(getOverviewTasksAsyncActions.request());
    }, [dispatch]);

    return {
        tasks,
    };
};
