import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
    changePagePublishedStatusRequested,
    changeSettingsRequested,
    changePrivacyModeRequested,
} from '../settings.actions';

import { updatePlacesVisibilitySaga } from './update-places-visibility.saga';
import { updatePagePrivacySaga } from './update-page-privacy.saga';
import { updatePagePublishedStatusSaga } from './update-page-published-status.saga';

export function* settingsSaga() {
    yield takeEvery(
        getType(changeSettingsRequested),
        updatePlacesVisibilitySaga,
    );
    yield takeEvery(getType(changePrivacyModeRequested), updatePagePrivacySaga);
    yield takeEvery(
        getType(changePagePublishedStatusRequested),
        updatePagePublishedStatusSaga,
    );
}
