import React from 'react';
import { TableProps } from '@material-ui/core/Table';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

type Props = TableProps & {
    contacts: Array<{
        name: string;
        phone: string | null;
        email: string | null;
    }>;
};

export const ContactsList = ({ contacts, ...props }: Props) => {
    return (
        <Table {...props} data-testid="contact-list-table">
            <TableBody>
                {contacts.map((contact) => (
                    <TableRow key={contact.name + contact.email}>
                        <TableCell data-testid="contact-name">
                            {contact.name}
                        </TableCell>
                        <TableCell data-testid="contact-email">
                            {contact.email}
                        </TableCell>
                        <TableCell data-testid="contact-phone">
                            {contact.phone}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
