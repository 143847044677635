import { combineReducers } from 'redux';

import { eventsReducer, IEventsStore } from '../events/model/events.store';
import {
    ISettingsStore,
    settingsReducer,
} from '../settings/model/settings.store';
import {
    IParticipantsStore,
    participantsReducer,
} from '../model/participants/participants.store';

import {
    IPhotosStore,
    photosReducer,
} from './overview/photos-section/model/photos.store';
import {
    contactsReducer,
    IContactsStore,
} from './notifications/contacts/model/contacts.store';
import {
    IOverviewEventsStore,
    overviewEventsReducer,
} from './overview/overview-events-section/model/overview-events.store';
import {
    IOverviewTasksStore,
    overviewTasksReducer,
} from './overview/overview-tasks-section/model/overview-tasks.store';

export interface IEditorStore {
    events: IEventsStore;
    photos: IPhotosStore;
    settings: ISettingsStore;
    contacts: IContactsStore;
    overviewEvents: IOverviewEventsStore;
    overviewTasks: IOverviewTasksStore;
    paricipants: IParticipantsStore;
}

/**
 * TODO: Move them to root reducers
 */
export const editorStore = combineReducers<IEditorStore>({
    events: eventsReducer,
    photos: photosReducer,
    settings: settingsReducer,
    contacts: contactsReducer,
    overviewEvents: overviewEventsReducer,
    overviewTasks: overviewTasksReducer,
    paricipants: participantsReducer,
});
