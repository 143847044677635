import React from 'react';
import { makeStyles, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../../components/section/section';

import { EventTableRow } from './event-table-row/event-table-row';
import { useOverviewEvents } from './use-overview-events';

interface IOverviewEventsSectionProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: theme.spacing(2),
    },
}));

export const OverviewEventsSection = (props: IOverviewEventsSectionProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { events } = useOverviewEvents();

    if (!events.length) {
        return null;
    }

    return (
        <Section title={t('overviewEventsSection.title')} {...props}>
            <Table className={styles.table}>
                <TableBody>
                    {events.map((event) => (
                        <EventTableRow key={event.id} event={event} />
                    ))}
                </TableBody>
            </Table>
        </Section>
    );
};
