import { createReducer } from 'typesafe-actions';

import { PrivacyMode } from '../../interfaces/privacy-mode';

import {
    changePagePublishedStatusSucceed,
    changeSettingsDataSucceed,
    changePrivacyModeSucceed,
    pagePublishedStatusRetrieved,
    pagePrivacyModeRetrieved,
    pageSettingsDataRetrieved,
} from './settings.actions';

export interface ISettingsStore {
    showPlaceOfBirthEnabled: boolean;
    showPlaceOfDeathEnabled: boolean;
    privacyMode: PrivacyMode;
    published: boolean;
    imageUploadingForCondolencesEnabled: boolean;
}

const initialState: ISettingsStore = {
    showPlaceOfBirthEnabled: false,
    showPlaceOfDeathEnabled: false,
    imageUploadingForCondolencesEnabled: false,
    privacyMode: PrivacyMode.PRIVATE,
    published: true,
};

type Actions =
    | ReturnType<typeof changeSettingsDataSucceed>
    | ReturnType<typeof changePrivacyModeSucceed>
    | ReturnType<typeof changePagePublishedStatusSucceed>
    | ReturnType<typeof pagePublishedStatusRetrieved>
    | ReturnType<typeof pagePrivacyModeRetrieved>
    | ReturnType<typeof pageSettingsDataRetrieved>;

export const settingsReducer = createReducer<ISettingsStore, Actions>(
    initialState,
)
    .handleAction(
        [changeSettingsDataSucceed, pageSettingsDataRetrieved],
        (state, { payload: { birth, death, images } }) => {
            const newState = { ...state };

            if (typeof birth !== 'undefined') {
                newState.showPlaceOfBirthEnabled = birth;
            }

            if (typeof death !== 'undefined') {
                newState.showPlaceOfDeathEnabled = death;
            }

            if (typeof images !== 'undefined') {
                newState.imageUploadingForCondolencesEnabled = images;
            }

            return newState;
        },
    )
    .handleAction(
        [changePrivacyModeSucceed, pagePrivacyModeRetrieved],
        (state, action) => ({
            ...state,
            privacyMode: action.payload,
        }),
    )
    .handleAction(
        [changePagePublishedStatusSucceed, pagePublishedStatusRetrieved],
        (state, action) => ({
            ...state,
            published: action.payload.published,
        }),
    );
