import { call, put, select } from 'redux-saga/effects';

import { avatarPhotoUploadActions } from '../photos.actions';
import { sendDeceasedPhotoRefToBackendActions } from '../../../../../api/background-photos/background-photos.actions';
import { getImageUploadSession } from '../../../../../api/image-upload/http/get-image-upload-session';
import { IImageUploadSessionResponseDto } from '../../../../../api/image-upload/dto/image-upload-session-response.dto';
import { uploadFileToS3 } from '../../../../../api/image-upload/http/upload-file-to-s3';
import { sendAvatarReferenceToApi } from '../../../../../api/avatar/http/send-avatar-reference-to-api';
import { IConfirmImageOnServerDto } from '../../../../../api/image-upload/dto/confirm-image-on-server.dto';
import { getPageSlug } from '../../../../../meta/model/selectors/get-page-slug.selector';

export function* sendAvatarPhotoSaga(
    action: ReturnType<typeof avatarPhotoUploadActions.request>,
) {
    const { avatar } = action.payload;
    const slug = yield select(getPageSlug);

    if (!avatar) {
        return;
    }

    try {
        const sessionData: IImageUploadSessionResponseDto = yield call(
            getImageUploadSession,
            avatar,
        );
        const imageRef: IConfirmImageOnServerDto = yield call(
            uploadFileToS3,
            sessionData,
            avatar,
        );

        yield call(sendAvatarReferenceToApi, slug, imageRef);
        yield put(sendDeceasedPhotoRefToBackendActions.request());

        yield put(sendDeceasedPhotoRefToBackendActions.success());
    } catch (err) {
        yield put(sendDeceasedPhotoRefToBackendActions.failure(err));
    }
}
