import { useDispatch, useSelector } from 'react-redux';

import { useNotificationMessage } from '../../../model/notification-message/use-notification-message';
import { getNotificationMessageStatus } from '../../../model/notification-message/selectors/get-notification-message-status.selector';
import { getNotificationMessageStatusMessage } from '../../../model/notification-message/selectors/get-notification-message-status-message.selector';

import { notificationMessageUpdated } from './model/message.actions';

export const useEditorNotificationMessage = () => {
    const { notificationExamples, notificationText } = useNotificationMessage();
    const dispatch = useDispatch();
    const notificationMessageStatus = useSelector(getNotificationMessageStatus);
    const notificationMessageStatusMessage = useSelector(
        getNotificationMessageStatusMessage,
    );

    return {
        notificationText,
        notificationExamples,
        notificationMessageUpdated(newMessage: string) {
            dispatch(notificationMessageUpdated({ message: newMessage }));
        },
        notificationMessageStatus,
        notificationMessageStatusMessage,
    };
};
