export const defaultMapConfig = {
    mapHeight: '320px',
    fullWidth: true,
    label: 'ceremonyAndReception.address',
    placeholder: 'ceremonyAndReception.address',
    draggable: true,
    zoom: 12,
    mapId: 'map',
    defaultLat: 52.41,
    defaultLng: 22.03,
};
