import { put, take, delay } from 'redux-saga/effects';

import { notificationMessageUpdated } from '../message.actions';
import { updateNotificationMessageApiActions } from '../../../../../api/contacts/contacts-api.actions';
import { setNotificationMessageStatus } from '../../../../../model/notification-message/notification-messages-model.actions';

export function* updateEditorNotificationMessageSaga(
    action: ReturnType<typeof notificationMessageUpdated>,
) {
    yield put(
        updateNotificationMessageApiActions.request({
            message: action.payload.message,
        }),
    );

    const error = yield take(updateNotificationMessageApiActions.failure);

    yield put(setNotificationMessageStatus(error.payload.status));
    yield delay(3000);
    yield put(setNotificationMessageStatus(null));
}
