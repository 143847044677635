import { get } from 'lodash';

import { ICreatedContact } from '../../../interfaces/contact';
import { IContactResponseDto } from '../dto/contact-response.dto';
import { NotificationContactStatus } from '../../../interfaces/notification-contact-status';

/**
 * If we need to display phone country code from api, map it here
 */
export const mapContactDtoToContact = (
    contactResponse: IContactResponseDto,
): ICreatedContact => {
    const isSuggested =
        contactResponse.attributes.status ===
        NotificationContactStatus.SUGGESTED;

    return {
        email: contactResponse.attributes.email,
        name: contactResponse.attributes.displayName,
        phone: contactResponse.attributes.phone,
        id: contactResponse.id,
        status: contactResponse.attributes.status,
        suggestionID: isSuggested
            ? get(contactResponse, 'relationships.suggestion.data.id')
            : undefined,
        isAdmin: contactResponse.attributes.isAdmin,
    };
};
