import { select } from 'redux-saga/effects';

import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { sharePageOnFacebook } from '../facebook/share-page-on-facebook';
import { getMemorialBaseUrl } from '../../utils/url-resolvers';

export function* facebookSharingSaga() {
    const slug = yield select(getPageSlug);

    const href = `${getMemorialBaseUrl(window.location.toString())}/${slug}`;

    sharePageOnFacebook(href);
}
