import { put, take } from 'redux-saga/effects';

import {
    changeSettingsRequested,
    changeSettingsDataSucceed,
} from '../settings.actions';
import { updateSettingsApiActions } from '../../../api/settings/settings.actions';

export function* updatePlacesVisibilitySaga(
    action: ReturnType<typeof changeSettingsRequested>,
) {
    const { birth, death, images } = action.payload;

    if (typeof birth !== 'undefined') {
        yield put(
            updateSettingsApiActions.request({
                birthPlaceVisible: birth,
            }),
        );
    } else if (typeof death !== 'undefined') {
        yield put(
            updateSettingsApiActions.request({
                deathPlaceVisible: death,
            }),
        );
    } else if (typeof images !== 'undefined') {
        yield put(
            updateSettingsApiActions.request({
                imageUploadingForCondolencesEnabled: images,
            }),
        );
    }

    const newSettings: ReturnType<
        typeof updateSettingsApiActions.success
    > = yield take(updateSettingsApiActions.success);

    yield put(
        changeSettingsDataSucceed({
            death: newSettings.payload.deathPlaceVisible,
            birth: newSettings.payload.birthPlaceVisible,
            images: newSettings.payload.imageUploadingForCondolencesEnabled,
        }),
    );
}
