import { createAction, createAsyncAction } from 'typesafe-actions';

import { ITask, ITaskNotYetCreated } from '../../interfaces/task';

export const fetchTasksAsyncActions = createAsyncAction(
    'editor/tasks/TASKS_FETCH_REQUESTED',
    'editor/tasks/TASKS_FETCH_SUCCEED',
    'editor/tasks/TASKS_FETCH_FAILED',
)<undefined, Array<ITask>, string>();

export const createTaskFormSubmitted = createAction(
    'editor/tasks/CREATE_TASK_FORM_SUBMITTED',
    (action) => (payload: { task: ITaskNotYetCreated }) => action(payload),
);

export const updateTaskFormSubmitted = createAction(
    'editor/tasks/UPDATE_TASK_FORM_SUBMITTED',
    (action) => (payload: { task: ITask; id: string }) => action(payload),
);

export const createTaskAsyncActions = createAsyncAction(
    'editor/tasks/CREATE_TASK_REQUESTED',
    'editor/tasks/CREATE_TASK_SUCCEED',
    'editor/tasks/CREATE_TASK_FAILED',
)<{ task: ITask }, { task: ITask }, string>();

export const updateTaskAsyncActions = createAsyncAction(
    'editor/tasks/UPDATE_TASK_REQUESTED',
    'editor/tasks/UPDATE_TASK_SUCCEED',
    'editor/tasks/UPDATE_TASK_FAILED',
)<
    {
        task: ITask;
        id: string;
    },
    { task: ITask },
    string
>();

export const deleteTaskAsyncActions = createAsyncAction(
    'editor/tasks/DELETE_TASK_REQUESTED',
    'editor/tasks/DELETE_TASK_SUCCEED',
    'editor/tasks/DELETE_TASK_FAILED',
)<{ id: string }, { id: string }, string>();

export const taskCreationRequested = createAction(
    'editor/tasks/TASK_CREATION_REQUESTED',
);

export const closeTaskCreateModalRequested = createAction(
    'editor/tasks/CLOSE_TASK_CREATE_MODAL_REQUESTED',
);

export const taskUpdateRequested = createAction(
    'editor/tasks/TASK_UPDATE_REQUESTED',
    (action) => (payload: { editedTask: ITask }) => action(payload),
);

export const closeTaskUpdateModalRequested = createAction(
    'editor/tasks/CLOSE_TASK_UPDATE_MODAL_REQUESTED',
);
