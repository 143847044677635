import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { SectionHeadline } from '../../components/section-headline/section-headline';
import { IconRounded } from '../../components/icon-rounded/icon-rounded';
import { Colors } from '../../../styles/colors';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { IMilestone } from '../../interfaces/milestone';
import { useDateFormatter } from '../../utils/use-date-formatter';

interface IMilestoneProps {
    last?: boolean;
    onDelete: (id: string) => unknown;
    onEdit: () => unknown;
    milestone: IMilestone;
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: Colors.ATHENS_GRAY,
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2.5, 3),
        position: 'relative',
    },
    date: {
        color: theme.palette.primary.light,
        marginRight: theme.spacing(4),
    },
    title: {
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(4),
            marginTop: theme.spacing(2),
        },
    },
    description: {
        borderTop: `1px solid ${Colors.IRON}`,
        color: Colors.MINE_SHAFT,
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(15.5),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    line: {
        backgroundColor: theme.palette.primary.light,
        height: `calc(100% - ${theme.spacing(2)}px)`,
        left: `calc(${theme.spacing(3.5)}px - 1px)`,
        position: 'absolute',
        top: theme.spacing(7.5),
        width: '1px',
        zIndex: 1,
    },
    icon: {
        color: theme.palette.primary.light,
    },
}));

export const Milestone = ({
    last = false,
    onDelete,
    onEdit,
    milestone,
}: IMilestoneProps) => {
    const styles = useStyles();
    const mobile = useIsMobile();
    const { getMonthYearDate } = useDateFormatter();

    const renderActions = () => (
        <>
            <IconRounded
                className={styles.icon}
                icon={EditIcon}
                onClick={onEdit}
            />
            <IconRounded
                icon={DeleteIcon}
                onClick={() => onDelete(milestone.id)}
                ml={3}
            />
        </>
    );

    return (
        <Box
            data-testid="timeline-milestone-container"
            className={styles.container}
        >
            {!last && <Box className={styles.line} />}
            <Box display="flex" justifyContent="space-between">
                <Box
                    display="flex"
                    alignItems="center"
                    flexWrap={mobile ? 'wrap' : undefined}
                >
                    <SectionHeadline
                        data-testid="milestone-date"
                        className={styles.date}
                    >
                        {getMonthYearDate(milestone.year, milestone.month)}
                    </SectionHeadline>
                    <Typography
                        data-testid="milestone-title"
                        className={styles.title}
                    >
                        {milestone.title}
                    </Typography>
                </Box>
                {!mobile && (
                    <Box data-testid="milestone-edit-delete" display="flex">
                        {renderActions()}
                    </Box>
                )}
            </Box>
            {milestone.description && (
                <Typography
                    data-testid="milestone-description"
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: milestone.description,
                    }}
                />
            )}
            {mobile && (
                <Box display="flex" justifyContent="flex-end" mt={4}>
                    {renderActions()}
                </Box>
            )}
        </Box>
    );
};
