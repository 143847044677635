import { compose, defaultProps } from 'recompose';
import { Typography, withStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

export const BlueHeadline = compose<TypographyProps, TypographyProps>(
    withStyles((theme) => ({
        root: {
            color: theme.palette.primary.light,
            fontSize: '2.3rem',
            fontWeight: 'normal',
        },
    })),
    defaultProps({
        variant: 'h1',
    }),
)(Typography);
