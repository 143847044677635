import { createReducer } from 'typesafe-actions';

import {
    tributeContentReceived,
    setUpdateTributeStatus,
} from './tribute-model.actions';

export enum UpdateTributeStatus {
    SUCCESS,
    INAPPROPRIATE_WORDS = 'INAPPROPRIATE_WORDS',
}

export interface ITributeModelStore {
    content: string;
    updateTributeStatus: UpdateTributeStatus | null;
}

const initialState: ITributeModelStore = {
    content: '',
    updateTributeStatus: null,
};

type Actions =
    | ReturnType<typeof tributeContentReceived>
    | ReturnType<typeof setUpdateTributeStatus>;

export const tributeModelReducer = createReducer<ITributeModelStore, Actions>(
    initialState,
)
    .handleAction(tributeContentReceived, (state, action) => ({
        ...state,
        content: action.payload.content,
    }))
    .handleAction(setUpdateTributeStatus, (state, action) => ({
        ...state,
        updateTributeStatus: action.payload,
    }));
