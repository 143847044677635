import { createAction, createAsyncAction } from 'typesafe-actions';

import { IMemorialEvent } from '../../interfaces/memorial-event';
import { IEventFormData } from '../event-form-data';

export const editorGetEventsAsyncActions = createAsyncAction(
    'editor/events/GET_EVENTS_REQUESTED',
    'editor/events/GET_EVENTS_SUCCEED',
    'editor/events/GET_EVENTS_FAILED',
)<undefined, IMemorialEvent[], string>();

export const createEventFormSubmitted = createAction(
    'editor/events/CREATE_EVENT_FORM_SUBMITTED',
    (action) => (payload: { event: IEventFormData }) => action(payload),
);

export const updateEventFormSubmitted = createAction(
    'editor/events/UPDATE_EVENT_FORM_SUBMITTED',
    (action) => (payload: { event: IEventFormData; id: string }) =>
        action(payload),
);

export const createEventInEditorSucceed = createAction(
    'editor/events/CREATE_EVENT_SUCCEED',
    (action) => (payload: { event: IMemorialEvent }) => action(payload),
);

export const createEventInEditorFailed = createAction(
    'editor/events/CREATE_EVENT_FAILED',
    (action) => (errorMessage: string) => action(errorMessage),
);

export const updateEventAsyncActions = createAsyncAction(
    'editor/events/UPDATE_EVENT_REQUESTED',
    'editor/events/UPDATE_EVENT_SUCCEED',
    'editor/events/UPDATE_EVENT_FAILED',
)<
    {
        event: IEventFormData;
        id: string;
    },
    { event: IMemorialEvent },
    string
>();

export const deleteEventAsyncActions = createAsyncAction(
    'editor/events/DELETE_EVENT_REQUESTED',
    'editor/events/DELETE_EVENT_SUCCEED',
    'editor/events/DELETE_EVENT_FAILED',
)<{ id: string }, string, string>();

export const eventCreationRequested = createAction(
    'editor/events/EVENT_CREATION_REQUESTED',
);

export const closeEventCreateModalRequested = createAction(
    'editor/events/CLOSE_EVENT_CREATE_MODAL_REQUESTED',
);

export const eventUpdateRequested = createAction(
    'editor/events/EVENT_UPDATE_REQUESTED',
    (action) => (payload: { editedEvent: IMemorialEvent }) => action(payload),
);

export const closeEventUpdateModalRequested = createAction(
    'editor/events/CLOSE_EVENT_UPDATE_MODAL_REQUESTED',
);
