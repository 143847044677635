import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { logoutRequested } from '../logout.actions';

import { handleLogoutRequestedSaga } from './handle-logout-requested.saga';

export function* logoutRequestedSaga() {
    yield takeEvery(getType(logoutRequested), handleLogoutRequestedSaga);
}
