import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    generateObituaryTextApiActions,
    updateObituaryTextApiActions,
} from '../obituary-api.actions';

import { updateObituaryApiSaga } from './update-obituary-api.saga';
import { generateObituaryApiSaga } from './generate-obituary-api.saga';

export function* obituaryApiSaga() {
    yield takeEvery(
        getType(updateObituaryTextApiActions.request),
        updateObituaryApiSaga,
    );
    yield takeEvery(
        getType(generateObituaryTextApiActions.request),
        generateObituaryApiSaga,
    );
}
