import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ITask, ITaskNotYetCreated } from '../interfaces/task';
import { TasksSelectors } from '../model/tasks/selectors/tasks.selectors';

import {
    closeTaskCreateModalRequested,
    closeTaskUpdateModalRequested,
    createTaskFormSubmitted,
    deleteTaskAsyncActions,
    fetchTasksAsyncActions,
    taskCreationRequested,
    taskUpdateRequested,
    updateTaskFormSubmitted,
} from './model/tasks.actions';
import { TasksSectionSelectors } from './model/selectors/tasks.selector';

export const useTasks = () => {
    const dispatch = useDispatch();
    const tasks = useSelector(TasksSelectors.getTasks);
    const createModalOpen = useSelector(
        TasksSectionSelectors.getCreateModalOpen,
    );
    const updateModalOpen = useSelector(
        TasksSectionSelectors.getUpdateModalOpen,
    );
    const editedTask = useSelector(TasksSectionSelectors.getEditedTask);

    useEffect(() => {
        dispatch(fetchTasksAsyncActions.request());
    }, [dispatch]);

    return {
        createModalOpen,
        updateModalOpen,
        taskCreationRequested() {
            dispatch(taskCreationRequested());
        },
        closeTaskCreateModalRequested() {
            dispatch(closeTaskCreateModalRequested());
        },
        taskUpdateRequested(task: ITask) {
            dispatch(
                taskUpdateRequested({
                    editedTask: task,
                }),
            );
        },
        closeTaskUpdateModalRequested() {
            dispatch(closeTaskUpdateModalRequested());
        },
        tasks,
        editedTask,
        onCreate(values: ITaskNotYetCreated) {
            dispatch(createTaskFormSubmitted({ task: values }));
        },
        onUpdate(values: ITask, id: string) {
            dispatch(updateTaskFormSubmitted({ task: values, id }));
        },
        onDelete(id: string) {
            dispatch(deleteTaskAsyncActions.request({ id }));
        },
    };
};
