import { put, take, delay } from 'redux-saga/effects';

import {
    wizardNotificationsMessageFieldChanged,
    wizardSetNotificationMessageStatus,
} from '../wizard-notifications.actions';
import { updateNotificationMessageApiActions } from '../../../../api/contacts/contacts-api.actions';

export function* wizardUpdateNotificationMessageSaga(
    action: ReturnType<typeof wizardNotificationsMessageFieldChanged>,
) {
    const { message } = action.payload;

    yield put(
        updateNotificationMessageApiActions.request({
            message,
        }),
    );

    const error = yield take(updateNotificationMessageApiActions.failure);

    yield put(wizardSetNotificationMessageStatus(error.payload.status));
    yield delay(3000);
    yield put(wizardSetNotificationMessageStatus(null));
}
