import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import { fetchMilestonesAsyncActions } from '../milestones.actions';
import { fetchMilestonesApiActions } from '../../../api/milestones/milestones-api.actions';

type PossibleAPIResultActions =
    | ReturnType<typeof fetchMilestonesApiActions.success>
    | ReturnType<typeof fetchMilestonesApiActions.failure>;

export function* fetchMilestonesSaga(
    action: ReturnType<typeof fetchMilestonesAsyncActions.request>,
) {
    yield put(fetchMilestonesApiActions.request());

    const result: PossibleAPIResultActions = yield take([
        fetchMilestonesApiActions.success,
        fetchMilestonesApiActions.failure,
    ]);

    switch (result.type) {
        case getType(fetchMilestonesApiActions.success):
            yield put(fetchMilestonesAsyncActions.success(result.payload));
            return;
        case getType(fetchMilestonesApiActions.failure):
            yield put(fetchMilestonesAsyncActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
