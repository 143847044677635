import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../editor/components/page-title/page-title';
import { DangerButton } from '../components/danger-button/danger-button';
import { PrimaryButton } from '../components/primary-button/primary-button';
import { useIsMobile } from '../../styles/use-is-mobile';

import { DisplaySettings } from './display-settings/display-settings';
import { PrivacySettings } from './privacy-settings/privacy-settings';
import { changePagePublishedStatusRequested } from './model/settings.actions';
import { getIsPagePublished } from './model/selectors/get-is-page-published';
import { WizardSettings } from './wizard-settings/wizard-settings';

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(5),
    },
}));

export const SettingsScreen = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    const mobile = useIsMobile();
    const dispatch = useDispatch();
    const published = useSelector(getIsPagePublished);

    const onPublishButtonClicked = () => {
        dispatch(changePagePublishedStatusRequested({ published: !published }));
    };

    return (
        <Box data-testid="settings-page-container" mb={3}>
            <PageTitle>{t('settings.title')}</PageTitle>
            <DisplaySettings className={styles.section} />
            <WizardSettings className={styles.section} />
            <PrivacySettings className={styles.section} />
            <Box display="flex" justifyContent="flex-end" my={3}>
                {!published ? (
                    <PrimaryButton
                        data-testid="memorial-take-online"
                        fullWidth={mobile}
                        onClick={onPublishButtonClicked}
                    >
                        {t('settings.takeThisPageOnlineButton')}
                    </PrimaryButton>
                ) : (
                    <DangerButton
                        data-testid="memorial-take-offline"
                        fullWidth={mobile}
                        onClick={onPublishButtonClicked}
                    >
                        {t('settings.takeThisPageOfflineButton')}
                    </DangerButton>
                )}
            </Box>
        </Box>
    );
};
