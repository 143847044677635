import React from 'react';
import { RouteComponentProps } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StepHeadline } from '../components/step-headline/step-headline';
import { WizardContentContainer } from '../components/wizard-content-container/wizard-content-container';
import { IContact } from '../../interfaces/contact';
import { useNotificationMessage } from '../../model/notification-message/use-notification-message';
import { getContactSuggestionStatus } from '../../model/notification-contacts/selectors/get-contact-suggestion-status.selector';
import { getContactSuggestionStatusMessage } from '../../model/notification-contacts/selectors/get-contact-suggestion-status-message.selector';
import { useWizardStep } from '../use-wizard-step';

import {
    wizardNotificationContactAddActions,
    wizardNotificationsFormSkipRequested,
    wizardNotificationsFormSubmitted,
    wizardNotificationsMessageFieldChanged,
} from './model/wizard-notifications.actions';
import { IWizardNotificationsFormValues } from './components/wizard-notifications-form/wizard-notifications-form-values';
import { ComposedWizardNotificationsForm } from './components/wizard-notifications-form/wizard-notifications-form';
import { getWizardCreatedContacts } from './model/selectors/get-wizard-created-contacts.selector';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
}));

export const WizardNotificationsScreen = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const styles = useStyles();
    const contacts = useSelector(getWizardCreatedContacts);
    const contactSuggestionStatus = useSelector(getContactSuggestionStatus);
    const contactSuggestionStatusMessage = useSelector(
        getContactSuggestionStatusMessage,
    );
    const { notificationExamples, statusMessage } = useNotificationMessage();
    const wizardStepProgress = useWizardStep();

    const onSubmit = (values: IWizardNotificationsFormValues) => {
        dispatch(wizardNotificationsFormSubmitted(values));
    };

    const onSkip = () => {
        dispatch(wizardNotificationsFormSkipRequested());
    };

    const onMessageChanged = (value: string) => {
        dispatch(wizardNotificationsMessageFieldChanged({ message: value }));
    };

    const onContactAdded = (contact: IContact) => {
        dispatch(wizardNotificationContactAddActions.request(contact));
    };

    return (
        <WizardContentContainer>
            <StepHeadline
                data-testid="wizard-notification-step-headline"
                className={styles.headline}
                text={t('notification.headline')}
                stepNo={wizardStepProgress + 3}
            />
            <ComposedWizardNotificationsForm
                onSubmit={onSubmit}
                onSkipRequested={onSkip}
                onMessageChanged={onMessageChanged}
                onContactAdded={onContactAdded}
                contacts={contacts}
                examples={notificationExamples}
                suggestionStatus={contactSuggestionStatus}
                statusMessage={contactSuggestionStatusMessage}
                notificationStatusMessage={statusMessage}
            />
        </WizardContentContainer>
    );
};
