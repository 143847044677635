import { createSelector } from 'reselect';
import i18n from 'i18next';

import { UpdateTributeStatus } from '../tribute.store';

import { getUpdateTributeStatus } from './get-update-tribute-status.selector';

export const getUpdateTributeStatusMessage = createSelector(
    getUpdateTributeStatus,
    (status) => {
        const UpdateTributeStatusMessages = {
            [UpdateTributeStatus.SUCCESS]: i18n.t(
                'updateTributeStatus.success',
            ),
            [UpdateTributeStatus.INAPPROPRIATE_WORDS]: i18n.t(
                'tributeMessageStatus.inappropriateWords',
            ),
        };

        return status !== null ? UpdateTributeStatusMessages[status] : null;
    },
);
