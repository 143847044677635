import { createSelector } from 'reselect';
import i18n from 'i18next';

import { NotificationMessageStatus } from '../wizard-notifications.store';

import { getWizardNotificationMessageStatus } from './get-wizard-notification-message-status.selector';

export const getWizardNotificationMessageStatusMessage = createSelector(
    getWizardNotificationMessageStatus,
    (status) => {
        const NotificationMessageStatusMessage = {
            [NotificationMessageStatus.INAPPROPRIATE_WORDS]: i18n.t(
                'notificationMessageStatus.inappropriateWords',
            ),
        };
        return status ? NotificationMessageStatusMessage[status] : null;
    },
);
