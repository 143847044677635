import React from 'react';
import { compose } from 'recompose';

import { withThemeProvider } from '../styles/with-theme-provider';
import { withRollbarSetup } from '../setup/with-rollbar-setup';

import { withRoutingSetup } from './routing/with-routing-setup';
import { store } from './store/store';
import { withStoreProvider } from './store/with-store-provider';
import { Routing } from './routing/routing';
import { withDatePickersConfiguration } from './utils/with-date-pickers-configuration';
import { withInvitationTokenValidation } from './with-invitation-token-validation';
import { withValidationMessagesTranslation } from './with-validation-messages-translation';

export const App: React.FC = () => {
    return (
        <div>
            <Routing />
        </div>
    );
};

const MuiProviderApp = compose(withDatePickersConfiguration)(App);

export const ComposedApp = compose(
    withRollbarSetup(),
    withValidationMessagesTranslation,
    withStoreProvider(store),
    withThemeProvider(),
    withRoutingSetup(),
    withInvitationTokenValidation,
)(MuiProviderApp);
