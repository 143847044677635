import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { OutlinedButton } from '../../../components/outlined-button/outlined-button';

type IProps = {
    description: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    link?: string;
    title: string;
};

const useStyles = makeStyles((theme) => ({
    description: {
        marginTop: theme.spacing(2),
    },
    sectionTitle: {
        color: theme.palette.primary.light,
        fontSize: '2rem',
    },
    sectionIcon: {
        marginRight: theme.spacing(2),
    },
    sectionBox: {
        alignItems: 'center',
        display: 'flex',
        marginTop: theme.spacing(5),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

export const ServiceSection = ({ description, icon, link, title }: IProps) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const Icon: React.FC<React.SVGProps<SVGSVGElement>> = icon;

    return (
        <>
            <Box
                data-testid={`digital-footprint-${title.toLowerCase()}`}
                className={styles.sectionBox}
            >
                <Icon className={styles.sectionIcon} />
                <Typography className={styles.sectionTitle} variant="h3">
                    {title}
                </Typography>
            </Box>
            <Typography className={styles.description}>
                {description}
            </Typography>
            {link && (
                <OutlinedButton
                    data-testid={`digital-footprint-open-${title.toLowerCase()}`}
                    className={styles.button}
                    onClick={() => window.open(link)}
                >
                    {t('deleteOnlineServices.openFormButton')}
                </OutlinedButton>
            )}
        </>
    );
};
