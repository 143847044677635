import { put, select, call } from 'redux-saga/effects';

import { updateMemorialPageCreationStepApiActions } from '../memorial-page-creation-step-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { sendNewMemorialCreationStep } from '../http/update-creation-step';

export function* updateMemorialCreationStepApiSaga(
    action: ReturnType<typeof updateMemorialPageCreationStepApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { step } = action.payload;

    try {
        yield call(sendNewMemorialCreationStep, { slug, step });

        yield put(updateMemorialPageCreationStepApiActions.success({ step }));
    } catch (e) {
        yield put(
            updateMemorialPageCreationStepApiActions.failure(e.statusText),
        );
    }
}
