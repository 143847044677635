import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { getDateLocale, getDateFnsUtils } from '../../setup/get-date-locale';
import { useLanguage } from '../configuration/use-language';

export const withDatePickersConfiguration = <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    return (
        <MuiPickersUtilsProvider
            locale={getDateLocale(useLanguage().code)}
            utils={getDateFnsUtils(useLanguage().code)}
        >
            <Component {...props} />
        </MuiPickersUtilsProvider>
    );
};
