import { apiService } from '../../api.service';
import { ICreatedContact } from '../../../interfaces/contact';
import { mapContactDtoToContact } from '../mappers/map-contact-dto-to-contact';

export const fetchNotificationContacts = (
    slug: string,
): Promise<ICreatedContact[]> =>
    apiService
        .get(`/memorial_pages/${slug}/participants`)
        .then((resp) => resp.json())
        .then((resp) =>
            resp.data.map((rawContact: any) =>
                mapContactDtoToContact(rawContact),
            ),
        );
