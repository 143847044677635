import { put, select, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';
import { push } from 'connected-react-router';

import { loginPageActions } from '../login-page.actions';
import { loginApiActions } from '../../../../api/login/login-api.actions';
import { saveTokens } from '../../../../api/auth-token-storage';
import { getPageSlug } from '../../../../meta/model/selectors/get-page-slug.selector';
import { getPageCreationStep } from '../../../../meta/model/selectors/get-page-creation-state.selector';
import { fetchPageActions } from '../../../../api/page/page-api.actions';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';
import {
    fetchContextsApiActions,
    setContext,
} from '../../../contexts/model/saga/context.actions';
import { LoginErrorCode } from '../../../../api/login/dto/login-errors.dto';
import { AuthPath } from '../../../../routing/auth-path';

export function* requestUserLoginSaga(
    action: ReturnType<typeof loginPageActions.request>,
) {
    const { password, email } = action.payload;

    yield put(
        loginApiActions.request({
            password,
            email,
        }),
    );

    const result = yield take([
        loginApiActions.success,
        loginApiActions.failure,
    ]);

    if (isActionOf(loginApiActions.success, result)) {
        yield put(loginPageActions.success(result.payload));
        yield saveTokens(
            result.payload.accessToken,
            result.payload.refreshToken,
        );
        const slug = yield select(getPageSlug);
        if (slug) {
            yield put(fetchPageActions.request({ slug }));
            yield put(fetchPageActions.request({ slug }));
            const fetchPageResult = yield take([
                fetchPageActions.success,
                fetchPageActions.failure,
            ]);

            if (isActionOf(fetchPageActions.success, fetchPageResult)) {
                const creationStep = yield select(getPageCreationStep);

                const routeToRedirect = resolveRouteFromMemorialCreationStep(
                    slug,
                    creationStep,
                );

                yield put(push(routeToRedirect));
            } else if (isActionOf(fetchPageActions.failure, fetchPageResult)) {
                yield put(push(AuthPath.CONTEXTS));
            }
        } else {
            yield put(fetchContextsApiActions.request());
            const contextResult = yield take([
                fetchContextsApiActions.success,
                fetchContextsApiActions.failure,
            ]);
            if (isActionOf(fetchContextsApiActions.success, contextResult)) {
                if (contextResult.payload.data.length > 1) {
                    yield put(push(AuthPath.CONTEXTS));
                } else {
                    yield put(
                        setContext({
                            slug: contextResult.payload.data[0].attributes.slug,
                            id: contextResult.payload.data[0].id,
                            creationStep:
                                contextResult.payload.data[0].attributes
                                    .currentWizardStep,
                        }),
                    );
                }
            } else if (
                isActionOf(fetchContextsApiActions.failure, contextResult)
            ) {
                yield put(loginPageActions.failure(LoginErrorCode.UNKNOWN));
            }
        }
    } else if (isActionOf(loginApiActions.failure, result)) {
        yield put(loginPageActions.failure(result.payload));
    }
}
