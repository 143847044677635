import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { updateVgApiActions } from '../vg-api.actions';

import { updateVgDataSaga } from './update-vg-data.saga';

export function* vgApiSaga() {
    yield takeEvery(getType(updateVgApiActions.request), updateVgDataSaga);
}
