import { ApiService } from '../../api.service';
import { IImageUploadSessionResponseDto } from '../dto/image-upload-session-response.dto';
import { IConfirmImageOnServerDto } from '../dto/confirm-image-on-server.dto';

export const uploadFileToS3 = (
    sessionData: IImageUploadSessionResponseDto,
    file: File,
): Promise<IConfirmImageOnServerDto> => {
    const formData = new FormData();
    Object.keys(sessionData.fields).forEach((key) => {
        formData.append(key, sessionData.fields[key]);
    });

    formData.append('file', file);

    const apiService = new ApiService({
        host: '',
        mapHeaders: (headers) => {
            return new Headers({
                ...headers,
                ...sessionData.headers,
            });
        },
    });

    return apiService
        .post(sessionData.url, {
            body: formData,
        })
        .then(() => {
            return {
                id: sessionData.fields.key.replace('uploads/cache/', ''),
                storage: 'cache',
                metadata: {
                    size: file.size,
                    filename: file.name,
                    mimeType: file.type,
                },
            };
        });
};
