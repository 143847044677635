import React from 'react';
import { Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';

type Props = GridProps & {
    children: [React.ReactNode, React.ReactNode];
};

// TODO: Fix mobile styles
export const FormDoubleInputsContainer = ({
    children,
    spacing = 3,
    ...props
}: Props) => {
    if (children.length !== 2) {
        throw new Error('FormDoubleInputsContainer accepts two React Nodes');
    }

    return (
        <Grid container spacing={spacing} {...props}>
            <Grid item xs={12} sm={6}>
                {children[0]}
            </Grid>
            <Grid item xs={12} sm={6}>
                {children[1]}
            </Grid>
        </Grid>
    );
};
