import { put } from '@redux-saga/core/effects';

import { updateMemorialPageCreationStepApiActions } from '../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { memorialPageCreationStepReceived } from '../meta.actions';
import { MemorialPageCreationStep } from '../../../interfaces/memorial-page-creation-step';

export function* saveCurrentWizardStepSaga(
    action: ReturnType<typeof updateMemorialPageCreationStepApiActions.success>,
) {
    yield put(
        memorialPageCreationStepReceived({
            state: action.payload.step as MemorialPageCreationStep,
        }),
    );
}
