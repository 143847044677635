import React, { ReactNode, useRef } from 'react';
import cx from 'classnames';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import { Edit } from '@material-ui/icons';

import { IconRounded } from '../../../components/icon-rounded/icon-rounded';

export interface IGalleryItemProps extends BoxProps {
    src: string;
    onEditClicked?(): unknown;
    renderMenu?(anchorRef: HTMLElement | null): ReactNode;
}

const useStyles = makeStyles<Theme, { src: string }>((theme) => ({
    root: {
        backgroundImage: ({ src }) => `url("${src}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 4,
        position: 'relative',
    },
    menuContainer: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    editButton: {
        padding: 0,
    },
}));

export const GalleryItem = ({
    src,
    onEditClicked,
    className,
    renderMenu,
}: IGalleryItemProps) => {
    const styles = useStyles({ src });
    const editButtonRef = useRef(null);

    return (
        <Box className={cx(styles.root, className)}>
            {onEditClicked && (
                <div className={styles.menuContainer}>
                    <IconButton
                        data-testid="picture-edit-button"
                        className={styles.editButton}
                        onClick={onEditClicked}
                        ref={editButtonRef}
                    >
                        <IconRounded icon={Edit} />
                    </IconButton>
                    {renderMenu && renderMenu(editButtonRef.current)}
                </div>
            )}
        </Box>
    );
};
