import { RouteProps } from 'react-router';

import { OverviewScreen } from '../editor/overview/overview-screen';
import { DeceasedScreen } from '../deceased/deceased-screen';
import { MediaAndContentScreen } from '../editor/media-and-content/media-and-content-screen';
import { NotificationsScreen } from '../editor/notifications/notifications-screen';
import { CeremonyAndReceptionScreen } from '../editor/ceremony-and-reception/ceremony-and-reception-screen';
import { DigitalFootprintScreen } from '../editor/digital-footprint/digital-footprint-screen';
import { SettingsScreen } from '../settings/settings-screen';
import { ParticipantsScreen } from '../editor/participants/participants-screen';
import { ObituaryScreen } from '../obituary/obituary-screen';

import { EditorPath } from './editor-paths';

/**
 * Subroutes for notify editor layout
 */
export const editorRoutes: RouteProps[] = [
    {
        path: EditorPath.OVERVIEW,
        component: OverviewScreen,
    },
    {
        path: EditorPath.DECEASED,
        component: DeceasedScreen,
    },
    {
        path: EditorPath.MEDIA_AND_CONTENT,
        component: MediaAndContentScreen,
    },
    {
        path: EditorPath.OBITUARY,
        component: ObituaryScreen,
    },
    {
        path: EditorPath.NOTIFICATIONS,
        component: NotificationsScreen,
    },
    {
        path: EditorPath.CEREMONY_AND_RECEPTION,
        component: CeremonyAndReceptionScreen,
    },
    {
        path: EditorPath.DIGITAL_FOOTPRINT,
        component: DigitalFootprintScreen,
    },
    {
        path: EditorPath.SETTINGS,
        component: SettingsScreen,
    },
    {
        path: EditorPath.PARTICIPANTS,
        component: ParticipantsScreen,
    },
];
