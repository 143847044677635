/**
 * Workaround for Safari
 * @see https://stackoverflow.com/questions/6427204/date-parsing-in-javascript-is-different-between-safari-and-chrome
 */
export function parseDate(date: string) {
    const parsed = Date.parse(date);
    if (!Number.isNaN(parsed)) {
        return parsed;
    }

    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}
