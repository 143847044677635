import { createReducer } from 'typesafe-actions';

import { LoginErrorCode } from '../../../api/login/dto/login-errors.dto';

import { loginFormStateChanged, loginPageActions } from './login-page.actions';

export interface ILoginPageStore {
    error: LoginErrorCode | null;
}

const initialState: ILoginPageStore = {
    error: null,
};
type Actions =
    | ReturnType<typeof loginPageActions.failure>
    | ReturnType<typeof loginFormStateChanged>;

export const loginPageReducer = createReducer<ILoginPageStore, Actions>(
    initialState,
)
    .handleAction(loginPageActions.failure, (state, action) => {
        const error = action.payload;

        if (!error) {
            return state;
        }

        return {
            ...state,
            error,
        };
    })
    .handleAction(loginFormStateChanged, (state, action) => ({
        ...state,
        error: null,
    }));
