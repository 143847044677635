import { apiService } from '../../api.service';
import { IMilestoneCreationDto } from '../dto/milestone-creation.dto';
import { IMilestoneResponseDto } from '../dto/milestone-response.dto';

export const updateMilestone = (
    dto: Partial<IMilestoneCreationDto>,
    slug: string,
    id: string,
): Promise<IMilestoneResponseDto> => {
    const url = `/memorial_pages/${slug}/milestones/${id}`;

    return apiService
        .patch(url, {
            json: dto,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
};
