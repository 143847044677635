import React, { useRef, useState } from 'react';
import {
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../components/secondary-button/secondary-button';
import { Section, ISectionProps } from '../editor/components/section/section';
import { ITask as TaskInterface } from '../interfaces/task';
import { ConfirmationModal } from '../components/confirmation-modal/confirmation-modal';

import { Task } from './task/task';
import { TaskModal } from './task-modal/task-modal';
import { useTasks } from './use-tasks';

export interface ITasksProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    headCell: {
        color: theme.palette.common.black,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        paddingLeft: 0,
    },
    tableHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
        },
    },
    lastHeaderCell: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export const Tasks = (props: ITasksProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const {
        tasks,
        createModalOpen,
        updateModalOpen,
        taskCreationRequested,
        closeTaskCreateModalRequested,
        taskUpdateRequested,
        closeTaskUpdateModalRequested,
        editedTask,
        onCreate,
        onUpdate,
        onDelete,
    } = useTasks();

    const taskToDelete = useRef<string | null>(null);

    const handleUpdate = (task: Omit<TaskInterface, 'id'>) => {
        onUpdate(
            {
                ...task,
                id: editedTask!.id,
            },
            editedTask!.id,
        );
    };

    return (
        <Section
            title={t('ceremonyAndReception.requestForHelp')}
            description={t('ceremonyAndReception.requestForHelpDescription')}
            {...props}
        >
            <TaskModal
                onClose={closeTaskCreateModalRequested}
                onCreate={onCreate}
                open={createModalOpen}
                title={t('ceremonyAndReception.addRequestForHelpModalTitle')}
            />
            <TaskModal
                initialValues={editedTask}
                onClose={closeTaskUpdateModalRequested}
                onUpdate={handleUpdate}
                open={updateModalOpen}
                title={t('ceremonyAndReception.editRequestForHelpModalTitle')}
            />
            {tasks.length > 0 && (
                <Table data-testid="requests-table-container">
                    <TableHead>
                        <TableRow className={styles.tableHeader}>
                            <TableCell className={styles.headCell}>
                                {t('ceremonyAndReception.tableColumns.task')}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t(
                                    'ceremonyAndReception.tableColumns.expirationDate',
                                )}
                            </TableCell>
                            <TableCell className={styles.headCell}>
                                {t('ceremonyAndReception.tableColumns.status')}
                            </TableCell>
                            <TableCell className={styles.lastHeaderCell} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks.map((task, index) => (
                            <Task
                                task={{
                                    ...task,
                                    expirationTime: task.expirationDate,
                                }}
                                key={task.id}
                                onDelete={() => {
                                    taskToDelete.current = task.id;
                                    setConfirmationModalOpen(true);
                                }}
                                onEdit={() => {
                                    taskUpdateRequested(task);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <SecondaryButton
                    data-testid="requests-add-request"
                    onClick={taskCreationRequested}
                >
                    {t('ceremonyAndReception.addNewRequestButton')}
                </SecondaryButton>
            </Box>
            <ConfirmationModal
                open={confirmationModalOpen}
                onConfirm={() => {
                    onDelete(taskToDelete.current!);
                    setConfirmationModalOpen(false);
                    taskToDelete.current = null;
                }}
                onCancel={() => {
                    setConfirmationModalOpen(false);
                }}
            />
        </Section>
    );
};
