import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SectionHeadline } from '../../../../../components/section-headline/section-headline';
import { ObituaryWritingStyle } from '../../../../model/obituary.types';

import { useObituaryWritingStyleSelectorStyles } from './obituary-writing-style-selector.styles';

export interface IObituaryWritingStyleSelectorProps {
    obituaryWritingStyle: ObituaryWritingStyle;
    onObituaryWritingStyleChange: (
        obituaryWritingStyle: ObituaryWritingStyle,
    ) => void;
}

export const ObituaryWritingStyleSelector = ({
    obituaryWritingStyle,
    onObituaryWritingStyleChange,
}: IObituaryWritingStyleSelectorProps) => {
    const { t } = useTranslation();
    const styles = useObituaryWritingStyleSelectorStyles();

    const options = Object.values(ObituaryWritingStyle);

    return (
        <Box>
            <Box mb={3}>
                <SectionHeadline>
                    {t('obituary.writingStyle.headline')}
                </SectionHeadline>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gridRowGap="0.75rem"
                gridColumnGap="1rem"
            >
                {options.map((option) => {
                    const selected = obituaryWritingStyle === option;
                    const variant = selected ? 'default' : 'outlined';
                    const color = selected ? 'primary' : 'default';

                    const classes = {
                        root: styles.chip,
                        label: styles.label,
                        colorPrimary: styles.selected,
                    };

                    const handleSelect = () => {
                        onObituaryWritingStyleChange(option);
                    };

                    return (
                        <Chip
                            key={option}
                            clickable
                            label={t(`obituary.writingStyle.${option}`)}
                            variant={variant}
                            onClick={handleSelect}
                            color={color}
                            classes={classes}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};
