import { IMilestoneCreationDto } from '../../api/milestones/dto/milestone-creation.dto';

import { IMilestoneFormValues } from './milestone-form-values';

export const mapMilestoneFormToDto = (
    formData: IMilestoneFormValues,
): IMilestoneCreationDto => {
    const dto: IMilestoneCreationDto = {
        title: formData.title,
        /**
         * In form date can be null, because initially its not set.
         * However form will not allow submit without date.
         */
        year: formData.year!,
        month: formData.month ? formData.month : undefined,
        description: formData.description,
    };

    return dto;
};
