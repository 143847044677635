import { createSelector } from 'reselect';
import i18n from 'i18next';

import { NotificationMessageStatus } from '../notification-message.store';

import { getNotificationMessageStatus } from './get-notification-message-status.selector';

export const getNotificationMessageStatusMessage = createSelector(
    getNotificationMessageStatus,
    (status) => {
        const NotificationMessageStatusMessage = {
            [NotificationMessageStatus.INAPPROPRIATE_WORDS]: i18n.t(
                'notificationMessageStatus.inappropriateWords',
            ),
        };
        return status ? NotificationMessageStatusMessage[status] : null;
    },
);
