import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    createTaskApiActions,
    deleteTaskApiActions,
    fetchTasksApiActions,
    updateTaskApiActions,
} from '../api-tasks.actions';

import { createTaskApiSaga } from './create-task-api.saga';
import { deleteTaskApiSaga } from './delete-task-api.saga';
import { updateTaskApiSaga } from './update-task-api.saga';
import { fetchTasksApiSaga } from './fetch-tasks-api.saga';

export function* tasksApiSaga() {
    yield takeEvery(getType(createTaskApiActions.request), createTaskApiSaga);
    yield takeEvery(getType(deleteTaskApiActions.request), deleteTaskApiSaga);
    yield takeEvery(getType(updateTaskApiActions.request), updateTaskApiSaga);
    yield takeEvery(getType(fetchTasksApiActions.request), fetchTasksApiSaga);
}
