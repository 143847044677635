import { IUpdateObituaryDto } from '../../../api/obituary/dto/update-obituary.dto';
import { ObituaryFormValues } from '../obituary.types';

export const mapObituaryFormToUpdateObituaryDto = (
    formData: ObituaryFormValues,
) => {
    const dto: IUpdateObituaryDto = {
        obituaryText: formData.obituaryText,
    };

    return dto;
};
