import { call, put, select } from 'redux-saga/effects';

import { updateSettingsApiActions } from '../settings.actions';
import { updateSettingsInApi } from '../update-settings-in-api';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';
import { PrivacyMode } from '../../../interfaces/privacy-mode';

export function* updateSettingsSaga(
    action: ReturnType<typeof updateSettingsApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response: IMemorialPageMetaDataResponse = yield call(
            updateSettingsInApi,
            slug,
            action.payload,
        );
        const page = response.data.attributes;

        yield put(
            updateSettingsApiActions.success({
                birthPlaceVisible: page.birthPlaceVisible,
                deathPlaceVisible: page.deathPlaceVisible,
                privacy: page.privacy as PrivacyMode,
                published: page.published,
                imageUploadingForCondolencesEnabled:
                    page.imageUploadingForCondolencesEnabled,
            }),
        );
    } catch (e) {
        yield put(updateSettingsApiActions.failure(e));
    }
}
