import { put } from 'redux-saga/effects';

import { resetPasswordActions } from '../login-page.actions';
import { sendPasswordResetEmailApiActions } from '../../../../api/password/password.actions';

export function* requestPasswordResetSaga(
    action: ReturnType<typeof resetPasswordActions.request>,
) {
    const { email } = action.payload;

    yield put(sendPasswordResetEmailApiActions.request({ email }));
}
