import { spawn } from 'redux-saga/effects';

import { authPasswordsSaga } from './password-page/model/saga/auth-passwords.saga';
import { loginPageSaga } from './login-page/model/saga/login-page.saga';
import { authInvitationSaga } from './invitation/model/saga/auth-invitation.saga';
import { resetPasswordPageSaga } from './reset-password-page/model/saga/reset-password-page.saga';
import { logoutRequestedSaga } from './logout/model/saga/logout.saga';

export function* authSaga() {
    yield spawn(loginPageSaga);
    yield spawn(authPasswordsSaga);
    yield spawn(authInvitationSaga);
    yield spawn(resetPasswordPageSaga);
    yield spawn(logoutRequestedSaga);
}
