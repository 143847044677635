import { SourceApp } from '../dto/event-response.dto';

export const buildSchedulableEventsUrl = (
    memorialPageSlug: string,
    eventId?: string | number,
) => {
    const baseUrl = `/memorial_pages/${memorialPageSlug}/events`;
    const eventIdUrlSection = eventId ? `/${eventId}` : '';
    const filtersSection = `?filter[source_app_eq]=${SourceApp.NotifyEditor}`;

    return `${baseUrl}${eventIdUrlSection}${filtersSection}`;
};
