import { put, select, call } from 'redux-saga/effects';

import { fetchEventsApiActions } from '../api-events.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { fetchEvents } from '../http/fetch-events';

export function* fetchEventsApiSaga(
    action: ReturnType<typeof fetchEventsApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response = yield call(fetchEvents, slug);
        yield put(fetchEventsApiActions.success(response));
    } catch (e) {
        console.error(e);
        yield put(fetchEventsApiActions.failure(e.statusText));
    }
}
