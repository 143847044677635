import { Crop } from 'react-image-crop';

export const getCroppedImage = (
    image: HTMLImageElement,
    crop: Crop,
    fileName: string,
): Promise<File | null> => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const adjustedCrop = {
        ...crop,
        x: crop.x || 0,
        y: crop.y || 0,
        width: crop.width || 0,
        height: crop.height || 0,
    };

    canvas.width = adjustedCrop.width;
    canvas.height = adjustedCrop.height;

    const ctx = canvas.getContext('2d');

    if (ctx) {
        ctx.drawImage(
            image,
            adjustedCrop.x * scaleX,
            adjustedCrop.y * scaleY,
            adjustedCrop.width * scaleX,
            adjustedCrop.height * scaleY,
            0,
            0,
            adjustedCrop.width,
            adjustedCrop.height,
        );
    }

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    reject();
                }

                const file =
                    blob && new File([blob], fileName, { type: 'image/jpeg' });

                resolve(file);
            },
            'image/jpeg',
            1,
        );
    });
};
