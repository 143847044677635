import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormTextField } from '../../../../../components/form-text-field/form-text-field';
import {
    DeceasedPersonalQuality,
    ObituaryQuestion,
    ObituaryQuestionAndAnswer,
} from '../../../../model/obituary.types';
import { DeceasedPersonalQualitiesSelector } from '../deceased-personal-qualities-selector/deceased-personal-qualities-selector';

import { useObituaryQuestionsAndAnswersStyles } from './obituary-questions-and-answers.styles';

export interface IObituaryQuestionsAndAnswersItemProps {
    questionNumber: number;
    isLastQuestion: boolean;
    obituaryQuestionAndAnswer: ObituaryQuestionAndAnswer;
    onObituaryQuestionAndAnswerValueChange: <T>(
        fieldName: keyof ObituaryQuestionAndAnswer,
    ) => (question: ObituaryQuestion, fieldValue: T) => void;
}

export const ObituaryQuestionsAndAnswersItem = ({
    questionNumber,
    isLastQuestion,
    obituaryQuestionAndAnswer,
    onObituaryQuestionAndAnswerValueChange,
}: IObituaryQuestionsAndAnswersItemProps) => {
    const { t } = useTranslation();
    const styles = useObituaryQuestionsAndAnswersStyles();

    const { question } = obituaryQuestionAndAnswer;

    const onObituaryQuestionAnswerChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const answer = e.target.value;
        onObituaryQuestionAndAnswerValueChange<string>('answer')(
            question,
            answer,
        );
    };

    const deceasedPersonalQualities = obituaryQuestionAndAnswer.info
        ? (obituaryQuestionAndAnswer.info as DeceasedPersonalQuality[])
        : [];

    const onDeceasedPersonalQualitiesChange = (info: string[]) => {
        onObituaryQuestionAndAnswerValueChange<string[]>('info')(
            question,
            info,
        );
    };

    return (
        <Box mb={isLastQuestion ? 0 : 5}>
            <Box mb={2}>
                <Typography className={styles.question}>
                    <span className={styles.questionNumber}>
                        {`${questionNumber}. `}
                    </span>
                    {t(`obituary.questionsAndAnswers.${question}.question`)}
                </Typography>
            </Box>
            {question === ObituaryQuestion.PERSONAL_QUALITIES && (
                <Box mb={2}>
                    <DeceasedPersonalQualitiesSelector
                        deceasedPersonalQualities={deceasedPersonalQualities}
                        onDeceasedPersonalQualitiesChange={
                            onDeceasedPersonalQualitiesChange
                        }
                    />
                </Box>
            )}
            <Box mb={2}>
                <Typography className={styles.example}>
                    {`${t(`obituary.questionsAndAnswers.example`)}: `}
                </Typography>
                <Typography className={styles.answerExample}>
                    {t(`obituary.questionsAndAnswers.${question}.example`)}
                </Typography>
            </Box>
            <FormTextField
                multiline
                rowsMax={99}
                value={obituaryQuestionAndAnswer.answer}
                onChange={onObituaryQuestionAnswerChange}
                label={t(`obituary.questionsAndAnswers.answer`)}
            />
        </Box>
    );
};
