import { call, put, select } from 'redux-saga/effects';

import { updateGalleryItemOnlineStatusApiActions } from '../gallery-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { updateImageOnlineStatus } from '../http/update-image-online-status';
import { IGalleryResponseDto } from '../dto/gallery-response.dto';

export function* updateGalleryItemApiSaga(
    action: ReturnType<typeof updateGalleryItemOnlineStatusApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id } = action.payload;

    try {
        const response: IGalleryResponseDto = yield call(
            updateImageOnlineStatus,
            slug,
            id,
            action.payload.status,
        );
        yield put(
            updateGalleryItemOnlineStatusApiActions.success({
                id: response.id,
                published: response.attributes.published,
                thumbSrc: response.attributes.thumbUrl,
                originalSrc: response.attributes.originalUrl,
            }),
        );
    } catch (e) {
        yield put(
            updateGalleryItemOnlineStatusApiActions.failure(e.statusText),
        );
    }
}
