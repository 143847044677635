import { createAsyncAction } from 'typesafe-actions';

import { UpdateTributeStatus } from '../../model/tribute/tribute.store';

import { IUpdateTributeDto } from './dto/update-tribute.dto';

export const updateTributeTextApiActions = createAsyncAction(
    'api/tribute/UPDATE_TEXT_REQUESTED',
    'api/tribute/UPDATE_TEXT_SUCCEEDED',
    'api/tribute/UPDATE_TEXT_FAILED',
)<
    IUpdateTributeDto,
    { tributeText: string },
    { status: UpdateTributeStatus }
>();
