import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    createMilestoneApiActions,
    deleteMilestoneApiActions,
    fetchMilestonesApiActions,
    updateMilestoneApiActions,
} from '../milestones-api.actions';

import { createMilestoneApiSaga } from './create-milestone-api.saga';
import { deleteMilestoneApiSaga } from './delete-milestone-api.saga';
import { fetchMilestonesApiSaga } from './fetch-milestones-api.saga';
import { updateMilestoneApiSaga } from './update-milestone-api.saga';

export function* milestonesApiSaga() {
    yield takeEvery(
        getType(createMilestoneApiActions.request),
        createMilestoneApiSaga,
    );

    yield takeEvery(
        getType(deleteMilestoneApiActions.request),
        deleteMilestoneApiSaga,
    );

    yield takeEvery(
        getType(updateMilestoneApiActions.request),
        updateMilestoneApiSaga,
    );

    yield takeEvery(
        getType(fetchMilestonesApiActions.request),
        fetchMilestonesApiSaga,
    );
}
