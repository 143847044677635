import { utcToZonedTime } from 'date-fns-tz';

import { IMemorialEvent } from '../../interfaces/memorial-event';
import { EventType } from '../../interfaces/event-type';
import { parseDate } from '../../utils/parse-date';

import {
    IEventResponseData,
    IEventResponseDto,
    ILocationAttributes,
    ICeremonyAttributes,
    ICeremony,
    SourceApp,
} from './dto/event-response.dto';

export const mapEventDtoToEvent = (
    eventDTO: IEventResponseData,
    locationDTO: ILocationAttributes,
    relatedCeremony?: ICeremonyAttributes,
): IMemorialEvent => {
    const { sourceApp } = eventDTO.attributes;
    const type = eventDTO.attributes.schedulableType as EventType;
    const isEventReadonly =
        type === EventType.CEREMONY || sourceApp === SourceApp.Partner;

    return {
        isReadOnly: isEventReadonly,
        type,
        id: eventDTO.id,
        title: eventDTO.attributes.title,
        description: eventDTO.attributes.description,
        city: locationDTO.city,
        street: locationDTO.street,
        zipCode: locationDTO.zipCode,
        date: eventDTO.attributes.startTimestamp
            ? utcToZonedTime(
                  parseDate(eventDTO.attributes.startTimestamp),
                  eventDTO.attributes.timeZone,
              )
            : null,
        location: locationDTO,
        isPrivate: relatedCeremony ? relatedCeremony.isPrivate : null,
        publicMessageForPrivateCeremony: relatedCeremony
            ? relatedCeremony.publicMessageForPrivateCeremony
            : null,
        funeralHomeNotes: relatedCeremony
            ? relatedCeremony.funeralHomeNotes
            : null,
        sourceApp,
    };
};

const getLocationFromCeremony = (
    response: IEventResponseDto,
    ceremony: ICeremony,
): ILocationAttributes => {
    const locationID =
        ceremony.relationships.location &&
        ceremony.relationships.location.data &&
        ceremony.relationships.location.data.id;

    const location = response.included.find(
        (inclusion) =>
            inclusion.type === 'location' && inclusion.id === locationID,
    );

    if (location) {
        return location.attributes as ILocationAttributes;
    }

    return {
        name: '',
        lat: '',
        lon: '',
        street: '',
        buildingNo: '',
        flatNo: '',
        floor: '',
        zipCode: '',
        city: '',
        state: '',
        countryId: '',
        municipality: '',
    };
};

const getCeremony = (
    response: IEventResponseDto,
    event: IEventResponseData,
): ICeremony => {
    const inclusions = response.included;
    return inclusions.find(
        (inclusion) =>
            inclusion.type === event.relationships.schedulable.data.type &&
            inclusion.id === event.relationships.schedulable.data.id,
    )!;
};

const getEvents = (
    response: IEventResponseDto,
    event: IEventResponseData,
): IMemorialEvent => {
    const relatedCeremony = getCeremony(response, event);
    const relatedLocation = getLocationFromCeremony(response, relatedCeremony);

    return mapEventDtoToEvent(
        event,
        relatedLocation,
        relatedCeremony.attributes as ICeremonyAttributes,
    );
};

export const mapEventResponseToEvents = (
    response: IEventResponseDto,
): IMemorialEvent | IMemorialEvent[] => {
    if (Array.isArray(response.data)) {
        return response.data.map((event) => {
            return getEvents(response, event);
        });
    }

    return getEvents(response, response.data);
};
