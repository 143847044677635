import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMilestone } from '../interfaces/milestone';
import { MilestonesSelectors } from '../model/milestones/milestones.selectors';

import {
    closeMilestoneCreateModalRequested,
    closeMilestoneUpdateModalRequested,
    fetchMilestonesAsyncActions,
    milestoneCreationRequested,
    milestoneUpdateRequested,
} from './model/milestones.actions';
import { MilestoneSectionSelectors } from './model/milestones-section.selectors';

export const useMilestones = () => {
    const dispatch = useDispatch();
    const milestones = useSelector(MilestonesSelectors.getMilestones);
    const createModalOpen = useSelector(
        MilestoneSectionSelectors.getCreateModalOpen,
    );
    const updateModalOpen = useSelector(
        MilestoneSectionSelectors.getUpdateModalOpen,
    );
    const editedMilestone = useSelector(
        MilestoneSectionSelectors.getEditedMilestone,
    );

    useEffect(() => {
        dispatch(fetchMilestonesAsyncActions.request());
    }, [dispatch]);

    return {
        createModalOpen,
        updateModalOpen,
        milestoneCreationRequested() {
            dispatch(milestoneCreationRequested());
        },
        closeMilestoneCreationModalRequested() {
            dispatch(closeMilestoneCreateModalRequested());
        },
        milestoneUpdateRequested(milestone: IMilestone) {
            dispatch(
                milestoneUpdateRequested({
                    editedMilestone: milestone,
                }),
            );
        },
        closeMilestoneUpdateModalRequested() {
            dispatch(closeMilestoneUpdateModalRequested());
        },
        milestones,
        editedMilestone,
    };
};
