import { createSelector } from 'reselect';

import { NotificationContactStatus } from '../../../../../interfaces/notification-contact-status';

import { getCurrentPage } from './get-current-page.selector';
import { getPerPage } from './get-per-page.selector';
import { resolveContactsSelector } from './resolve-contacts-selector';

export const makeGetContactsPaginated = (
    filter: NotificationContactStatus | null,
) => {
    return createSelector(
        resolveContactsSelector(filter),
        getCurrentPage,
        getPerPage,
        (contacts, currentPage, perPage) => {
            const startIndex = (currentPage - 1) * perPage;
            const endIndex = currentPage * perPage;

            return contacts.slice(startIndex, endIndex);
        },
    );
};
