import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../components/page-title/page-title';

import { Contacts } from './contacts/contacts';
import { Message } from './message/message';

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(5),
    },
}));

export const NotificationsScreen = ({ history }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Box data-testid="notifications-page-container" mb={3}>
            <PageTitle>{t('notifications.title')}</PageTitle>
            <Message className={styles.section} />
            <Contacts className={styles.section} />
        </Box>
    );
};
