export type ObituaryFormValues = {
    obituaryText: string;

    deceasedPreferredName: string;
    deceasedReligion?: DeceasedReligion;
    obituaryQuestionsAndAnswers: ObituaryQuestionAndAnswer[];
    obituaryWritingStyle: ObituaryWritingStyle;
    obituaryUnforgettableQuote: ObituaryUnforgettableQuote;
};

export type ObituaryQuestionAndAnswer = {
    question: ObituaryQuestion;
    answer: string;
    info?: string | string[];
};

export type ObituaryUnforgettableQuote = {
    include: boolean;
    theme: ObituaryUnforgettableQuoteTheme;
    info?: string;
};

export enum ObituaryQuestion {
    PERSONAL_QUALITIES = 'personal_qualities',
    MILESTONES_AND_ACCOMPLISHMENTS = 'milestones_and_accomplishments',
    HOBBIES_AND_INTERESTS = 'hobbies_and_interests',
    SPECIAL_MEMORIES_AND_MOMENTS = 'special_memories_and_moments',
    POSITIVE_IMPACT_OR_INSPIRATION = 'positive_impact_or_inspiration',
    QUOTES_SAYINGS_OR_LESSONS = 'quotes_sayings_or_lessons',
    CORE_VALUES_AND_BELIEFS = 'core_values_and_beliefs',
    OTHER = 'other',
}

export enum ObituaryWritingStyle {
    TRADITIONAl = 'traditional',
    FORMAL = 'formal',
    RELIGIOUS = 'religious',
    POETIC = 'poetic',
    HEARTFELT = 'heartfelt',
    WARM = 'warm',
    LIGHT_HEARTED = 'light_hearted',
    INSPIRATIONAL = 'inspirational',
}

export enum ObituaryUnforgettableQuoteTheme {
    RELIGION = 'religion',
    LOVE = 'love',
    COURAGE = 'courage',
    FRIENDSHIP = 'friendship',
    FAMILY = 'family',
    WISDOM = 'wisdom',
    SUCCESS = 'success',
    ADVENTURE = 'adventure',
    CUSTOM = 'custom',
}

export enum DeceasedPersonalQuality {
    LOVING = 'loving',
    KIND = 'kind',
    FUNNY = 'funny',
    FRIENDLY = 'friendly',
    INTELLIGENT = 'intelligent',
    WISE = 'wise',
    ADVENTUROUS = 'adventurous',
    GENEROUS = 'generous',
    THOUGHTFUL = 'thoughtful',
    FAITH_FILLED = 'faith_filled',
    INTERESTING = 'interesting',
    OPTIMISTIC = 'optimistic',
    ANALYTICAL = 'analytical',
    HANDY = 'handy',
    CREATIVE = 'creative',
    COURAGEOUS = 'courageous',
    SELFLESS = 'selfless',
    SPIRITED = 'spirited',
    GENTLE = 'gentle',
    PASSIONATE = 'passionate',
    PATIENT = 'patient',
    HUMBLE = 'humble',
    TALENTED = 'talented',
    SINCERE = 'sincere',
    CHARISMATIC = 'charismatic',
    ENTHUSIASTIC = 'enthusiastic',
    RESILIENT = 'resilient',
    DISCIPLINED = 'disciplined',
    AMBITIOUS = 'ambitious',
    EMPATHETIC = 'empathetic',
    WARM_HEARTED = 'warm_hearted',
}

export enum DeceasedReligion {
    MUSLIM = 'muslim',
    JEWISH = 'jewish',
    JEHOVAHS_WITNESSES = 'jehovahsWitnesses',
    MORMON = 'mormon',
    BUDDHIST = 'buddhist',
    HINDUIST = 'hinduist',
    EVANGELICAL_LUTHERAN_CHURCH = 'evangelicalLutheranChurch',
    NORWEGIAN_CHURCH = 'norwegianChurch',
    CATHOLIC_CHURCH = 'catholicChurch',
    FREE_CHURCH = 'freeChurch',
    HUMAN_ETHICAL = 'humanEthical',
    PERSONAL = 'personal',
    ADVENTIST_CHURCH = 'adventistChurch',
    PRIVATE = 'private',
    CATHOLIC = 'catholic',
    ADVENTIST = 'adventist',
    PROTESTANT = 'protestant',
    NONBELIEVER = 'nonBeliever',
}
