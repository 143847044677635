import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    deleteGalleryItemActions,
    fetchGalleryItemsActions,
    newGalleryImageAddedByUser,
    updateGalleryImageOnlineStatusActions,
} from '../gallery.actions';

import { newGalleryImageSaga } from './new-gallery-image.saga';
import { deleteGalleryImageSaga } from './delete-gallery-image.saga';
import { fetchGalleryItemsSaga } from './fetch-gallery-items.saga';
import { updateGalleryImageStatusSaga } from './update-gallery-image-status.saga';

export function* gallerySaga() {
    yield takeEvery(getType(newGalleryImageAddedByUser), newGalleryImageSaga);
    yield takeEvery(
        getType(deleteGalleryItemActions.request),
        deleteGalleryImageSaga,
    );
    yield takeEvery(
        getType(fetchGalleryItemsActions.request),
        fetchGalleryItemsSaga,
    );
    yield takeEvery(
        updateGalleryImageOnlineStatusActions.request,
        updateGalleryImageStatusSaga,
    );
}
