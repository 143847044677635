import { createAsyncAction } from 'typesafe-actions';

import { ILoginSuccessResponseDto } from './dto/login-success-response.dto';
import { LoginErrorCode } from './dto/login-errors.dto';

export const loginApiActions = createAsyncAction(
    'api/login/LOGIN_REQUEST',
    'api/login/LOGIN_SUCCEED',
    'api/login/LOGIN_FAILED',
)<
    { email: string; password: string },
    ILoginSuccessResponseDto,
    LoginErrorCode
>();
