import { combineReducers } from 'redux';

import {
    loginPageReducer,
    ILoginPageStore,
} from './login-page/model/login-page.store';
import {
    authInvitationReducer,
    IInvitationStore,
} from './invitation/model/invitation.store';
import {
    IResetPasswordStore,
    resetPasswordReducer,
} from './reset-password-page/model/reset-password.store';
import { contextReducer, IContextStore } from './contexts/model/context.store';

export interface IAuthStore {
    loginPage: ILoginPageStore;
    invitation: IInvitationStore;
    resetPassword: IResetPasswordStore;
    contextSelection: IContextStore;
}

export const authStore = combineReducers<IAuthStore>({
    loginPage: loginPageReducer,
    invitation: authInvitationReducer,
    resetPassword: resetPasswordReducer,
    contextSelection: contextReducer,
});
