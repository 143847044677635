import { delay, put, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { wizardNotificationContactAddActions } from '../wizard-notifications.actions';
import { addNotificationContactApiActions } from '../../../../api/contacts/contacts-api.actions';
import { setContactSuggestionStatus } from '../../../../model/notification-contacts/notification-contacts.actions';

export function* wizardAddContactSaga(
    action: ReturnType<typeof wizardNotificationContactAddActions.request>,
) {
    const contact = action.payload;

    yield put(
        addNotificationContactApiActions.request({
            displayName: contact.name,
            email: contact.email!,
            phone: contact.phone!,
        }),
    );

    const result = yield take([
        addNotificationContactApiActions.success,
        addNotificationContactApiActions.failure,
    ]);

    if (isActionOf(addNotificationContactApiActions.success, result)) {
        yield put(
            wizardNotificationContactAddActions.success(result.payload.contact),
        );
    }

    yield put(setContactSuggestionStatus(result.payload.status));
    yield delay(3000);
    yield put(setContactSuggestionStatus(null));
}
