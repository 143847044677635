import { apiService } from '../../api.service';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';
import { IUpdateVgDto } from '../dto/update-vg-dto';

export const updateVgData = (
    dto: IUpdateVgDto,
    slug: string,
): Promise<IMemorialPageMetaDataResponse> =>
    apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                vgOrdered: dto.vgOrdered,
            },
        })
        .then((resp) => resp.json());
