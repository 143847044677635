import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getTheme } from '../app/model/theme/selectors/get-theme.selector';
import { THEME_DETAILS } from '../app/utils/get-theme';

import { theme } from './theme';

export const withThemeProvider = () => <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const th = useSelector(getTheme);
    const url = window.location.href;
    let muiTheme: Theme;
    if (th) {
        muiTheme = theme[th as keyof typeof theme];
    } else if (url.includes(THEME_DETAILS.vg.url)) {
        muiTheme = theme.vg;
    } else {
        muiTheme = theme.default;
    }

    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Component {...props} />
        </ThemeProvider>
    );
};
