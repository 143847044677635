import React from 'react';
import { compose } from 'recompose';
import { FormikProps, withFormik } from 'formik';
import { Box, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormTextField } from '../../../../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../../../../components/field-error-message/field-error-message';
import { StatusMessage } from '../../../../components/status-message/status-message';
import { createShouldShowError } from '../../../../utils/should-show-error';
import { PrimaryButton } from '../../../../components/primary-button/primary-button';
import { CenterBox } from '../../../../components/center-box/center-box';
import { getResetPasswordStatusMessage } from '../../../reset-password-page/model/selectors/get-reset-password-status-message.selector';

import { forgotPasswordFormValidationSchema } from './forgot-password-form-validation-schema';
import { IForgotPasswordFormValues } from './forgot-password-form-values';

interface IOuterProps {
    onSubmit(values: IForgotPasswordFormValues): unknown;
}

interface IProps extends IOuterProps, FormikProps<IForgotPasswordFormValues> {}

export const ForgetPasswordForm = ({
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
}: IProps) => {
    const { t } = useTranslation();
    const shouldShowError = createShouldShowError(errors, touched);
    const statusMessage = useSelector(getResetPasswordStatusMessage);

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={shouldShowError('email')}>
                <FormTextField
                    error={shouldShowError('email')}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    name="email"
                    data-testid="forgot-page-email-input"
                    label={t('forgotPasswordForm.emailLabel')}
                />
                <FieldErrorMessage
                    data-testid="forgot-page-email-error"
                    name="email"
                />
                {statusMessage && (
                    <Box mt={2}>
                        <StatusMessage>{statusMessage}</StatusMessage>
                    </Box>
                )}
            </FormControl>
            <CenterBox mt={4}>
                <PrimaryButton data-testid="forgot-page-submit" type="submit">
                    {t('loginScreen.resetPasswordButton')}
                </PrimaryButton>
            </CenterBox>
        </form>
    );
};

export const ComposedForgotPasswordForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IForgotPasswordFormValues>({
        validationSchema: () => forgotPasswordFormValidationSchema(),
        mapPropsToValues() {
            return {
                email: '',
            };
        },
        handleSubmit(values, bag) {
            bag.props.onSubmit(values);
        },
    }),
)(ForgetPasswordForm);
