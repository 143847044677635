import { createSelector } from 'reselect';
import i18n from 'i18next';

import { UpdateDeceasedDataStatus } from '../deceased-person.store';

import { getUpdateDeceasedStatus } from './get-update-deceased-status.selector';

export const getUpdateDeceasedStatusMessage = createSelector(
    getUpdateDeceasedStatus,
    (status) => {
        const UpdateDeceasedDataStatusMessages = {
            [UpdateDeceasedDataStatus.SUCCESS]: i18n.t(
                'updateDeceasedDataStatus.success',
            ),
            [UpdateDeceasedDataStatus.ERROR]: i18n.t(
                'updateDeceasedDataStatus.error',
            ),
        };

        return status !== null
            ? UpdateDeceasedDataStatusMessages[status]
            : null;
    },
);
