import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    createTaskFormSubmitted,
    deleteTaskAsyncActions,
    fetchTasksAsyncActions,
    updateTaskFormSubmitted,
} from '../tasks.actions';

import { fetchTasksSaga } from './fetch-tasks.saga';
import { createTaskSaga } from './create-task.saga';
import { updateTaskSaga } from './update-task.saga';
import { deleteTaskSaga } from './delete-task.saga';

export function* tasksSaga() {
    yield takeEvery(getType(fetchTasksAsyncActions.request), fetchTasksSaga);
    yield takeEvery(getType(createTaskFormSubmitted), createTaskSaga);
    yield takeEvery(getType(updateTaskFormSubmitted), updateTaskSaga);
    yield takeEvery(getType(deleteTaskAsyncActions.request), deleteTaskSaga);
}
