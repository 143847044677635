import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { invitationCheckApiActions } from '../invitation-check.actions';

import { checkInvitationTokenSaga } from './check-invitation-token.saga';

export function* invitationSaga() {
    yield takeEvery(
        getType(invitationCheckApiActions.request),
        checkInvitationTokenSaga,
    );
}
