import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOverviewEventsAsyncActions } from './model/overview-events.actions';
import { getOverviewEvents } from './model/selectors/get-overview-events.selector';

export const useOverviewEvents = () => {
    const dispatch = useDispatch();
    const events = useSelector(getOverviewEvents);

    useEffect(() => {
        dispatch(getOverviewEventsAsyncActions.request());
    }, [dispatch]);

    return {
        events,
    };
};
