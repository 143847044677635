import * as yup from 'yup';

import { getPasswordValidation } from '../../utils/password-validation';

import { IPasswordFormValues } from './password-form-values';

export const accountFormValidationSchema = () =>
    yup.object<IPasswordFormValues>({
        repeatPassword: yup
            .string()
            .oneOf([yup.ref('password')], 'errors.passwordsDontMatch')
            .required(),
        password: getPasswordValidation().required(),
    });
