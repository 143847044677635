import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../editor/components/section/section';
import { SecondaryButton } from '../components/secondary-button/secondary-button';
import { IMilestone } from '../interfaces/milestone';
import { ConfirmationModal } from '../components/confirmation-modal/confirmation-modal';
import { useIsMobile } from '../../styles/use-is-mobile';

import { Milestone } from './milestone/milestone';
import { MilestoneModal } from './milestone-modal/milestone-modal';
import { useMilestones } from './use-milestones';
import {
    createMilestoneFormSubmitted,
    deleteMilestoneAsyncActions,
    updateMilestoneFormSubmitted,
} from './model/milestones.actions';
import { IMilestoneFormValues } from './model/milestone-form-values';

interface IMilestonesProps extends ISectionProps {}

export const Milestones = ({ ...props }: IMilestonesProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mobile = useIsMobile();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const {
        createModalOpen,
        updateModalOpen,
        milestoneCreationRequested,
        closeMilestoneCreationModalRequested,
        milestoneUpdateRequested,
        closeMilestoneUpdateModalRequested,
        milestones,
        editedMilestone,
    } = useMilestones();

    const milestoneToDelete = useRef<string | null>(null);

    const onCreate = (values: IMilestoneFormValues) => {
        dispatch(
            createMilestoneFormSubmitted({
                milestone: values,
            }),
        );
    };

    const onUpdate = (values: IMilestoneFormValues, id: string) => {
        dispatch(
            updateMilestoneFormSubmitted({
                milestone: values,
                id,
            }),
        );
    };

    const onDelete = (id: string) => {
        milestoneToDelete.current = id;
        setDeleteConfirmationOpen(true);
    };

    const onDeleteConfirmed = () => {
        dispatch(
            deleteMilestoneAsyncActions.request({
                id: milestoneToDelete.current as string,
            }),
        );
        setDeleteConfirmationOpen(false);
    };

    const onDeleteAborted = () => {
        setDeleteConfirmationOpen(false);
    };

    return (
        <Section
            title={t('mediaAndContent.timeline')}
            description={t('mediaAndContent.timelineDescription')}
            {...props}
        >
            <MilestoneModal
                onClose={closeMilestoneCreationModalRequested}
                onCreate={onCreate}
                open={createModalOpen}
                title={t('mediaAndContent.addEventModalTitle')}
            />
            <MilestoneModal
                initialValues={editedMilestone}
                onClose={closeMilestoneUpdateModalRequested}
                onUpdate={onUpdate}
                open={updateModalOpen}
                title={t('mediaAndContent.editEventModalTitle')}
            />
            {milestones.map((milestone: IMilestone, index: number) => (
                <Milestone
                    key={`${milestone.title} - ${index}`}
                    last={milestones.length === index + 1}
                    onDelete={onDelete}
                    onEdit={() => {
                        milestoneUpdateRequested(milestone);
                    }}
                    milestone={milestone}
                />
            ))}
            <Box display="flex" justifyContent="flex-end">
                <SecondaryButton
                    data-testid="timeline-add-milestone"
                    fullWidth={mobile}
                    onClick={milestoneCreationRequested}
                >
                    {t('mediaAndContent.addMilestoneButton')}
                </SecondaryButton>
            </Box>

            <ConfirmationModal
                onConfirm={onDeleteConfirmed}
                onCancel={onDeleteAborted}
                open={deleteConfirmationOpen}
            />
        </Section>
    );
};
