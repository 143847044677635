import React from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import { ConnectedTaskForm } from '../task-form/task-form';
import { BlueHeadline } from '../../components/blue-headline/blue-headline';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { ITask, ITaskNotYetCreated } from '../../interfaces/task';

type Props = Omit<DialogProps, 'onSubmit' & 'onClose'> & {
    initialValues?: ITask | null;
    onCreate?: (values: ITaskNotYetCreated) => unknown;
    onUpdate?: (values: ITaskNotYetCreated) => unknown;
    onClose(): unknown;
    title: string;
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export const TaskModal = ({
    initialValues,
    onCreate,
    onUpdate,
    onClose,
    title,
    ...props
}: Props) => {
    const mobile = useIsMobile();
    const styles = useStyles();

    return (
        <Dialog
            fullScreen={mobile}
            fullWidth
            maxWidth="md"
            {...props}
            onClose={onClose}
        >
            <Box
                data-testid="task-request-modal"
                px={mobile ? 4 : 12}
                pt={6.5}
                pb={3}
                position="relative"
            >
                <DialogTitle disableTypography>
                    <BlueHeadline align="center">{title}</BlueHeadline>
                </DialogTitle>
                <ConnectedTaskForm
                    initialValues={initialValues}
                    onClose={onClose}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                />
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Dialog>
    );
};
