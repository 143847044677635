import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    photosStepSkipRequested,
    wizardPhotosStepSubmitted,
} from '../wizard-photos.actions';

import { skipWizardPhotosStepSaga } from './skip-wizard-photos-step.saga';
import { submitWizardPhotosStepSaga } from './submit-wizard-photos-step.saga';

export function* wizardPhotosSaga() {
    yield takeEvery(getType(photosStepSkipRequested), skipWizardPhotosStepSaga);
    yield takeEvery(
        getType(wizardPhotosStepSubmitted),
        submitWizardPhotosStepSaga,
    );
}
