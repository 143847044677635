import { createAction } from 'typesafe-actions';

export const facebookSharingRequested = createAction(
    'sharing/FACEBOOK_SHARING_REQUESTED',
);

export const messengerSharingRequested = createAction(
    'sharing/MESSENGER_SHARING_REQUESTED',
);

export const whatsappSharingRequested = createAction(
    'sharing/WHATSAPP_SHARING_REQUESTED',
);
