import { ILanguage } from '../interfaces/language';

export const saveLanguageInStorage = (language: ILanguage) => {
    localStorage.setItem(
        'notify-editor-language',
        JSON.stringify({ language }),
    );
};

export const getLanguageFromStorage = () => {
    const language = localStorage.getItem('notify-editor-language');

    return language && JSON.parse(language).language;
};
