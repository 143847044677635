import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    getNotificationContacts,
    addNotificationContact,
    toggleAllContactsSelected,
    suggestedContactRejectionRequested,
    contactDeletionRequested,
} from '../contacts.actions';

import { getNotificationContactsSaga } from './get-notifiation-contacts.saga';
import { addNotificationContactSaga } from './add-notification-contact.saga';
import { selectAllContactsSaga } from './select-all-contacts.saga';
import { rejectSuggestedContactSaga } from './reject-suggested-contact.saga';
import { deleteContactSaga } from './delete-contact.saga';

export function* contactsSaga() {
    yield takeEvery(
        getType(getNotificationContacts.request),
        getNotificationContactsSaga,
    );

    yield takeEvery(
        getType(addNotificationContact.request),
        addNotificationContactSaga,
    );

    yield takeEvery(getType(toggleAllContactsSelected), selectAllContactsSaga);
    yield takeEvery(
        getType(suggestedContactRejectionRequested),
        rejectSuggestedContactSaga,
    );
    yield takeEvery(getType(contactDeletionRequested), deleteContactSaga);
}
