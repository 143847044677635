import { apiService } from '../api.service';

import { IMemorialPageMetaDataResponse } from './dto/memorial-page-data-response.dto';

export const fetchMemorialPageData = (
    slug: string,
): Promise<IMemorialPageMetaDataResponse> => {
    const url = `/memorial_pages/${slug}`;

    return apiService.get(url).then((resp) => resp.json());
};
