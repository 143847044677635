import { useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addYears, format, formatDistance, subYears } from 'date-fns';

import { getLanguage } from '../model/configuration/selectors/get-language.selector';
import { getDateLocale } from '../../setup/get-date-locale';
import { SupportedLanguageCode } from '../interfaces/supported-language-code';

export function getShortDate(
    langCode: SupportedLanguageCode,
    date: string | Date,
) {
    const dateObj = new Date(date);

    return format(dateObj, 'd.LL.yyyy', {
        locale: getDateLocale(langCode),
    });
}

export function getDateFromMonthYear(
    year: number | null,
    month?: number | null,
) {
    const temporaryDate = new Date();
    if (year) {
        temporaryDate.setFullYear(year);
    }
    if (month) {
        temporaryDate.setMonth(month - 1);
    }

    return temporaryDate.toISOString();
}

export function getMonthYearDate(
    langCode: SupportedLanguageCode,
    year: number,
    month?: number,
) {
    const dateObj = new Date();
    dateObj.setFullYear(year, month && month - 1);

    if (month) {
        return format(dateObj, 'LL.yyyy', {
            locale: getDateLocale(langCode),
        });
    }
    return format(dateObj, 'yyyy', {
        locale: getDateLocale(langCode),
    });
}

export function parseDateString(date: string) {
    const [year, month, day] = date.split('-');
    const now = new Date();
    now.setFullYear(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
    );
    return now;
}

export const useDateFormatter = () => {
    const lang = useSelector(getLanguage);

    function getTime(date: string | Date) {
        let formatPattern = 'H:mm';

        if (lang.code === 'en') {
            formatPattern = 'h:mm a';
        }

        return format(new Date(date), formatPattern, {
            locale: getDateLocale(lang.code),
        });
    }

    return {
        getLongDate(date: string) {
            let formatPattern = 'H:mm, dd.LL.yyyy';

            if (lang.code === 'en') {
                formatPattern = 'h:mm a, dd.LL.yyyy';
            }

            return format(new Date(date), formatPattern, {
                locale: getDateLocale(lang.code),
            });
        },
        getShortDate: (date: string | Date) => getShortDate(lang.code, date),
        getMonthYearDate: (year: number, month?: number) =>
            getMonthYearDate(lang.code, year, month),
        getDaysAgo(date: string) {
            return formatDistance(new Date(date), new Date(), {
                addSuffix: true,
                locale: getDateLocale(lang.code),
            });
        },
        getYearsBeforeDate(date: string | Date, years: number) {
            return subYears(new Date(date), years);
        },
        getYearsAfterDate(date: string | Date, years: number) {
            return addYears(new Date(date), years);
        },
        getTime,
        formatSelectedPickerDate(dateString: MaterialUiPickersDate) {
            if (!dateString) {
                return '';
            }

            return getShortDate(lang.code, dateString.toISOString());
        },
        formatSelectedPickerTime(dateString: MaterialUiPickersDate) {
            if (!dateString) {
                return '';
            }

            return getTime(dateString.toISOString());
        },
        formatMonthYearPickerDate(month?: number | null) {
            if (month) {
                return 'MM.yyyy';
            }
            return 'yyyy';
        },
    };
};
