import { createAction } from 'typesafe-actions';

import {
    ICreatedContact,
    IContactStatusUpdate,
} from '../../interfaces/contact';

import { ContactSuggestionStatus } from './notification-contacts.store';

export const notificationContactsReceived = createAction(
    'model/notification-contacts/CONTACTS_RECEIVED',
    (action) => (payload: { contacts: ICreatedContact[] }) => action(payload),
);

export const notificationSingleContactReceived = createAction(
    'model/notification-contacts/SINGLE_CONTACT_RECEIVED',
    (action) => (payload: { contact: ICreatedContact }) => action(payload),
);

export const setContactSuggestionStatus = createAction(
    'model/notification-contacts/SET_CONTACT_SUGGESTION_STATUS',
    (action) => (payload: ContactSuggestionStatus | null) => action(payload),
);

export const setContactsStatuses = createAction(
    'model/notification-contacts/SET_CONTACTS_STATUSES',
    (action) => (payload: IContactStatusUpdate[]) => action(payload),
);
