import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { fetchTasksAsyncActions } from '../tasks.actions';
import { fetchTasksApiActions } from '../../../api/tasks/api-tasks.actions';

export function* fetchTasksSaga(
    action: ReturnType<typeof fetchTasksAsyncActions.request>,
) {
    yield put(fetchTasksApiActions.request());

    const resultAction:
        | ReturnType<typeof fetchTasksApiActions.success>
        | ReturnType<typeof fetchTasksApiActions.failure> = yield take([
        fetchTasksApiActions.success,
        fetchTasksApiActions.request,
    ]);

    switch (resultAction.type) {
        case getType(fetchTasksApiActions.success):
            yield put(fetchTasksAsyncActions.success(resultAction.payload));
            break;
        case getType(fetchTasksApiActions.failure):
            yield put(fetchTasksAsyncActions.failure(resultAction.payload));
            break;
        default:
            yield;
    }
}
