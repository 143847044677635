import cx from 'classnames';
import React from 'react';
import { compose, mapProps } from 'recompose';
import {
    Button,
    createStyles,
    Theme,
    WithStyles,
    withStyles,
    withTheme,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { CameraAlt } from '@material-ui/icons';

import { Colors } from '../../../../../styles/colors';

const styles = createStyles((theme: Theme) => ({
    root: {
        borderColor: Colors.DOVE_GRAY,
    },
    label: {
        color: Colors.DOVE_GRAY,
        '& svg': {
            // TODO: Make this nicer
            marginRight: theme.spacing(1),
        },
    },
    filled: {
        backgroundColor: theme.palette.common.white,
    },
}));

/**
 * For some reason WithStyles<typeof styles> only gets classes
 * from button, so it has to be additionally injected
 */
type WithExtraClasses = { classes: { filled: string } };

type OuterProps = {
    active?: boolean;
};

type AllProps = ButtonProps &
    OuterProps &
    WithExtraClasses &
    WithStyles<typeof styles>;

export const PhotoButton = compose<AllProps, ButtonProps & OuterProps>(
    withStyles(styles),
    withTheme,
    mapProps(({ children, classes, active, className, ...props }: AllProps) => {
        const { filled, ...buttonClasses } = classes;

        return {
            variant: 'outlined',
            classes: buttonClasses,
            className: cx(className, {
                [filled]: active,
            }),
            children: (
                <>
                    <CameraAlt />
                    {children}
                </>
            ),
            ...props,
        };
    }),
)(Button);
