import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { fetchDictionaryApiActions } from '../../api/dictionaries/dictionaries-api.actions';
import { DictionaryType } from '../../interfaces/dictionary-type';
import { getWizardNotificationMessageStatusMessage } from '../../wizard/notifications-step/model/selectors/get-wizard-notification-message-status-message.selector';

import { getNotificationMessageText } from './selectors/get-notification-message-text.selector';
import { getNotificationMessageExamples } from './selectors/get-notification-message-examples.selector';
import { getAreMessageExamplesFetched } from './selectors/get-are-message-examples-fetchd.selector';

export const useNotificationMessage = () => {
    const dispatch = useDispatch();
    const notificationText = useSelector(getNotificationMessageText);
    const notificationExamples = useSelector(getNotificationMessageExamples);
    const examplesFetched = useSelector(getAreMessageExamplesFetched);
    const statusMessage = useSelector(
        getWizardNotificationMessageStatusMessage,
    );

    useEffect(() => {
        if (!examplesFetched) {
            dispatch(
                fetchDictionaryApiActions.request({
                    type: DictionaryType.NOTIFICATION_MESSAGES,
                }),
            );
        }
    }, [dispatch, examplesFetched]);

    return {
        notificationText,
        notificationExamples,
        statusMessage,
    };
};
