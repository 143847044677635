import { call, put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { addNotificationContactApiActions } from '../contacts-api.actions';
import { addNotificationContact } from '../http/add-notification-contact';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { notificationSingleContactReceived } from '../../../model/notification-contacts/notification-contacts.actions';
import { ContactSuggestionStatus } from '../../../model/notification-contacts/notification-contacts.store';
import { mapContactDtoToContact } from '../mappers/map-contact-dto-to-contact';

export function* addNotificationContactSaga(
    action: ReturnType<typeof addNotificationContactApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const response = yield call(addNotificationContact, slug, action.payload);

    if (!response.errors) {
        const contact = mapContactDtoToContact(response.data);

        yield put(notificationSingleContactReceived({ contact }));

        yield put(
            addNotificationContactApiActions.success({
                contact,
                status: ContactSuggestionStatus.SUCCESS,
            }),
        );
    } else if (find(response.errors, { code: 'is_taken' })) {
        yield put(
            addNotificationContactApiActions.failure({
                status: ContactSuggestionStatus.ALREADY_EXISTS,
            }),
        );
    } else {
        yield put(
            addNotificationContactApiActions.failure({
                status: ContactSuggestionStatus.ERROR,
            }),
        );
    }
}
