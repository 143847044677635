import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    deleteGalleryItemApiActions,
    fetchGalleryItemsApiActions,
    updateGalleryItemOnlineStatusApiActions,
} from '../gallery-api.actions';

import { deleteGalleryItemApiSaga } from './delete-gallery-item-api.saga';
import { fetchGalleryItemsApiSaga } from './fetch-gallery-items-api.saga';
import { updateGalleryItemApiSaga } from './update-gallery-item-status-api.saga';

// TODO: @see https://bitbucket.org/memcareteam/notify-editor/issues/17/complete-gallery-saga-to-include-adding
export function* galleryApiSaga() {
    yield takeEvery(
        getType(deleteGalleryItemApiActions.request),
        deleteGalleryItemApiSaga,
    );

    yield takeEvery(
        getType(fetchGalleryItemsApiActions.request),
        fetchGalleryItemsApiSaga,
    );

    yield takeEvery(
        getType(updateGalleryItemOnlineStatusApiActions.request),
        updateGalleryItemApiSaga,
    );
}
