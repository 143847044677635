import { delay, put, take } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';

import { updateObituaryRequested } from '../obituary.actions';
import {
    obituaryReceived,
    setObituaryStatus,
} from '../../../model/obituary/obituary-model.actions';
import { ObituaryStatus } from '../../../model/obituary/obituary.store';
import { updateObituaryTextApiActions } from '../../../api/obituary/obituary-api.actions';
import { mapObituaryFormToUpdateObituaryDto } from '../mappers/map-obituary-form-to-update-obituary-dto';

export function* handleUpdateObituarySaga(
    action: ReturnType<typeof updateObituaryRequested>,
) {
    const formData = action.payload;

    const dto = mapObituaryFormToUpdateObituaryDto(formData);

    yield put(updateObituaryTextApiActions.request(dto));

    const result = yield take([
        updateObituaryTextApiActions.success,
        updateObituaryTextApiActions.failure,
    ]);

    if (isActionOf(updateObituaryTextApiActions.success, result)) {
        yield put(
            obituaryReceived({
                obituaryText: result.payload.obituaryText || '',
            }),
        );
        yield put(setObituaryStatus(ObituaryStatus.SUCCESS));
        yield delay(3000);
        yield put(setObituaryStatus(null));
    } else if (isActionOf(updateObituaryTextApiActions.failure, result)) {
        yield put(setObituaryStatus(result.payload.obituaryStatus));
        yield delay(3000);
        yield put(setObituaryStatus(null));
    }
}
