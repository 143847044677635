import { call, put } from 'redux-saga/effects';

import { updateTaskApiActions } from '../api-tasks.actions';
import { updateTask } from '../update-task';

export function* updateTaskApiSaga(
    action: ReturnType<typeof updateTaskApiActions.request>,
) {
    try {
        const response = yield call(
            updateTask,
            action.payload,
            action.payload.id,
        );
        yield put(updateTaskApiActions.success(response));
    } catch (e) {
        yield put(updateTaskApiActions.failure(e.statusText));
    }
}
