import { apiService } from '../../api.service';
import { IGalleryResponseDto } from '../dto/gallery-response.dto';

export const updateImageOnlineStatus = (
    slug: string,
    id: string,
    status: 'online' | 'offline',
): Promise<IGalleryResponseDto> =>
    apiService
        .patch(`/memorial_pages/${slug}/gallery_photos/${id}`, {
            json: {
                gallery_photo: {
                    published: status === 'online',
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
