import { ISuggestionDto } from '../dto/suggestion.dto';
import { ISuggestion } from '../../../interfaces/suggestion';

import { mapSuggestionDtoToSuggestion } from './map-suggestion-dto-to-suggestion';

export const mapSuggestionsDtoToSuggestions = (
    dtos: ISuggestionDto[],
): ISuggestion[] => {
    return (dtos || []).map(mapSuggestionDtoToSuggestion);
};
