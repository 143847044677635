import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { suggestionsFetchRequested } from '../suggestions.actions';

import { fetchSuggestionsSaga } from './fetch-suggestions.saga';

export function* suggestionsSaga() {
    yield takeEvery(getType(suggestionsFetchRequested), fetchSuggestionsSaga);
}
