import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Section,
    ISectionProps,
} from '../../editor/components/section/section';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { wizardRestartRequested } from '../../wizard/about-deceased-step/model/wizard-about-deceased.actions';

export interface IWizardSettingsProps extends ISectionProps {}

export const WizardSettings = (props: IWizardSettingsProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    const dispatch = useDispatch();

    const onWizardOptionClicked = () => {
        dispatch(wizardRestartRequested());
    };

    return (
        <Section title={t('wizardSettings.title')} {...props}>
            <Box mt={4}>
                <PrimaryButton
                    size="large"
                    color="primary"
                    onClick={onWizardOptionClicked}
                    fullWidth={mobile}
                >
                    {t('wizardSettings.restartWizard')}
                </PrimaryButton>
            </Box>
        </Section>
    );
};
