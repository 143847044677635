import { apiService } from '../../api.service';
import { IDictionaryEntryDto } from '../dto/dictionary-entry.dto';

export const fetchDictionary = ({
    type,
}: {
    type: string;
}): Promise<IDictionaryEntryDto[]> =>
    apiService
        .get(`/dictionaries/${type}/entries`)
        .then((resp) => resp.json())
        .then((resp) => resp.data);
