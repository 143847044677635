import { apiService } from '../../api.service';
import { IMemorialEvent } from '../../../interfaces/memorial-event';
import { IEventCreateRequestDto } from '../dto/event-request.dto';
import { mapEventResponseToEvents } from '../map-event-response-to-events';
import { SourceApp } from '../dto/event-response.dto';

import { buildSchedulableEventsUrl } from './helpers';

export const createEvent = (
    dto: IEventCreateRequestDto,
    slug: string,
): Promise<IMemorialEvent> => {
    const url = buildSchedulableEventsUrl(slug);

    const updateDto: IEventCreateRequestDto = {
        ...dto,
        event: {
            ...dto.event,
            publishedAtMemorialPage: true,
            sourceApp: SourceApp.NotifyEditor,
        },
    };
    return apiService
        .post(url, {
            json: updateDto,
        })
        .then((result) => result.json())
        .then((resp) => mapEventResponseToEvents(resp) as IMemorialEvent);
};
