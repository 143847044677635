import { call, put } from 'redux-saga/effects';

import { loginApiActions } from '../login-api.actions';
import { loginUser } from '../login-user';
import { ILoginSuccessResponseDto } from '../dto/login-success-response.dto';

export function* loginUserToApiSaga(
    action: ReturnType<typeof loginApiActions.request>,
) {
    try {
        const resp: ILoginSuccessResponseDto = yield call(
            loginUser,
            action.payload.email,
            action.payload.password,
        );

        yield put(loginApiActions.success(resp));
    } catch (e) {
        yield put(loginApiActions.failure(e));
    }
}
