import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@material-ui/core';

import { Section } from '../editor/components/section/section';
import { PageTitle } from '../editor/components/page-title/page-title';

import { ConnectedObituaryForm } from './components/obituary-form/obituary-form';
import { useObituaryScreen } from './use-obituary-screen';
import { useObituaryScreenStyles } from './obituary-screen.styles';

export const ObituaryScreen = () => {
    const { t } = useTranslation();
    const styles = useObituaryScreenStyles();

    const {
        obituaryText,
        generatedObituary,
        obituaryStatus,
        obituaryStatusMessage,
        obituaryLoading,
        deceased,
        onSubmit,
        onGenerate,
    } = useObituaryScreen();

    return (
        <Box>
            <Box mb={5}>
                <PageTitle>{t('menu.obituary')}</PageTitle>
            </Box>
            <Section title={t('obituary.sectionTitle')}>
                <Box mt={4} whiteSpace="pre-wrap">
                    <Typography
                        className={styles.introduction}
                        dangerouslySetInnerHTML={{
                            __html: t('obituary.introduction'),
                        }}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <Divider light />
                </Box>
                <ConnectedObituaryForm
                    deceasedName={deceased.firstName}
                    obituaryText={obituaryText}
                    generatedObituary={generatedObituary}
                    onSubmit={onSubmit}
                    onGenerate={onGenerate}
                    obituaryStatus={obituaryStatus}
                    obituaryStatusMessage={obituaryStatusMessage}
                    obituaryLoading={obituaryLoading}
                />
            </Section>
        </Box>
    );
};
