import { createAction, createAsyncAction } from 'typesafe-actions';

import { IContextResponse } from '../../dto/context-response.dto';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { ILoginSuccessResponseDto } from '../../../../api/login/dto/login-success-response.dto';
import { LoginErrorCode } from '../../../../api/login/dto/login-errors.dto';

export const fetchContextsApiActions = createAsyncAction(
    'api/contexts/FETCH_CONTEXTS_REQUESTED',
    'api/contexts/FETCH_CONTEXTS_SUCCEEDED',
    'api/contexts/FETCH_CONTEXTS_FAILED',
)<undefined, IContextResponse, string>();

export const changeContextApiActions = createAsyncAction(
    'api/contexts/CHANGE_CONTEXTS_REQUESTED',
    'api/contexts/CHANGE_CONTEXTS_SUCCEEDED',
    'api/contexts/CHANGE_CONTEXTS_FAILED',
)<
    {
        contextId: number;
        slug: string;
        creationStep: MemorialPageCreationStep | null;
    },
    ILoginSuccessResponseDto,
    LoginErrorCode
>();

export const setContext = createAction(
    'contexts/SET_CONTEXT',
    (action) => (payload: {
        slug: string;
        creationStep: MemorialPageCreationStep | null;
        id: string;
    }) => action(payload),
);
