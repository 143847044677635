import { createAsyncAction } from 'typesafe-actions';

import { ICreatedContact } from '../../interfaces/contact';
import { ContactSuggestionStatus } from '../../model/notification-contacts/notification-contacts.store';
import { NotificationMessageStatus } from '../../model/notification-message/notification-message.store';

import { IAddNotificationContactDto } from './dto/add-notification-contact.dto';

export const updateNotificationMessageApiActions = createAsyncAction(
    'api/contacts/UPDATE_NOTIFICATION_MESSAGE_REQUESTED',
    'api/contacts/UPDATE_NOTIFICATION_MESSAGE_SUCCEED',
    'api/contacts/UPDATE_NOTIFICATION_MESSAGE_FAILED',
)<{ message: string }, undefined, { status: NotificationMessageStatus }>();

export const addNotificationContactApiActions = createAsyncAction(
    'api/contacts/ADD_NOTIFICATION_CONTACT_REQUESTED',
    'api/contacts/ADD_NOTIFICATION_CONTACT_SUCCEED',
    'api/contacts/ADD_NOTIFICATION_CONTACT_FAILED',
)<
    IAddNotificationContactDto,
    { contact: ICreatedContact; status: ContactSuggestionStatus },
    { status: ContactSuggestionStatus }
>();

export const rejectSuggestedContactApiActions = createAsyncAction(
    'api/contacts/REJECT_SUGGESTED_CONTACT_REQUESTED',
    'api/contacts/REJECT_SUGGESTED_CONTACT_SUCCEED',
    'api/contacts/REJECT_SUGGESTED_CONTACT_FAILED',
)<{ id: string }, ICreatedContact, string>();

export const deleteContactApiActions = createAsyncAction(
    'api/contacts/DELETE_CONTACT_REQUESTED',
    'api/contacts/DELETE_CONTACT_SUCCEED',
    'api/contacts/DELETE_CONTACT_FAILED',
)<{ id: string }, ICreatedContact, string>();

export const fetchNotificationContactsApiActions = createAsyncAction(
    'api/contacts/FETCH_NOTIFICATION_CONTACTS_REQUESTED',
    'api/contacts/FETCH_NOTIFICATION_CONTACTS_SUCCEED',
    'api/contacts/FETCH_NOTIFICATION_CONTACTS_FAILED',
)<undefined, ICreatedContact[], string>();

export const sendNotificationsToContactsApiActions = createAsyncAction(
    'api/contacts/SEND_NOTIFICATIONS_TO_CONTACTS_REQUESTED',
    'api/contacts/SEND_NOTIFICATIONS_TO_CONTACTS_SUCCEED',
    'api/contacts/SEND_NOTIFICATIONS_TO_CONTACTS_FAILED',
)<{ ids: string[] }, undefined, string>();
