import { createSelector } from 'reselect';

import { RootStore } from '../../../store/store';

const getTasksSectionStore = (store: RootStore) => store.tasksSection;

const getCreateModalOpen = createSelector(
    getTasksSectionStore,
    (store) => store.createModalOpen,
);

const getEditedTask = createSelector(
    getTasksSectionStore,
    (store) => store.editedTask,
);

const getUpdateModalOpen = createSelector(
    getTasksSectionStore,
    (store) => store.updateModalOpen,
);

export const TasksSectionSelectors = {
    getCreateModalOpen,
    getEditedTask,
    getUpdateModalOpen,
};
