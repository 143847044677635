import { createAsyncAction } from 'typesafe-actions';

export const acceptGallerySuggestionApiActions = createAsyncAction(
    'api/gallery-suggestions/ACCEPT_REQUESTED',
    'api/gallery-suggestions/ACCEPT_SUCCEEDED',
    'api/gallery-suggestions/ACCEPT_FAILED',
)<{ id: string }, undefined, string>();

export const rejectGallerySuggestionApiActions = createAsyncAction(
    'api/gallery-suggestions/REJECT_REQUESTED',
    'api/gallery-suggestions/REJECT_SUCCEEDED',
    'api/gallery-suggestions/REJECT_FAILED',
)<{ id: string }, undefined, string>();
