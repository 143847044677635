import { combineReducers } from 'redux';

import {
    IWizardAboutDeceasedStore,
    wizardAboutDeceasedReducer,
} from './about-deceased-step/model/wizard-about-deceased.store';
import {
    wizardNotificationsReducer,
    IWizardNotificationsStore,
} from './notifications-step/model/wizard-notifications.store';

export interface IWizardStore {
    notifications: IWizardNotificationsStore;
    terms: IWizardAboutDeceasedStore;
}

export const wizardStore = combineReducers<IWizardStore>({
    notifications: wizardNotificationsReducer,
    terms: wizardAboutDeceasedReducer,
});
