import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { fetchBackgroundPhotosApiActions } from '../../api/background-photos/background-photos.actions';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';

import { getDeceasedSelectedBackground } from './selectors/get-deceased-selected-background.selector';
import { getDeceasedAvatarImage } from './selectors/get-deceased-avatar-image.selector';
import { getDeceasedAvailableBackgrounds } from './selectors/get-deceased-available-backgrounds.selector';

export const useDeceasedDisplayImages = () => {
    const selectedBackground = useSelector(getDeceasedSelectedBackground);
    const avatar = useSelector(getDeceasedAvatarImage);
    const availableBackgrounds = useSelector(getDeceasedAvailableBackgrounds);
    const dispatch = useDispatch();
    const slug = useSelector(getPageSlug);

    useEffect(() => {
        if (slug) {
            dispatch(fetchBackgroundPhotosApiActions.request());
        }
    }, [dispatch, slug]);

    return {
        selectedBackground,
        avatar,
        availableBackgrounds,
    };
};
