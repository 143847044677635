import React, { ChangeEvent, ReactNode, useRef } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { OutlinedButton } from '../../../components/outlined-button/outlined-button';
import { Section, ISectionProps } from '../../components/section/section';
import { Colors } from '../../../../styles/colors';
import { hexToRgba } from '../../../utils/hex-to-rgba';
import { useDateFormatter } from '../../../utils/use-date-formatter';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { SelectBackgroundModal } from '../../../wizard/photos-step/components/select-background-modal/select-background-modal';
import { ImageCropModal } from '../../../components/image-crop-modal/image-crop-modal';
import { useDeceasedPersonData } from '../../../model/deceased-person/use-deceased-person-data';
import { ReactComponent as CandleIcon } from '../../../assets/icons/candle.svg';
import { deceasedDisplayImagesAvatarReceived } from '../../../model/deceased-display-images/deceased-display-images.actions';
import { getTheme } from '../../../model/theme/selectors/get-theme.selector';

import { useEditorPhotos } from './use-editor-photos';

interface IPhotosSectionProps extends ISectionProps {
    /**
     * Technically this is bad design of components, because
     * these components are hardcoded in sections.
     *
     * Its parent (screen/page) which should use sections and put there
     * children.
     *
     * Its big refactor so just inject a child for now
     */
    topContent?: ReactNode;
}

const useStyles = makeStyles<Theme, { backgroundSrc: string | null }>(
    (theme) => ({
        container: {
            backgroundColor: hexToRgba(Colors.WHISPER, 0.3),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: ({ backgroundSrc }) => {
                if (backgroundSrc) {
                    return `url("${backgroundSrc}")`;
                }
                return '';
            },
            paddingBottom: theme.spacing(8),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(14),
            width: '100%',
        },
        infoContainer: {
            boxShadow: `0 3px 40px -5px ${Colors.IRON}`,
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(5),
            position: 'relative',
            borderRadius: 10,
            backgroundColor: `${Colors.WHITE}d9`,
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 6),
            },
            '&$vg': {
                backgroundColor: `${Colors.WHITE}b3`,
            },
        },
        accountCircle: {
            alignItems: 'center',
            borderRadius: '18rem',
            backgroundColor: Colors.BLACK,
            display: 'flex',
            height: '10rem',
            justifyContent: 'center',
            left: '50%',
            position: 'absolute',
            top: '-1.5rem',
            transform: 'translate(-50%, -50%)',
            width: '10rem',
            objectFit: 'contain',
            [theme.breakpoints.up('sm')]: {
                height: '16rem',
                width: '16rem',
            },
        },
        candleCircle: {
            backgroundColor: Colors.MYSTIC,
        },
        nameBox: {
            alignItems: 'center',
            backgroundColor: Colors.MYSTIC,
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
            boxShadow: `0 5px 9px -3px ${Colors.RHINO}`,
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            padding: theme.spacing(1, 6),
            position: 'absolute',
            right: 0,
            top: '100%',
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                borderRadius: '3px',
                bottom: '-5.4rem',
                left: '50%',
                right: 'initial',
                top: 'initial',
                transform: 'translate(-50%, -50%)',
            },
            '&$vg': {
                borderRadius: 0,
                boxShadow: 'none',
                backgroundColor: Colors.VG_DARK,
                [theme.breakpoints.up('md')]: {
                    fontSize: '18pt',
                },
            },
        },
        name: {
            color: Colors.RHINO,
            fontSize: '1.6rem',
            fontWeight: 'bold',
            [theme.breakpoints.up('sm')]: {
                fontSize: '2.6rem',
            },
            '&$vg': {
                textTransform: 'uppercase',
                color: Colors.WHITE,
                [theme.breakpoints.up('sm')]: {
                    fontSize: '18pt',
                },
            },
        },
        dateType: {
            color: Colors.TONYS_PINK,
            fontSize: '1rem',
            fontWeight: 'bold',
            position: 'relative',
            textTransform: 'uppercase',
            '&:after': {
                content: '""',
                width: '0.6rem',
                height: '1px',
                backgroundColor: Colors.TONYS_PINK,
                position: 'absolute',
                right: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
                [theme.breakpoints.up('sm')]: {
                    right: '-2rem',
                    width: '1rem',
                },
            },
            '&:before': {
                content: '""',
                width: '0.6rem',
                height: '1px',
                backgroundColor: Colors.TONYS_PINK,
                position: 'absolute',
                left: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
                [theme.breakpoints.up('sm')]: {
                    left: '-2rem',
                    width: '1rem',
                },
            },
            '&$vg': {
                fontFamily: 'Raleway',
                fontWeight: 400,
                color: Colors.BLACK,
                [theme.breakpoints.up('sm')]: {
                    fontSize: '11pt',
                },
                '&:after, &:before': {
                    display: 'none',
                },
            },
        },
        date: {
            color: Colors.MINE_SHAFT,
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginTop: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                fontSize: '2rem',
            },
        },
        button: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '21rem',
            },
        },
        backgroundButton: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(2),
                marginTop: 0,
            },
        },
        buttonsContainer: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'center',
            },
        },
        line: {
            backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${hexToRgba(
                Colors.TONYS_PINK,
                0.79,
            )}, rgba(0, 0, 0, 0))`,
            border: 0,
            display: 'none',
            height: '1px',
            left: 0,
            position: 'absolute',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        lineTop: {
            top: '1rem',
            '&$vg': {
                display: 'none',
            },
        },
        lineBottom: {
            bottom: '1rem',
        },
        vg: {},
    }),
);

export const PhotosSection = (props: IPhotosSectionProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    const {
        avatarPreview,
        availableBackgrounds,
        backgroundModalOpen,
        selectedBackgroundID,
        setSelectedBackgroundID,
        backgroundPhotoChangeRequested,
        closeBackgroundPhotoChangeModalRequested,
        sendBackgroundImageId,
        selectedBackgroundSrc,
        uploadFile,
        imageCropModalOpen,
        setImageCropModalOpen,
        selectedFile,
        setSelectedFile,
    } = useEditorPhotos();
    const th = useSelector(getTheme);

    const styles = useStyles({
        backgroundSrc: selectedBackgroundSrc,
    });

    const { deceasedPersonData } = useDeceasedPersonData();

    const { getShortDate } = useDateFormatter();

    const avatarUploadRef = useRef<HTMLInputElement>(null);
    const avatarImageRef = useRef<HTMLImageElement>(null);

    const onChangeAvatarClick = () => {
        avatarUploadRef.current!.click();
    };

    const dispatch = useDispatch();

    const onAvatarUploaded = (event: ChangeEvent<HTMLInputElement>) => {
        if (!get(event, 'target.files.length')) {
            return;
        }

        const file = event.target!.files![0] as File;

        if (file) {
            Resizer.imageFileResizer(
                file,
                400,
                400,
                'JPEG',
                100,
                0,
                (uri) => {
                    const resizedFile = new File([uri as Blob], file.name);
                    setSelectedFile(resizedFile);
                },
                'blob',
                300,
                300,
            );
        }

        setImageCropModalOpen(true);

        // eslint-disable-next-line
        event.target.value = ''; // to prevent blocking same file upload, any cleaner solution?
    };

    return (
        <Section {...props}>
            {props.topContent}
            <Box
                data-testid="overview-deceased-container"
                className={styles.container}
            >
                <Box className={cx(styles.infoContainer, styles[th])}>
                    <Box
                        className={cx(styles.line, styles.lineTop, styles[th])}
                    />
                    <Box className={cx(styles.line, styles.lineBottom)} />
                    <Box
                        data-testid="deceased-birth"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography className={cx(styles.dateType, styles[th])}>
                            {t('common.birth')}
                        </Typography>
                        <Typography className={styles.date}>
                            {deceasedPersonData.birthDate
                                ? getShortDate(deceasedPersonData.birthDate)
                                : '-'}
                        </Typography>
                    </Box>
                    {avatarPreview ? (
                        <img
                            data-testid="deceased-avatar-photo"
                            alt=""
                            className={styles.accountCircle}
                            src={avatarPreview}
                            ref={avatarImageRef}
                        />
                    ) : (
                        <Box
                            data-testid="deceased-avatar-candle"
                            className={cx(
                                styles.accountCircle,
                                styles.candleCircle,
                            )}
                        >
                            <CandleIcon />
                        </Box>
                    )}
                    <Box
                        data-testid="deceased-death"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography className={cx(styles.dateType, styles[th])}>
                            {t('common.death')}
                        </Typography>
                        <Typography className={styles.date}>
                            {deceasedPersonData.deathDate
                                ? getShortDate(deceasedPersonData.deathDate)
                                : '-'}
                        </Typography>
                    </Box>
                    <Box
                        data-testid="deceased-name"
                        className={cx(styles.nameBox, styles[th])}
                    >
                        <Typography className={cx(styles.name, styles[th])}>
                            {deceasedPersonData.name}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={styles.buttonsContainer}>
                <OutlinedButton
                    data-testid="deceased-change-avatar"
                    className={styles.button}
                    onClick={onChangeAvatarClick}
                >
                    {t('overview.changePhotoButton')}
                </OutlinedButton>
                <input
                    ref={avatarUploadRef}
                    type="file"
                    hidden
                    accept="image/jpeg,image/png"
                    max={1}
                    onChange={onAvatarUploaded}
                />
                {(availableBackgrounds.length > 1 || !selectedBackgroundID) && (
                    <OutlinedButton
                        data-testid="deceased-change-background"
                        className={cx(styles.button, styles.backgroundButton)}
                        onClick={backgroundPhotoChangeRequested}
                    >
                        {t('overview.changeBackgroundButton')}
                    </OutlinedButton>
                )}
            </Box>
            <SelectBackgroundModal
                fullScreen={mobile}
                data-testid="wizard-photos-bg-modal"
                backgroundImages={availableBackgrounds}
                onSelected={(id) => {
                    if (id) {
                        sendBackgroundImageId(id);
                    }
                    setSelectedBackgroundID(id);
                    closeBackgroundPhotoChangeModalRequested();
                }}
                open={backgroundModalOpen}
                selectedID={selectedBackgroundID}
                onCancel={closeBackgroundPhotoChangeModalRequested}
            />
            <ImageCropModal
                cancellable
                fileName={get(selectedFile, 'name', '')}
                fullScreen={mobile}
                image={avatarPreview}
                onCancel={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onClose={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onCrop={(file) => {
                    setSelectedFile(file);
                    uploadFile(file);
                    setImageCropModalOpen(false);

                    if (avatarImageRef && avatarImageRef.current) {
                        dispatch(
                            deceasedDisplayImagesAvatarReceived(
                                avatarImageRef.current.src,
                            ),
                        );
                    }
                }}
                open={imageCropModalOpen}
            />
        </Section>
    );
};
