import { apiService } from '../api.service';
import { IMemorialPageMetaDataResponse } from '../page/dto/memorial-page-data-response.dto';

import { IUpdateSettingsDto } from './dto/update-settings.dto';

export const updateSettingsInApi = (
    slug: string,
    dto: Partial<IUpdateSettingsDto>,
): Promise<IMemorialPageMetaDataResponse> =>
    apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                memorialPage: dto,
            },
        })
        .then((resp) => resp.json());
