import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchDictionaryApiActions } from '../dictionaries-api.actions';

import { fetchDictionarySaga } from './fetch-dictionary.saga';

export function* dictionariesApiSaga() {
    yield takeEvery(
        getType(fetchDictionaryApiActions.request),
        fetchDictionarySaga,
    );
}
