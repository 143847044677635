import { put, call, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { updateTributeTextApiActions } from '../tribute-api.actions';
import { updateTributeText } from '../http/update-tribute-text';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { UpdateTributeStatus } from '../../../model/tribute/tribute.store';

export function* updateTributeApiSaga(
    action: ReturnType<typeof updateTributeTextApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const resp = yield call(updateTributeText, {
        slug,
        dto: action.payload,
    });

    if (!resp.errors) {
        yield put(
            updateTributeTextApiActions.success({
                tributeText: resp.data.attributes.tributeText || '',
            }),
        );
    } else if (find(resp.errors, { code: 'not_decent' })) {
        yield put(
            updateTributeTextApiActions.failure({
                status: UpdateTributeStatus.INAPPROPRIATE_WORDS,
            }),
        );
    } else {
        yield put(
            updateTributeTextApiActions.failure({
                status: resp.errors[0].title,
            }),
        );
    }
}
