import React from 'react';
import {
    Box,
    FormControl,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { getHours, getMinutes, set } from 'date-fns';

import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { SecondaryTextButton } from '../../components/secondary-text-button/secondary-text-button';
import { FormTextField } from '../../components/form-text-field/form-text-field';
import { FormDoubleInputsContainer } from '../../components/form-double-inputs-container/form-double-inputs-container';
import { RichTextEditor } from '../../components/rich-text-editor/rich-text-editor';
import { FieldErrorMessage } from '../../components/field-error-message/field-error-message';
import { ITaskData } from '../task/task';
import { createShouldShowError } from '../../utils/should-show-error';
import { ITaskNotYetCreated, TaskStatus } from '../../interfaces/task';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { COUNTRY_CODES } from '../../utils/get-country-codes';

import { TaskFormValidationSchema } from './task-form-validation-schema';

type OuterProps = {
    initialValues?: ITaskNotYetCreated | null;
    onCreate?: (values: ITaskNotYetCreated) => unknown;
    onUpdate?: (values: ITaskNotYetCreated) => unknown;
    onClose(): unknown;
};

type Props = FormikProps<ITaskData> & OuterProps;

const useStyles = makeStyles((theme) => ({
    cancel: {
        marginRight: theme.spacing(2),
    },
}));

export const TaskForm = ({
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    onClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const {
        formatSelectedPickerDate,
        formatSelectedPickerTime,
    } = useDateFormatter();

    const shouldShowError = createShouldShowError(errors, touched);

    const statusOptions = [
        {
            value: TaskStatus.TODO,
            label: t('ceremonyAndReception.taskTodo'),
        },
        {
            value: TaskStatus.IN_PROGRESS,
            label: t('ceremonyAndReception.taskInProgress'),
        },
        {
            value: TaskStatus.DONE,
            label: t('ceremonyAndReception.taskDone'),
        },
    ];

    return (
        <form data-testid="task-request-form-container" onSubmit={handleSubmit}>
            <Box mt={4}>
                <FormControl
                    data-testid="task-request-title"
                    error={shouldShowError('task')}
                    fullWidth
                >
                    <FormTextField
                        error={shouldShowError('task')}
                        name="task"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.task}
                        label={t('ceremonyAndReception.titleLabel')}
                    />
                    <FieldErrorMessage
                        data-testid="task-request-title-error"
                        name="task"
                    />
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormDoubleInputsContainer spacing={2}>
                    <FormControl
                        data-testid="task-request-expiration-date"
                        error={shouldShowError('expirationDate')}
                        fullWidth
                    >
                        <DatePicker
                            error={shouldShowError('expirationDate')}
                            clearable
                            inputVariant="outlined"
                            fullWidth
                            name="expirationDate"
                            onClose={() => {
                                handleBlur({
                                    target: { name: 'expirationDate' },
                                });
                            }}
                            onChange={(date) => {
                                if (date) {
                                    setFieldValue('expirationDate', date);
                                    return;
                                }

                                setFieldValue('expirationDate', null);
                            }}
                            value={values.expirationDate}
                            label={t(
                                'ceremonyAndReception.expirationDateLabel',
                            )}
                            labelFunc={formatSelectedPickerDate}
                            cancelLabel={t('common.cancel')}
                            clearLabel={t('common.delete')}
                        />
                        <FieldErrorMessage
                            data-testid="task-request-expiration-date-error"
                            name="expirationDate"
                        />
                    </FormControl>
                    <FormControl
                        data-testid="task-request-exparation-time"
                        error={shouldShowError('expirationTime')}
                        fullWidth
                    >
                        <TimePicker
                            ampm={i18n.language === COUNTRY_CODES.EN}
                            error={shouldShowError('expirationTime')}
                            clearable
                            inputVariant="outlined"
                            fullWidth
                            name="expirationTime"
                            onClose={() => {
                                handleBlur({
                                    target: { name: 'expirationTime' },
                                });
                            }}
                            onChange={(time) => {
                                if (time) {
                                    setFieldValue('expirationTime', time);
                                    return;
                                }

                                setFieldValue('expirationTime', null);
                            }}
                            value={values.expirationTime}
                            label={t(
                                'ceremonyAndReception.expirationTimeLabel',
                            )}
                            labelFunc={formatSelectedPickerTime}
                            cancelLabel={t('common.cancel')}
                            clearLabel={t('common.delete')}
                        />
                        <FieldErrorMessage
                            data-testid="task-request-expiration-time-error"
                            name="expirationTime"
                        />
                    </FormControl>
                </FormDoubleInputsContainer>
            </Box>
            <Box mt={2}>
                <Select
                    data-testid="task-request-status"
                    error={shouldShowError('status')}
                    name="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    placeholder={t('ceremonyAndReception.statusLabel')}
                    variant="outlined"
                    fullWidth
                >
                    {statusOptions.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                            {status.label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box data-testid="task-request-editor" mt={2}>
                <RichTextEditor
                    minHeight="20rem"
                    minWidth="60rem"
                    name="description"
                    onChange={handleChange}
                    placeholder={t(
                        'ceremonyAndReception.descriptionPlaceholder',
                    )}
                    value={values.description}
                />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <SecondaryTextButton
                    data-testid="task-request-cancel"
                    className={styles.cancel}
                    onClick={onClose}
                >
                    {t('common.cancel')}
                </SecondaryTextButton>
                <SecondaryButton data-testid="task-request-save" type="submit">
                    {t('common.save')}
                </SecondaryButton>
            </Box>
        </form>
    );
};

export const ConnectedTaskForm = compose<Props, OuterProps>(
    withFormik<OuterProps, ITaskData>({
        enableReinitialize: true,
        handleSubmit(values, { props }) {
            const task: ITaskNotYetCreated = {
                description: values.description,
                status: values.status,
                task: values.task,
                expirationDate: null,
            };

            if (values.expirationDate) {
                let day = values.expirationDate;
                const time = values.expirationTime!;

                if (time) {
                    day = set(day, {
                        hours: getHours(time),
                        minutes: getMinutes(time),
                    });
                }

                task.expirationDate = day;
            }

            if (props.onUpdate && props.initialValues) {
                props.onUpdate(task);
            } else if (props.onCreate) {
                props.onCreate(task);
            }
        },
        mapPropsToValues({ initialValues }) {
            if (initialValues) {
                return {
                    task: initialValues.task,
                    status: initialValues.status,
                    description: initialValues.description,
                    expirationDate: initialValues.expirationDate,
                    expirationTime: initialValues.expirationDate,
                };
            }

            return {
                task: '',
                description: '',
                expirationDate: null,
                expirationTime: null,
                status: TaskStatus.TODO,
            };
        },
        validationSchema: () => TaskFormValidationSchema(),
    }),
)(TaskForm);
