import { put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { fetchEventsApiActions } from '../../../api/events/api-events.actions';
import { IMemorialEvent } from '../../../interfaces/memorial-event';
import { editorGetEventsAsyncActions } from '../events.actions';

export function* fetchEventsSaga() {
    yield put(fetchEventsApiActions.request());

    const responseAction:
        | ReturnType<typeof fetchEventsApiActions.success>
        | ReturnType<typeof fetchEventsApiActions.failure> = yield take([
        fetchEventsApiActions.success,
        fetchEventsApiActions.failure,
    ]);

    const mapEventResponseToModel = (payload: IMemorialEvent[]) => {
        return payload.map((event) => ({
            ...event,
            description: event.description || '',
        }));
    };

    switch (responseAction.type) {
        case getType(fetchEventsApiActions.success):
            yield put(
                editorGetEventsAsyncActions.success(
                    mapEventResponseToModel(
                        responseAction.payload as IMemorialEvent[],
                    ),
                ),
            );
            break;
        case getType(fetchEventsApiActions.failure):
            yield put(
                editorGetEventsAsyncActions.failure(responseAction.payload),
            );
            break;
        default:
            yield;
    }
}
