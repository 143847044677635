import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import cx from 'classnames';

import { Colors } from '../../../../styles/colors';

type Props = {
    name: string;
    count?: number;
    active?: boolean;
} & TypographyProps;

const useStyles = makeStyles((theme) => ({
    filter: {
        color: Colors.MINE_SHAFT,
        cursor: 'pointer',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(0.5),
        transition: 'color .3s',
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    filterActive: {
        borderBottom: `2px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
    },
}));

export const Filter = ({
    name,
    count,
    active = false,
    className,
    ...props
}: Props) => {
    const styles = useStyles();

    const filterClass = cx(
        styles.filter,
        { [styles.filterActive]: active },
        className,
    );

    return (
        <Typography className={filterClass} {...props}>
            {active ? `${name} (${count})` : name}
        </Typography>
    );
};
