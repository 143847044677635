import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Section, ISectionProps } from '../../components/section/section';
import { Pagination } from '../../../components/pagination/pagination';
import { SecondaryButton } from '../../../components/secondary-button/secondary-button';
import { IContact } from '../../../interfaces/contact';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { getIsPagePublished } from '../../../settings/model/selectors/get-is-page-published';
import { StatusMessage } from '../../../components/status-message/status-message';

import { ContactsTable } from './contacts-table/contacts-table';
import { ConnectedContactForm } from './contact-form/contact-form';
import { useEditorContacts } from './use-editor-contacts';

export interface IContactsProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    statusMessage: {
        marginTop: theme.spacing(2),
    },
    actionContainer: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
    buttonContainer: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
}));

export const Contacts = (props: IContactsProps) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const published = useSelector(getIsPagePublished);
    const styles = useStyles();
    const {
        contacts,
        contactsCount,
        selectedContactsIds,
        allContactsSelected,
        toggleAllContactsSelected,
        toggleContactSelected,
        perPageChanged,
        nextPageRequested,
        prevPageRequested,
        currentPage,
        pagesCount,
        perPage,
        addNotificationContact,
        selectedFilter,
        sendNotifications,
        setSelectedFilter,
        contactSuggestionStatus,
        contactSuggestionStatusMessage,
    } = useEditorContacts();

    const onSubmit = (contact: IContact) => {
        addNotificationContact(contact);
    };

    return (
        <Section
            title={t('notifications.contacts')}
            description={t('notifications.contactsDescription')}
            {...props}
        >
            <Box mt={6} mb={4}>
                <ConnectedContactForm
                    onSubmit={onSubmit}
                    suggestionStatus={contactSuggestionStatus}
                    statusMessage={contactSuggestionStatusMessage}
                />
            </Box>
            <ContactsTable
                contacts={contacts}
                contactsCount={contactsCount}
                onFilterChange={setSelectedFilter}
                selectedFilter={selectedFilter}
                selectedContactsIds={selectedContactsIds}
                allContactsSelected={allContactsSelected}
                toggleAllContactsSelected={toggleAllContactsSelected}
                toggleContactSelected={toggleContactSelected}
            />
            {!published && (
                <StatusMessage
                    className={styles.statusMessage}
                    warning={!published}
                >
                    {t('notifications.notPublishedWarning')}
                </StatusMessage>
            )}
            {contacts.length > 0 && (
                <Box
                    mt={3}
                    display={isMobile ? 'block' : 'flex'}
                    className={styles.actionContainer}
                >
                    <Pagination
                        currentPage={currentPage}
                        pagesCount={pagesCount}
                        perPage={perPage}
                        onNextClick={nextPageRequested}
                        onPrevClick={prevPageRequested}
                        onPerPageChange={perPageChanged}
                    />
                    <Box className={styles.buttonContainer}>
                        <SecondaryButton
                            data-testid="contact-send-message"
                            onClick={sendNotifications}
                            fullWidth={isMobile}
                            disabled={!published}
                        >
                            {t('notifications.sendMessageToSelectedButton')}
                        </SecondaryButton>
                    </Box>
                </Box>
            )}
        </Section>
    );
};
