import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    createEventFormSubmitted,
    deleteEventAsyncActions,
    editorGetEventsAsyncActions,
    updateEventFormSubmitted,
} from '../events.actions';

import { fetchEventsSaga } from './fetch-events.saga';
import { createEventSaga } from './create-event.saga';
import { updateEventSaga } from './update-event.saga';
import { deleteEventSaga } from './delete-event.saga';

export function* eventsSaga() {
    yield takeEvery(
        getType(editorGetEventsAsyncActions.request),
        fetchEventsSaga,
    );
    yield takeEvery(getType(createEventFormSubmitted), createEventSaga);
    yield takeEvery(getType(updateEventFormSubmitted), updateEventSaga);
    yield takeEvery(getType(deleteEventAsyncActions.request), deleteEventSaga);
}
