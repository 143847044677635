import { makeStyles } from '@material-ui/core';

export const useObituaryFormStyles = makeStyles(() => ({
    obituaryTextContainer: {
        position: 'relative',
    },
    obituaryTextEditor: {
        '& .ql-container ': {
            fontSize: '1.5rem',
        },
    },
    hiddenObituaryTextEditor: {
        '& .ql-editor ': {
            visibility: 'hidden',
        },
    },
}));
