import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Section, ISectionProps } from '../editor/components/section/section';

import { SuggestedGalleryItem } from './components/suggested-gallery-item/suggested-gallery-item';
import { useGallerySuggestions } from './use-gallery-suggestions';

interface IGallerySuggestionsProps extends ISectionProps {}

const useStyles = makeStyles(() => ({
    item: {
        width: '100%',
        paddingBottom: '100%',
    },
}));

export const GallerySuggestions = (props: IGallerySuggestionsProps) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const {
        galleryItems,
        acceptGallerySuggestion,
        rejectGallerySuggestion,
    } = useGallerySuggestions();

    return (
        <Section
            title={t('mediaAndContent.suggestionsGallery')}
            description={t('mediaAndContent.suggestionsGalleryDescription')}
            {...props}
        >
            <Grid
                data-testid="gallery-suggestions-pictures-container"
                container
                spacing={2}
            >
                {galleryItems.map(({ thumbSrc, id, suggestionId }) => (
                    <Grid
                        data-testid={`gallery-suggestions-picture-${id}`}
                        item
                        xs={6}
                        sm={3}
                        key={id}
                    >
                        <SuggestedGalleryItem
                            className={styles.item}
                            onAcceptClick={() => {
                                acceptGallerySuggestion(suggestionId);
                            }}
                            onRejectClick={() => {
                                rejectGallerySuggestion(suggestionId);
                            }}
                            src={thumbSrc}
                        />
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
};
