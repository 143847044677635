import { createAction } from 'typesafe-actions';

import { PrivacyMode } from '../../interfaces/privacy-mode';

export const changeSettingsRequested = createAction(
    `editor/settings/CHANGE_SETTINGS_REQUESTED`,
    (action) => (
        payload: Partial<{ death: boolean; birth: boolean; images: boolean }>,
    ) => action(payload),
);

export const changeSettingsDataSucceed = createAction(
    `editor/settings/CHANGE_DATES_VISIBILITY_SUCCEED`,
    (action) => (
        payload: Partial<{ death: boolean; birth: boolean; images: boolean }>,
    ) => action(payload),
);

export const pageSettingsDataRetrieved = createAction(
    `editor/settings/PAGE_PLACES_VISIBILITY_SETTINGS_RETRIEVED`,
    (action) => (
        payload: Partial<{ death: boolean; birth: boolean; images: boolean }>,
    ) => action(payload),
);

export const changePrivacyModeRequested = createAction(
    'editor/settings/CHANGE_PRIVACY_MODE_REQUESTED',
    (action) => (payload: PrivacyMode) => action(payload),
);

export const changePrivacyModeSucceed = createAction(
    'editor/settings/CHANGE_PRIVACY_MODE_SUCCEED',
    (action) => (payload: PrivacyMode) => action(payload),
);

export const pagePrivacyModeRetrieved = createAction(
    'editor/settings/PAGE_PRIVACY_MODE_RETRIEVED',
    (action) => (payload: PrivacyMode) => action(payload),
);

export const changePagePublishedStatusRequested = createAction(
    `edit/settings/CHANGE_PAGE_PUBLISHED_STATUS_REQUESTED`,
    (action) => (payload: { published: boolean }) => action(payload),
);

export const changePagePublishedStatusSucceed = createAction(
    `edit/settings/CHANGE_PAGE_PUBLISHED_STATUS_SUCCEED`,
    (action) => (payload: { published: boolean }) => action(payload),
);

export const pagePublishedStatusRetrieved = createAction(
    `edit/settings/CHANGE_PAGE_PUBLISHED_STATUS_SUCCEED`,
    (action) => (payload: { published: boolean }) => action(payload),
);
