import { call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import { backgroundImageChangeActions } from '../photos.actions';
import { updateMemorialBackground } from '../../../../../api/background-photos/http/update-memorial-background';
import { getPageSlug } from '../../../../../meta/model/selectors/get-page-slug.selector';
import { IMemorialPageMetaDataResponse } from '../../../../../api/page/dto/memorial-page-data-response.dto';
import { deceasedDisplayImagesReceived } from '../../../../../model/deceased-display-images/deceased-display-images.actions';

// TODO: Move to shared util
const extractDisplayImagesFromResp = (resp: IMemorialPageMetaDataResponse) => {
    const backgroundData = resp.included.find(
        (incl) => incl.type === 'background',
    );
    const deceasedData = resp.included.find(
        (incl) => incl.type === 'deceased_person',
    );

    return {
        avatarSrc: get(deceasedData, 'attributes.photoOriginalUrl', null),
        backgroundID: get(backgroundData, 'id', null),
    };
};

/**
 * TODO: Move to API
 */
export function* sendBackgroundIdSaga(
    action: ReturnType<typeof backgroundImageChangeActions.request>,
) {
    const { backgroundID } = action.payload;
    const slug = yield select(getPageSlug);

    try {
        const resp: IMemorialPageMetaDataResponse = yield call(
            updateMemorialBackground,
            slug,
            backgroundID,
        );
        yield put(backgroundImageChangeActions.success());
        yield put(
            deceasedDisplayImagesReceived(extractDisplayImagesFromResp(resp)),
        );
    } catch (err) {
        yield put(backgroundImageChangeActions.failure(err));
    }
}
