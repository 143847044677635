import React from 'react';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../styles/use-is-mobile';
import { IParticipant } from '../../../interfaces/participant';
import { IMemorialEvent } from '../../../interfaces/memorial-event';

const useStyles = makeStyles((theme) => ({
    row: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateAreas: `"name name name name" "email email email email" "phone phone phone phone" "event event event event"`,
        },
    },
    bodyCell: {
        fontSize: '1.6rem',
        paddingLeft: 0,
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    emailCell: {
        gridArea: 'email',
    },
    phoneCell: { gridArea: 'phone' },
    eventCell: { gridArea: 'event' },
    nameCell: {
        gridArea: 'name',
        wordBreak: 'break-all',
    },
    link: {
        color: theme.palette.primary.light,
        display: 'block',
        maxWidth: '30rem',
        overflowWrap: 'break-word',
        wordBreak: 'break-all',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    mobileCell: {
        borderBottom: 'none',
        paddingBottom: 0,
    },
}));

type Props = IParticipant & {
    event?: IMemorialEvent;
    onEventTitleClick: (event: IMemorialEvent) => void;
};

export const ParticipantsTableRow = ({
    fullName,
    phone,
    email,
    event,
    onEventTitleClick,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const isMobile = useIsMobile();

    const nameLabel = (
        <Typography variant="caption">
            {t('participants.tableColumns.name')}
        </Typography>
    );

    const emailLabel = (
        <Typography variant="caption">
            {t('participants.tableColumns.email')}
        </Typography>
    );

    const phoneLabel = (
        <Typography variant="caption">
            {t('participants.tableColumns.phone')}
        </Typography>
    );

    const eventLabel = (
        <Typography variant="caption">
            {t('participants.tableColumns.event')}
        </Typography>
    );

    const handleEventTitleClick = () => {
        if (event) {
            onEventTitleClick(event);
        }
    };

    return (
        <TableRow className={styles.row}>
            <TableCell
                data-testid="participant-name"
                className={cx(styles.bodyCell, styles.nameCell, {
                    [styles.mobileCell]: isMobile,
                })}
            >
                {isMobile && nameLabel}
                <Typography>{fullName}</Typography>
            </TableCell>
            <TableCell
                data-testid="participant-email"
                className={cx(styles.bodyCell, styles.emailCell, {
                    [styles.mobileCell]: isMobile,
                })}
            >
                {isMobile && emailLabel}
                <a className={styles.link} href={`mailto:${email}`}>
                    {email}
                </a>
            </TableCell>
            <TableCell
                data-testid="participant-phone"
                className={cx(styles.bodyCell, styles.phoneCell, {
                    [styles.mobileCell]: isMobile,
                })}
            >
                {isMobile && phoneLabel}
                <Typography>{phone}</Typography>
            </TableCell>
            <TableCell
                data-testid="participant-event"
                className={cx(styles.bodyCell, styles.eventCell)}
            >
                {isMobile && eventLabel}
                {event ? (
                    <Typography
                        onClick={handleEventTitleClick}
                        className={styles.link}
                    >
                        {event.title}
                    </Typography>
                ) : (
                    <Typography>-</Typography>
                )}
            </TableCell>
        </TableRow>
    );
};
