import { createAction, createAsyncAction } from 'typesafe-actions';

import { IContact, ICreatedContact } from '../../../../interfaces/contact';
import { NotificationContactStatus } from '../../../../interfaces/notification-contact-status';

export const getNotificationContacts = createAsyncAction(
    'editor/contacts/GET_NOTIFICATION_CONTACTS_REQUESTED',
    'editor/contacts/GET_NOTIFICATION_CONTACTS_SUCCEED',
    'editor/contacts/GET_NOTIFICATION_CONTACTS_FAILED',
)<undefined, ICreatedContact[], Error>();

export const addNotificationContact = createAsyncAction(
    'editor/contacts/ADD_NOTIFICATION_CONTACT_REQUESTED',
    'editor/contacts/ADD_NOTIFICATION_CONTACT_SUCCEED',
    'editor/contacts/ADD_NOTIFICATION_CONTACT_FAILED',
)<IContact, ICreatedContact, Error>();

export const toggleAllContactsSelected = createAction(
    'editor/contacts/TOGGLE_ALL_CONTACTS_SELECTED',
    (action) => (payload: { filter: NotificationContactStatus }) =>
        action(payload),
);

export const toggleContactSelected = createAction(
    'editor/contacts/TOGGLE_CONTACT_SELECTED',
    (action) => (payload: { id: string }) => action(payload),
);

export const toggleManyContactsSelected = createAction(
    'editor/contacts/TOGGLE_MANY_CONTACTS_SELECTED',
    (action) => (payload: { ids: string[] }) => action(payload),
);

export const toggleAllContactsNotSelected = createAction(
    'editor/contacts/TOGGLE_ALL_CONTACTS_NOT_SELECTED',
);

export const perPageChanged = createAction(
    'editor/contacts/PER_PAGE_CHANGED',
    (action) => (payload: { perPage: number }) => action(payload),
);

export const nextPageRequested = createAction(
    'editor/contacts/NEXT_PAGE_REQUESTED',
);

export const prevPageRequested = createAction(
    'editor/contacts/PREV_PAGE_REQUESTED',
);

export const pagingResetRequested = createAction(
    'editor/contacts/PAGING_RESET_REQUESTED',
);

export const suggestedContactRejectionRequested = createAction(
    'editor/contacts/SUGGESTED_CONTACT_REJECTION_REQUESTED',
    (action) => (payload: { id: string }) => action(payload),
);

export const contactDeletionRequested = createAction(
    'editor/contacts/CONTACT_DELETION_REQUESTED',
    (action) => (payload: { id: string }) => action(payload),
);
