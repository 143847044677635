import { createSelector } from 'reselect';

import { RootStore } from '../../../../store/store';

const getContextStore = (store: RootStore) => store.auth.contextSelection;

const getContexts = createSelector(
    getContextStore,
    (store) => store.contexts,
);

export const ContextSelectors = {
    getContexts,
};
