import { IConfirmImageOnServerDto } from '../../image-upload/dto/confirm-image-on-server.dto';
import { apiService } from '../../api.service';
import { IGalleryResponseDto } from '../dto/gallery-response.dto';

export const sendNewGalleryItemToApi = (
    slug: string,
    data: IConfirmImageOnServerDto,
): Promise<IGalleryResponseDto> => {
    return apiService
        .post(`/memorial_pages/${slug}/gallery_photos`, {
            json: {
                gallery_photo: {
                    published: true,
                    image: JSON.stringify(data),
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
};
