import * as yup from 'yup';

import { IForgotPasswordFormValues } from './forgot-password-form-values';

export const forgotPasswordFormValidationSchema = () =>
    yup.object<IForgotPasswordFormValues>({
        email: yup
            .string()
            .email()
            .required(),
    });
