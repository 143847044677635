import { createAction } from 'typesafe-actions';

import { ObituaryStatus } from './obituary.store';

export const obituaryReceived = createAction(
    'model/obituary/OBITUARY_RECEIVED',
    (action) => (payload: { obituaryText: string }) => action(payload),
);

export const generatedObituaryReceived = createAction(
    'model/obituary/GENERATED_OBITUARY_RECEIVED',
    (action) => (payload: { obituaryText: string }) => action(payload),
);

export const setObituaryStatus = createAction(
    'model/obituary/SET_UPDATE_OBITUARY_STATUS',
    (action) => (payload: ObituaryStatus | null) => action(payload),
);
