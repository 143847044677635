import { apiService } from '../../api.service';

export const acceptSuggestion = ({
    slug,
    id,
}: {
    slug: string;
    id: string;
}): Promise<Response> =>
    apiService
        .patch(`/memorial_pages/${slug}/suggestions/${id}/accept`)
        .then((resp) => resp.json());
