import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';

import { memorialPageSlugReceived } from './model/meta.actions';

const withPageSlugDetectingWithoutRouter = <
    Props extends RouteComponentProps<{ slug: string }>
>(
    Component: React.ComponentType,
) => (props: Props) => {
    const dispatch = useDispatch();
    const { slug } = props.match.params;

    useEffect(() => {
        dispatch(
            memorialPageSlugReceived({
                slug,
            }),
        );
    }, [slug, dispatch]);

    return <Component {...props} />;
};

export const withPageSlugDetecting = compose<
    RouteComponentProps<{ slug: string }>,
    {}
>(
    withRouter,
    withPageSlugDetectingWithoutRouter,
);
