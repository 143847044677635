import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { getOverviewTasksAsyncActions } from '../overview-tasks.actions';

import { fetchOverviewTasksSaga } from './get-overview-tasks.saga';

export function* overviewTasksSaga() {
    yield takeEvery(
        getType(getOverviewTasksAsyncActions.request),
        fetchOverviewTasksSaga,
    );
}
