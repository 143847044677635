import { createAction, createAsyncAction } from 'typesafe-actions';

export const backgroundImageChangeActions = createAsyncAction(
    'editor/photos/BACKGROUND_IMAGE_CHANGE_SUBMIT_REQUESTED',
    'editor/photos/BACKGROUND_IMAGE_CHANGE_SUBMIT_SUCCEED',
    'editor/photos/BACKGROUND_IMAGE_CHANGE_SUBMIT_FAILED',
)<{ backgroundID: string }, undefined, Error>();

export const avatarPhotoUploadActions = createAsyncAction(
    'editor/photos/AVATAR_PHOTO_UPLOAD_REQUESTED',
    'editor/photos/AVATAR_PHOTO_UPLOAD_SUCCEED',
    'editor/photos/AVATAR_PHOTO_UPLOAD_FAILED',
)<{ avatar: File }, undefined, Error>();

export const backgroundPhotoChangeRequested = createAction(
    'editor/photos/BACKGROUND_PHOTO_CHANGE_REQUESTED',
);

export const closeBackgroundPhotoChangeModalRequested = createAction(
    'editor/photos/CLOSE_BACKGROUND_PHOTO_CHANGE_MODAL_REQUESTED',
);
