import { apiService } from '../../api.service';
import { ISendInvitationResponseDto } from '../dto/send-invitation-response.dto';

export const sendInvitations = (slug: string, participantsIDs: string[]) =>
    apiService
        .post(`/memorial_pages/${slug}/send_invitations`, {
            json: {
                participantIds: participantsIDs,
            },
        })
        .then((resp) => resp.json())
        .then((responseBody: ISendInvitationResponseDto) => {
            return responseBody.data.map((item) => ({
                ...item.attributes,
                id: item.id,
                contactId: item.relationships.role.data.id,
            }));
        });
