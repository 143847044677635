import { utcToZonedTime } from 'date-fns-tz';

import { apiService } from '../api.service';
import { ITask } from '../../interfaces/task';

import { ICreatedTaskDto } from './dto/created-task-dto';
import { TaskResponseDto } from './dto/task-response.dto';

export const updateTask = (
    dto: Partial<ICreatedTaskDto>,
    id: string,
): Promise<ITask> => {
    const url = `/tasks/${id}`;

    return apiService
        .patch(url, {
            json: dto,
        })
        .then((resp) => resp.json())
        .then((response): TaskResponseDto => response.data)
        .then(
            (taskResponse): ITask => ({
                description: taskResponse.attributes.description,
                expirationDate: taskResponse.attributes.deadlineTimestamp
                    ? utcToZonedTime(
                          taskResponse.attributes.deadlineTimestamp,
                          taskResponse.attributes.timeZone,
                      )
                    : null,
                id: taskResponse.id,
                task: taskResponse.attributes.title,
                status: taskResponse.attributes.status,
            }),
        );
};
