import { call, put, select } from '@redux-saga/core/effects';

import { updateMilestoneApiActions } from '../milestones-api.actions';
import { IMilestoneResponseDto } from '../dto/milestone-response.dto';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { updateMilestone } from '../http/update-milestone';

export function* updateMilestoneApiSaga(
    action: ReturnType<typeof updateMilestoneApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id, ...dto } = action.payload;

    try {
        const response: IMilestoneResponseDto = yield call(
            updateMilestone,
            dto,
            slug,
            id,
        );
        yield put(
            updateMilestoneApiActions.success({
                year: response.attributes.year,
                month: response.attributes.month,
                description: response.attributes.description,
                id: response.id,
                title: response.attributes.title,
            }),
        );
    } catch (e) {
        yield put(updateMilestoneApiActions.failure(e.statusText));
    }
}
