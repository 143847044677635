import { createAsyncAction } from 'typesafe-actions';

import { IPageSettings } from '../../interfaces/page-settings';

import { IUpdateSettingsDto } from './dto/update-settings.dto';

export const updateSettingsApiActions = createAsyncAction(
    'api/settings/UPDATE_REQUEST',
    'api/settings/UPDATE_SUCCEED',
    'api/settings/UPDATE_FAILED',
)<Partial<IUpdateSettingsDto>, IPageSettings, Error>();
