import { createSelector } from 'reselect';

import { sortEventsByDate } from '../../../../../utils/sort-events-by-date';

import { getOverviewEventsStore } from './get-overview-events-store.selector';

export const getOverviewEvents = createSelector(
    getOverviewEventsStore,
    (store) => store.events.sort(sortEventsByDate),
);
