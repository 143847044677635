import { createAsyncAction } from 'typesafe-actions';

import { IMemorialPageMetaDataResponse } from '../page/dto/memorial-page-data-response.dto';

import { IUpdateVgDto } from './dto/update-vg-dto';

export const updateVgApiActions = createAsyncAction(
    'api/vg/UPDATE_VG_DATA_REQUESTED',
    'api/vg/UPDATE_VG_DATA_SUCCEED',
    'api/vg/UPDATE_VG_DATA_FAILED',
)<IUpdateVgDto, IMemorialPageMetaDataResponse, string>();
