import { put, take } from '@redux-saga/core/effects';

import { fetchOverviewApiActions } from '../../../api/overview/overview-api.actions';
import { overviewReceived } from '../overview.actions';

export function* fetchOverviewSaga() {
    yield put(fetchOverviewApiActions.request());

    const resp: ReturnType<typeof fetchOverviewApiActions.success> = yield take(
        fetchOverviewApiActions.success,
    );

    yield put(overviewReceived(resp.payload));
}
