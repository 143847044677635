import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../components/page-title/page-title';
import { Gallery } from '../../gallery/gallery';
import { GallerySuggestions } from '../../gallery-suggestions/gallery-suggestions';
import { Milestones } from '../../milestones/milestones';
import { Tribute } from '../../tribute/tribute';

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(5),
    },
}));

export const MediaAndContentScreen = () => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Box data-testid="media-content-page-container" mb={3}>
            <PageTitle>{t('mediaAndContent.title')}</PageTitle>
            <Gallery className={styles.section} />
            <GallerySuggestions className={styles.section} />
            <Tribute className={styles.section} />
            <Milestones className={styles.section} />
        </Box>
    );
};
