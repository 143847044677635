import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { passwordsFormSubmitted } from '../password-page.actions';

import { submitPasswordsSaga } from './submit-passwords.saga';

export function* authPasswordsSaga() {
    yield takeEvery(getType(passwordsFormSubmitted), submitPasswordsSaga);
}
