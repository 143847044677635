import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SectionHeadline } from '../../../../../components/section-headline/section-headline';
import {
    ObituaryQuestion,
    ObituaryQuestionAndAnswer,
} from '../../../../model/obituary.types';

import { useObituaryQuestionsAndAnswersStyles } from './obituary-questions-and-answers.styles';
import { ObituaryQuestionsAndAnswersItem } from './obituary-questions-and-answers-item';

export interface IObituaryQuestionsAndAnswersProps {
    obituaryQuestionsAndAnswers: ObituaryQuestionAndAnswer[];
    onObituaryQuestionsAndAnswersChange: (
        obituaryQuestionsAndAnswers: ObituaryQuestionAndAnswer[],
    ) => void;
}

export const ObituaryQuestionsAndAnswers = ({
    obituaryQuestionsAndAnswers,
    onObituaryQuestionsAndAnswersChange,
}: IObituaryQuestionsAndAnswersProps) => {
    const { t } = useTranslation();
    const styles = useObituaryQuestionsAndAnswersStyles();

    const questions = Object.values(ObituaryQuestion);

    function handleObituaryQuestionAndAnswerValueChange<T>(
        fieldName: keyof ObituaryQuestionAndAnswer,
    ) {
        return (question: ObituaryQuestion, fieldValue: T) => {
            const questionIndex = obituaryQuestionsAndAnswers.findIndex(
                (questionAndAnswer) => questionAndAnswer.question === question,
            );
            const obituaryQuestionAndAnswer =
                obituaryQuestionsAndAnswers[questionIndex];

            const isNewAnswer = !obituaryQuestionAndAnswer;
            if (isNewAnswer) {
                return onObituaryQuestionsAndAnswersChange([
                    ...obituaryQuestionsAndAnswers,
                    {
                        question,
                        answer: '',
                        info: '',
                        [fieldName]: fieldValue,
                    },
                ]);
            }
            const answer =
                fieldName === 'answer'
                    ? fieldValue
                    : obituaryQuestionAndAnswer.answer;
            const info =
                fieldName === 'info'
                    ? fieldValue
                    : obituaryQuestionAndAnswer.info;

            const isQuestionWithoutAnswer = !answer && !info;
            if (isQuestionWithoutAnswer) {
                return onObituaryQuestionsAndAnswersChange([
                    ...obituaryQuestionsAndAnswers.slice(0, questionIndex),
                    ...obituaryQuestionsAndAnswers.slice(questionIndex + 1),
                ]);
            }

            return onObituaryQuestionsAndAnswersChange([
                ...obituaryQuestionsAndAnswers.slice(0, questionIndex),
                {
                    ...obituaryQuestionAndAnswer,
                    [fieldName]: fieldValue,
                },
                ...obituaryQuestionsAndAnswers.slice(questionIndex + 1),
            ]);
        };
    }

    return (
        <Box className={styles.questionsAndAnswers}>
            <Box mb={3}>
                <SectionHeadline>
                    {t('obituary.questionsAndAnswers.headline')}
                </SectionHeadline>
            </Box>
            <Box display="flex" flexDirection="column">
                {questions.map((question, index) => {
                    const obituaryQuestionWithoutAnswer = {
                        question,
                        answer: '',
                        info: '',
                    };

                    const obituaryQuestionWithAnswer = obituaryQuestionsAndAnswers.find(
                        (questionAndAnswer) =>
                            questionAndAnswer.question === question,
                    );

                    const obituaryQuestionAndAnswer =
                        obituaryQuestionWithAnswer ||
                        obituaryQuestionWithoutAnswer;

                    const questionNumber = index + 1;
                    const isLastQuestion = questionNumber === questions.length;

                    return (
                        <ObituaryQuestionsAndAnswersItem
                            key={question}
                            questionNumber={questionNumber}
                            isLastQuestion={isLastQuestion}
                            obituaryQuestionAndAnswer={
                                obituaryQuestionAndAnswer
                            }
                            onObituaryQuestionAndAnswerValueChange={
                                handleObituaryQuestionAndAnswerValueChange
                            }
                        />
                    );
                })}
            </Box>
        </Box>
    );
};
