import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMemorialEvent } from '../interfaces/memorial-event';
import { EventType } from '../interfaces/event-type';

import { getEvents } from './model/selectors/get-events.selector';
import { getCreateModalOpen } from './model/selectors/get-create-modal-open.selector';
import { getUpdateModalOpen } from './model/selectors/get-update-modal-open.selector';
import { getEditedEvent } from './model/selectors/get-edited-event.selector';
import {
    closeEventCreateModalRequested,
    closeEventUpdateModalRequested,
    createEventFormSubmitted,
    deleteEventAsyncActions,
    eventCreationRequested,
    eventUpdateRequested,
    editorGetEventsAsyncActions,
    updateEventFormSubmitted,
} from './model/events.actions';
import { IEventFormData } from './event-form-data';

export const useEvents = () => {
    const dispatch = useDispatch();
    const events = useSelector(getEvents);
    const createModalOpen = useSelector(getCreateModalOpen);
    const updateModalOpen = useSelector(getUpdateModalOpen);
    const editedEvent = useSelector(getEditedEvent);

    const isCeremonyPrivate =
        editedEvent && events.find((event) => event.type === EventType.CEREMONY)
            ? editedEvent.type === EventType.CEREMONY &&
              !!events.find((event) => event.type === EventType.CEREMONY)!
                  .isPrivate
            : false;

    useEffect(() => {
        dispatch(editorGetEventsAsyncActions.request());
    }, [dispatch]);

    return {
        createModalOpen,
        updateModalOpen,
        eventCreationRequested() {
            dispatch(eventCreationRequested());
        },
        closeEventCreateModalRequested() {
            dispatch(closeEventCreateModalRequested());
        },
        eventUpdateRequested(event: IMemorialEvent) {
            dispatch(
                eventUpdateRequested({
                    editedEvent: event,
                }),
            );
        },
        closeEventUpdateModalRequested() {
            dispatch(closeEventUpdateModalRequested());
        },
        events,
        editedEvent,
        onCreate(values: IEventFormData) {
            dispatch(
                createEventFormSubmitted({
                    event: {
                        ...values,
                        date: values.date ? new Date(values.date) : null,
                        time:
                            values.date && values.time
                                ? new Date(values.time)
                                : null,
                    },
                }),
            );
        },
        onUpdate(values: IEventFormData, id: string) {
            dispatch(
                updateEventFormSubmitted({
                    event: {
                        ...values,
                        date: values.date ? new Date(values.date) : null,
                        time:
                            values.date && values.time
                                ? new Date(values.time)
                                : null,
                    },
                    id,
                }),
            );
        },
        onDelete(id: string) {
            dispatch(deleteEventAsyncActions.request({ id }));
        },
        isCeremonyPrivate,
    };
};
