import { put, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { push } from 'connected-react-router';

import { wizardNotificationsFormSubmitted } from '../wizard-notifications.actions';
import { sendNotificationsToContactsApiActions } from '../../../../api/contacts/contacts-api.actions';
import { getWizardCreatedContacts } from '../selectors/get-wizard-created-contacts.selector';
import { ICreatedContact } from '../../../../interfaces/contact';
import { getPageSlug } from '../../../../meta/model/selectors/get-page-slug.selector';
import { updateMemorialPageCreationStepApiActions } from '../../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';

export function* wizardNotificationFormSubmitSaga(
    action: ReturnType<typeof wizardNotificationsFormSubmitted>,
) {
    const contacts: ICreatedContact[] = yield select(getWizardCreatedContacts);

    const slug = yield select(getPageSlug);

    yield put(
        sendNotificationsToContactsApiActions.request({
            ids: contacts.map((contact) => contact.id),
        }),
    );

    yield take(getType(sendNotificationsToContactsApiActions.success));

    yield put(
        updateMemorialPageCreationStepApiActions.request({
            step: MemorialPageCreationStep.FINISHED,
        }),
    );

    yield take(updateMemorialPageCreationStepApiActions.success);

    yield put(
        push(
            resolveRouteFromMemorialCreationStep(
                slug,
                /**
                 * Or login?
                 */
                MemorialPageCreationStep.FINISHED,
            ),
        ),
    );

    /**
     * TODO: Handle error with update step
     */
}
