import { put, take } from '@redux-saga/core/effects';

import { rejectGallerySuggestionApiActions } from '../../../api/gallery-suggestions/gallery-suggestions.actions';
import {
    gallerySuggestionRejectionRequested,
    removeGallerySuggestion,
} from '../gallery-suggestions.actions';

export function* rejectGallerySuggestionSaga(
    action: ReturnType<typeof gallerySuggestionRejectionRequested>,
) {
    const { id } = action.payload;

    yield put(rejectGallerySuggestionApiActions.request({ id }));

    yield take(rejectGallerySuggestionApiActions.success);

    yield put(removeGallerySuggestion({ id }));
}
