import { call, put, select } from 'redux-saga/effects';

import { fetchTasksApiActions } from '../api-tasks.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { fetchTasks } from '../fetch-tasks';

export function* fetchTasksApiSaga(
    action: ReturnType<typeof fetchTasksApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response = yield call(fetchTasks, slug);
        yield put(fetchTasksApiActions.success(response));
    } catch (e) {
        yield put(fetchTasksApiActions.failure(e.statusText));
    }
}
