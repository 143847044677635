import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    divider: {
        width: '50px',
        background: theme.palette.primary.light,
        height: '2px',
        margin: '0 auto',
    },
}));

export const ShortDivider = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => {
    const styles = useStyles();

    return <div className={cx(styles.divider, className)} {...props} />;
};
