import React, { useState } from 'react';
import {
    Box,
    ClickAwayListener,
    makeStyles,
    Typography,
} from '@material-ui/core';
import {
    KeyboardArrowRight,
    KeyboardArrowDown,
    KeyboardArrowLeft,
} from '@material-ui/icons';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Colors } from '../../../styles/colors';
import { useIsMobile } from '../../../styles/use-is-mobile';

type Props = {
    currentPage: number;
    pagesCount: number;
    perPage: number;
    onNextClick(): unknown;
    onPrevClick(): unknown;
    onPerPageChange(perPage: number): unknown;
    perPageOptions?: number[];
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'inline-flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    paginationContainer: {
        border: `1px solid ${Colors.SILVER}`,
        borderRadius: '4px',
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    pagesBox: {
        borderLeft: `1px solid ${Colors.SILVER}`,
        borderRight: `1px solid ${Colors.SILVER}`,
        padding: theme.spacing(0.75, 1.75),
        display: 'flex',
        alignItems: 'center',
    },
    box: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: theme.spacing(0.75, 1.5),
    },
    boxPerPage: {
        borderRight: `1px solid ${Colors.SILVER}`,
        cursor: 'initial',
    },
    boxDisabled: {
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    text: {
        color: Colors.DOVE_GRAY,
        fontWeight: 'bold',
    },
    icon: {
        color: Colors.DOVE_GRAY,
    },
    perPageDropdown: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${Colors.SILVER}`,
        borderRadius: '4px',
        position: 'absolute',
        right: 0,
        top: `calc(100% + 2px)`,
        width: '100%',
        zIndex: 1,
    },
    perPageItem: {
        color: Colors.DOVE_GRAY,
        fontWeight: 'bold',
        padding: theme.spacing(1, 0),
        textAlign: 'center',
        '&:hover': {
            backgroundColor: Colors.SILVER,
        },
    },
}));

const PAGE_OPTIONS = [5, 10, 15, 25];

export const Pagination = ({
    currentPage,
    pagesCount,
    perPage,
    onNextClick,
    onPrevClick,
    onPerPageChange,
    perPageOptions,
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const [perPageDropdownOpen, setPerPageDropdownOpen] = useState(false);
    const isMobile = useIsMobile();
    const pageOptions = perPageOptions || PAGE_OPTIONS;

    const prevClass = cx(styles.box, {
        [styles.boxDisabled]: currentPage === 1,
    });

    const nextClass = cx(styles.box, {
        [styles.boxDisabled]: currentPage === pagesCount,
    });

    return (
        <Box className={styles.container}>
            <Box
                data-testid="contact-paggination-prev-next"
                className={styles.paginationContainer}
            >
                <Box className={prevClass} onClick={onPrevClick}>
                    <KeyboardArrowLeft className={styles.icon} />
                    {!isMobile && (
                        <Typography className={styles.text}>
                            {t('pagination.prev')}
                        </Typography>
                    )}
                </Box>
                <Box className={styles.pagesBox}>
                    <Typography className={styles.text}>
                        {currentPage}/{pagesCount}
                    </Typography>
                </Box>
                <Box className={nextClass} onClick={onNextClick}>
                    {!isMobile && (
                        <Typography className={styles.text}>
                            {t('pagination.next')}
                        </Typography>
                    )}
                    <KeyboardArrowRight className={styles.icon} />
                </Box>
            </Box>

            <Box className={styles.paginationContainer} ml={3}>
                {!isMobile && (
                    <Box className={cx(styles.box, styles.boxPerPage)}>
                        <Typography className={styles.text}>
                            {t('pagination.view')}
                        </Typography>
                    </Box>
                )}
                <ClickAwayListener
                    onClickAway={() => setPerPageDropdownOpen(false)}
                >
                    <Box
                        data-testid="contact-paggination-perpage-view"
                        className={styles.box}
                        onClick={() => {
                            if (!perPageDropdownOpen) {
                                setPerPageDropdownOpen(true);
                            }
                        }}
                        position="relative"
                    >
                        <Typography className={styles.text}>
                            {perPage}
                        </Typography>
                        <KeyboardArrowDown className={styles.icon} />
                        {perPageDropdownOpen && (
                            <Box className={styles.perPageDropdown}>
                                {pageOptions.map((option) => (
                                    <Typography
                                        className={styles.perPageItem}
                                        key={option}
                                        onClick={() => {
                                            onPerPageChange(option);
                                            setPerPageDropdownOpen(false);
                                        }}
                                    >
                                        {option}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Box>
                </ClickAwayListener>
            </Box>
        </Box>
    );
};
