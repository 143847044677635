import { put, select, take } from '@redux-saga/core/effects';

import { acceptGallerySuggestionApiActions } from '../../../api/gallery-suggestions/gallery-suggestions.actions';
import {
    gallerySuggestionAcceptanceRequested,
    removeGallerySuggestion,
    moveGallerySuggestionToGallery,
} from '../gallery-suggestions.actions';
import { getGalleryItemSuggestionById } from '../selectors/get-gallery-item-suggestion-by-id.selector';

export function* acceptGallerySuggestionSaga(
    action: ReturnType<typeof gallerySuggestionAcceptanceRequested>,
) {
    const { id } = action.payload;
    const galleryItem = yield select(getGalleryItemSuggestionById(id));

    yield put(acceptGallerySuggestionApiActions.request({ id }));

    yield take(acceptGallerySuggestionApiActions.success);

    yield put(removeGallerySuggestion({ id }));

    yield put(moveGallerySuggestionToGallery(galleryItem));
}
