import React, { ChangeEvent, useCallback } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    Fade,
    FormControl,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

import { FormTextField } from '../../../../../components/form-text-field/form-text-field';
import { SectionHeadline } from '../../../../../components/section-headline/section-headline';
import { FieldErrorMessage } from '../../../../../components/field-error-message/field-error-message';
import {
    ObituaryUnforgettableQuote,
    ObituaryUnforgettableQuoteTheme,
} from '../../../../model/obituary.types';

import { useObituaryUnforgettableQuoteSelectorStyles } from './obituary-unforgettable-quote-selector.styles';

export interface IObituaryUnforgettableQuoteSelectorProps {
    obituaryUnforgettableQuote: ObituaryUnforgettableQuote;
    onObituaryUnforgettableQuoteChange: (
        obituaryUnforgettableQuote: ObituaryUnforgettableQuote,
    ) => void;
    customUnforgettableQuoteThemeInputError: boolean;
    customUnforgettableQuoteThemeInputName: string;
    handleCustomUnforgettableQuoteThemeBlur: StandardInputProps['onBlur'];
}

export const ObituaryUnforgettableQuoteSelector = ({
    obituaryUnforgettableQuote,
    onObituaryUnforgettableQuoteChange,
    customUnforgettableQuoteThemeInputName,
    customUnforgettableQuoteThemeInputError,
    handleCustomUnforgettableQuoteThemeBlur,
}: IObituaryUnforgettableQuoteSelectorProps) => {
    const { t } = useTranslation();
    const styles = useObituaryUnforgettableQuoteSelectorStyles();

    const options = Object.values(ObituaryUnforgettableQuoteTheme);

    const checkIfSelected = useCallback(
        (option: ObituaryUnforgettableQuote['theme']) => {
            return option && obituaryUnforgettableQuote.theme === option;
        },
        [obituaryUnforgettableQuote],
    );

    const toggleIncludeObituaryUnforgettableQuote = () => {
        onObituaryUnforgettableQuoteChange({
            ...obituaryUnforgettableQuote,
            include: !obituaryUnforgettableQuote.include,
        });
    };

    const handleObituaryUnforgettableQuoteChange = (
        e: ChangeEvent<HTMLInputElement>,
    ) => {
        onObituaryUnforgettableQuoteChange({
            ...obituaryUnforgettableQuote,
            info: e.target.value,
        });
    };

    const renderIncludeUnforgettableQuoteCheckbox = () => {
        const checkboxClasses = {
            checked: styles.checkbox,
        };

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        classes={checkboxClasses}
                        checked={obituaryUnforgettableQuote.include}
                        onChange={toggleIncludeObituaryUnforgettableQuote}
                    />
                }
                label={
                    <Typography>
                        {t(`obituary.unforgettableQuote.include`)}
                    </Typography>
                }
            />
        );
    };

    const renderUnforgettableQuoteThemeSelector = () => {
        return (
            <>
                <Typography className={styles.chooseTheme}>
                    {t(`obituary.unforgettableQuote.chooseTheme`)}
                </Typography>
                <Box
                    mt={2}
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gridRowGap="0.75rem"
                    gridColumnGap="1rem"
                >
                    {options.map((option) => {
                        const selected = checkIfSelected(option);
                        const variant = selected ? 'default' : 'outlined';
                        const color = selected ? 'primary' : 'default';

                        const classes = {
                            root: styles.chip,
                            label: styles.label,
                            colorPrimary: styles.selected,
                        };

                        const handleSelect = () => {
                            onObituaryUnforgettableQuoteChange({
                                ...obituaryUnforgettableQuote,
                                theme: option,
                            });
                        };

                        return (
                            <Chip
                                key={option}
                                clickable
                                label={t(
                                    `obituary.unforgettableQuote.${option}.theme`,
                                )}
                                variant={variant}
                                onClick={handleSelect}
                                color={color}
                                classes={classes}
                            />
                        );
                    })}
                </Box>
            </>
        );
    };

    const renderUnforgettableQuoteCustomThemeInput = () => {
        return (
            <FormControl
                fullWidth
                error={customUnforgettableQuoteThemeInputError}
            >
                <FormTextField
                    required
                    name={customUnforgettableQuoteThemeInputName}
                    error={customUnforgettableQuoteThemeInputError}
                    onChange={handleObituaryUnforgettableQuoteChange}
                    onBlur={handleCustomUnforgettableQuoteThemeBlur}
                    value={obituaryUnforgettableQuote.info}
                    label={t(
                        `obituary.unforgettableQuote.${ObituaryUnforgettableQuoteTheme.CUSTOM}.info`,
                    )}
                />
                <FieldErrorMessage
                    name={customUnforgettableQuoteThemeInputName}
                />
            </FormControl>
        );
    };

    return (
        <Box>
            <Box mb={3}>
                <SectionHeadline>
                    {t('obituary.unforgettableQuote.headline')}
                </SectionHeadline>
            </Box>
            <Box>{renderIncludeUnforgettableQuoteCheckbox()}</Box>
            {obituaryUnforgettableQuote.include && (
                <Fade in timeout={500}>
                    <Box mt={1}>{renderUnforgettableQuoteThemeSelector()}</Box>
                </Fade>
            )}
            {obituaryUnforgettableQuote.include &&
                obituaryUnforgettableQuote.theme ===
                    ObituaryUnforgettableQuoteTheme.CUSTOM && (
                    <Fade in timeout={500}>
                        <Box mt={3}>
                            {renderUnforgettableQuoteCustomThemeInput()}
                        </Box>
                    </Fade>
                )}
        </Box>
    );
};
