import React from 'react';
import cx from 'classnames';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { Colors } from '../../../../styles/colors';

interface NewPhotoItemProps extends ButtonBaseProps {}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        border: `1px dashed ${Colors.IRON}`,
        background: Colors.ATHENS_GRAY,
        borderRadius: 4,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    label: {
        fontWeight: 700,
        color: Colors.MINE_SHAFT,
    },
    icon: {
        color: Colors.MINE_SHAFT,
    },
    iconContainer: {
        background: theme.palette.common.white,
        width: theme.spacing(4),
        height: theme.spacing(4),
        boxShadow: theme.shadows[1],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
}));

export const NewPhotoItem = ({ className, ...props }: NewPhotoItemProps) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <ButtonBase className={cx(styles.root, className)} {...props}>
            <Box className={styles.content}>
                <div className={styles.iconContainer}>
                    <Add className={styles.icon} />
                </div>
                <Typography className={styles.label}>
                    {t('common.addPhoto')}
                </Typography>
            </Box>
        </ButtonBase>
    );
};
