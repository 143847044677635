import { apiService } from '../../api.service';

export const deleteContact = ({
    slug,
    id,
}: {
    slug: string;
    id: string;
}): Promise<Response> =>
    apiService.delete(`/memorial_pages/${slug}/participants/${id}`);
