import { createAction, createAsyncAction } from 'typesafe-actions';

import { IUpdateDeceasedDto } from '../../../api/deceased/dto/update-deceased-dto';

import { IWizardVgFormValues } from './wizard-vg-form-values';

export const vgFormSubmitted = createAction(
    'wizard/vg/FORM_SUBMITTED',
    (action) => (payload: IWizardVgFormValues) => action(payload),
);

export const vgSubmitActions = createAsyncAction(
    'wizard/vg/FORM_SUBMIT_REQUESTED',
    'wizard/vg/FORM_SUBMIT_SUCCEED',
    'wizard/vg/FORM_SUBMIT_FAILED',
)<IUpdateDeceasedDto, undefined, string>();
