import * as yup from 'yup';

import { IMilestoneFormValues } from '../model/milestone-form-values';

export const milestoneFormValidation = () =>
    yup.object().shape<IMilestoneFormValues>({
        title: yup.string(),
        date: yup
            .string()
            .nullable()
            .required(),
        year: yup.number().required(),
        month: yup.number().nullable(),
        description: yup.string().nullable(),
    });
