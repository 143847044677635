import { createAsyncAction } from 'typesafe-actions';

import { ITask } from '../../interfaces/task';

import { ITaskDTO, ICreatedTaskDto } from './dto/created-task-dto';

export const createTaskApiActions = createAsyncAction(
    'api/tasks/CREATE_TASK_REQUESTED',
    'api/tasks/CREATE_TASK_SUCCEED',
    'api/tasks/CREATE_TASK_FAILED',
)<ITaskDTO, ITask, string>();

export const deleteTaskApiActions = createAsyncAction(
    'api/tasks/DELETE_TASK_REQUESTED',
    'api/tasks/DELETE_TASK_SUCCEED',
    'api/tasks/DELETE_TASK_FAILED',
)<{ id: string }, { id: string }, string>();

export const updateTaskApiActions = createAsyncAction(
    'api/tasks/UPDATE_TASK_REQUESTED',
    'api/tasks/UPDATE_TASK_SUCCEED',
    'api/tasks/UPDATE_TASK_FAILED',
)<Partial<ICreatedTaskDto> & { id: string }, ITask, string>();

export const fetchTasksApiActions = createAsyncAction(
    'api/tasks/FETCH_TASK_REQUESTED',
    'api/tasks/FETCH_TASK_SUCCEED',
    'api/tasks/FETCH_TASK_FAILED',
)<undefined, ITask[], string>();
