import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { vgFormSubmitted } from '../wizard-vg.actions';

import { submitVgFormSaga } from './submit-vg-form.saga';

export function* wizardVgSaga() {
    yield takeEvery(getType(vgFormSubmitted), submitVgFormSaga);
}
