import { put, take } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';

import { deleteGalleryItemActions } from '../gallery.actions';
import { deleteGalleryItemApiActions } from '../../../api/gallery/gallery-api.actions';

type PossibleApiResults =
    | ReturnType<typeof deleteGalleryItemApiActions.success>
    | ReturnType<typeof deleteGalleryItemApiActions.failure>;

export function* deleteGalleryImageSaga(
    action: ReturnType<typeof deleteGalleryItemActions.request>,
) {
    yield put(deleteGalleryItemApiActions.request({ id: action.payload.id }));

    const result: PossibleApiResults = yield take([
        deleteGalleryItemApiActions.success,
        deleteGalleryItemApiActions.failure,
    ]);

    switch (result.type) {
        case getType(deleteGalleryItemApiActions.success):
            yield put(
                deleteGalleryItemActions.success({ id: result.payload.id }),
            );
            break;
        case getType(deleteGalleryItemApiActions.failure):
            yield put(deleteGalleryItemActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
