import React from 'react';
import cx from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '15px',
        position: 'relative',
        fontWeight: 700,
        fontSize: '1.8rem',
        '&:before': {
            content: '""',
            borderRadius: '7px',
            width: '7px',
            height: '7px',
            backgroundColor: theme.palette.primary.light,
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
}));

export const SectionHeadline = ({ className, ...props }: TypographyProps) => {
    const styles = useStyles();

    return (
        <Typography
            className={cx(styles.root, className)}
            variant="h3"
            {...props}
        />
    );
};
