import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    languageRetrieved,
    languageChangeRequested,
} from '../configuration.actions';

import { handleLanguageRetrieveFromMemorialPageSaga } from './handle-language-retrieve-from-memorial-page.saga';
import { handleLanguageChangeRequested } from './handle-language-change.requested';

export function* configurationSaga() {
    yield takeEvery(
        getType(languageRetrieved),
        handleLanguageRetrieveFromMemorialPageSaga,
    );
    yield takeEvery(
        getType(languageChangeRequested),
        handleLanguageChangeRequested,
    );
}
