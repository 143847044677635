import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { PageTitle } from '../components/page-title/page-title';
import { Section } from '../components/section/section';
import { Pagination } from '../../components/pagination/pagination';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { getPageSlug } from '../../meta/model/selectors/get-page-slug.selector';
import { EditorPath } from '../../routing/editor-paths';
import { resolveRouteWithSlug } from '../../routing/resolve-route-with-slug';

import { ParticipantsTable } from './participants-table/participants-table';
import { useParticipants } from './use-participants';

const PARTICIPANTS_PAGE_OPTIONS = [10, 25, 50, 100];

type Props = RouteComponentProps;

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(5),
    },
    actionContainer: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
}));

export const ParticipantsScreen = ({ history, match }: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const isMobile = useIsMobile();
    const {
        participants,
        currentPage,
        pageCount,
        pageSize,
        perPageChanged,
        nextPageRequested,
        prevPageRequested,
    } = useParticipants();

    const slug = useSelector(getPageSlug) || get(match, 'params.slug');

    const redirectToEventsPage = () => {
        history.push(
            resolveRouteWithSlug(slug)(EditorPath.CEREMONY_AND_RECEPTION),
        );
    };

    return (
        <Box data-testid="participants-page-container" mb={3}>
            <PageTitle>{t('participants.title')}</PageTitle>
            <Section
                description={t('participants.description')}
                className={styles.section}
            >
                <Box mt={6} mb={4}>
                    <ParticipantsTable
                        participants={participants}
                        redirectToEventsPage={redirectToEventsPage}
                    />
                    {participants.length > 0 && (
                        <Box
                            mt={3}
                            display={isMobile ? 'block' : 'flex'}
                            className={styles.actionContainer}
                        >
                            <Pagination
                                currentPage={currentPage}
                                pagesCount={pageCount}
                                perPage={pageSize}
                                perPageOptions={PARTICIPANTS_PAGE_OPTIONS}
                                onNextClick={nextPageRequested}
                                onPrevClick={prevPageRequested}
                                onPerPageChange={perPageChanged}
                            />
                        </Box>
                    )}
                </Box>
            </Section>
        </Box>
    );
};
