import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IContact } from '../../../interfaces/contact';
import { NotificationContactStatus } from '../../../interfaces/notification-contact-status';
import { sendNotificationsToContactsApiActions } from '../../../api/contacts/contacts-api.actions';
import { getContactSuggestionStatus } from '../../../model/notification-contacts/selectors/get-contact-suggestion-status.selector';
import { getContactSuggestionStatusMessage } from '../../../model/notification-contacts/selectors/get-contact-suggestion-status-message.selector';

import {
    addNotificationContact,
    getNotificationContacts,
    nextPageRequested,
    perPageChanged,
    prevPageRequested,
    toggleAllContactsSelected,
    toggleContactSelected,
    pagingResetRequested,
} from './model/contacts.actions';
import { makeGetContactsPaginated } from './model/selectors/get-contacts-paginated.selector';
import { makeGetContacts } from './model/selectors/get-contacts.selector';
import { getSelectedContactsIds } from './model/selectors/get-selected-contacts-ids.selector';
import { getCurrentPage } from './model/selectors/get-current-page.selector';
import { getPerPage } from './model/selectors/get-per-page.selector';
import { makeGetAreAllContactsSelected } from './model/selectors/get-are-all-contacts-selected.selector';
import { makeGetPagesCount } from './model/selectors/get-pages-count.selector';

export const useEditorContacts = () => {
    const [selectedFilter, setSelectedFilter] = useState<
        NotificationContactStatus
    >(NotificationContactStatus.NOT_INFORMED);
    const dispatch = useDispatch();
    const contacts = useSelector(makeGetContactsPaginated(selectedFilter));
    const contactsCount = useSelector(makeGetContacts(selectedFilter)).length;
    const selectedContactsIds = useSelector(getSelectedContactsIds);
    const allContactsSelected = useSelector(
        makeGetAreAllContactsSelected(selectedFilter),
    );
    const currentPage = useSelector(getCurrentPage);
    const pagesCount = useSelector(makeGetPagesCount(selectedFilter));
    const perPage = useSelector(getPerPage);
    const contactSuggestionStatus = useSelector(getContactSuggestionStatus);
    const contactSuggestionStatusMessage = useSelector(
        getContactSuggestionStatusMessage,
    );

    useEffect(() => {
        dispatch(getNotificationContacts.request());
    }, [dispatch]);

    return {
        contacts,
        contactsCount,
        selectedContactsIds,
        allContactsSelected,
        currentPage,
        pagesCount,
        perPage,
        contactSuggestionStatus,
        contactSuggestionStatusMessage,
        setSelectedFilter(filter: NotificationContactStatus) {
            setSelectedFilter(filter);
            dispatch(pagingResetRequested());
        },
        selectedFilter,
        toggleAllContactsSelected() {
            dispatch(toggleAllContactsSelected({ filter: selectedFilter }));
        },
        toggleContactSelected(id: string) {
            dispatch(toggleContactSelected({ id }));
        },
        perPageChanged(count: number) {
            dispatch(perPageChanged({ perPage: count }));
        },
        nextPageRequested() {
            dispatch(nextPageRequested());
        },
        prevPageRequested() {
            dispatch(prevPageRequested());
        },
        addNotificationContact(contact: IContact) {
            dispatch(addNotificationContact.request(contact));
        },
        sendNotifications() {
            dispatch(
                sendNotificationsToContactsApiActions.request({
                    ids: selectedContactsIds,
                }),
            );
        },
    };
};
