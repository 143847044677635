import { find, get } from 'lodash';

import { ISuggestionsResponse } from '../../../api/suggestions/dto/suggestions-response.dto';
import { IGalleryItemSuggestion } from '../../../interfaces/gallery-item';

export const mapSuggestionsToGalleryItemSuggestions = (
    payload: ISuggestionsResponse,
): IGalleryItemSuggestion[] => {
    const galleryItemSuggestions: IGalleryItemSuggestion[] = [];

    payload.data.forEach((item) => {
        const id = get(item, 'relationships.suggestable.data.id');
        const relatedData = find(payload.included, { id })!;

        if (!relatedData || relatedData.type !== 'gallery_photo') {
            return;
        }

        const galleryItemSuggestion = {
            id: relatedData.id,
            published: relatedData.attributes.published,
            originalSrc: relatedData.attributes.originalUrl,
            thumbSrc: relatedData.attributes.thumbUrl,
            suggestionId: item.id,
            status: item.attributes.status,
        };

        galleryItemSuggestions.push(galleryItemSuggestion);
    });

    return galleryItemSuggestions;
};
