import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    avatarPhotoUploadActions,
    backgroundImageChangeActions,
} from '../photos.actions';

import { sendBackgroundIdSaga } from './send-background-id.saga';
import { sendAvatarPhotoSaga } from './send-avatar-photo.saga';

export function* photosSaga() {
    yield takeEvery(
        getType(backgroundImageChangeActions.request),
        sendBackgroundIdSaga,
    );
    yield takeEvery(
        getType(avatarPhotoUploadActions.request),
        sendAvatarPhotoSaga,
    );
}
