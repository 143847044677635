import { createAction, createAsyncAction } from 'typesafe-actions';

import { IGalleryItem } from '../../interfaces/gallery-item';

export const updateGalleryImageOnlineStatusActions = createAsyncAction(
    'editor/gallery/UPDATE_GALLERY_IMAGE_ONLINE_STATUS_REQUESTED',
    'editor/gallery/UPDATE_GALLERY_IMAGE_ONLINE_STATUS_SUCCEED',
    'editor/gallery/UPDATE_GALLERY_IMAGE_ONLINE_STATUS_FAILED',
)<{ status: 'online' | 'offline'; id: string }, IGalleryItem, string>();

export const deleteGalleryItemActions = createAsyncAction(
    'editor/gallery/DELETE_IMAGE_REQUESTED',
    'editor/gallery/DELETE_IMAGE_SUCCEED',
    'editor/gallery/DELETE_IMAGE_FAILED',
)<{ id: string }, { id: string }, string>();

export const newGalleryImageAddedByUser = createAction(
    'editor/gallery/NEW_GALLERY_IMAGE_ADDED_BY_USER',
    (action) => (payload: { file: File }) => action(payload),
);

export const galleryNewImageUploadRefToApiActions = createAsyncAction(
    'editor/gallery/NEW_IMAGE_UPLOAD_REF_TO_API_REQUESTED',
    'editor/gallery/NEW_IMAGE_UPLOAD_REF_TO_API_SUCCEED',
    'editor/gallery/NEW_IMAGE_UPLOAD_REF_TO_API_FAILED',
)<undefined, IGalleryItem, string>();

export const fetchGalleryItemsActions = createAsyncAction(
    'editor/gallery/FETCH_GALLERY_ITEMS_REQUESTED',
    'editor/gallery/FETCH_GALLERY_ITEMS_SUCCEED',
    'editor/gallery/FETCH_GALLERY_ITEMS_FAILED',
)<undefined, IGalleryItem[], string>();
