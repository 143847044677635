import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { invitationsFetchRequested } from '../invitations.actions';

import { fetchInvitationsSaga } from './fetch-invitations.saga';

export function* invitationsSaga() {
    yield takeEvery(getType(invitationsFetchRequested), fetchInvitationsSaga);
}
