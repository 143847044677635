import * as yup from 'yup';

import { IWizardNotificationsFormValues } from './wizard-notifications-form-values';

export const wizardNotificationsFormValidation = () =>
    yup.object<IWizardNotificationsFormValues>({
        message: yup.string().required(),
        newContact: yup.object({
            email: yup.string().email(),
            phone: yup.string().transform((value: string, originalValue) => {
                /**
                 * Trim "+" or "+1" or "+48" etc
                 */
                if (value.indexOf('+') === 0 && value.length <= 4) {
                    return '';
                }

                return value;
            }),
            name: yup.string(),
        }),
    });
