import { call, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { wizardPhotosStepSubmitted } from '../wizard-photos.actions';
import { sendDeceasedPhotoRefToBackendActions } from '../../../../api/background-photos/background-photos.actions';
import { getImageUploadSession } from '../../../../api/image-upload/http/get-image-upload-session';
import { IImageUploadSessionResponseDto } from '../../../../api/image-upload/dto/image-upload-session-response.dto';
import { uploadFileToS3 } from '../../../../api/image-upload/http/upload-file-to-s3';
import { sendAvatarReferenceToApi } from '../../../../api/avatar/http/send-avatar-reference-to-api';
import { IConfirmImageOnServerDto } from '../../../../api/image-upload/dto/confirm-image-on-server.dto';
import { updateMemorialBackground } from '../../../../api/background-photos/http/update-memorial-background';
import { WizardPath } from '../../../../routing/wizard-paths';
import { getPageSlug } from '../../../../meta/model/selectors/get-page-slug.selector';
import { resolveRouteWithSlug } from '../../../../routing/resolve-route-with-slug';
import { updateMemorialPageCreationStepApiActions } from '../../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';

export function* submitWizardPhotosStepSaga(
    action: ReturnType<typeof wizardPhotosStepSubmitted>,
) {
    const { avatar, backgroundID } = action.payload;
    const slug = yield select(getPageSlug);

    const notificationsRoute = resolveRouteWithSlug(slug)(
        WizardPath.NOTIFICATION,
    );

    if (!avatar) {
        yield call(updateMemorialBackground, slug, backgroundID);

        yield put(
            updateMemorialPageCreationStepApiActions.request({
                step: MemorialPageCreationStep.NOTIFICATION,
            }),
        );

        yield put(push(notificationsRoute));

        return;
    }

    try {
        const sessionData: IImageUploadSessionResponseDto = yield call(
            getImageUploadSession,
            avatar,
        );
        const imageRef: IConfirmImageOnServerDto = yield call(
            uploadFileToS3,
            sessionData,
            avatar,
        );

        // TODO: Move to API module
        yield call(sendAvatarReferenceToApi, slug, imageRef);
        yield put(sendDeceasedPhotoRefToBackendActions.request());

        // TODO: Move to api module
        yield call(updateMemorialBackground, slug, backgroundID);

        yield put(sendDeceasedPhotoRefToBackendActions.success());

        yield put(
            updateMemorialPageCreationStepApiActions.request({
                step: MemorialPageCreationStep.NOTIFICATION,
            }),
        );

        yield take(updateMemorialPageCreationStepApiActions.success);

        yield put(
            push(
                resolveRouteFromMemorialCreationStep(
                    slug,
                    MemorialPageCreationStep.NOTIFICATION,
                ),
            ),
        );
    } catch (err) {
        yield put(sendDeceasedPhotoRefToBackendActions.failure(err));
    }
}
