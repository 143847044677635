import { apiService } from '../../api.service';
import { IConfirmImageOnServerDto } from '../../image-upload/dto/confirm-image-on-server.dto';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';

export const sendAvatarReferenceToApi = (
    slug: string,
    dto: IConfirmImageOnServerDto,
): Promise<IMemorialPageMetaDataResponse> => {
    return apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                deceasedPerson: {
                    photo: JSON.stringify(dto),
                },
            },
        })
        .then((resp) => resp.json());
};
