import { createReducer } from 'typesafe-actions';

import { IOverview } from '../../interfaces/overview';

import { overviewReceived } from './overview.actions';

export interface IOverviewsStore extends IOverview {}

const initialState: IOverviewsStore = {
    condolencesCount: 0,
    suggestionsCount: 0,
    invitationsCount: 0,
};

type Actions = ReturnType<typeof overviewReceived>;

export const overviewReducer = createReducer<IOverviewsStore, Actions>(
    initialState,
).handleAction(overviewReceived, (state, action) => ({
    ...state,
    ...action.payload,
}));
