import { IWizardVgFormValues } from '../../../wizard/vg-step/model/wizard-vg-form-values';

export const mapVgFormToDTO = (
    formData: IWizardVgFormValues,
): { vgOrdered: boolean } => {
    const dto: { vgOrdered: boolean } = {
        vgOrdered: formData.vgOrdered || false,
    };

    return dto;
};
