import { createSelector } from 'reselect';

import { RootStore } from '../../store/store';

const getGalleryStore = (store: RootStore) => store.gallery;

const getGalleryPhotos = createSelector(
    getGalleryStore,
    (store) => store.galleryItems,
);

export const GallerySelectors = {
    getGalleryPhotos,
};
