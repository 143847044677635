import { createReducer } from 'typesafe-actions';

import { ITask } from '../../interfaces/task';
import {
    createTaskApiActions,
    deleteTaskApiActions,
    fetchTasksApiActions,
    updateTaskApiActions,
} from '../../api/tasks/api-tasks.actions';

export interface ITasksStore {
    tasks: ITask[];
}

const initialState: ITasksStore = {
    tasks: [],
};

type Actions =
    | ReturnType<typeof createTaskApiActions.success>
    | ReturnType<typeof updateTaskApiActions.success>
    | ReturnType<typeof deleteTaskApiActions.success>
    | ReturnType<typeof fetchTasksApiActions.success>;

export const tasksReducer = createReducer<ITasksStore, Actions>(initialState)
    .handleAction(fetchTasksApiActions.success, (state, action) => {
        return {
            ...state,
            tasks: action.payload,
        };
    })
    .handleAction(createTaskApiActions.success, (state, action) => {
        return {
            ...state,
            tasks: [...state.tasks, action.payload],
        };
    })
    .handleAction(updateTaskApiActions.success, (state, action) => {
        return {
            ...state,
            tasks: state.tasks.map((task) => {
                if (task.id !== action.payload.id) {
                    return task;
                }

                return action.payload;
            }),
        };
    })
    .handleAction(deleteTaskApiActions.success, (state, action) => {
        return {
            ...state,
            tasks: state.tasks.filter((task) => task.id !== action.payload.id),
        };
    });
