import React from 'react';
import { Box, Dialog, IconButton, makeStyles } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../../../components/secondary-button/secondary-button';
import { BlueHeadline } from '../../../components/blue-headline/blue-headline';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { Colors } from '../../../../styles/colors';
import { hexToRgba } from '../../../utils/hex-to-rgba';

type Props = Omit<DialogProps, 'onClose'> & {
    onAccepted(): unknown;
    onClose(): unknown;
};

const useStyles = makeStyles((theme) => ({
    headline: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
    },
    text: {
        backgroundColor: hexToRgba(Colors.WHISPER, 0.4),
        color: Colors.DOVE_GRAY,
        fontSize: '14px',
        height: '30rem',
        lineHeight: '26px',
        overflow: 'scroll',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: '65vh',
        },
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export const TermsAndConditionsModal = ({
    onAccepted,
    onClose,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const mobile = useIsMobile();

    return (
        <Dialog
            fullWidth
            fullScreen={mobile}
            maxWidth="md"
            onClose={onClose}
            {...props}
        >
            <Box data-testid="wizard-terms-modal" p={4} position="relative">
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <BlueHeadline className={styles.headline}>
                    {t('deceased.termsAndContitionsHeadline')}
                </BlueHeadline>
                <div data-testid="wizard-terms-content" className={styles.text}>
                    {t('deceased.termsAndContitionsContent')}
                </div>
                <Box
                    mt={4}
                    mb={mobile ? 2 : 4}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <SecondaryButton
                        data-testid="wizard-terms-accept"
                        fullWidth={mobile}
                        onClick={onAccepted}
                    >
                        {t('deceased.acceptButton')}
                    </SecondaryButton>
                </Box>
            </Box>
        </Dialog>
    );
};
