import { apiService } from '../../api.service';
import { IMemorialPageMetaDataResponse } from '../../page/dto/memorial-page-data-response.dto';
import { IUpdateDeceasedDto } from '../dto/update-deceased-dto';

export const updateDeceasedData = (
    dto: IUpdateDeceasedDto,
    slug: string,
): Promise<IMemorialPageMetaDataResponse> =>
    apiService
        .patch(`/memorial_pages/${slug}`, {
            json: {
                deceasedPerson: dto,
            },
        })
        .then((resp) => resp.json());
