import { createReducer } from 'typesafe-actions';

import { IMemorialEvent } from '../../../../interfaces/memorial-event';

import { getOverviewEventsAsyncActions } from './overview-events.actions';

export interface IOverviewEventsStore {
    events: IMemorialEvent[];
}

const initialState: IOverviewEventsStore = {
    events: [],
};

type Actions = ReturnType<typeof getOverviewEventsAsyncActions.success>;

export const overviewEventsReducer = createReducer<
    IOverviewEventsStore,
    Actions
>(initialState).handleAction(
    getOverviewEventsAsyncActions.success,
    (state, action) => {
        return {
            ...state,
            events: action.payload,
        };
    },
);
