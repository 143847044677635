import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchContextsApiActions, setContext } from './context.actions';
import { changeContextsSaga, fetchContextsSaga } from './fetch-contexts-saga';

export function* contextSaga() {
    yield takeEvery(
        getType(fetchContextsApiActions.request),
        fetchContextsSaga,
    );
    yield takeEvery(getType(setContext), changeContextsSaga);
}
