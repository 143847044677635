import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { tributeFormSubmitted } from '../tribute.actions';

import { handleTributeFormSubmitSaga } from './handle-tribute-form-submit.saga';

export function* tributeSaga() {
    yield takeEvery(getType(tributeFormSubmitted), handleTributeFormSubmitSaga);
}
