import { createReducer } from 'typesafe-actions';

import { ICondolence } from '../../interfaces/condolence';

import { condolencesReceived } from './condolences.actions';

export interface ICondolencesStore {
    condolences: ICondolence[];
}

const initialState: ICondolencesStore = {
    condolences: [],
};

type Actions = ReturnType<typeof condolencesReceived>;

export const condolencesReducer = createReducer<ICondolencesStore, Actions>(
    initialState,
).handleAction(condolencesReceived, (state, action) => ({
    ...state,
    condolences: action.payload,
}));
