import { call, select, put } from '@redux-saga/core/effects';

import { createMilestoneApiActions } from '../milestones-api.actions';
import { createMilestone } from '../http/create-milestone';
import { IMilestoneResponseDto } from '../dto/milestone-response.dto';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';

export function* createMilestoneApiSaga(
    action: ReturnType<typeof createMilestoneApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response: IMilestoneResponseDto = yield call(
            createMilestone,
            action.payload,
            slug,
        );
        yield put(
            createMilestoneApiActions.success({
                year: response.attributes.year,
                month: response.attributes.month,
                description: response.attributes.description,
                id: response.id,
                title: response.attributes.title,
            }),
        );
    } catch (e) {
        yield put(createMilestoneApiActions.failure(e.statusText));
    }
}
