import { createSelector } from 'reselect';

import { getEditorContactsStore } from '../../../editor/notifications/contacts/model/selectors/get-editor-contacts-store.selector';

import { getSuggestedNotificationContacts } from './get-suggested-notification-contacts';

export const getEditorSuggestedNotificationContacts = createSelector(
    getSuggestedNotificationContacts,
    getEditorContactsStore,
    (contacts, editorContacts) =>
        editorContacts.selectedContactsIds.filter((id) =>
            contacts.map((contact) => contact.id).includes(id),
        ),
);
