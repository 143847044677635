import { ILanguage } from '../../interfaces/language';
import { ReactComponent as UnitedKingdomIcon } from '../../assets/icons/flags/united-kingdom.svg';
import { ReactComponent as PolandIcon } from '../../assets/icons/flags/republic-of-poland.svg';
import { ReactComponent as NorwayIcon } from '../../assets/icons/flags/norway.svg';
import { ReactComponent as GermanyIcon } from '../../assets/icons/flags/germany.svg';
import { ReactComponent as FranceIcon } from '../../assets/icons/flags/france.svg';
import { ReactComponent as SwedenIcon } from '../../assets/icons/flags/sweden.svg';

const languagesWithFlagsMap: {
    [key: string]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
    en: UnitedKingdomIcon,
    pl: PolandIcon,
    nb: NorwayIcon,
    de: GermanyIcon,
    fr: FranceIcon,
    sv: SwedenIcon,
};

export const mapLanguageWithFlag = (language: ILanguage) => ({
    ...language,
    icon: languagesWithFlagsMap[language.code],
});

export const mapLanguagesToFlags = (languages: ILanguage[]) =>
    languages.map((language) => mapLanguageWithFlag(language)).filter(Boolean);
