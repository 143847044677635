import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GallerySelectors } from '../model/gallery/gallery.selector';

import {
    deleteGalleryItemActions,
    fetchGalleryItemsActions,
    newGalleryImageAddedByUser,
    updateGalleryImageOnlineStatusActions,
} from './model/gallery.actions';
import { GallerySectionSelectors } from './model/selectors/gallery-section.selector';

export const useGallerySectionState = () => {
    const dispatch = useDispatch();
    const galleryItems = useSelector(GallerySelectors.getGalleryPhotos);
    const uploadInProgress = useSelector(
        GallerySectionSelectors.getIsItemUploading,
    );

    useEffect(() => {
        dispatch(fetchGalleryItemsActions.request());
    }, [dispatch]);

    return {
        onDelete(id: string) {
            dispatch(
                deleteGalleryItemActions.request({
                    id,
                }),
            );
        },
        onTakeOffline(id: string) {
            dispatch(
                updateGalleryImageOnlineStatusActions.request({
                    id,
                    status: 'offline',
                }),
            );
        },
        onPutOnline(id: string) {
            dispatch(
                updateGalleryImageOnlineStatusActions.request({
                    id,
                    status: 'online',
                }),
            );
        },
        onFileUploaded(file: File) {
            dispatch(
                newGalleryImageAddedByUser({
                    file,
                }),
            );
        },
        galleryItems,
        uploadInProgress,
    };
};
