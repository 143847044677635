import { createReducer } from 'typesafe-actions';

import { invitationTokenValidationActions } from './invitation.actions';

export interface IInvitationStore {
    token: string | null;
    userExists: boolean | null;
}

const initialState: IInvitationStore = {
    token: null,
    userExists: null,
};

type Actions =
    | ReturnType<typeof invitationTokenValidationActions.request>
    | ReturnType<typeof invitationTokenValidationActions.success>;

export const authInvitationReducer = createReducer<IInvitationStore, Actions>(
    initialState,
)
    .handleAction(
        invitationTokenValidationActions.request,
        (state, action) => ({
            ...state,
            token: action.payload.token,
        }),
    )
    .handleAction(
        invitationTokenValidationActions.success,
        (state, action) => ({
            ...state,
            userExists: action.payload.isUserPersisted,
        }),
    );
