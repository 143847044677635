import { createReducer } from 'typesafe-actions';

import { vgReceived } from './vg.actions';

export interface IVgStore {
    vgOrderable: boolean;
}

const initialState: IVgStore = {
    vgOrderable: false,
};

type Actions = ReturnType<typeof vgReceived>;

export const vgReducer = createReducer<IVgStore, Actions>(
    initialState,
).handleAction(vgReceived, (state, action) => ({
    ...state,
    vgOrderable: action.payload,
}));
