import { createAction } from 'typesafe-actions';

export const wizardPhotosStepSubmitted = createAction(
    'wizard/photos/PHOTOS_STEP_SUBMITTED',
    (action) => (payload: { avatar: File | null; backgroundID: string }) =>
        action(payload),
);

export const photosStepSkipRequested = createAction(
    'wizard/photos/PHOTOS_STEP_SKIP_REQUESTED',
);
