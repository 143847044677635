import { apiService } from '../../api.service';
import { IMilestoneResponseDto } from '../dto/milestone-response.dto';

export const fetchMilestones = (
    slug: string,
): Promise<IMilestoneResponseDto[]> => {
    const url = `/memorial_pages/${slug}/milestones`;

    return apiService
        .get(url)
        .then((result) => result.json())
        .then((resp) => resp.data);
};
