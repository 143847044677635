import { apiService } from '../../api.service';
import { IImageUploadSessionResponseDto } from '../dto/image-upload-session-response.dto';

/**
 * Ask server to return AWS url on which file will be uploaded to.
 */
export const getImageUploadSession = (
    file: File,
): Promise<IImageUploadSessionResponseDto> => {
    const { name, type } = file;

    return apiService
        .get(
            `/files/presign?filename=${encodeURIComponent(name)}&type=${type}`,
            {
                headers: {
                    'Key-Inflection': 'snake',
                },
            },
        )
        .then((resp) => resp.json());
};
