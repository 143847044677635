import { createAction } from 'typesafe-actions';

import { ResetPasswordStatus } from './reset-password.store';

export const setNewResetPasswordRequested = createAction(
    'reset-password-page/NEW_PASSWORD_REQUESTED',
    (action) => (payload: { password: string; token: string }) =>
        action(payload),
);

export const setResetPasswordStatus = createAction(
    'reset-password-page/SET_RESET_PASSWORD_STATUS',
    (action) => (payload: ResetPasswordStatus | null) => action(payload),
);
