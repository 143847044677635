import { createSelector } from 'reselect';
import i18n from 'i18next';

import { ResetPasswordStatus } from '../reset-password.store';

import { getResetPasswordStatus } from './get-reset-password-status.selector';

export const getResetPasswordStatusMessage = createSelector(
    getResetPasswordStatus,
    (status) => {
        const ResetPasswordStatusMessages = {
            [ResetPasswordStatus.MAIL_SEND_SUCCEEDED]: i18n.t(
                'resetPasswordStatus.mailSendSucceeded',
            ),
            [ResetPasswordStatus.PASSWORD_SET_SUCCEEDED]: i18n.t(
                'resetPasswordStatus.passwordSetSucceeded',
            ),
        };

        return status ? ResetPasswordStatusMessages[status] : null;
    },
);
