import i18next from 'i18next';

import {
    DeceasedPersonalQuality,
    ObituaryFormValues,
    ObituaryQuestion,
} from '../obituary.types';
import { IGenerateObituaryDto } from '../../../api/obituary/dto/generate-obituary.dto';

export const mapObituaryFormToGenerateObituaryDto = (
    formData: ObituaryFormValues,
) => {
    const dto: IGenerateObituaryDto = {
        preferredName: formData.deceasedPreferredName,
        questionsAndAnswers: formData.obituaryQuestionsAndAnswers.map(
            (obituaryQuestionAndAnswer) => {
                let customizedAnswer;

                if (
                    obituaryQuestionAndAnswer.question ===
                        ObituaryQuestion.PERSONAL_QUALITIES &&
                    obituaryQuestionAndAnswer.info
                ) {
                    const deceasedPersonalQualities = (obituaryQuestionAndAnswer.info as DeceasedPersonalQuality[])
                        .map((deceasedPersonalQuality) =>
                            i18next.t(
                                `obituary.deceased.personalQuality.${deceasedPersonalQuality}`,
                            ),
                        )
                        .join(', ');

                    customizedAnswer = `${obituaryQuestionAndAnswer.answer.trim()}. ${deceasedPersonalQualities}`
                        .trim()
                        .replace('..', '.');
                }

                return {
                    question: i18next.t(
                        `obituary.questionsAndAnswers.${obituaryQuestionAndAnswer.question}.question`,
                    ),
                    answer:
                        customizedAnswer || obituaryQuestionAndAnswer.answer,
                };
            },
        ),
        style: i18next.t(
            `obituary.writingStyle.${formData.obituaryWritingStyle}`,
        ),
    };

    if (formData.deceasedReligion) {
        dto.religion = formData.deceasedReligion;
    }

    if (formData.obituaryUnforgettableQuote.include) {
        dto.includeQuote = true;
        dto.quoteTheme = i18next.t(
            `obituary.unforgettableQuote.${formData.obituaryUnforgettableQuote.theme}.theme`,
        );
        dto.quoteInfo = formData.obituaryUnforgettableQuote.info || undefined;
    }

    return dto;
};
