import { call, put, select } from '@redux-saga/core/effects';

import { rejectGallerySuggestionApiActions } from '../gallery-suggestions.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { rejectSuggestion } from '../../suggestions/http/reject-suggestion';

export function* rejectGallerySuggestionApiSaga(
    action: ReturnType<typeof rejectGallerySuggestionApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id } = action.payload;

    try {
        yield call(rejectSuggestion, { slug, id });

        yield put(rejectGallerySuggestionApiActions.success());
    } catch (err) {
        yield put(rejectGallerySuggestionApiActions.failure(err.statusText));
    }
}
