import { createAction, createAsyncAction } from 'typesafe-actions';

import { ILoginSuccessResponseDto } from '../../../api/login/dto/login-success-response.dto';
import { LoginErrorCode } from '../../../api/login/dto/login-errors.dto';

export const loginPageActions = createAsyncAction(
    'auth/login-page/LOGIN_REQUESTED',
    'auth/login-page/LOGIN_SUCCEED',
    'auth/login-page/LOGIN_FAILED',
)<
    { email: string; password: string },
    ILoginSuccessResponseDto,
    LoginErrorCode
>();

export const loginFormStateChanged = createAction(
    `auth/login-page/FORM_STATE_CHANGED`,
);

export const resetPasswordActions = createAsyncAction(
    'auth/login-page/RESET_PASSWORD_REQUESTED',
    'auth/login-page/RESET_PASSWORD_SUCCEED',
    'auth/login-page/RESET_PASSWORD_FAILED',
)<{ email: string }, undefined, string>();
