import { createAsyncAction } from 'typesafe-actions';

import { ObituaryStatus } from '../../model/obituary/obituary.store';

import { IUpdateObituaryDto } from './dto/update-obituary.dto';
import { IGenerateObituaryDto } from './dto/generate-obituary.dto';

export const updateObituaryTextApiActions = createAsyncAction(
    'api/obituary/UPDATE_REQUESTED',
    'api/obituary/UPDATE_SUCCEEDED',
    'api/obituary/UPDATE_FAILED',
)<
    IUpdateObituaryDto,
    { obituaryText: string },
    { obituaryStatus: ObituaryStatus }
>();

export const generateObituaryTextApiActions = createAsyncAction(
    'api/obituary/GENERATE_REQUESTED',
    'api/obituary/GENERATE_SUCCEEDED',
    'api/obituary/GENERATE_FAILED',
)<
    IGenerateObituaryDto,
    { obituaryText: string },
    { obituaryStatus: ObituaryStatus }
>();
