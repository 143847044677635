import { IContextResponse } from '../dto/context-response.dto';
import { apiService } from '../../../api/api.service';
import { ILoginSuccessResponseDto } from '../../../api/login/dto/login-success-response.dto';

export const fetchContexts = (): Promise<IContextResponse> =>
    apiService
        .get(
            `/contexts/memorial_pages?include=deceased_person&page[size]=1000&page[number]=1`,
        )
        .then((resp) => resp.json());

export const changeContext = (
    contextId: number,
): Promise<ILoginSuccessResponseDto> =>
    apiService
        .put(`/sessions`, {
            json: {
                auth: {
                    context_id: contextId,
                    context_type: 'MemorialPage',
                },
            },
            /**
             * Disable error on non-200 to handle it manually and get error codes
             */
            throwHttpErrors: false,
        })
        .then(async (resp) => {
            if (resp.status === 401) {
                const respBody = await resp.json();

                return Promise.reject(respBody.errors);
            }

            return resp.json();
        })
        .then((resp) => resp.data.attributes);
