import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchOverviewApiActions } from '../overview-api.actions';

import { fetchOverviewSaga } from './fetch-overview.saga';

export function* overviewApiSaga() {
    yield takeEvery(
        getType(fetchOverviewApiActions.request),
        fetchOverviewSaga,
    );
}
