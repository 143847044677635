import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBackgroundPhotosApiActions } from '../../../api/background-photos/background-photos.actions';
import { useDeceasedDisplayImages } from '../../../model/deceased-display-images/use-deceased-display-images';
import { getPreviewFromFile } from '../../../utils/get-preview-from-file';

import { getEditorBackgroundModalOpen } from './model/selectors/get-background-modal-open.selector';
import {
    avatarPhotoUploadActions,
    backgroundImageChangeActions,
    backgroundPhotoChangeRequested,
    closeBackgroundPhotoChangeModalRequested,
} from './model/photos.actions';

export const useEditorPhotos = () => {
    const {
        availableBackgrounds,
        selectedBackground,
        avatar,
    } = useDeceasedDisplayImages();
    const dispatch = useDispatch();
    const [selectedBackgroundID, setSelectedBackgroundID] = useState<string>();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
    const backgroundModalOpen = useSelector(getEditorBackgroundModalOpen);

    useEffect(() => {
        dispatch(fetchBackgroundPhotosApiActions.request());
    }, [dispatch]);

    useEffect(() => {
        if (availableBackgrounds.length && selectedBackground) {
            setSelectedBackgroundID(selectedBackground);

            return;
        }

        if (availableBackgrounds.length) {
            setSelectedBackgroundID(availableBackgrounds[0].id);
        }
    }, [availableBackgrounds, setSelectedBackgroundID, selectedBackground]);

    const getBackgroundImageSrc = () => {
        const selectedBg = availableBackgrounds.find(
            (bg) => bg.id === selectedBackground,
        );

        if (selectedBg) {
            return selectedBg.src;
        }

        return null;
    };

    const avatarPreview =
        (selectedFile && getPreviewFromFile(selectedFile)) || avatar;

    return {
        avatarPreview,
        backgroundModalOpen,
        selectedBackgroundID,
        setSelectedBackgroundID,
        imageCropModalOpen,
        setImageCropModalOpen,
        availableBackgrounds,
        selectedBackgroundSrc: getBackgroundImageSrc(),
        selectedFile,
        setSelectedFile,
        backgroundPhotoChangeRequested() {
            dispatch(backgroundPhotoChangeRequested());
        },
        closeBackgroundPhotoChangeModalRequested() {
            dispatch(closeBackgroundPhotoChangeModalRequested());
        },
        sendBackgroundImageId(backgroundID: string) {
            dispatch(backgroundImageChangeActions.request({ backgroundID }));
        },
        uploadFile(file: File) {
            dispatch(
                avatarPhotoUploadActions.request({
                    avatar: file,
                }),
            );
        },
    };
};
