import { createReducer } from 'typesafe-actions';

import { ICreatedContact } from '../../interfaces/contact';
import { NotificationContactStatus } from '../../interfaces/notification-contact-status';

import {
    notificationContactsReceived,
    notificationSingleContactReceived,
    setContactSuggestionStatus,
    setContactsStatuses,
} from './notification-contacts.actions';

export enum ContactSuggestionStatus {
    SUCCESS = 'SUCCESS',
    ALREADY_EXISTS = 'ALREADY_EXISTS',
    ERROR = 'ERROR',
}

export interface INotificationContactsStore {
    contacts: ICreatedContact[];
    contactSuggestionStatus: ContactSuggestionStatus | null;
}

const initialStore: INotificationContactsStore = {
    contacts: [],
    contactSuggestionStatus: null,
};

type Actions =
    | ReturnType<typeof notificationContactsReceived>
    | ReturnType<typeof setContactSuggestionStatus>
    | ReturnType<typeof notificationSingleContactReceived>
    | ReturnType<typeof setContactsStatuses>;

export const notificationContactsReducer = createReducer<
    INotificationContactsStore,
    Actions
>(initialStore)
    .handleAction(notificationContactsReceived, (state, action) => ({
        ...state,
        contacts: action.payload.contacts,
    }))
    .handleAction(notificationSingleContactReceived, (state, action) => {
        const newContact = action.payload.contact;

        const isContactPresent = state.contacts.find(
            (existingContact) => existingContact.id === newContact.id,
        );

        let updatedContacts: ICreatedContact[] = state.contacts;

        if (isContactPresent) {
            updatedContacts = updatedContacts.map((contact) => {
                if (contact.id === newContact.id) {
                    return newContact;
                }

                return contact;
            });
        } else {
            updatedContacts = [...updatedContacts, newContact];
        }

        return {
            ...state,
            contacts: updatedContacts,
        };
    })
    .handleAction(setContactSuggestionStatus, (state, action) => ({
        ...state,
        contactSuggestionStatus: action.payload,
    }))
    .handleAction(setContactsStatuses, (state, action) => {
        return {
            ...state,
            contacts: state.contacts.map(
                (contact: ICreatedContact): ICreatedContact => {
                    const contactUpdate = action.payload.find(
                        (item) => item.id === contact.id,
                    );

                    return {
                        ...contact,
                        status:
                            contactUpdate && contactUpdate.status === 'sent'
                                ? NotificationContactStatus.INFORMED
                                : contact.status,
                    } as ICreatedContact;
                },
            ),
        };
    });
