import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchSuggestionsApiActions } from '../suggestions-api.actions';

import { fetchSuggestionsSaga } from './fetch-suggestions.saga';

export function* suggestionsApiSaga() {
    yield takeEvery(
        getType(fetchSuggestionsApiActions.request),
        fetchSuggestionsSaga,
    );
}
