import React from 'react';
import { useTranslation } from 'react-i18next';

import { setupValidationMessages } from '../setup/setup-validation-messages';

export const withValidationMessagesTranslation = <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const { t } = useTranslation();

    setupValidationMessages(t);

    return <Component {...props} />;
};
