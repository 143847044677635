import { getType } from 'typesafe-actions';
import { put, take } from 'redux-saga/effects';

import {
    createEventFormSubmitted,
    createEventInEditorFailed,
    createEventInEditorSucceed,
} from '../events.actions';
import { mapEventToDTO } from '../map-event-to-dto';
import { createEventApiActions } from '../../../api/events/api-events.actions';

export function* createEventSaga(
    action: ReturnType<typeof createEventFormSubmitted>,
) {
    const { payload } = action;
    const dto = mapEventToDTO(payload.event);

    yield put(
        createEventApiActions.request({
            ...dto,
            event: {
                ...dto.event,
                startTimestamp: dto.event.startTimestamp,
            },
        }),
    );

    const resultAction:
        | ReturnType<typeof createEventApiActions.success>
        | ReturnType<typeof createEventApiActions.failure> = yield take([
        createEventApiActions.success,
        createEventApiActions.request,
    ]);

    switch (resultAction.type) {
        case getType(createEventApiActions.success):
            yield put(
                createEventInEditorSucceed({
                    event: resultAction.payload,
                }),
            );
            break;
        case getType(createEventApiActions.failure):
            yield put(createEventInEditorFailed(resultAction.payload));
            break;
        default:
            yield;
    }
}
