import { call, put, select } from '@redux-saga/core/effects';

import { deleteMilestoneApiActions } from '../milestones-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { deleteMilestone } from '../http/delete-milestone';

export function* deleteMilestoneApiSaga(
    action: ReturnType<typeof deleteMilestoneApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        yield call(deleteMilestone, slug, action.payload.id);
        yield put(
            deleteMilestoneApiActions.success({
                id: action.payload.id,
            }),
        );
    } catch (e) {
        yield put(deleteMilestoneApiActions.failure(e.statusText));
    }
}
