import * as yup from 'yup';

export const deceasedFormValidation = () =>
    yup.object({
        name: yup.string().required(),
        birthDate: yup.date().nullable(),
        deathDate: yup.date().nullable(),
        birthPlace: yup.string(),
        deathPlace: yup.string(),
    });
