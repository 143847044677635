import { compose, mapProps } from 'recompose';
import { Typography, withStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

export const PageTitle = compose<TypographyProps, TypographyProps>(
    withStyles((theme) => ({
        root: {
            color: theme.palette.primary.main,
        },
    })),
    mapProps((props) => ({
        ...props,
        align: 'center',
        variant: 'h1',
    })),
)(Typography);
