import { createReducer } from 'typesafe-actions';

import { IGalleryItemSuggestion } from '../../interfaces/gallery-item';

import {
    gallerySuggestionsRequested,
    gallerySuggestionsReceived,
    removeGallerySuggestion,
} from './gallery-suggestions.actions';

export interface IGallerySuggestionsStore {
    galleryItems: IGalleryItemSuggestion[];
}

const initialState: IGallerySuggestionsStore = {
    galleryItems: [],
};

type Actions =
    | ReturnType<typeof gallerySuggestionsRequested>
    | ReturnType<typeof gallerySuggestionsReceived>
    | ReturnType<typeof removeGallerySuggestion>;

export const gallerySuggestionsReducer = createReducer<
    IGallerySuggestionsStore,
    Actions
>(initialState)
    .handleAction(gallerySuggestionsReceived, (state, action) => ({
        ...state,
        galleryItems: action.payload,
    }))
    .handleAction(removeGallerySuggestion, (state, action) => ({
        ...state,
        galleryItems: state.galleryItems.filter(
            (item) => item.suggestionId !== action.payload.id,
        ),
    }));
