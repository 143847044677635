import { apiService } from '../../api.service';
import { ICreatedContact } from '../../../interfaces/contact';
import { IAddNotificationContactDto } from '../dto/add-notification-contact.dto';

export const addNotificationContact = (
    slug: string,
    dto: IAddNotificationContactDto,
): Promise<ICreatedContact> =>
    apiService
        .post(`/memorial_pages/${slug}/participants`, {
            json: {
                participant: dto,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
