import {
    Box,
    FormControl,
    FormHelperText,
    makeStyles,
} from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { createShouldShowError } from '../../../../utils/should-show-error';
import { FormTextField } from '../../../../components/form-text-field/form-text-field';
import { FieldErrorMessage } from '../../../../components/field-error-message/field-error-message';
import { CenterBox } from '../../../../components/center-box/center-box';
import { PrimaryButton } from '../../../../components/primary-button/primary-button';

import { ILoginFormValues } from './login-form-values';
import { loginFormValidation } from './login-form-validation';

interface IOuterProps {
    onSubmit(values: ILoginFormValues): unknown;
    onChange?(values: ILoginFormValues): unknown;
    globalError: string | null;
}

interface IProps extends FormikProps<ILoginFormValues>, IOuterProps {}

const useStyles = makeStyles((theme) => ({
    control: {
        marginBottom: theme.spacing(2),
    },
}));

export const LoginForm = ({
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    globalError,
    onChange,
}: IProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const shouldShowError = createShouldShowError(errors, touched);
    const prevValues = useRef<ILoginFormValues>(values);

    useEffect(() => {
        if (
            prevValues.current.password === values.password &&
            prevValues.current.email === values.email
        ) {
            return;
        }

        if (onChange) {
            onChange(values);
        }

        prevValues.current = values;
    }, [values, onChange]);

    return (
        <form onSubmit={handleSubmit}>
            <FormControl
                className={styles.control}
                fullWidth
                error={shouldShowError('email')}
            >
                <FormTextField
                    error={shouldShowError('email')}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    name="email"
                    label={t('loginForm.emailLabel')}
                    data-testid="login-page-email-input"
                />
                <FieldErrorMessage
                    data-testid="login-page-email-error"
                    name="email"
                />
            </FormControl>
            <FormControl
                className={styles.control}
                fullWidth
                error={shouldShowError('password')}
            >
                <FormTextField
                    error={shouldShowError('password')}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    label={t('loginForm.passwordLabel')}
                    type="password"
                    data-testid="login-page-password-input"
                />
                <FieldErrorMessage
                    data-testid="login-page-password-error"
                    name="password"
                />
            </FormControl>

            {globalError && (
                <Box mb={4}>
                    <FormHelperText error data-testid="login-form-global-error">
                        {globalError}
                    </FormHelperText>
                </Box>
            )}

            <CenterBox>
                <PrimaryButton data-testid="login-page-submit" type="submit">
                    {t('common.login')}
                </PrimaryButton>
            </CenterBox>
        </form>
    );
};

export const ComposedLoginForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, ILoginFormValues>({
        validationSchema: () => loginFormValidation(),
        handleSubmit(values, bag) {
            bag.props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                password: '',
                email: '',
            };
        },
    }),
)(LoginForm);
