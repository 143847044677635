import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    createEventApiActions,
    deleteEventApiActions,
    fetchEventsApiActions,
    updateEventApiActions,
} from '../api-events.actions';

import { createEventApiSaga } from './create-event-api.saga';
import { fetchEventsApiSaga } from './fetch-events-api.saga';
import { deleteEventApiSaga } from './delete-event-api.saga';
import { updateEventApiSaga } from './update-event-api.saga';

export function* apiEventsSaga() {
    yield takeEvery(getType(createEventApiActions.request), createEventApiSaga);
    yield takeEvery(getType(updateEventApiActions.request), updateEventApiSaga);
    yield takeEvery(getType(deleteEventApiActions.request), deleteEventApiSaga);
    yield takeEvery(getType(fetchEventsApiActions.request), fetchEventsApiSaga);
}
