import { apiService } from '../api.service';

import { ILoginSuccessResponseDto } from './dto/login-success-response.dto';
import { LoginErrorCode, LoginErrorsDto } from './dto/login-errors.dto';

export const loginUser = (
    email: string,
    password: string,
): Promise<ILoginSuccessResponseDto> =>
    apiService
        .post(`/sessions`, {
            json: {
                auth: {
                    email,
                    password,
                },
            },
            /**
             * Disable error on non-200 to handle it manually and get error codes
             */
            throwHttpErrors: false,
        })
        .then(async (resp) => {
            if (resp.status === 401) {
                const respBody = await resp.json();

                return Promise.reject(respBody.errors);
            }

            return resp.json();
        })
        .then((resp) => resp.data.attributes)
        .catch((err: LoginErrorsDto | Error) => {
            if (Array.isArray(err)) {
                return Promise.reject(err[0].code);
            }

            return Promise.reject(LoginErrorCode.UNKNOWN);
        });
