import { useSelector } from 'react-redux';

import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';

import { getDeceasedData } from './selectors/get-deceased-data.selector';
import { getUpdateDeceasedStatus } from './selectors/get-update-deceased-status.selector';
import { getUpdateDeceasedStatusMessage } from './selectors/get-update-deceased-status-message.selector';
import { UpdateDeceasedDataStatus } from './deceased-person.store';

export const useDeceasedPersonData = (): {
    deceasedPersonData: IDeceasedPersonData;
    updateDeceasedStatus: UpdateDeceasedDataStatus | null;
    updateDeceasedStatusMessage: string | null;
} => {
    const data = useSelector(getDeceasedData);
    const updateDeceasedStatus = useSelector(getUpdateDeceasedStatus);
    const updateDeceasedStatusMessage = useSelector(
        getUpdateDeceasedStatusMessage,
    );

    return {
        deceasedPersonData: data,
        updateDeceasedStatus,
        updateDeceasedStatusMessage,
    };
};
