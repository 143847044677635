import { takeEvery } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';

import { invitationTokenValidationActions } from '../invitation.actions';

import { invitationTokenValidationSaga } from './invitation-token-validation.saga';

export function* authInvitationSaga() {
    yield takeEvery(
        getType(invitationTokenValidationActions.request),
        invitationTokenValidationSaga,
    );
}
