import { put, take } from '@redux-saga/core/effects';

import { fetchInvitationsApiActions } from '../../../api/invitations/invitations-api.actions';
import { invitationsReceived } from '../invitations.actions';

export function* fetchInvitationsSaga() {
    yield put(fetchInvitationsApiActions.request());

    const resp: ReturnType<
        typeof fetchInvitationsApiActions.success
    > = yield take(fetchInvitationsApiActions.success);

    yield put(invitationsReceived(resp.payload));
}
