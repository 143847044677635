import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    passwordResetApiActions,
    passwordsSetApiActions,
    sendPasswordResetEmailApiActions,
} from '../password.actions';

import { setUserPasswordSaga } from './set-user-password.saga';
import { resetUserPasswordSaga } from './reset-user-password.saga';
import { sendPasswordResetEmailSaga } from './send-password-reset-email.saga';

export function* apiPasswordSaga() {
    yield takeEvery(
        getType(passwordsSetApiActions.request),
        setUserPasswordSaga,
    );

    yield takeEvery(
        getType(passwordResetApiActions.request),
        resetUserPasswordSaga,
    );

    yield takeEvery(
        getType(sendPasswordResetEmailApiActions.request),
        sendPasswordResetEmailSaga,
    );
}
