import { createAsyncAction } from 'typesafe-actions';

import { IGalleryItem } from '../../interfaces/gallery-item';

export const updateGalleryItemOnlineStatusApiActions = createAsyncAction(
    'api/gallery/UPDATE_GALLERY_ITEM_ONLINE_STATUS_REQUESTED',
    'api/gallery/UPDATE_GALLERY_ITEM_ONLINE_STATUS_SUCCEED',
    'api/gallery/UPDATE_GALLERY_ITEM_ONLINE_STATUS_FAILED',
)<{ id: string; status: 'online' | 'offline' }, IGalleryItem, string>();

export const deleteGalleryItemApiActions = createAsyncAction(
    'api/gallery/DELETE_GALLERY_ITEM_REQUESTED',
    'api/gallery/DELETE_GALLERY_ITEM_SUCCEED',
    'api/gallery/DELETE_GALLERY_ITEM_FAILED',
)<{ id: string }, { id: string }, string>();

export const fetchGalleryItemsApiActions = createAsyncAction(
    'api/gallery/FETCH_GALLERY_ITEMS_REQUESTED',
    'api/gallery/FETCH_GALLERY_ITEMS_SUCCEED',
    'api/gallery/FETCH_GALLERY_ITEMS_FAILED',
)<undefined, IGalleryItem[], string>();
