import { createReducer } from 'typesafe-actions';

import { IBackgroundImage } from '../../interfaces/background-image';
import { fetchBackgroundPhotosApiActions } from '../../api/background-photos/background-photos.actions';

import {
    deceasedDisplayImagesReceived,
    deceasedDisplayImagesAvatarReceived,
} from './deceased-display-images.actions';

export interface IDeceasedDisplayImagesStore {
    availableBackgrounds: IBackgroundImage[];
    selectedBackgroundID: string | null;
    avatarSrc: string | null;
}

const initialState: IDeceasedDisplayImagesStore = {
    availableBackgrounds: [],
    selectedBackgroundID: null,
    avatarSrc: null,
};

type Actions =
    | ReturnType<typeof fetchBackgroundPhotosApiActions.success>
    | ReturnType<typeof deceasedDisplayImagesAvatarReceived>
    | ReturnType<typeof deceasedDisplayImagesReceived>;

export const deceasedDisplayImagesReducer = createReducer<
    IDeceasedDisplayImagesStore,
    Actions
>(initialState)
    .handleAction(fetchBackgroundPhotosApiActions.success, (state, action) => {
        return {
            ...state,
            availableBackgrounds: action.payload,
        };
    })
    .handleAction(deceasedDisplayImagesReceived, (state, action) => {
        return {
            ...state,
            avatarSrc: action.payload.avatarSrc,
            selectedBackgroundID: action.payload.backgroundID,
        };
    })
    .handleAction(deceasedDisplayImagesAvatarReceived, (state, action) => {
        return {
            ...state,
            avatarSrc: action.payload,
        };
    });
