import React, { useRef, useState } from 'react';
import {
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../components/secondary-button/secondary-button';
import { Section, ISectionProps } from '../editor/components/section/section';
import { useDateFormatter } from '../utils/use-date-formatter';
import { ConfirmationModal } from '../components/confirmation-modal/confirmation-modal';
import { useIsMobile } from '../../styles/use-is-mobile';
import { Colors } from '../../styles/colors';

import { Event } from './event/event';
import { EventModal } from './event-modal/event-modal';
import { useEvents } from './use-events';
import { IEventDisplay } from './event-display';

export interface IEventsProps extends ISectionProps {}

const useStyles = makeStyles((theme) => ({
    headCell: {
        color: theme.palette.common.black,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        paddingLeft: 0,
    },
}));

export const Events = ({ className }: IEventsProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { getTime, getShortDate } = useDateFormatter();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const isMobile = useIsMobile();

    const {
        events,
        createModalOpen,
        updateModalOpen,
        eventCreationRequested,
        closeEventCreateModalRequested,
        eventUpdateRequested,
        closeEventUpdateModalRequested,
        editedEvent,
        onCreate,
        onUpdate,
        onDelete,
        isCeremonyPrivate,
    } = useEvents();

    const eventToDelete = useRef<string | null>(null);

    const displayEvents = events.map(
        (event): IEventDisplay => ({
            ...event,
            dateDisplay: event.date ? getShortDate(event.date) : '',
            timeDisplay: event.date ? getTime(event.date) : '',
        }),
    );

    const renderDesktopList = () => (
        <Table data-testid="events-table-container">
            <TableHead>
                <TableRow>
                    <TableCell className={styles.headCell}>
                        {t('ceremonyAndReception.tableColumns.title')}
                    </TableCell>
                    <TableCell className={styles.headCell}>
                        {t('ceremonyAndReception.tableColumns.date')}
                    </TableCell>
                    <TableCell className={styles.headCell}>
                        {t('ceremonyAndReception.tableColumns.typeOfEvent')}
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {displayEvents.map((event) => (
                    <Event
                        event={event}
                        key={event.id}
                        onDelete={(id) => {
                            eventToDelete.current = id;
                            setConfirmationModalOpen(true);
                        }}
                        onEdit={() => {
                            eventUpdateRequested(event);
                        }}
                    />
                ))}
            </TableBody>
        </Table>
    );

    const renderMobileList = () => (
        <Box data-testid="events-table-container">
            {displayEvents.map((event, index) => (
                <Box
                    py={2}
                    key={event.id}
                    borderBottom={
                        index !== displayEvents.length - 1
                            ? `1px solid ${Colors.SILVER}`
                            : undefined
                    }
                >
                    <Event
                        event={event}
                        key={event.id}
                        onDelete={(id) => {
                            eventToDelete.current = id;
                            setConfirmationModalOpen(true);
                        }}
                        onEdit={() => {
                            eventUpdateRequested(event);
                        }}
                    />
                </Box>
            ))}
        </Box>
    );

    return (
        <Section
            title={t('ceremonyAndReception.events')}
            description={t('ceremonyAndReception.eventsDescription')}
            className={className}
        >
            <EventModal
                onClose={closeEventCreateModalRequested}
                onCreate={onCreate}
                open={createModalOpen}
                title={t('ceremonyAndReception.newEventModalTitle')}
                isCeremonyPrivate={isCeremonyPrivate}
            />
            <EventModal
                edit
                initialValues={
                    editedEvent
                        ? {
                              ...editedEvent,
                              time: editedEvent.date,
                          }
                        : null
                }
                onClose={closeEventUpdateModalRequested}
                onUpdate={onUpdate}
                open={updateModalOpen}
                title={t('ceremonyAndReception.editEventModalTitle')}
                isCeremonyPrivate={isCeremonyPrivate}
            />
            {events.length > 0 && isMobile && renderMobileList()}
            {events.length > 0 && !isMobile && renderDesktopList()}
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <SecondaryButton
                    data-testid="events-add-event"
                    onClick={eventCreationRequested}
                >
                    {t('ceremonyAndReception.addEventButton')}
                </SecondaryButton>
            </Box>
            <ConfirmationModal
                open={confirmationModalOpen}
                onConfirm={() => {
                    onDelete(eventToDelete.current!);
                    setConfirmationModalOpen(false);
                    eventToDelete.current = null;
                }}
                onCancel={() => {
                    setConfirmationModalOpen(false);
                }}
            />
        </Section>
    );
};
