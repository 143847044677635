import { getType } from 'typesafe-actions';
import { put, take, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { memorialPageSlugReceived } from '../../../../meta/model/meta.actions';
import { updateMemorialPageCreationStepApiActions } from '../../../../api/memorial-creation-step/memorial-page-creation-step-api.actions';
import { MemorialPageCreationStep } from '../../../../interfaces/memorial-page-creation-step';
import { resolveRouteFromMemorialCreationStep } from '../../../../routing/resolve-route-from-page-creation-step';
import { getTokens, saveTokens } from '../../../../api/auth-token-storage';
import { vgFormSubmitted, vgSubmitActions } from '../wizard-vg.actions';
import { mapVgFormToDTO } from '../../../../model/vg/mappers/map-vg-form-to-dto';
import { updateVgApiActions } from '../../../../api/vg/vg-api.actions';

export function* submitVgFormSaga(action: ReturnType<typeof vgFormSubmitted>) {
    const formData = action.payload;
    const dto = mapVgFormToDTO(formData);

    yield put(updateVgApiActions.request(dto));

    const result:
        | ReturnType<typeof updateVgApiActions.success>
        | ReturnType<typeof updateVgApiActions.failure> = yield take([
        updateVgApiActions.success,
        updateVgApiActions.failure,
    ]);

    /**
     * Save old tokens, which are namespaced to stale slug
     */
    const tokens = yield call(getTokens);

    switch (result.type) {
        case getType(updateVgApiActions.success):
            // eslint-disable-next-line no-case-declarations
            const newSlug = result.payload.data.attributes.slug;

            yield put(
                memorialPageSlugReceived({
                    slug: newSlug,
                }),
            );

            yield call(saveTokens, tokens.accessToken, tokens.refreshToken);

            yield put(vgSubmitActions.success());

            yield put(
                updateMemorialPageCreationStepApiActions.request({
                    step: MemorialPageCreationStep.PHOTOS,
                }),
            );

            yield take(updateMemorialPageCreationStepApiActions.success);

            yield put(
                push(
                    resolveRouteFromMemorialCreationStep(
                        newSlug,
                        MemorialPageCreationStep.PHOTOS,
                    ),
                ),
            );

            /**
             * TODO: Handle failed step update
             */
            break;
        case getType(updateVgApiActions.failure):
            yield put(vgSubmitActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
