import { InvitationDto } from '../dto/invitation.dto';
import { IInvitation } from '../../../interfaces/invitation';

export const mapInvitationDtoToInvitation = (
    dto: InvitationDto,
): IInvitation => {
    const invitation: IInvitation = {
        id: dto.id,
        email: dto.attributes.email,
        isUserPersisted: dto.attributes.isUserPersisted,
        memorialPageSlug: dto.attributes.memorialPageSlug,
        phone: dto.attributes.phone,
    };

    if (dto.attributes.memorialPageCurrentWizardStep) {
        invitation.currentWizardStep =
            dto.attributes.memorialPageCurrentWizardStep;
    }

    return invitation;
};
