import { createReducer } from 'typesafe-actions';

import { IDictionaryEntry } from '../../interfaces/dictionary-entry';

import {
    notificationMessageExamplesReceived,
    notificationMessageTextReceived,
    setNotificationMessageStatus,
} from './notification-messages-model.actions';

export enum NotificationMessageStatus {
    INAPPROPRIATE_WORDS = 'INAPPROPRIATE_WORDS',
}

export interface INotificationMessageStore {
    messageText: string;
    examples: IDictionaryEntry[];
    examplesFetched: boolean;
    messageStatus: NotificationMessageStatus | null;
}

const initialState: INotificationMessageStore = {
    examples: [],
    messageText: '',
    examplesFetched: false,
    messageStatus: null,
};

type Actions =
    | ReturnType<typeof notificationMessageTextReceived>
    | ReturnType<typeof notificationMessageExamplesReceived>
    | ReturnType<typeof setNotificationMessageStatus>;

export const notificationsMessageReducer = createReducer<
    INotificationMessageStore,
    Actions
>(initialState)
    .handleAction(notificationMessageTextReceived, (state, action) => ({
        ...state,
        messageText: action.payload.text,
    }))
    .handleAction(notificationMessageExamplesReceived, (state, action) => ({
        ...state,
        examples: action.payload.examples,
        examplesFetched: true,
    }))
    .handleAction(setNotificationMessageStatus, (state, action) => ({
        ...state,
        messageStatus: action.payload,
    }));
