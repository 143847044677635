import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { getTokenFromUrl } from './utils/get-token-from-url';
import { invitationTokenValidationActions } from './auth/invitation/model/invitation.actions';

export const withInvitationTokenValidation = <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        /**
         * Run this effect only if use is in root route which takes token.
         * Kill it, if user is in other route
         */
        if (location.pathname !== '/') {
            return;
        }

        const token = getTokenFromUrl();

        if (!token) {
            dispatch(
                invitationTokenValidationActions.failure('Token not provided'),
            );

            return;
        }

        dispatch(invitationTokenValidationActions.request({ token }));
    }, [dispatch, location.pathname]);

    return <Component {...props} />;
};
