import { put, call, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { generateObituaryTextApiActions } from '../obituary-api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { ObituaryStatus } from '../../../model/obituary/obituary.store';
import { generateObituary } from '../http/generate-obituary';

export function* generateObituaryApiSaga(
    action: ReturnType<typeof generateObituaryTextApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    try {
        const resp = yield call(generateObituary, {
            slug,
            dto: action.payload,
        });

        if (!resp.errors) {
            yield put(
                generateObituaryTextApiActions.success({
                    obituaryText: resp.data.content || '',
                }),
            );
        } else if (find(resp.errors, { code: 'not_decent' })) {
            yield put(
                generateObituaryTextApiActions.failure({
                    obituaryStatus: ObituaryStatus.INAPPROPRIATE_WORDS,
                }),
            );
        } else {
            yield put(
                generateObituaryTextApiActions.failure({
                    obituaryStatus: ObituaryStatus.GENERATE_ERROR,
                }),
            );
        }
    } catch {
        yield put(
            generateObituaryTextApiActions.failure({
                obituaryStatus: ObituaryStatus.GENERATE_ERROR,
            }),
        );
    }
}
