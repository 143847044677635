import { call, put, select } from 'redux-saga/effects';

import { fetchNotificationContactsApiActions } from '../contacts-api.actions';
import { fetchNotificationContacts } from '../http/fetch-notification-contacts';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { notificationContactsReceived } from '../../../model/notification-contacts/notification-contacts.actions';
import { ICreatedContact } from '../../../interfaces/contact';

export function* fetchNotificationContactsSaga(
    action: ReturnType<typeof fetchNotificationContactsApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const response: ICreatedContact[] = yield call(
            fetchNotificationContacts,
            slug,
        );
        yield put(notificationContactsReceived({ contacts: response }));
        yield put(fetchNotificationContactsApiActions.success(response));
    } catch (err) {
        yield put(fetchNotificationContactsApiActions.failure(err));
    }
}
