import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { fetchParticipantsApiActions } from '../participants-api.actions';

import { fetchParticipantsSaga } from './fetch-participants.saga';

export function* participantsApiSaga() {
    yield takeEvery(
        getType(fetchParticipantsApiActions.request),
        fetchParticipantsSaga,
    );
}
