import { createSelector } from 'reselect';

import { RootStore } from '../../store/store';

export const getMilestonesStore = (store: RootStore) => store.milestones;

const getMilestones = createSelector(
    getMilestonesStore,
    (store) =>
        store.milestones.sort((m1, m2) => {
            if (m1.year < m2.year) {
                return 1;
            }
            if (m1.year === m2.year) {
                if (m1.month && m2.month) {
                    return m1.month < m2.month ? 1 : -1;
                }
                if (m1.month && !m2.month) {
                    return 1;
                }
                if (!m1.month && m2.month) {
                    return -1;
                }
                return -1;
            }
            return -1;
        }),
);

export const MilestonesSelectors = {
    getMilestones,
};
