import React, { ChangeEvent } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Section,
    ISectionProps,
} from '../../editor/components/section/section';
import { SettingOptionBlock } from '../components/setting-option-block/setting-option-block';
import { getPrivacyMode } from '../model/selectors/get-privacy-mode.selector';
import { changePrivacyModeRequested } from '../model/settings.actions';
import { PrivacyMode } from '../../interfaces/privacy-mode';

export interface IPrivacySettingsProps extends ISectionProps {}

export const PrivacySettings = (props: IPrivacySettingsProps) => {
    const { t } = useTranslation();
    const privacyMode = useSelector(getPrivacyMode);
    const dispatch = useDispatch();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newMode = event.target.value as PrivacyMode;

        dispatch(changePrivacyModeRequested(newMode));
    };

    return (
        <Section title={t('privacySettings.title')} {...props}>
            <Box mt={4}>
                <Box mb={4}>
                    <SettingOptionBlock
                        data-testid="privacy-memorial-public"
                        checked={PrivacyMode.PUBLIC === privacyMode}
                        onChange={onChange}
                        value={PrivacyMode.PUBLIC}
                        headline={t('privacySettings.public')}
                        description={t('privacySettings.publicDescription')}
                    />
                </Box>
                <Box mb={4}>
                    <SettingOptionBlock
                        data-testid="privacy-memorial-semiprivate"
                        headline={t('privacySettings.semiPrivate')}
                        value={PrivacyMode.SEMI_PRIVATE}
                        description={t(
                            'privacySettings.semiPrivateDescription',
                        )}
                        onChange={onChange}
                        checked={PrivacyMode.SEMI_PRIVATE === privacyMode}
                    />
                </Box>
                <SettingOptionBlock
                    data-testid="privacy-memorial-private"
                    headline={t('privacySettings.private')}
                    value={PrivacyMode.PRIVATE}
                    description={t('privacySettings.privateDescription')}
                    checked={PrivacyMode.PRIVATE === privacyMode}
                    onChange={onChange}
                />
            </Box>
        </Section>
    );
};
