import React from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import { BlueHeadline } from '../../components/blue-headline/blue-headline';
import { ConnectedMilestoneForm } from '../milestone-form/milestone-form';
import { IMilestoneFormValues } from '../model/milestone-form-values';
import { useIsMobile } from '../../../styles/use-is-mobile';

type Props = Omit<DialogProps, 'onSubmit' & 'onClose'> & {
    initialValues?: IMilestoneFormValues & { id: string } | null;
    onCreate?: (values: IMilestoneFormValues) => unknown;
    onUpdate?: (values: IMilestoneFormValues, id: string) => unknown;
    /**
     * Override Dialog's onClose since it has some hacky stuff and
     * we use onClose on button too
     */
    onClose(): unknown;
    title: string;
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export const MilestoneModal = ({
    initialValues,
    onCreate,
    onUpdate,
    onClose,
    title,
    ...props
}: Props) => {
    const mobile = useIsMobile();
    const styles = useStyles();

    return (
        <Dialog
            fullScreen={mobile}
            fullWidth
            maxWidth="md"
            {...props}
            onClose={onClose}
        >
            <Box
                data-testid="milestone-modal"
                px={mobile ? 4 : 12}
                pt={6.5}
                pb={3}
                position="relative"
            >
                <DialogTitle disableTypography>
                    <BlueHeadline align="center">{title}</BlueHeadline>
                </DialogTitle>
                <ConnectedMilestoneForm
                    initialValues={initialValues}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                />
                <IconButton className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Dialog>
    );
};
